import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { CloseSidenav, SetLayout } from '../layout/layout.actions';
import { LayoutStateModel } from '../layout/layout.state';
import { LOGGER, Logger } from '../logging/logger.service';
import { ProfileType } from '../shared/models/profile.model';
import { AuthenticationService } from '../shared/services/authentication.service';
import { RegisterStoreService } from '../shared/services/register-store.service';
import { isDefined } from '../shared/utilities/utils';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    @Inject(LOGGER) private logger: Logger,
    private authenticationService: AuthenticationService,
    private registerStoreService: RegisterStoreService,
    private router: Router,
    private dialogRef: MatDialog,
    private store: Store
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.needsToken(request)) {
      const token = this.authenticationService.getCachedAuthToken();
      if (token) {
        request = this.addToken(request, token);
      }
    }

    return next.handle(request).pipe(
      timeout(30000),
      tap((event) => {
        if (event instanceof HttpResponse) {
          var errorRequests = this.authenticationService.getErrorRequests();
          var index = errorRequests.indexOf(request.url);
          if (index > -1 && event.status.toString()[0] === '2') {
            var newErrorRequests = [...errorRequests];
            var newErrorList = newErrorRequests.splice(index, 1);
            this.authenticationService.updateErrorRequests(newErrorList);
          }
        }
      }),
      catchError((err: any) => {
        return this.handleError(request, next, err);
      })
    );
  }

  private needsToken(request: HttpRequest<any>): boolean {
    return request.url.indexOf('assets') < 0;
  }

  private handleError(request: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent<any>> {
    this.resetLayout();

    //If accesstoken fail to connect to the API, it will return status 0 (spinner never stop.)
    if (error.status === 0 && request.url.indexOf('/identity/accesstoken') > -1) {
      this.logger.error('TokenInterceptor handleError status 0 ' + request.url + ' ' + error.status);
      this.router.navigate(['/message', 'error']);
      return EMPTY;
    }

    // If hubspot, logger or assets we do not handle this as an error.
    // iOS gets a status of 0 when the requests gets cancelled.
    if (
      error.status === 0 ||
      request.url.indexOf('api.hsforms.com') > -1 ||
      request.url.indexOf('api/logger') > -1 ||
      request.url.indexOf('assets/') > -1
    ) {
      return EMPTY;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        return this.handle401Error(request, next, error);
      } else {
        return this.handleOtherErrors(request, error);
      }
    }

    this.clearStateStore(request);
    this.logger.error('TokenInterceptor handleError ' + request.url + ' ' + error.status);
    this.router.navigate(['/message', 'error']);
    return EMPTY;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent<any>> {
    // If the user token expired, set the store to a logged out state and route to the home screen.
    this.authenticationService.updateIsLoggedIn(false);
    this.authenticationService.updateIsInitialized(false);

    //User must be logged in
    const url = window.location.href;
    if (this.authenticationService.shouldBeLoggedInUrl(url)) {
      var errorRequests = this.authenticationService.getErrorRequests();

      if (errorRequests.length > 0) {
        this.authenticationService.updateErrorRequests([]);
        this.router.navigate(['/message', 'error']);
        return EMPTY;
      } else {
        var newErrorRequests = [...errorRequests];
        newErrorRequests.push(request.url);
        this.authenticationService.updateErrorRequests(newErrorRequests);
      }

      this.authenticationService.updateUrl(window.location.href);
      this.router.navigate(['/initialize']);
      return EMPTY;
    }

    this.router.navigate(['/home']);
    return EMPTY;
  }

  private handleOtherErrors(request: HttpRequest<any>, error: any): Observable<HttpEvent<any>> {
    // If registration fails, clear the state store. User should not be able to continue.
    this.logger.error('TokenInterceptor handleOtherErrors ' + request.url + ' ' + error.status);
    this.clearStateStore(request);

    if (this.isValidationError(error)) {
      this.clearStateStore(request);
      return throwError(error.error.Message);
    }

    if (error.status === 503) {
      this.clearStateStore(request);
      this.router.navigate(['/message', 'maintenance']);
      return EMPTY;
    }

    if (request.url.indexOf('registration/register') > -1) {
      if (this.registerStoreService.getProfileType() === ProfileType.minor) {
        this.router.navigate(['/minor', 'complete']);
        this.registerStoreService.clearRegistrationStore();
      } else {
        this.router.navigate(['/message', 'registration-error']);
        this.authenticationService.clearAllStateStores();
      }
      return EMPTY;
    }

    this.clearStateStore(request);
    this.router.navigate(['/message', 'error']);
    return EMPTY;
  }

  private clearStateStore(request: HttpRequest<any>) {
    if (
      request.url.indexOf('/api/registration/register') > -1 ||
      request.url.indexOf('/api/registration/identityverify') > -1
    ) {
      this.authenticationService.clearAllStateStores();
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private isValidationError(err: HttpErrorResponse): boolean {
    if (isDefined(err.error?.Origin) && err.error.Origin === 'EasyID' && err.error.ErrorType === 'Validation') {
      return true;
    }
    return false;
  }

  private resetLayout() {
    this.dialogRef.closeAll();
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: false,
      pageTitle: '',
      actionLeft: null,
      actionRight: null
    };
    this.store.dispatch(new SetLayout(layoutState));
    this.store.dispatch(new CloseSidenav());
  }
}
