import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ThriveComponent } from './thrive.component';
@UntilDestroy()
@Component({
  selector: 'eid-thrive-mobile',
  templateUrl: './thrive.mobile.component.html',
  styleUrls: ['./thrive.mobile.component.scss']
})
export class ThriveMobileComponent extends ThriveComponent {}
