import { Component, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { groupBy, isNil } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay, startWith, tap } from 'rxjs/operators';

import { StaticCountry, StaticCountryModel } from '../../../registration/models/country.model';
import { ComplianceCategory, ProductDetailModel, ProductType } from '../../../registration/models/product.model';
import { CountryIdEnum, TaxTypeEnum, TaxTypeIdEnum } from '../../constants/enums';
import { SelectorList, SelectorListItem } from '../../models/selector-list-item.model';
import { TaxDataModel, TaxIdentificationType } from '../../models/tax.model';
import { ProductService } from '../../services/product.service';
import { StaticDataService } from '../../services/static-data.service';
import { fieldInvalid, fieldSummaryError, fieldTypeError, fieldValid } from '../../utilities/utils';
import { TaxValidator } from '../../validators/tax.validator';

export class TaxDialogData {
  residenceCountryId: number;
  productType: ProductType;
  userRegistered: boolean;
  countrySelectList: SelectorList;
}

@UntilDestroy()
@Component({
  selector: 'eid-crs-add-tax',
  templateUrl: './add-crs-tax.component.html',
  styleUrls: ['./add-crs-tax.component.scss']
})
export class AddCrsTaxComponent {
  public residenceCountryId: number;
  public productType: ProductType;
  public userRegistered: boolean;
  public countrySelectList: SelectorList;

  public addTaxForm: FormGroup;
  public countries$: Observable<StaticCountryModel>;
  public taxIdentificationTypes$: Observable<TaxDataModel>;

  public taxIdentificationTypesSelectList$: Observable<SelectorList>;
  public residence: StaticCountry;
  public country: SelectorListItem;
  public taxType: SelectorListItem;
  public countries: StaticCountry[];

  public isSubmitted: boolean;
  public showReason$: Observable<boolean>;
  public typeSelectedDescription: string;

  private fb: FormBuilder;
  public fieldInvalid = fieldInvalid;
  public fieldValid = fieldValid;
  public fieldTypeError = fieldTypeError;
  public fieldSummaryError = fieldSummaryError;

  private staticDataService: StaticDataService;
  private productService: ProductService;
  private productDetail: ProductDetailModel;

  constructor(
    protected injector: Injector,
    public dialogRef: MatDialogRef<AddCrsTaxComponent>,
    @Inject(MAT_DIALOG_DATA) public config: TaxDialogData
  ) {
    this.createServices(injector);
    this.residenceCountryId = config.residenceCountryId;
    this.productType = config.productType;
    this.userRegistered = config.userRegistered;
    this.countrySelectList = config.countrySelectList;
    this.productDetail = this.productService.getProductDetail(this.productType);

    this.createForm();
    this.loadData();
  }

  private createServices(injector: Injector) {
    this.fb = injector.get(FormBuilder);
    this.staticDataService = injector.get(StaticDataService);
    this.productService = injector.get(ProductService);
  }

  public createForm(): void {
    this.addTaxForm = this.fb.group(
      {
        taxCountry: ['', [Validators.required]],
        taxIdentificationType: ['', [Validators.required]],
        taxNumber: [''],
        taxNoNumberReason: ['']
      },
      {
        validators: [TaxValidator.ResidenceComplianceValidate]
      }
    );
  }

  public save() {
    this.isSubmitted = true;
    this.addTaxForm.markAllAsTouched();

    if (this.addTaxForm.valid) {
      const data = {
        countryId: this.addTaxForm.controls.taxCountry.value,
        taxIdentificationTypeId: this.addTaxForm.controls.taxIdentificationType.value,
        taxIdentificationNumber: this.addTaxForm.controls.taxNumber.value,
        taxNoNumberReason: this.addTaxForm.controls.taxNoNumberReason.value
      };
      this.dialogRef.close(data);
    }
  }

  private loadData(): void {
    this.countries$ = this.staticDataService.getCountries().pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    this.taxIdentificationTypes$ = this.staticDataService
      .getTaxIdentificationTypes()
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    var taxCountryChange$;

    if (
      this.productDetail.complianceCategory === ComplianceCategory.combined ||
      this.residenceCountryId !== CountryIdEnum.southAfrica
    ) {
      taxCountryChange$ = this.addTaxForm.get('taxCountry').valueChanges.pipe(startWith(this.residenceCountryId));
    } else {
      taxCountryChange$ = this.addTaxForm.get('taxCountry').valueChanges.pipe();
    }

    this.taxIdentificationTypesSelectList$ = combineLatest([
      this.countries$,
      this.taxIdentificationTypes$,
      taxCountryChange$
    ]).pipe(
      tap(([countries, taxDataModel, taxCountryChange]: [StaticCountryModel, TaxDataModel, number]) => {
        this.residence = countries.countries.find((c) => c.countryId === this.residenceCountryId);
        this.countries = countries.countries;

        var selectedCountry = this.getSelectedCountry(taxCountryChange, countries);

        if (selectedCountry !== null) {
          this.country = new SelectorListItem(selectedCountry.countryName, selectedCountry.countryId);
          this.addTaxForm.get('taxCountry').patchValue(selectedCountry.countryId, { emitEvent: false, onlySelf: true });
        }

        this.taxType = new SelectorListItem('', '');
        this.addTaxForm.get('taxIdentificationType').patchValue('');
      }),
      map(([countries, taxDataModel, taxCountryChange]: [StaticCountryModel, TaxDataModel, number]) => {
        var selectedCountry = this.getSelectedCountry(taxCountryChange, countries);
        return this.getGroupedList(selectedCountry, taxDataModel.taxIdentificationTypes);
      }, untilDestroyed(this))
    );

    this.showReason$ = this.addTaxForm.get('taxIdentificationType').valueChanges.pipe(
      startWith(false),
      map((taxIdType) => {
        if (taxIdType === TaxTypeIdEnum.notAvailable) {
          this.typeSelectedDescription = TaxTypeEnum.notAvailable.toLowerCase();
          return true;
        }
        if (taxIdType === TaxTypeIdEnum.notIssued) {
          this.typeSelectedDescription = TaxTypeEnum.notIssued.toLowerCase();
          return true;
        }
        return false;
      })
    );
  }

  private getSelectedCountry(taxCountryChange: any, countries: StaticCountryModel) {
    var selectedCountry;
    if (isNil(taxCountryChange) || taxCountryChange === 0 || taxCountryChange === '') {
      if (
        this.productDetail.complianceCategory === ComplianceCategory.combined ||
        this.residenceCountryId !== CountryIdEnum.southAfrica
      ) {
        selectedCountry = countries.countries.filter((c) => c.countryId === this.residenceCountryId)[0];
      } else {
        selectedCountry = null;
      }
    } else {
      selectedCountry = countries.countries.filter((c) => c.countryId === taxCountryChange)[0];
    }
    return selectedCountry;
  }

  private getGroupedList(country: any, taxIdentificationTypes: TaxIdentificationType[]) {
    let filteredIdTypes;
    if (country === null) {
      return null;
    } else {
      filteredIdTypes = this.staticDataService.getTaxIdTypesByCountry(taxIdentificationTypes, country.countryCode2);
    }

    var taxIdTypeSelectList = filteredIdTypes.map((c) => ({
      label: c.description,
      value: c.taxIdentificationTypeId
    })) as SelectorListItem[];

    const groupedList = groupBy(taxIdTypeSelectList, (x) => x.label.charAt(0).toUpperCase());
    //Push to the end of the list.
    groupedList['ZZ'] = [
      {
        label: TaxTypeEnum.notIssued,
        value: TaxTypeIdEnum.notIssued
      },
      {
        label: TaxTypeEnum.notAvailable,
        value: TaxTypeIdEnum.notAvailable
      }
    ];

    return groupedList;
  }

  public updateFormField(fieldName: string, item) {
    this.addTaxForm.get(fieldName).patchValue(item.value);
  }
}
