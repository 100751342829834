import { Component, OnInit, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';

import { SafeUrl } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ProductDisplayModel } from '../../../registration/models/product.model';
import { UserState } from '../../../store/user.state';
import { CurrentUser } from '../../models/current-user.model';
import { ProductService } from '../../services/product.service';
import { UserStoreService } from '../../services/user-store.service';

export interface DashboardTile {
  icon: string;
  class: string;
  title: string;
  description: string;
  target?: string;
  routerLink?: string[];
  url?: SafeUrl;
}

@Component({
  template: `
    <eid-products-desktop *eidBreakpoint="'desktop'"></eid-products-desktop>
    <eid-products-mobile *eidBreakpoint="'mobile'"></eid-products-mobile>
  `
})
export class ProductsContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class ProductsComponent implements OnInit {
  @Select(UserState.currentUser) public currentUser$: Observable<CurrentUser>;

  @Input() public size: 'small' | 'large' = 'small';

  public products$: Observable<ProductDisplayModel[]>;

  constructor(private productService: ProductService, private userStoreService: UserStoreService) {}

  public ngOnInit(): void {
    const currentUser = this.userStoreService.getUser();
    this.products$ = of(this.productService.getQualifyingProductDisplay(currentUser));
  }
}
