import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { EngagementModule } from './engagement/engagement.module';
import { BaseUrlInterceptor } from './interceptors/baseUrl.interceptor';
import { MockInterceptor } from './interceptors/mock.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { LayoutModule } from './layout/layout.module';
import { LayoutState } from './layout/layout.state';
import { CustomIconService } from './shared/services/custom-icon.service';
import { EngagementService } from './shared/services/engagement.service';
import { InitializeService } from './shared/services/initialize.service';
import { SharedModule } from './shared/shared.module';
import { AuthenticationState } from './store/authentication.state';
import { ComplianceState } from './store/compliance.state';
import { EngagementState } from './store/engagement.state';
import { GameState } from './store/game.state';
import { RegistrationState } from './store/registration.state';
import { UserState } from './store/user.state';

function initializeApp(appConfig: AppConfig) {
  return () => appConfig.loadConfiguration();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    EngagementModule,
    SharedModule,

    NgxsModule.forRoot(
      [AuthenticationState, RegistrationState, UserState, ComplianceState, GameState, EngagementState, LayoutState],
      {
        compatibility: {
          strictContentSecurityPolicy: true
        },
        developmentMode: !environment.production
      }
    ),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.SessionStorage,
      key: ['authenticate', 'register', 'currentuser', 'compliance', 'game', 'engagement']
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production })
  ],
  providers: [
    CustomIconService,
    InitializeService,
    EngagementService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MockInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
