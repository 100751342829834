import { AbstractControl, FormArray } from '@angular/forms';
import { isNil } from 'lodash';

import { CountryIdEnum, TaxTypeIdEnum } from '../constants/enums';
import { ValidatorPattern } from '../constants/validator-patterns';
import { isDefined } from '../utilities/utils';

export class TaxValidator {
  static NoDataValidate(control: AbstractControl) {
    const taxRegistered = control.get('taxRegistered').value;
    const noDataReason = control.get('noDataReason').value;
    control.get('noDataReason').setErrors(null);

    if (!taxRegistered && noDataReason.trim() === '') {
      control.get('noDataReason').setErrors({ required: true });
    } else if (!taxRegistered && !ValidatorPattern.startEndSpaces.test(noDataReason)) {
      control.get('noDataReason').setErrors({ startEndSpaces: true });
    }
  }

  static TaxNumberValidate(control: AbstractControl) {
    const taxRegistered = control.get('taxRegistered')?.value;
    if (!taxRegistered) {
      return;
    }

    TaxValidator.TaxNumberRegisteredValidate(control);
  }

  static TaxNumberRegisteredValidate(control: AbstractControl) {
    const taxCountry = control.get('taxCountry');
    const taxIdentificationType = control.get('taxIdentificationType');
    const taxNumber = control.get('taxNumber');
    taxNumber.setErrors(null);

    if (isNil(taxNumber) || taxNumber.value.trim() === '') {
      control.get('taxNumber').setErrors({ required: true });
      return;
    }

    if (isDefined(taxCountry) && isDefined(taxIdentificationType)) {
      if (!TaxValidator.validateSAIDnumber(taxIdentificationType, taxNumber)) {
        return;
      }

      if (!ValidatorPattern.startEndSpaces.test(taxNumber.value)) {
        control.get('taxNumber').setErrors({ startEndSpaces: true });
        return;
      }

      if (!ValidatorPattern.taxNumbers.test(taxNumber.value)) {
        control.get('taxNumber').setErrors({ pattern: true });
        return;
      }
    }
  }

  static NoDataComplianceValidate(countryOfResidenceId: number) {
    return (control: AbstractControl) => {
      const taxRegistered = control.get('taxRegistered').value;
      const noDataReason = control.get('noDataReason').value;
      control.get('noDataReason').setErrors(null);

      if (countryOfResidenceId !== CountryIdEnum.southAfrica) {
        if (!taxRegistered && noDataReason.trim() === '') {
          control.get('noDataReason').setErrors({ required: true });
        } else if (!taxRegistered && !ValidatorPattern.startEndSpaces.test(noDataReason)) {
          control.get('noDataReason').setErrors({ startEndSpaces: true });
        }
      }
    };
  }

  static USTinValidate(control: AbstractControl) {
    const hasTinNumber = control.get('hasTinNumber').value;
    const tinNumber = control.get('tinNumber').value?.trim();
    const tinType = control.get('tinType').value;

    if (hasTinNumber === true) {
      if (isNil(tinNumber) || tinNumber === '') {
        control.get('tinNumber').setErrors({ required: true });
      }
      if (isNil(tinType) || tinType === '') {
        control.get('tinType').setErrors({ required: true });
      }
      if (tinNumber.length > 9) {
        control.get('tinNumber').setErrors({ length: true });
      }
      if (!ValidatorPattern.taxNumbers.test(tinNumber)) {
        control.get('tinNumber').setErrors({ pattern: true });
      }
    } else {
      control.get('tinNumber').setErrors(null);
      control.get('tinType').setErrors(null);
    }
  }

  static ResidenceListValidate(control: AbstractControl) {
    const taxRegistered = control.get('taxRegistered').value;
    const formResidences = (control.get('residences') as FormArray).controls;

    formResidences.forEach((residence) => {
      const taxCountry = residence.get('taxCountry');
      const taxIdentificationType = residence.get('taxIdentificationType');
      const taxNumber = residence.get('taxNumber');
      taxCountry.setErrors(null);
      taxIdentificationType.setErrors(null);
      taxNumber.setErrors(null);

      if (taxRegistered) {
        // Tax Residence
        TaxValidator.validateThisResidence(taxCountry, taxIdentificationType, taxNumber);
      }
    });
  }

  static ResidenceComplianceValidate(control: AbstractControl) {
    const taxCountry = control.get('taxCountry');
    const taxIdentificationType = control.get('taxIdentificationType');
    const taxNumber = control.get('taxNumber');
    const reason = control.get('taxNoNumberReason');
    taxCountry.setErrors(null);
    taxIdentificationType.setErrors(null);
    taxNumber.setErrors(null);
    reason.setErrors(null);

    // Tax Residence
    TaxValidator.validateComplianceResidence(taxCountry, taxIdentificationType, taxNumber, reason);
  }

  static EW8TaxNumber() {
    return (control: AbstractControl) => {
      const taxNumber = control;

      //Tax Number
      if (isDefined(taxNumber)) {
        // All Tax Types:
        // First check if there is at least one character
        if (taxNumber.value !== '') {
          if (!ValidatorPattern.startEndSpaces.test(taxNumber.value)) {
            taxNumber.setErrors({ startEndSpaces: true });
            return { startEndSpaces: true };
          }
          if (!ValidatorPattern.taxNumbers.test(taxNumber.value)) {
            control.setErrors({ pattern: true });
            return { pattern: true };
          }
        }
      }
    };
  }

  private static validateThisResidence(
    taxCountry: AbstractControl<any, any>,
    taxIdentificationType: AbstractControl<any, any>,
    taxNumber: AbstractControl<any, any>
  ) {
    if (isDefined(taxCountry)) {
      if (taxCountry.value === null || taxCountry.value === '') {
        taxCountry.setErrors({ required: true });
      }
    }

    // Tax ID Type
    if (isDefined(taxIdentificationType)) {
      if (taxIdentificationType.value === null || taxIdentificationType.value === '') {
        taxIdentificationType.setErrors({ required: true });
      }
    }

    // Tax Number
    if (isDefined(taxNumber)) {
      // SA Tax No. Type:
      TaxValidator.validateSAIDnumber(taxIdentificationType, taxNumber);

      // All Tax Types:
      // First check if there is at least one character
      if (!ValidatorPattern.startEndSpaces.test(taxNumber.value)) {
        taxNumber.setErrors({ startEndSpaces: true });
      }

      if (taxNumber.value === '') {
        taxNumber.setErrors({ required: true });
      }

      if (!ValidatorPattern.taxNumbers.test(taxNumber.value)) {
        taxNumber.setErrors({ pattern: true });
      }
    }
  }

  private static validateSAIDnumber(
    taxIdentificationType: AbstractControl<any, any>,
    taxNumber: AbstractControl<any, any>
  ): boolean {
    if (
      isDefined(taxIdentificationType) &&
      (taxIdentificationType.value === 1 || taxIdentificationType.value?.value === 1)
    ) {
      if (!ValidatorPattern.tax.test(taxNumber.value)) {
        taxNumber.setErrors({ startWith: true });
        return false;
      } else if (taxNumber.value.indexOf(' ') >= 0) {
        taxNumber.setErrors({ spaces: true });
        return false;
      } else if (taxNumber.value.toString().replace(/\s+/g, '').length !== 10) {
        taxNumber.setErrors({ length: true });
        return false;
      }
    }
    return true;
  }

  private static validateComplianceResidence(
    taxCountry: AbstractControl,
    taxIdentificationType: AbstractControl,
    taxNumber: AbstractControl,
    reason: AbstractControl
  ) {
    if (isDefined(taxCountry)) {
      if (taxCountry.value === null || taxCountry.value === '') {
        taxCountry.setErrors({ required: true });
      }
    }

    // Tax ID Type
    if (isDefined(taxIdentificationType)) {
      if (taxIdentificationType.value === null || taxIdentificationType.value === '') {
        taxIdentificationType.setErrors({ required: true });
      }

      //Reason required - if taxType is one that needs a reason
      if (TaxValidator.isReason(taxIdentificationType.value)) {
        if (isNil(reason.value) || reason.value.trim() === '') {
          reason.setErrors({ required: true });
        }
      } else {
        if (isNil(taxNumber.value) || taxNumber.value.trim() === '') {
          taxNumber.setErrors({ required: true });
        }

        if (!ValidatorPattern.taxNumbers.test(taxNumber.value)) {
          taxNumber.setErrors({ pattern: true });
        }
      }
    }

    // Tax Number
    if (isDefined(taxNumber) && taxNumber.value.trim() !== '') {
      // SA Tax No. Type:
      TaxValidator.validateSAIDnumber(taxIdentificationType, taxNumber);

      // All Tax Types:
      // First check if there is at least one character
      if (!ValidatorPattern.startEndSpaces.test(taxNumber.value)) {
        taxNumber.setErrors({ startEndSpaces: true });
      }

      if (!ValidatorPattern.taxNumbers.test(taxNumber.value)) {
        taxNumber.setErrors({ pattern: true });
      }
    }
  }

  private static isReason(taxIdentificationType) {
    if (isDefined(taxIdentificationType) && taxIdentificationType !== '') {
      if (taxIdentificationType === TaxTypeIdEnum.notIssued || taxIdentificationType === TaxTypeIdEnum.notAvailable) {
        return true;
      }
    }
    return false;
  }
}
