<div class="live-prices-card">
  <div class="card-panel">
    <div class="left-col">
      <mat-icon class="right-bottom-icon" svgIcon="deposit-price-icon"></mat-icon>
      <h3>Live Price Purchases</h3>
    </div>
    <div class="right-col">
      <div>
        <h2 class="right-bottom-h2">YOUR STATS</h2>
        <h3 class="right-bottom-h3">
          <span>{{ (engagement$ | async)?.userPurchasedLivePrices }}</span> Live Prices
        </h3>
      </div>
      <div>
        <h2 class="right-bottom-h2">COMMUNITY AVG</h2>
        <h3 class="right-bottom-h3">
          <span>{{ (engagement$ | async)?.communityPurchasedLivePricesAverage }}</span> Live Prices
        </h3>
      </div>
    </div>
  </div>
</div>
