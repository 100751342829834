<div class="eid-header-mobile__content">
  <div
    class="app-logo"
    [ngClass]="{
      'two-logos': productLogo()
    }"
  >
    <div class="main"></div>
    <div *ngIf="productLogo() === true" class="product-logo"></div>
  </div>
</div>
