import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { GameStateModel } from '../shared/models/game-state.model';
import { Game, UpdateGamePath, UpdateGameRedirect, UpdateGameState } from './game.actions';

const gameDefaults: GameStateModel = {
  gamePath: '',
  gameRedirect: ''
};

@State<GameStateModel>({
  name: 'game',
  defaults: gameDefaults
})
@Injectable()
export class GameState {
  @Selector()
  static game(state: GameStateModel) {
    return state;
  }

  @Selector()
  static gamePath(state: GameStateModel) {
    return state.gamePath;
  }

  @Selector()
  static gameRedirect(state: GameStateModel) {
    return state.gameRedirect;
  }

  @Action(Game)
  game({ patchState }: StateContext<GameStateModel>) {
    patchState({
      gamePath: '',
      gameRedirect: ''
    });
  }

  @Action(UpdateGameState)
  updateGameState({ patchState }: StateContext<GameStateModel>) {
    patchState({
      gamePath: '',
      gameRedirect: ''
    });
  }

  @Action(UpdateGamePath)
  updateGamePath({ patchState }: StateContext<GameStateModel>, { payload }: UpdateGamePath) {
    patchState({
      gamePath: payload
    });
  }

  @Action(UpdateGameRedirect)
  updateGameRedirect({ patchState }: StateContext<GameStateModel>, { payload }: UpdateGameRedirect) {
    patchState({
      gameRedirect: payload
    });
  }
}
