<div class="box">
  <div class="root">
    <div class="left">
      <div class="left-header">
        <mat-icon svgIcon="dividends" class="header-icon-md"></mat-icon>
        <h2 class="header-title">Monthly Dividends</h2>
      </div>
      <div class="left-body">
        <div>
          <h3 class="left-body-h3">YOUR STATS</h3>
        </div>

        <div class="left-body-container">
          <div class="div-total">
            <mat-icon *ngIf="dividentsUp" svgIcon="increase-circle" class="increase-icon"></mat-icon>
            <mat-icon *ngIf="!dividentsUp" class="decrease-icon" svgIcon="decrease-circle"></mat-icon>

            <h2 class="left-body-h2">R {{ userMonthlyDividendValue }}</h2>
          </div>

          <div class="div-shares">
            <h2 class="left-body-h2">{{ userMonthlyDividendCount }} &#160;<span>Dividends</span></h2>
          </div>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="right-top">
        <div>
          <h3 class="right-h3">
            {{ date | date: 'LLL' }} '{{ date | date: 'yy' }} dividends<br />
            as % of your total value
          </h3>
        </div>

        <div>
          <h2 class="right-h2">{{ dividendPercentageToPortfolio }}%</h2>
        </div>
      </div>

      <div class="right-bottom">
        <div>
          <h3 class="right-h3">COMMUNITY AVERAGE</h3>
        </div>

        <div>
          <h2 class="right-h2">
            {{ communityDividendAverage }}
            <span> Dividends </span>
          </h2>
        </div>
      </div>
    </div>

    <mat-icon svgIcon="dividends-corner" class="right-icon"></mat-icon>
  </div>
</div>
