import { Component } from '@angular/core';
import { DepositsValueComponent } from './deposits-value.component';

@Component({
  selector: 'eid-deposits-value-mobile',
  templateUrl: './deposits-value.mobile.component.html',
  styleUrls: ['./deposits-value.mobile.component.scss']
})
export class DepositsValueMobileComponent extends DepositsValueComponent {}

