import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ProductType } from '../../../registration/models/product.model';
import { RegistrationState } from '../../../store/registration.state';
import { CloseSidenav, ToggleSidenav } from '../../layout.actions';

@UntilDestroy()
@Component({
  selector: 'eid-public-header-mobile',
  templateUrl: './public-header.mobile.component.html',
  styleUrls: ['./public-header.mobile.component.scss']
})
export class PublicHeaderMobileComponent implements OnInit {
  @Select(RegistrationState.productType) public productType$: Observable<ProductType>;
  public productTypes = ProductType;

  constructor(protected matIconRegistry: MatIconRegistry, protected domSanitizer: DomSanitizer, private store: Store) {}

  ngOnInit() {
    this.matIconRegistry.addSvgIcon(
      'chevron-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-left.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'hash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/pound.svg')
    );
    this.matIconRegistry.addSvgIcon('menu', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/menu.svg'));
  }

  public callAction() {
    this.store.dispatch(new ToggleSidenav());
  }

  public closeNavigation(): void {
    this.store.dispatch(new CloseSidenav());
  }
}
