import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ProductType } from '../registration/models/product.model';
import { ProfileType } from '../shared/models/profile.model';
import { RegisterStoreService } from '../shared/services/register-store.service';
import { UserStoreService } from '../shared/services/user-store.service';
import { GuardService } from './guard.service';

@UntilDestroy()
@Injectable()
export class MinorRegistrationGuard implements CanActivate {
  constructor(
    private router: Router,
    private registerStoreService: RegisterStoreService,
    private userStoreService: UserStoreService,
    private guardService: GuardService
  ) {}

  canActivate() {
    if (!this.guardService.isInitialized()) {
      return false;
    }

    const url = window.location.href;
    if (url.indexOf('minor/complete') > -1) {
      return true;
    }

    const user = this.userStoreService.getUser();
    if (user.userId === '') {
      this.router.navigate(['/register/country']);
      return false;
    }

    const registrationStore = this.registerStoreService.getRegistrationStore();
    if (registrationStore.profileType !== ProfileType.minor) {
      this.registerStoreService
        .setupMinorStore()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          if (this.userStoreService.getProductType() === ProductType.satrix) {
            this.router.navigate(['/minor', 'personal']);
          } else {
            this.router.navigate(['/minor', 'product']);
          }
          return true;
        });
    }

    return true;
  }
}
