<div class="funded-account-img">
  <img src="../../../assets/engagement/funded-accounts/background.svg" class="funded-account-bg-img" />
  <img src="../../../assets/engagement/funded-accounts/rectangle.svg" class="funded-account-rectangle" />
</div>
<div class="funded-accounts-root">
  <div class="funded-accounts-header">
    <mat-icon svgIcon="funds" class="funded-accounts-icon"></mat-icon>
    <h2 class="black-text funded-accounts-title">Funded Trusts and Accounts</h2>
  </div>
  <div class="funded-accounts-container">
    <div *ngFor="let account of (engagement$ | async)?.fundedAccount" class="funded-account-box">
      <div class="account-type-box">
        <img
          src="../../../assets/icons/engagement/currencies/{{ account.name }}.svg"
          alt="{{ account.name }}"
          class="funded-account-type-logo"
        />
      </div>
      <div class="account-checkbox">
        <div class="checkbox-valid" *ngIf="account.funded"></div>
      </div>
      <h2 *ngIf="account.funded" class="black-text account-status">Funded</h2>
      <h2 *ngIf="!account.funded" class="black-text account-status">Not Funded</h2>
    </div>
  </div>
</div>
