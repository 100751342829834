import { Injectable } from '@angular/core';

@Injectable()
export class AddressUtilsService {
  constructor() {}

  public getAddressParts(address) {
    const address1Parts = address.split(' ');
    let addressNumber = '';
    let addressName = '';
    if (address1Parts[0].match(/[0-9]/g)) {
      addressNumber = address1Parts[0];
    } else if (address1Parts[address1Parts.length - 1].match(/[0-9]/g)) {
      addressNumber = address1Parts[address1Parts.length - 1];
    }
    addressName = address.replace(addressNumber, '')?.trim();
    return {
      addressNumber,
      addressName
    };
  }
}
