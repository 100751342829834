import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { groupBy } from 'lodash';
import { Observable } from 'rxjs';

import { SetLayout } from '../../../layout/layout.actions';
import { LayoutState, LayoutStateModel } from '../../../layout/layout.state';
import { HeaderAction } from '../../../layout/models/header-action.model';
import { SelectorList, SelectorListItem } from '../../models/selector-list-item.model';
import { SelectorListComponent } from '../selector-list/selector-list.component';

@UntilDestroy()
@Component({
  selector: 'eid-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorComponent),
      multi: true
    }
  ]
})
export class SelectorComponent implements ControlValueAccessor, AfterViewInit, AfterViewChecked {
  @Select(LayoutState.getPageTitle) public pageTitle$: Observable<string>;
  @Select(LayoutState.getLeftAction) public leftAction$: Observable<HeaderAction>;
  @Select(LayoutState.getRightAction) public rightAction$: Observable<HeaderAction>;

  @HostBinding('class.disabled') public disabledCls: boolean;

  @Input() selectorGroupedList?: SelectorList;
  @Input() selectorList: SelectorListItem[];
  @Input() description: string;
  @Input() value: SelectorListItem;
  @Input() searchable = true;
  @Input() simple = false;
  @Input() reverse = false;
  @Input() required = false;
  @Input() highlightSelectedValue = true;

  disabled = false;

  private isDialog: boolean;
  private pageTitle: string;
  private actionLeft: HeaderAction;
  private actionRight: HeaderAction;

  @Output() selectionChange = new EventEmitter<SelectorListItem>();

  @ViewChild('prefix') prefix: ElementRef;
  public hasPrefix = false;
  public stateSnapshot: Partial<LayoutStateModel>;

  constructor(public dialog: MatDialog, private store: Store, private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.checkPrefix();
  }

  ngAfterViewChecked(): void {
    this.checkPrefix();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    if (!this.disabled) {
      this.onTouched = fn;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.disabledCls = isDisabled;
  }

  private checkPrefix(): void {
    this.hasPrefix = this.prefix?.nativeElement?.children?.length > 0;
    this.cdRef.detectChanges();
  }

  @HostListener('click', ['$event']) onClick(evt) {
    if (this.disabled || (!this.selectorGroupedList && !this.selectorList)) {
      return;
    }
    if (!this.selectorGroupedList) {
      this.selectorGroupedList = groupBy(this.selectorList, (item) => {
        return item.label.charAt(0).toUpperCase();
      });
    }
    this.saveHeaderOriginalState();
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SelectorListComponent, {
      maxWidth: '100vw',
      panelClass: 'selector-overlay',
      hasBackdrop: false,
      data: {
        simple: this.simple,
        reverse: this.reverse,
        description: this.description,
        searchable: this.searchable,
        list: this.selectorList,
        groups: this.selectorGroupedList,
        selectedValue: this.value,
        highlight: this.highlightSelectedValue
      }
    });

    dialogRef
      .afterOpened()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogLayout(dialogRef);
      });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.onTouched();
        this.value = result ? result : undefined;
        this.selectionChange.emit({
          label: this.value ? this.value.label : '',
          value: this.value ? this.value.value : ''
        });
        this.resetLayout();
      });
  }

  private dialogLayout(dialogRef) {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: true,
      pageTitle: this.description,
      actionLeft: new HeaderAction(undefined, 'chevron-left', null, () => {
        dialogRef.close();
      }),
      actionRight: null
    };
    this.store.dispatch(new SetLayout(layoutState));
  }

  public resetLayout() {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: this.isDialog,
      pageTitle: this.pageTitle,
      actionLeft: this.actionLeft,
      actionRight: this.actionRight
    };
    this.store.dispatch(new SetLayout(layoutState));
  }

  private saveHeaderOriginalState(): void {
    this.isDialog = this.store.selectSnapshot(LayoutState.getIsDialog);
    this.pageTitle = this.store.selectSnapshot(LayoutState.getPageTitle);
    this.actionLeft = this.store.selectSnapshot(LayoutState.getLeftAction);
    this.actionRight = this.store.selectSnapshot(LayoutState.getRightAction);
  }
}
