import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBundle } from '../../../models/urlBundle';
import { UrlProviderService } from '../../../services/url.provider';

@Component({
  selector: 'eid-gcash-error',
  templateUrl: './gcash-error.component.html',
  styleUrls: ['./gcash-error.component.scss']
})
export class GCashErrorComponent {
  @Input() errorType: 'general' | 'registration' = 'general';

  public urlBundle$: Observable<UrlBundle>;

  constructor(public urlProviderService: UrlProviderService) {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
  }
}
