<mwl-calendar-month-view
  [weekStartsOn]="0"
  [events]="events"
  [viewDate]="viewDate"
  [headerTemplate]="customCellTemplate"
>
</mwl-calendar-month-view>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-row">
    <div class="cal-cell cal-past columnHeader" *ngFor="let label of daysLabel">
      {{ label }}
    </div>
  </div>
</ng-template>
