import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProductDisplayModel, ProductType } from '../../registration/models/product.model';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ProductService } from '../../shared/services/product.service';
import { RegisterStoreService } from '../../shared/services/register-store.service';
import { RegistrationState } from '../../store/registration.state';

@Component({
  template: `
    <eid-product-desktop *eidBreakpoint="'desktop'"></eid-product-desktop>
    <eid-product-mobile *eidBreakpoint="'mobile'"></eid-product-mobile>
  `
})
export class ProductContainerComponent {}

@UntilDestroy()
@Component({ template: '' })
export class ProductComponent implements OnInit {
  @Select(RegistrationState.productType) productType$: Observable<ProductType>;

  public products$: Observable<ProductDisplayModel[]>;

  constructor(
    private registerStoreService: RegisterStoreService,
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.products$ = of(productService.getProductDisplay());
  }

  ngOnInit(): void {
    this.removeProductSelection();
  }

  removeProductSelection() {
    this.registerStoreService.updateProduct(ProductType.none).pipe(take(1), untilDestroyed(this)).subscribe();
  }

  public selectProduct(productId: ProductType) {
    this.registerStoreService
      .updateProduct(productId.toString().toLowerCase())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const isUserLoggedIn = this.authenticationService.getTokenModel().isLoggedIn;
        const isV1Product = this.registerStoreService.isV1Product(productId);

        if (isUserLoggedIn) {
          this.router.navigate(['/register/country']);
        } else {
          if (isV1Product) {
            this.router.navigate(['/register/country']);
          } else {
            this.authenticationService.registerUser();
          }
        }
      });
  }
}
