import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';

import { ProductType } from '../registration/models/product.model';
import { Theme } from '../registration/models/theme.model';
import { MenuItem } from '../shared/models/menu-item.model';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ProductService } from '../shared/services/product.service';
import { RegisterStoreService } from '../shared/services/register-store.service';
import { UrlProviderService } from '../shared/services/url.provider';
import { AuthenticationState } from '../store/authentication.state';
import { RegistrationState } from '../store/registration.state';
import { UserState } from '../store/user.state';
import { SetTheme } from './layout.actions';
import { LayoutState } from './layout.state';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  @Select(RegistrationState.productType) public product$: Observable<string>;
  @Select(UserState.isProfileComplete) public isProfileComplete$: Observable<boolean>;
  @Select(LayoutState.getActiveTheme) public theme$: Observable<Theme>;
  @Select(AuthenticationState.isLoggedIn) public isLoggedIn$: Observable<boolean>;

  private currentTheme: string;
  private currentProduct: string;

  public menus$: Observable<MenuItem[]>;
  public urlChanges$: Observable<string>;

  login = {
    id: 'login',
    label: 'Log in',
    displayLabel: true,
    icon: null,
    displayIcon: false,
    action: () => {
      this.authenticationService.authorizeUser();
    }
  };
  logout = {
    id: 'logout',
    label: 'Log out',
    displayLabel: true,
    icon: null,
    displayIcon: false,
    routerLink: ['/logout']
  };
  eeCreateId = {
    id: 'createid',
    label: 'Create your EasyID',
    displayLabel: true,
    icon: null,
    displayIcon: false,
    routerLink: ['/profile-type']
  };
  satrixCreateId = {
    id: 'createid',
    label: 'Create Your SatrixNOW Account',
    displayLabel: true,
    icon: null,
    displayIcon: false,
    routerLink: ['/profile-type']
  };
  faq = {
    id: 'faq',
    label: 'FAQ',
    displayLabel: true,
    icon: null,
    displayIcon: false,
    routerLink: ['/faq']
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private location: Location,
    private store: Store,
    protected registerStoreService: RegisterStoreService,
    private authenticationService: AuthenticationService,
    private urlProviderService: UrlProviderService,
    private productService: ProductService
  ) {
    this.urlChanges$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => (event as RouterEvent)?.url),
      startWith(''),
      shareReplay()
    );

    this.detectTheme();

    this.menus$ = combineLatest([this.urlProviderService.urlBundle$, this.product$, this.isLoggedIn$]).pipe(
      map(([urlBundle, product, isLoggedIn]) => {
        return this.getRegistrationMenu(product, isLoggedIn, urlBundle);
      })
    );

    // Check theme changes to set the correct product name
    combineLatest([this.theme$, this.urlChanges$]).subscribe(([theme, urlChanges]) => {
      if (!theme) {
        return;
      }
      this.setThemeClass(theme, urlChanges);
      this.setFavicon(theme);
    });
  }

  public getRegistrationMenu(product, isLoggedIn, urlBundle): MenuItem[] {
    let createId = this.eeCreateId;
    let login;
    if (product === ProductType.satrix) {
      createId = this.satrixCreateId;
      login = {
        id: 'login',
        label: 'Log in',
        displayLabel: true,
        icon: null,
        displayIcon: false,
        url: urlBundle.login,
        newTab: false
      };
    } else {
      login = this.login;
    }
    const url = window.location.href;
    const registrationIsComplete = url.indexOf('register/complete') > -1;

    if (registrationIsComplete && product !== ProductType.satrix) {
      login.label = 'My Profile';
    }

    if (isLoggedIn) {
      return registrationIsComplete ? [this.logout, this.faq] : [this.logout, createId, this.faq];
    }
    return registrationIsComplete ? [login, this.faq] : [login, createId, this.faq];
  }

  private setFavicon(theme: Theme) {
    const favIcon: HTMLLinkElement = document.querySelector('#app-icon');
    const date = new Date();
    if (theme === Theme.satrix) {
      favIcon.href = '../assets/favicon-satrix.ico?v=' + date.getTime();
      return;
    } else if (theme === Theme.telkom) {
      favIcon.href = '../assets/favicon-telkom.ico?v=' + date.getTime();
      return;
    }
    favIcon.href = '../assets/favicon-easyid.ico?v=' + date.getTime();
  }

  private setThemeClass(theme: Theme, urlChanges: string) {
    var newTheme = theme.toString();
    if (theme === Theme.gcash) {
      if (urlChanges.indexOf('/game') > -1 || urlChanges.indexOf('/register/') > -1) {
        /// This theme is for game / registration
        newTheme = 'gcash-blue';
      } else {
        /// This theme is for compliance
        newTheme = 'gcash-red';
      }
    }
    this.document.getElementsByTagName('html')[0].classList.remove('theme--' + this.currentTheme);
    this.document.getElementsByTagName('html')[0].classList.add('theme--' + newTheme);
    this.currentTheme = newTheme;
  }

  /**
   * Detect active theme based on product
   */
  public detectTheme(): void {
    this.product$
      .pipe(
        map((product) => {
          switch (product) {
            case Theme.satrix:
              this.store.dispatch(new SetTheme(product));
              break;
            case Theme.telkom:
              this.store.dispatch(new SetTheme(product));
              break;
            case Theme.gcash:
              this.store.dispatch(new SetTheme(product));
              break;
            default:
              this.store.dispatch(new SetTheme(Theme.easyid));
              break;
          }
          this.setProduct(product);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public getArticle(): Observable<string> {
    return this.theme$.pipe(
      map((theme: Theme) => {
        switch (theme) {
          case Theme.satrix:
            return 'a';
          case Theme.telkom:
            return 'a';
          case Theme.gcash:
            return 'a';
          case Theme.easyid:
            return 'an';
          default:
            return;
        }
      })
    );
  }

  public setProduct(product?: string) {
    product = product.toLowerCase();
    if (!product || product === '') {
      return;
    }

    product = this.productService.getProductDetail(product).productTheme;

    this.document.getElementsByTagName('html')[0].classList.remove('product--' + this.currentProduct);
    if (product !== ProductType.none) {
      this.document.getElementsByTagName('html')[0].classList.add('product--' + product);
    }
    this.currentProduct = product;
  }

  public navigate(pageId: string) {
    switch (pageId) {
      case 'back':
        this.location.back();
        break;
      case 'login':
        this.router.navigate(['/action']);
        break;
      default:
        break;
    }
  }
}
