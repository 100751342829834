<button mat-fab color="primary" class="theme-switcher" [matMenuTriggerFor]="devMenu">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #devMenu="matMenu" yPosition="above">
  <button mat-menu-item [matMenuTriggerFor]="themeMenu">Themes</button>
  <mat-divider></mat-divider>
  <button *ngIf="mock" mat-menu-item (click)="setMockEE(false)">Use EE requests</button>
  <button *ngIf="!mock" mat-menu-item (click)="setMockEE(true)">Mock EE requests</button>
</mat-menu>

<mat-menu #themeMenu="matMenu" yPosition="above">
  <button *ngFor="let p of productTypes | keyvalue" mat-menu-item (click)="setProduct(p.value)">
    {{ p.value }}
    <span *ngIf="p.value === 'satrix'"> - Satrix Theme</span>
    <span *ngIf="p.value !== 'satrix'"> - EasyID Theme</span>
  </button>
</mat-menu>
