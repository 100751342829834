<!-- Burger menu -->
<div class="action action--left">
  <button (click)="callAction()">
    <mat-icon [svgIcon]="'menu'" [style.color]="'var(--eid-col-gray)'"></mat-icon>
  </button>
</div>
<div class="eid-header-mobile__content">
  <!-- Logo -->
  <div class="app-logo">
    <div class="main"></div>
    <div
      *ngIf="(productType$ | async) !== productTypes.none && (productType$ | async) !== productTypes.satrix"
      class="product-logo"
    ></div>
  </div>
</div>
