import { LogLevel } from '../app/logging/log-level';
import { LogType } from '../app/logging/logger.service';

export const environment = {
  production: false,

  hsenabled: true,
  devToolsEnabled: true,
  openEasyEnabled: false,
  easyCryptoEnabled: false,
  complianceEnabled: false,
  complianceRedirectEnabled: true,
  minorEnabled: false,
  gameEnabled: true,
  gameSponsorEnabled: true,
  manualVerification: true,
  gamePrizeEnabled: false,
  gameMonthly: true,

  apiUrl: 'https://localhost:5001',
  hosturl: 'localhost:4200',

  idpurl: 'https://uatidentity.openeasy.io',
  idpClientId: '2866eac2b4194a7dba156c237f64d33a',
  idpScope: 'openid profile platform api_gateway user_profile_api',

  easyequitiesurl: 'https://uatplatform.easyequities.io',
  mrphyurl: 'https://robodevapp.azurewebsites.net',
  easypropertiesurl: 'https://uatplatform.easyproperties.co.za',
  satrixurl: 'https://uatplatform.satrixnow.co.za',
  easycryptourl: 'https://devapi.dcx10.com/',
  thriveurl: "https://thrive.apps.easyequities.io",

  foreignVerification: 'onfido',
  v1Products: 'mrphy, easyproperties, attooh, easycrypto',

  hsportalid: '1690236',
  hsformid: 'a7ea8f6d-e8e9-4f19-835e-d74504c0d1c4',

  logType: LogType.webApi,
  logLevel: LogLevel.Debug,
  logPrefix: 'EasyID-UI',

  gameChallengeCalc: {
    timezone: 'America/New_York',
    challengeEndHour: '16',
    challengeEndDay: '5',
    displayTimezone: 'Asia/Manila',
    timezoneLabel: 'PHST'
  }
};
