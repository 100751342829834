import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { GuardService } from './guard.service';

@Injectable()
export class ProductGuard implements CanActivate {
  constructor(private guardService: GuardService) {}

  canActivate() {
    return this.guardService.profileTypeSelected();
  }
}
