import { AbstractControl } from '@angular/forms';
import { defer, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

export function isDefined(value: any) {
  return value !== null && value !== undefined;
}

export function valuesOf(control: AbstractControl): Observable<any>;
export function valuesOf<T>(control: AbstractControl): Observable<T | null>;
export function valuesOf(control: AbstractControl): Observable<any> {
  return defer(() => control.valueChanges.pipe(startWith(control.value)));
}

export function fieldInvalid(control: AbstractControl): boolean {
  return isDefined(control) && (control.dirty || control.touched) && control.invalid;
}

export function fieldValid(control: AbstractControl): boolean {
  return isDefined(control) && (control.dirty || control.touched) && !control.invalid;
}

export function fieldRadioValid(control: AbstractControl, value: any): boolean {
  return isDefined(control) && (control.dirty || control.touched) && !control.invalid && control.value === value;
}

export function fieldTypeError(control: AbstractControl, errorCode: string): boolean {
  return isDefined(control) && (control.dirty || control.touched) && control.hasError(errorCode);
}

export function fieldSummaryError(control: AbstractControl): boolean {
  return isDefined(control) && control.invalid;
}

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  const picked = keys
    .filter((key) => keys.indexOf(key as K) > -1)
    .reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {} as Pick<T, K>);
  return picked;
}

export function omit<T extends object, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> {
  const omitted = Object.keys(obj)
    .filter((key) => keys.indexOf(key as K) < 0)
    .reduce((result, key) => {
      (result as any)[key] = obj[key as K];
      return result;
    }, {} as Omit<T, K>);
  return omitted;
}
