<div class="box">
  <div class="root">
    <div class="holdings-container">
      <div class="header">
        <div class="header-left">
          <a (click)="holdings()" class="holdings-link">
            <div>
              <mat-icon svgIcon="holdings-sm" class="header-icon-sm"></mat-icon>
              <h2 class="header-title">Diversification</h2>
            </div>
            <div id="holdings-chevron">
              <mat-icon svgIcon="chevron-right-lg" class="chevron-icon"></mat-icon>
            </div>
          </a>
        </div>

        <div class="header-right-sm">
          <div>
            <h4>Your Holdings</h4>
            <h3>{{ (engagement$ | async)?.userHoldingAccount }}</h3>
          </div>

          <div>
            <h4>Community Avg</h4>
            <h3>{{ (engagement$ | async)?.communityHoldingsAverage }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
