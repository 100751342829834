<div class="container">
  <div class="row row--justify-space-between row--align-center">
    <div class="logo">
      <div class="logo__app"></div>
      <div
        *ngIf="(productType$ | async) !== productTypes.none && (productType$ | async) !== productTypes.satrix"
        class="product-logo"
      ></div>
    </div>
    <div class="eid-navigation">
      <a [routerLink]="['/profile-type']" [ngClass]="routerClasses()">Create your Profile</a>

      <a (click)="logout()" [routerLinkActive]="'active'">Log out</a>

      <button mat-icon-button [matMenuTriggerFor]="menu" class="collapsed-menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="responsive-menu" xPosition="before" yPosition="above">
        <a mat-menu-item [routerLink]="['/profile-type']" [ngClass]="routerClasses()">Create your Profile</a>
        <a mat-menu-item (click)="logout()" [routerLinkActive]="'active'">Log out</a>
      </mat-menu>
    </div>
  </div>
</div>
