import { Component, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { LOGGER, Logger } from '../../logging/logger.service';
import { EngagementService } from '../../shared/services/engagement.service';

@UntilDestroy()
@Component({
  selector: 'eid-share-activity',
  templateUrl: './share-activity.component.html',
  styleUrls: ['./share-activity.component.scss']
})
export class ShareActivityComponent {
  public imagePath: string;
  public shareURL: string;
  public loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(LOGGER) private logger: Logger,
    private router: Router,
    private engagementService: EngagementService,
    private meta: Meta
  ) {
    this.logger.debug('ShareActivityComponent');
    const url = window.location.href;
    const fileName = this.getShareID(url);

    this.getImagePath(fileName, url);
  }

  private getImagePath(fileName: string, url: string) {
    this.engagementService
      .imagePath(fileName)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (imageURL) => {
          this.imagePath = imageURL.url;
          this.shareURL = url;
          this.addTags(this.imagePath, this.shareURL);
          this.loading$.next(false);
        },
        error: (message) => {
          this.loading$.next(false);
          this.router.navigate(['/message/error']);
        }
      });
  }

  private getShareID(url: string) {
    const searchParams = this.getQueryParameters(url);
    return searchParams.get('shareid');
  }

  private getQueryParameters(url): URLSearchParams {
    if (url === '') {
      return null;
    }
    const newURL = new URL(url);
    return newURL.searchParams;
  }

  private addTags(imagePath: string, shareURL: string) {
    this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ name: 'twitter:title', content: 'Check out my monthly activities on EasyEquities' });
    this.meta.addTag({ name: 'twitter:image', content: imagePath });

    this.meta.addTag({ name: 'og:url', content: shareURL });
    this.meta.addTag({ name: 'og:type', content: 'website' });
    this.meta.addTag({ name: 'og:title', content: 'Check out my monthly activities on EasyEquities' });
    this.meta.addTag({ name: 'og:image:url', content: imagePath });
    this.meta.addTag({ name: 'og:image:secure_url', content: imagePath });
  }
}
