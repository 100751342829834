<div class="header">
  <button [disabled]="!canShowChevrons" mat-icon-button (click)="previousYear()">
    <mat-icon *ngIf="canShowChevrons">keyboard_arrow_left</mat-icon>
  </button>

  <div class="btn-group">
    <button
      [ngClass]="{
        'btn-solid btn-solid--primary active': calendar.currentView === 'multi-year',
        'btn-outline btn-outline--gray': calendar.currentView !== 'multi-year'
      }"
      (click)="changeView('multi-year')"
    >
      {{ yearLabel }}
    </button>
    <button
      [ngClass]="{
        'btn-solid btn-solid--primary active': calendar.currentView === 'year',
        'btn-outline btn-outline--gray': calendar.currentView !== 'year'
      }"
      (click)="changeView('year')"
    >
      {{ monthLabel }}
    </button>
    <button
      [ngClass]="{
        'btn-solid btn-solid--primary active': calendar.currentView === 'month',
        'btn-outline btn-outline--gray': calendar.currentView !== 'month'
      }"
      (click)="changeView('month')"
    >
      {{ dayLabel }}
    </button>
  </div>

  <button [disabled]="!canShowChevrons" mat-icon-button (click)="nextYear()">
    <mat-icon *ngIf="canShowChevrons">keyboard_arrow_right</mat-icon>
  </button>
</div>
