<div class="container">
  <div class="row row--justify-space-between row--align-center">
    <div class="logo">
      <div class="logo__app"></div>
      <!-- <div class="logo__powered-by"></div> -->
      <div *ngIf="productType$ | async" class="product-logo"></div>
    </div>
    <div class="eid-navigation">
      <a *ngIf="(isSatrix$ | async) !== true" (click)="login()">Sign In</a>
      <a *ngIf="isSatrix$ | async" [href]="(urlBundle$ | async)?.login">Sign In</a>

      <a *ngIf="isSatrix$ | async" [routerLink]="['/register']" [routerLinkActive]="'active'"
        >Create Your SatrixNOW Account</a
      >
      <a *ngIf="(isSatrix$ | async) !== true" [routerLink]="['/profile-type']" [ngClass]="routerClasses()"
        >Create your EasyID</a
      >

      <a [routerLink]="['/faq']" [routerLinkActive]="'active'">FAQ</a>

      <button mat-icon-button [matMenuTriggerFor]="menu" class="collapsed-menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="responsive-menu" xPosition="before" yPosition="above">
        <a *ngIf="(isSatrix$ | async) !== true" (click)="login()">Sign In</a>
        <a *ngIf="isSatrix$ | async" [href]="(urlBundle$ | async)?.login">Sign In</a>

        <a
          *ngIf="(isSatrix$ | async) !== true"
          mat-menu-item
          [routerLink]="['/profile-type']"
          [ngClass]="routerClasses()"
          >Create your EasyID</a
        >
        <a *ngIf="isSatrix$ | async" mat-menu-item [routerLink]="['/register']" [routerLinkActive]="'active'"
          >Create Your SatrixNOW Account</a
        >
        <a
          mat-menu-item
          [routerLink]="['/site', 'faq']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
          >FAQ</a
        >
      </mat-menu>
    </div>
  </div>
</div>
