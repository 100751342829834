import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { EngagementService } from '../../shared/services/engagement.service';

@Component({
  template: `
    <eid-dividend-desktop *eidBreakpoint="'desktop'"></eid-dividend-desktop>
    <eid-dividend-mobile *eidBreakpoint="'mobile'"></eid-dividend-mobile>
  `
})
export class DividendContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class DividendComponent {
  /* dividend card */
  public userMonthlyDividendCount = 0;
  public userMonthlyDividendValue = 0;
  public communityDividendAverage = 0;
  public dividendPercentageToPortfolio = 0;

  public userPreviousMonthDepositValue = 0;

  public date: Date;
  public dividentsUp: boolean;

  constructor(private engagementService: EngagementService) {
    this.date = new Date();
    this.date.setMonth(this.date.getMonth() - 1);

    this.engagementService
      .getEngagementData()
      .pipe(
        map((data) => {
          this.userMonthlyDividendCount = data?.userMonthlyDividendCount;
          this.dividentsUp = data?.userMonthlyDividendValue >= data?.userPreviousMonthDividendValue;

          this.communityDividendAverage = data?.communityDividendAverage;
          this.userMonthlyDividendValue = data?.userMonthlyDividendValue;
          this.userPreviousMonthDepositValue = data?.userPreviousMonthDepositValue;

          if (data?.portfolioValue > 0) {
            this.dividendPercentageToPortfolio = parseFloat(
              parseFloat('' + (data.userMonthlyDividendValue / data.portfolioValue) * 100).toFixed(6)
            );
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
