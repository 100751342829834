import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Game, UpdateGamePath, UpdateGameRedirect } from '../../store/game.actions';
import { GameState } from '../../store/game.state';

@Injectable({ providedIn: 'root' })
export class GameStoreService {
  constructor(private store: Store) {}

  public clearGame() {
    this.store.dispatch(new Game());
  }

  public updateGamePath(path: string) {
    return this.store.dispatch(new UpdateGamePath(path));
  }

  public getGamePath() {
    return this.store.selectSnapshot(GameState.gamePath);
  }

  public updateGameRedirect(redirect: string) {
    return this.store.dispatch(new UpdateGameRedirect(redirect));
  }

  public getGameRedirect() {
    return this.store.selectSnapshot(GameState.gameRedirect);
  }
}
