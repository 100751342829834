import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { EngagementService } from '../../shared/services/engagement.service';
import { EngagementModel } from '../models/engagement.model';

@Component({
  template: `
    <eid-engagement-desktop *eidBreakpoint="'desktop'"></eid-engagement-desktop>
    <eid-engagement-mobile *eidBreakpoint="'mobile'"></eid-engagement-mobile>
  `
})
export class EngagementContainerComponent {}
@UntilDestroy()
@Component({
  template: ''
})
export class EngagementComponent {
  public engagement$: Observable<EngagementModel>;

  public blur = false;
  public showBeta = true;
  public loading = false;

  constructor(private engagementService: EngagementService) {
    this.loading = true;

    this.engagement$ = this.engagementService.getEngagementData();

    this.engagement$.pipe(untilDestroyed(this)).subscribe(() => (this.loading = false));
  }

  public toggleBlur(event) {
    this.blur = event;
  }

  public closeBeta(event) {
    this.showBeta = !event;
  }

  public updateLoading($event) {
    setTimeout(() => {
      this.loading = $event;
    }, 0);
  }
}
