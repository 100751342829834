import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ComplianceStateModel } from '../shared/models/compliance-state.model';
import {
  Compliance,
  UpdateComplianceState,
  UpdateOutstandingCompliance,
  UpdateRedirectUrl,
  UpdateSuccessRedirectUrl,
  UpdateUserId
} from './compliance.actions';

const complianceDefaults: ComplianceStateModel = {
  outstandingCompliance: {
    userHasOutstandingDeclarations: false,
    outstandingDeclarations: []
  },
  userId: '',
  redirectUrl: '',
  successRedirectUrl: ''
};

@State<ComplianceStateModel>({
  name: 'compliance',
  defaults: complianceDefaults
})
@Injectable()
export class ComplianceState {
  @Selector()
  static compliance(state: ComplianceStateModel) {
    return state;
  }

  @Selector()
  static redirectUrl(state: ComplianceStateModel) {
    return state.redirectUrl;
  }

  @Selector()
  static successRedirectUrl(state: ComplianceStateModel) {
    return state.successRedirectUrl;
  }

  @Selector()
  static userId(state: ComplianceStateModel) {
    return state.userId;
  }

  @Selector()
  static outstandingCompliance(state: ComplianceStateModel) {
    return state.outstandingCompliance;
  }

  @Action(Compliance)
  compliance({ patchState }: StateContext<ComplianceStateModel>) {
    patchState({
      redirectUrl: '',
      successRedirectUrl: '',
      userId: '',
      outstandingCompliance: null
    });
  }

  @Action(UpdateComplianceState)
  updateComplianceState({ patchState }: StateContext<ComplianceStateModel>, { payload }: UpdateComplianceState) {
    patchState({
      outstandingCompliance: payload.outstandingCompliance,
      redirectUrl: payload.redirectUrl,
      successRedirectUrl: payload.successRedirectUrl
    });
  }

  @Action(UpdateRedirectUrl)
  updateRedirectUrl({ patchState }: StateContext<ComplianceStateModel>, { payload }: UpdateRedirectUrl) {
    patchState({
      redirectUrl: payload
    });
  }

  @Action(UpdateSuccessRedirectUrl)
  updateSuccessRedirectUrl({ patchState }: StateContext<ComplianceStateModel>, { payload }: UpdateSuccessRedirectUrl) {
    patchState({
      successRedirectUrl: payload
    });
  }

  @Action(UpdateUserId)
  updateUserId({ patchState }: StateContext<ComplianceStateModel>, { payload }: UpdateUserId) {
    patchState({
      userId: payload
    });
  }

  @Action(UpdateOutstandingCompliance)
  updateOutstandingCompliance(
    { patchState }: StateContext<ComplianceStateModel>,
    { payload }: UpdateOutstandingCompliance
  ) {
    patchState({
      outstandingCompliance: payload
    });
  }
}
