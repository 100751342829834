import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { RegisterStoreService } from '../../shared/services/register-store.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'eid-root-layout',
  template: `
    <eid-root-layout-desktop *eidBreakpoint="'desktop'" class="eid-root-layout-desktop"></eid-root-layout-desktop>
    <eid-root-layout-mobile *eidBreakpoint="'mobile'" class="eid-root-layout-mobile"></eid-root-layout-mobile>
  `
})
export class RootLayoutContainerComponent {}

@Component({ template: '' })
export class RootLayoutComponent implements OnInit {
  public showDevTools = false;

  constructor(protected layoutService: LayoutService, protected registerStoreService: RegisterStoreService) {
    this.showDevTools = environment.devToolsEnabled;
  }

  ngOnInit(): void {
    this.layoutService.detectTheme();
  }

  public onActivate(mobile?: boolean) {
    let scrollable: HTMLElement;

    if (mobile) {
      scrollable = document.querySelector('.mat-drawer-content');
    } else {
      scrollable = document.querySelector('html');
    }

    if (scrollable) {
      (scrollable as HTMLElement).scrollTop = 0;
    }
  }
}
