import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductType } from '../../registration/models/product.model';
import { UrlBundle } from '../../shared/models/urlBundle';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { RegisterStoreService } from '../../shared/services/register-store.service';
import { UrlProviderService } from '../../shared/services/url.provider';
import { RegistrationState } from '../../store/registration.state';

export interface Question {
  q: string;
  a: string;
}

@UntilDestroy()
@Component({
  selector: 'eid-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Select(RegistrationState.productType) productType$: Observable<ProductType>;

  public urlBundle$: Observable<UrlBundle>;
  public questions$: Observable<Question[]>;
  public isSatrix$: Observable<boolean>;
  public productTypes = ProductType;

  constructor(
    private router: Router,
    private urlProviderService: UrlProviderService,
    private authenticationService: AuthenticationService,
    protected registerStoreService: RegisterStoreService
  ) {
    this.removeProductSelection();
    this.urlBundle$ = this.urlProviderService.urlBundle$;
  }

  removeProductSelection() {
    var product = this.registerStoreService.getProduct();
    var incomingProduct = this.registerStoreService.getIncomingProduct();
    if (product !== ProductType.satrix && incomingProduct === ProductType.none) {
      this.registerStoreService.dispathProduct(ProductType.none);
    }
  }

  ngOnInit(): void {
    this.questions$ = this.productType$.pipe(
      map((product) => {
        return this.getQuestions(product);
      })
    );
  }

  private getQuestions(productType: ProductType) {
    if (productType === ProductType.satrix) {
      return [];
    } else {
      return [
        {
          q: 'What is an EasyID?',
          a:
            // eslint-disable-next-line max-len
            'An EasyID is the personal account you use to access Easy Apps like EasyEquities, MrPhy, EasyProperties, and more to come in the future. It includes the information you use to sign in, as well as all the contact, fica, tax, and banking details that you’ll use across Easy Apps and services.'
        },
        {
          q: 'When do I use my EasyID?',
          a:
            // eslint-disable-next-line max-len
            'Any time you activate a new Easy service or use any Easy app on desktop or mobile, you will be asked to sign in with your EasyID and password. Once signed in you’ll have access to the service and all the personal information in your account.'
        },
        {
          q: 'How many EasyIDs do I need?',
          a:
            // eslint-disable-next-line max-len
            'Just one. Use the same EasyID everywhere you sign in to ensure that all your Easy apps work together seamlessly and you can access your wallet funds via inter-account transfers between your Easy Apps.'
        },
        {
          q: 'How can I keep my EasyID secure?',
          a:
            // eslint-disable-next-line max-len
            'Security and privacy are very important to us and we provide a number of ways to secure your EasyID and protect your privacy including strong passwords, encryption, and more.'
        }
      ];
    }
  }

  public login(): void {
    this.authenticationService.authorizeUser();
  }

  public createEasyId(): void {
    this.router.navigate(['/profile-type']);
  }
}
