import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { merge } from 'lodash';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { LOGGER, Logger } from './logging/logger.service';
import { ProductType } from './registration/models/product.model';
import { AuthenticationService } from './shared/services/authentication.service';
import { ComplianceStoreService } from './shared/services/compliance-store.service';
import { ProductService } from './shared/services/product.service';
import { RegisterStoreService } from './shared/services/register-store.service';
import { UserStoreService } from './shared/services/user-store.service';
import { isDefined, omit } from './shared/utilities/utils';

@Injectable()
export class AppConfig {
  jsonConfig = '/assets/appConfig.json';
  jsonDevConfig = '/assets/appConfig.dev.json';

  constructor(
    @Inject(LOGGER) private logger: Logger,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private registerStoreService: RegisterStoreService,
    private userStoreService: UserStoreService,
    private productService: ProductService,
    private complianceStoreService: ComplianceStoreService
  ) {}

  public loadConfiguration() {
    let configPath = this.jsonConfig;
    if (!environment.production) {
      configPath = this.jsonDevConfig;
    }
    const url = window.location.href;
    return this.http
      .get(configPath)
      .pipe(
        map((config) => this.getAppConfig(config)),
        switchMap(() => {
          //Update the product type before the site loads. This is to have the correct theme from the start.
          const tokenModel = this.authenticationService.getTokenModel();
          const model = omit(tokenModel, 'token', 'idToken', 'issued', 'expires');
          this.logger.debug('LoadConfiguration ' + url, model);
          return this.updateProductType(url);
        }),
        map(() => {
          //Do not clear initialization when user should be logged in.
          const isGame = url.toLowerCase().indexOf('/game') > -1;
          const isCompliance = url.toLowerCase().indexOf('compliance') > -1;
          const isProfile =
            url.toLowerCase().indexOf('/profile/') > -1 ||
            url.toLowerCase().indexOf('/dashboard') > -1 ||
            url.toLowerCase().indexOf('/engagement') > -1;

          if (!isGame && !isCompliance && !isProfile) {
            this.authenticationService.updateIsInitialized(false);
          }
          if (isCompliance) {
            this.complianceStoreService.clearOutstandingDeclarations();
          }
          if (isGame || isCompliance || isProfile) {
            return;
          }
          this.authenticationService.updateIsInitialized(false);
          return;
        })
      )
      .toPromise();
  }

  private updateProductType(url) {
    const searchParamsLower = this.getQueryParameters(url.toLowerCase());
    let productType = ProductType.none.toString();

    if (url.toLowerCase().indexOf(ProductType.satrix) > -1) {
      productType = ProductType.satrix;
    }

    if (url.toLowerCase().indexOf('gstocks.io') > -1) {
      productType = 'gcash';
    }

    const registrationid = searchParamsLower.get('registrationid');
    if (url.indexOf('telkom') > -1 && registrationid !== null) {
      productType = 'telkom';
    }

    const productid = searchParamsLower.get('productid');
    if (isDefined(productid) && productid !== '') {
      productType = productid;
    }

    if (productType !== null && productType !== ProductType.none) {
      this.userStoreService.updateProductType(productType);
      return this.registerStoreService.dispatchIncomingProduct(productType);
    }

    return of(true);
  }

  private getQueryParameters(url): URLSearchParams {
    if (url === '') {
      return null;
    }
    const newURL = new URL(url);
    return newURL.searchParams;
  }

  private getAppConfig(config: any) {
    const mappedProductDetail = config.productDetails.map((p) => {
      if (p.game === 'false' || p.game === 'true') {
        return { ...p, game: Boolean(JSON.parse(p.game)) };
      }
      return { ...p, game: false };
    });
    this.productService.init(mappedProductDetail, config.productDisplay);

    merge(environment, config);
    environment.production = Boolean(JSON.parse(config.production));

    environment.hsenabled = Boolean(JSON.parse(config.hsenabled));
    environment.devToolsEnabled = Boolean(JSON.parse(config.devToolsEnabled));
    environment.openEasyEnabled = Boolean(JSON.parse(config.openEasyEnabled));
    environment.easyCryptoEnabled = Boolean(JSON.parse(config.easyCryptoEnabled));
    environment.complianceEnabled = Boolean(JSON.parse(config.complianceEnabled));
    environment.complianceRedirectEnabled = Boolean(JSON.parse(config.complianceRedirectEnabled));
    environment.minorEnabled = Boolean(JSON.parse(config.minorEnabled));
    if (config.mockData === 'true') {
      this.registerStoreService.updateUseMockEE(true);
    } else {
      this.registerStoreService.updateUseMockEE(false);
    }
    environment.gameEnabled = Boolean(JSON.parse(config.gameEnabled));
    environment.gameSponsorEnabled = Boolean(JSON.parse(config.gameSponsorEnabled));
    environment.manualVerification = Boolean(JSON.parse(config.manualVerification));
    environment.gamePrizeEnabled = Boolean(JSON.parse(config.gamePrizeEnabled));
    environment.gameMonthly = Boolean(JSON.parse(config.gameMonthly));

    environment.apiUrl = config.apiUrl;
    environment.hosturl = config.hosturl;

    environment.idpurl = config.idpurl;
    environment.idpClientId = config.idpClientId;
    environment.idpScope = config.idpScope;

    environment.easyequitiesurl = config.easyequitiesurl;
    environment.mrphyurl = config.mrphyurl;
    environment.easypropertiesurl = config.easypropertiesurl;
    environment.satrixurl = config.satrixurl;
    environment.easycryptourl = config.easycryptourl;
    environment.thriveurl = config.thriveurl;

    environment.foreignVerification = config.foreignVerification;
    environment.v1Products = config.v1Products;

    environment.logType = config.logType;
    environment.logLevel = config.logLevel;
    environment.logPrefix = config.logPrefix;

    environment.gameChallengeCalc = config.gameChallengeCalc;

    // This needs to be hardcoded due to pentest security requirements.
    // Cannot change per environment due to the fact that Teamcity build once and Octopus promote to different environments.
    environment.hsportalid = '1690236';
    environment.hsformid = 'a7ea8f6d-e8e9-4f19-835e-d74504c0d1c4';
  }
}
