import { NgModule } from '@angular/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { SharedModule } from '../shared/shared.module';
import {
  AccountActivityComponent,
  AccountActivityContainerComponent
} from './account-activity/account-activity.component';
import { AccountActivityDesktopComponent } from './account-activity/account-activity.desktop.component';
import { AccountActivityMobileComponent } from './account-activity/account-activity.mobile.component';
import { ActivityStatsComponent, ActivityStatsContainerComponent } from './activity-stats/activity-stats.component';
import { ActivityStatsDesktopComponent } from './activity-stats/activity-stats.desktop.component';
import { ActivityStatsMobileComponent } from './activity-stats/activity-stats.mobile.component';
import { BetaComponent } from './beta/beta.component';
import { BlurComponent, BlurContainerComponent } from './blur/blur.component';
import { BlurDesktopComponent } from './blur/blur.desktop.component';
import { BlurMobileComponent } from './blur/blur.mobile.component';
import { CalendarComponent, CalendarContainerComponent } from './calendar/calendar.component';
import { CalendarDesktopComponent } from './calendar/calendar.desktop.component';
import { CalendarMobileComponent } from './calendar/calendar.mobile.component';
import { DepositsValueComponent, DepositsValueContainerComponent } from './deposits-value/deposits-value.component';
import { DepositsValueDesktopComponent } from './deposits-value/deposits-value.desktop.component';
import { DepositsValueMobileComponent } from './deposits-value/deposits-value.mobile.component';
import { DepositsComponent, DepositsContainerComponent } from './deposits/deposits.component';
import { DepositsDesktopComponent } from './deposits/deposits.desktop.component';
import { DepositsMobileComponent } from './deposits/deposits.mobile.component';
import { DividendComponent, DividendContainerComponent } from './dividend/dividend.component';
import { DividendDesktopComponent } from './dividend/dividend.desktop.component';
import { DividendMobileComponent } from './dividend/dividend.mobile.component';
import { EngagementRoutingModule } from './engagement-routing.module';
import { EngagementComponent, EngagementContainerComponent } from './engagement/engagement.component';
import { EngagementDesktopComponent } from './engagement/engagement.desktop.component';
import { EngagementMobileComponent } from './engagement/engagement.mobile.component';
import { FundedAccountsComponent, FundedAccountsContainerComponent } from './funded-accounts/funded-accounts.component';
import { FundedAccountsDesktopComponent } from './funded-accounts/funded-accounts.desktop.component';
import { FundedAccountsMobileComponent } from './funded-accounts/funded-accounts.mobile.component';
import { HoldingsComponent, HoldingsContainerComponent } from './holdings/holdings.component';
import { HoldingsDesktopComponent } from './holdings/holdings.desktop.component';
import { HoldingsMobileComponent } from './holdings/holdings.mobile.component';
import { LivePricesComponent, LivePricesContainerComponent } from './live-prices/live-prices.component';
import { LivePricesDesktopComponent } from './live-prices/live-prices.desktop.component';
import { LivePricesMobileComponent } from './live-prices/live-prices.mobile.component';
import { MobileFundedAccountsComponent } from './mobile-funded-accounts/mobile-funded-accounts.component';
import { MobileHoldingsComponent } from './mobile-holdings/mobile-holdings.component';
import { MonthSelectorDesktopComponent } from './month-selector/month-selector-desktop.component';
import { MonthSelectorMobileComponent } from './month-selector/month-selector-mobile.component';
import { MonthSelectorComponent, MonthSelectorContainerComponent } from './month-selector/month-selector.component';
import { PortfolioContainerComponent, ProfileComponent } from './portfolio/portfolio.component';
import { PortfolioDesktopComponent } from './portfolio/portfolio.desktop.component';
import { PortfolioMobileComponent } from './portfolio/portfolio.mobile.component';
import { ReferralsComponent, ReferralsContainerComponent } from './referrals/referrals.component';
import { ReferralsDesktopComponent } from './referrals/referrals.desktop.component';
import { ReferralsMobileComponent } from './referrals/referrals.mobile.component';
import { ShareService } from './services/share-service';
import { ShareButtonComponent } from './share-button/share-button.component';
import { ShareButtonsComponent, ShareButtonsContainerComponent } from './share-buttons/share-buttons.component';
import { ShareButtonsDesktopComponent } from './share-buttons/share-buttons.desktop.component';
import { ShareButtonsMobileComponent } from './share-buttons/share-buttons.mobile.component';
import { ShareComponent } from './share/share.component';
import { ThriveComponent, ThriveContainerComponent } from './thrive/thrive.component';
import { ThriveDesktopComponent } from './thrive/thrive.desktop.component';
import { ThriveMobileComponent } from './thrive/thrive.mobile.component';

@NgModule({
  declarations: [
    EngagementContainerComponent,
    EngagementComponent,
    EngagementDesktopComponent,
    EngagementMobileComponent,

    AccountActivityContainerComponent,
    AccountActivityComponent,
    AccountActivityDesktopComponent,
    AccountActivityMobileComponent,

    ActivityStatsContainerComponent,
    ActivityStatsComponent,
    ActivityStatsDesktopComponent,
    ActivityStatsMobileComponent,

    CalendarContainerComponent,
    CalendarComponent,
    CalendarDesktopComponent,
    CalendarMobileComponent,

    DepositsValueComponent,
    DepositsValueContainerComponent,
    DepositsValueDesktopComponent,
    DepositsValueMobileComponent,

    DepositsContainerComponent,
    DepositsComponent,
    DepositsDesktopComponent,
    DepositsMobileComponent,

    DividendContainerComponent,
    DividendComponent,
    DividendDesktopComponent,
    DividendMobileComponent,

    HoldingsContainerComponent,
    HoldingsComponent,
    HoldingsDesktopComponent,
    HoldingsMobileComponent,

    LivePricesContainerComponent,
    LivePricesComponent,
    LivePricesDesktopComponent,
    LivePricesMobileComponent,

    PortfolioContainerComponent,
    ProfileComponent,
    PortfolioDesktopComponent,
    PortfolioMobileComponent,

    ReferralsContainerComponent,
    ReferralsComponent,
    ReferralsDesktopComponent,
    ReferralsMobileComponent,

    FundedAccountsContainerComponent,
    FundedAccountsComponent,
    FundedAccountsMobileComponent,
    FundedAccountsDesktopComponent,

    ThriveContainerComponent,
    ThriveComponent,
    ThriveDesktopComponent,
    ThriveMobileComponent,

    MonthSelectorContainerComponent,
    MonthSelectorComponent,
    MonthSelectorDesktopComponent,
    MonthSelectorMobileComponent,

    MobileFundedAccountsComponent,
    MobileHoldingsComponent,

    ShareComponent,
    ShareButtonsContainerComponent,
    ShareButtonsComponent,
    ShareButtonsDesktopComponent,
    ShareButtonsMobileComponent,
    ShareButtonComponent,

    BlurContainerComponent,
    BlurComponent,
    BlurDesktopComponent,
    BlurMobileComponent,

    BetaComponent
  ],
  imports: [
    SharedModule,
    EngagementRoutingModule,
    ClipboardModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  providers: [ShareService]
})
export class EngagementModule {}
