import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractingEntity } from '../../../../registration/models/contracting.model';
import { ProductDetailModel, ProductType } from '../../../../registration/models/product.model';
import { Theme } from '../../../../registration/models/theme.model';
import { RegistrationState } from '../../../../store/registration.state';
import { UserState } from '../../../../store/user.state';
import { UrlBundle } from '../../../models/urlBundle';
import { ProductService } from '../../../services/product.service';
import { RegisterStoreService } from '../../../services/register-store.service';
import { UrlProviderService } from '../../../services/url.provider';

export interface TopicCard {
  image: string;
  title: string;
  content: string;
  url: string;
}

@UntilDestroy()
@Component({
  selector: 'eid-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  @Select(RegistrationState.contractingEntity) public regContractingEntity$: Observable<ContractingEntity>;
  @Select(UserState.contractingEntity) public userContractingEntity$: Observable<ContractingEntity>;

  public themes = Theme;
  public productTypes = ProductType;

  public productDetail: ProductDetailModel;

  public contractingEntity$: Observable<ContractingEntity>;
  public entities = ContractingEntity;

  public cards$: Observable<TopicCard[]>;

  public urlBundle$: Observable<UrlBundle>;

  constructor(
    public urlProviderService: UrlProviderService,
    private location: Location,
    private registerStoreService: RegisterStoreService,
    private productService: ProductService
  ) {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
    const productType = this.registerStoreService.getProduct();
    this.productDetail = this.productService.getProductDetail(productType);

    this.cards$ = this.urlProviderService.urlBundle$.pipe(
      map((urlBundle: UrlBundle) => {
        return this.getCards(urlBundle);
      })
    );
  }

  ngOnInit() {
    this.contractingEntity$ = combineLatest([this.regContractingEntity$, this.userContractingEntity$]).pipe(
      map(([regCE, userCE]) => {
        if (userCE === ContractingEntity.unknown && regCE !== ContractingEntity.unknown) {
          return regCE;
        }
        if (userCE !== ContractingEntity.unknown && regCE === ContractingEntity.unknown) {
          return userCE;
        }
        return ContractingEntity.unknown;
      })
    );
  }

  public getCards(urlBundle: UrlBundle) {
    return [
      {
        image: 'https://cdn2.hubspot.net/hubfs/1690236/EE-Error-pages/image-10.jpg',
        title: 'Read',
        // eslint-disable-next-line @typescript-eslint/quotes
        content: "Lerato's top Easy blogs",
        url: urlBundle.blog
      },
      {
        image: 'https://cdn2.hubspot.net/hubfs/1690236/EE-Error-pages/image-11.jpg',
        title: 'Listen',
        // eslint-disable-next-line @typescript-eslint/quotes
        content: "Lebo's favourite podcasts",
        url: urlBundle.podcast
      },
      {
        image: 'https://cdn2.hubspot.net/hubfs/1690236/EE-Error-pages/image-12.jpg',
        title: 'Watch',
        // eslint-disable-next-line @typescript-eslint/quotes
        content: "Lola's favourite videos",
        url: urlBundle.video
      }
    ];
  }

  public supportUrl(): void {
    this.urlBundle$.pipe(untilDestroyed(this)).subscribe((urlBundle) => {
      window.location.href = urlBundle.support;
    });
  }

  public newTicketUrl(): void {
    this.urlBundle$.pipe(untilDestroyed(this)).subscribe((urlBundle) => {
      window.location.href = urlBundle.newTicket;
    });
  }

  public back(): void {
    this.location.back();
  }
}
