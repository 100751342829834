import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { UrlBundle } from '../../../../shared/models/urlBundle';
import { UrlProviderService } from '../../../../shared/services/url.provider';

@UntilDestroy()
@Component({
  selector: 'eid-footer-game',
  templateUrl: './footer-game.component.html',
  styleUrls: ['./footer-game.component.scss']
})
export class FooterGameComponent {
  @HostBinding('attr.class') private layoutCls = 'eid-game-footer';
  public urlBundle$: Observable<UrlBundle>;

  constructor(private urlProviderService: UrlProviderService, private router: Router) {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
  }

  public isChallenge() {
    const url = window.location.href;
    return url.indexOf('/game/challenge') > -1;
  }

  public goto(url: string): void {
    this.urlBundle$.pipe(untilDestroyed(this)).subscribe((urlBundle) => {
      Object.keys(urlBundle).forEach((key) => {
        if (key === url) {
          window.open(urlBundle[key], '_blank');
        }
      });
    });
  }
}
