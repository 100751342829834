import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { ShareService } from '../services/share-service';

@UntilDestroy()
@Component({
  selector: 'eid-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent {
  @Input() svgIcon;
  @Input() buttonType;
  @Input() platform;
  @Input() blurred;

  @Output() public showMore: EventEmitter<boolean> = new EventEmitter();
  @Output() public showLess: EventEmitter<boolean> = new EventEmitter();
  @Output() public busy: EventEmitter<boolean> = new EventEmitter();

  public copied: boolean = false;

  constructor(private shareService: ShareService, private clipboard: Clipboard) {}

  public async shareButtonClicked(buttonType: string) {
    const shareButton = this.shareService.getShareConfig(buttonType);

    switch (shareButton.action) {
      case 'share':
        return this.share(shareButton);
      case 'copy':
        return this.copy();
      case 'more':
        return this.more();
      case 'less':
        return this.less();
      default:
        return this.share(shareButton);
    }
  }

  private async share(shareButton) {
    this.busy.emit(true);
    (await this.shareService.saveImage(this.platform, this.blurred))
      .pipe(
        map((url: string) => {
          var fullURL = window.location.origin + '/share?shareid=' + url;
          var toShareURL = this.shareService.getShareImageURL(shareButton, fullURL);
          window.open(toShareURL);
        }),
        untilDestroyed(this)
      )
      .subscribe(() => this.busy.emit(false));
  }

  private async copy() {
    this.busy.emit(true);
    (await this.shareService.saveImage(this.platform, this.blurred))
      .pipe(
        map((url: string) => {
          var fullURL = window.location.origin + '/share?shareid=' + url;
          this.copied = true;
          this.clipboard.copy(fullURL);
          setTimeout(() => {
            this.copied = false;
          }, 2000);
        }),
        untilDestroyed(this)
      )
      .subscribe(() => this.busy.emit(false));
  }

  private more() {
    this.showMore.emit(true);
  }

  private less() {
    this.showLess.emit(true);
  }
}
