import { Component, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ProductType } from '../../../registration/models/product.model';
import { UserState } from '../../../store/user.state';
import { CurrentUser } from '../../models/current-user.model';
import { RegisterStoreService } from '../../services/register-store.service';
import { UrlProviderService } from '../../services/url.provider';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'eid-current-user',
  template: `
    <eid-current-user-desktop *eidBreakpoint="'desktop'"></eid-current-user-desktop>
    <eid-current-user-mobile *eidBreakpoint="'mobile'"></eid-current-user-mobile>
  `
})
export class CurrentUserContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class CurrentUserComponent {
  @Select(UserState.currentUser) public currentUser$: Observable<CurrentUser>;

  public productTypes = ProductType;
  public minorEnabled = environment.minorEnabled;
  public utilService: UtilService;
  public registerStoreService: RegisterStoreService;
  public urlProviderService: UrlProviderService;
  private router: Router;

  public minorDescription: string;

  constructor(protected injector: Injector) {
    this.createServices(injector);
    this.minorDescription = this.registerStoreService.getProduct() === ProductType.satrix ? 'Child' : 'Kid';
  }

  private createServices(injector: Injector): void {
    this.router = injector.get(Router);
    this.utilService = injector.get(UtilService);
    this.registerStoreService = injector.get(RegisterStoreService);
    this.urlProviderService = injector.get(UrlProviderService);
  }

  public isMinor(dob) {
    return this.utilService.isMinor(dob);
  }

  public minor() {
    if (this.registerStoreService.getProduct() !== ProductType.satrix) {
      this.registerStoreService.dispathProduct(ProductType.none);
    }
    this.router.navigate(['/minor', 'product']);
  }

  public back() {
    this.urlProviderService.urlBundle$.pipe(untilDestroyed(this)).subscribe((urlBundle) => {
      window.location.href = urlBundle.login;
    });
  }

  public logout() {
    this.router.navigate(['/logout']);
  }
}
