import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';

import { ContractingEntity } from '../registration/models/contracting.model';
import { ProductType } from '../registration/models/product.model';
import { ProfileType } from '../shared/models/profile.model';
import { RegistrationStateModel, Steps, StepValidator } from '../shared/models/registration-state.model';
import {
  Register,
  UpdateAddress,
  UpdateAffiliate,
  UpdateContractingEntity,
  UpdateCountry,
  UpdateFicaDetail,
  UpdateIdVerify,
  UpdateIncomingProductType,
  UpdateJumioDetails,
  UpdateKidAddress,
  UpdateKidCommunication,
  UpdateOnfidoDetails,
  UpdatePartnerInformation,
  UpdatePersonalDetail,
  UpdateProductType,
  UpdateProfileType,
  UpdateRegistrationState,
  UpdateStepValidators,
  UpdateTaxCompliance,
  UpdateUseMockEE,
  UpdateUser
} from './registration.actions';

const registrationStateModelDefaults: RegistrationStateModel = {
  registrationId: '',

  countrySelect: {
    citizenship: {
      countryId: 0,
      countryCode2: '',
      countryCode3: '',
      countryName: '',
      countryPhoneCode: '',
      idTypes: null
    },
    residence: {
      countryId: 0,
      countryCode2: '',
      countryCode3: '',
      countryName: '',
      countryPhoneCode: '',
      idTypes: null
    },
    birth: {
      countryId: 0,
      countryCode2: '',
      countryCode3: '',
      countryName: '',
      countryPhoneCode: '',
      idTypes: null
    }
  },
  contractingEntity: ContractingEntity.unknown,
  productType: ProductType.none,
  incomingProductType: ProductType.none,
  affiliate: {
    marketingChannelReferral: '',
    referralCode: '',
    subsystemId: ''
  },
  profileType: ProfileType.none,
  partnerInformation: '',
  partnerReference: '',
  enqueueRegistration: false,
  guardianUserId: '',
  user: {
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    marketingReferral: '',
    referral: '',
    sub: ''
  },
  personalDetail: {
    titleId: 0,
    firstName: '',
    middleInitials: '',
    lastName: '',
    idSourceCountry: '',
    identificationTypeId: '',
    identificationNumber: '',
    passportExpiryDate: null,
    dob: null,
    genderId: -99,
    countryCode: '',
    mobileNumber: '',
    cityOfBirth: ''
  },
  address: {
    unitNumber: '',
    complexName: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    code: '',
    provinceId: 0
  },
  ficaDetail: {
    incomeSourceId: 0,
    incomeSourceOtherDescription: '',
    fundSourceId: 0,
    fundSourceOtherDescription: '',
    currentEarningsStatusId: 0,
    incomeBandId: 0,
    investmentExperienceLevelId: 0,
    riskProfileId: 0,
    doesClientHaveDebt: false,
    isProminentPerson: false,
    prominentPersonReason: ''
  },
  taxCompliance: {
    taxIdentificationNumbers: [],
    taxResidenceTinExplanation: ''
  },
  idVerify: {
    done: false,
    valid: false,
    reference: ''
  },
  jumioDetails: [],
  onfidoDetails: [],
  stepValidators: [
    { step: Steps.country, valid: false },
    { step: Steps.user, valid: false },
    { step: Steps.personalDetail, valid: false },
    { step: Steps.address, valid: false },
    { step: Steps.ficaDetail, valid: false },
    { step: Steps.verification, valid: false }
  ],
  useMockEE: false,
  kidAddress: false,
  kidCommunication: false
};

@State<RegistrationStateModel>({
  name: 'register',
  defaults: registrationStateModelDefaults
})
@Injectable()
export class RegistrationState {
  @Selector()
  static registrationId(state: RegistrationStateModel) {
    return state.registrationId;
  }

  @Selector()
  static country(state: RegistrationStateModel) {
    return state.countrySelect;
  }

  @Selector()
  static contractingEntity(state: RegistrationStateModel) {
    return state.contractingEntity;
  }

  @Selector()
  static productType(state: RegistrationStateModel) {
    return state.productType;
  }

  @Selector()
  static incomingProductType(state: RegistrationStateModel) {
    return state.incomingProductType;
  }

  @Selector()
  static affiliate(state: RegistrationStateModel) {
    return state.affiliate;
  }

  @Selector()
  static profileType(state: RegistrationStateModel) {
    return state.profileType;
  }

  @Selector()
  static user(state: RegistrationStateModel) {
    return state.user;
  }

  @Selector()
  static personalDetail(state: RegistrationStateModel) {
    return state.personalDetail;
  }

  @Selector()
  static address(state: RegistrationStateModel) {
    return state.address;
  }

  @Selector()
  static ficaDetail(state: RegistrationStateModel) {
    return state.ficaDetail;
  }

  @Selector()
  static taxCompliance(state: RegistrationStateModel) {
    return state.taxCompliance;
  }

  @Selector()
  static stepValidator(state: RegistrationStateModel) {
    return state.stepValidators;
  }

  @Selector()
  static idVerify(state: RegistrationStateModel) {
    return state.idVerify;
  }

  @Selector()
  static jumioDetails(state: RegistrationStateModel) {
    return state.jumioDetails;
  }

  @Selector()
  static onfidoDetails(state: RegistrationStateModel) {
    return state.onfidoDetails;
  }

  @Selector()
  static partnerInformation(state: RegistrationStateModel) {
    return state.partnerInformation;
  }

  @Selector()
  static useMockEE(state: RegistrationStateModel) {
    return state.useMockEE;
  }

  @Selector()
  static kidAddress(state: RegistrationStateModel) {
    return state.kidAddress;
  }

  @Selector()
  static kidCommunication(state: RegistrationStateModel) {
    return state.kidCommunication;
  }

  @Action(Register)
  registration({ patchState }: StateContext<RegistrationStateModel>) {
    patchState({
      registrationId: '',
      countrySelect: null,
      contractingEntity: ContractingEntity.unknown,
      productType: ProductType.none,
      incomingProductType: ProductType.none,
      affiliate: null,
      profileType: ProfileType.none,
      partnerInformation: '',
      partnerReference: '',
      enqueueRegistration: false,
      guardianUserId: '',
      user: null,
      personalDetail: null,
      address: null,
      ficaDetail: null,
      taxCompliance: null,
      idVerify: null,
      jumioDetails: null,
      onfidoDetails: null,
      stepValidators: null,
      useMockEE: false,
      kidAddress: false,
      kidCommunication: false
    });
  }

  @Action(UpdateRegistrationState)
  updateRegistrationState(
    { patchState, setState }: StateContext<RegistrationStateModel>,
    { payload }: UpdateRegistrationState
  ) {
    if (payload !== null) {
      if (payload.incomingProductType !== ProductType.none) {
        payload.productType = payload.incomingProductType;
      }
      patchState({
        registrationId: payload.registrationId,
        countrySelect: payload.countrySelect,
        contractingEntity: payload.contractingEntity,
        incomingProductType: payload.incomingProductType,
        productType: payload.productType,
        affiliate: payload.affiliate,
        profileType: payload.profileType,
        partnerInformation: payload.partnerInformation,
        partnerReference: payload.partnerReference,
        enqueueRegistration: payload.enqueueRegistration,
        guardianUserId: payload.guardianUserId,
        user: payload.user,
        personalDetail: payload.personalDetail,
        address: payload.address,
        ficaDetail: payload.ficaDetail,
        taxCompliance: payload.taxCompliance,
        idVerify: payload.idVerify,
        jumioDetails: payload.jumioDetails,
        onfidoDetails: payload.onfidoDetails,
        stepValidators: payload.stepValidators
      });
    } else {
      setState(registrationStateModelDefaults);
    }
  }

  @Action(UpdateCountry)
  updateCountry({ patchState, setState }: StateContext<RegistrationStateModel>, { payload }: UpdateCountry) {
    setState(
      patch({
        stepValidators: updateItem<StepValidator>((route) => route.step === Steps.country, {
          step: Steps.country,
          valid: true
        })
      })
    );
    patchState({
      countrySelect: payload
    });
  }

  @Action(UpdateContractingEntity)
  updateContractingEntity({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateContractingEntity) {
    patchState({
      contractingEntity: payload
    });
  }

  @Action(UpdateProductType)
  updateProductType({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateProductType) {
    patchState({
      productType: payload
    });
  }

  @Action(UpdateIncomingProductType)
  updateIncomingProductType({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateProductType) {
    patchState({
      productType: payload,
      incomingProductType: payload
    });
  }

  @Action(UpdateAffiliate)
  updateAffiliate({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateAffiliate) {
    patchState({
      affiliate: payload
    });
  }

  @Action(UpdateProfileType)
  updateProfileType({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateProfileType) {
    patchState({
      profileType: payload
    });
  }

  @Action(UpdateUser)
  updateUser({ patchState, setState }: StateContext<RegistrationStateModel>, { payload }: UpdateUser) {
    setState(
      patch({
        stepValidators: updateItem<StepValidator>((route) => route.step === Steps.user, {
          step: Steps.user,
          valid: true
        })
      })
    );
    patchState({
      user: payload
    });
  }

  @Action(UpdatePersonalDetail)
  updatePersonalDetail(
    { patchState, setState }: StateContext<RegistrationStateModel>,
    { payload }: UpdatePersonalDetail
  ) {
    setState(
      patch({
        stepValidators: updateItem<StepValidator>((route) => route.step === Steps.personalDetail, {
          step: Steps.personalDetail,
          valid: true
        })
      })
    );
    patchState({
      personalDetail: payload
    });
  }

  @Action(UpdateAddress)
  updateAddress({ patchState, setState }: StateContext<RegistrationStateModel>, { payload }: UpdateAddress) {
    setState(
      patch({
        stepValidators: updateItem<StepValidator>((route) => route.step === Steps.address, {
          step: Steps.address,
          valid: true
        })
      })
    );
    patchState({
      address: payload
    });
  }

  @Action(UpdateFicaDetail)
  updateFicaDetail({ patchState, setState }: StateContext<RegistrationStateModel>, { payload }: UpdateFicaDetail) {
    setState(
      patch({
        stepValidators: updateItem<StepValidator>((route) => route.step === Steps.ficaDetail, {
          step: Steps.ficaDetail,
          valid: true
        })
      })
    );
    patchState({
      ficaDetail: payload
    });
  }

  @Action(UpdateTaxCompliance)
  updateTaxCompliance({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateTaxCompliance) {
    patchState({
      taxCompliance: payload
    });
  }

  @Action(UpdateIdVerify)
  updateIdVerify({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateIdVerify) {
    patchState({
      idVerify: payload
    });
  }

  @Action(UpdateJumioDetails)
  updateJumioDetails({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateJumioDetails) {
    patchState({
      jumioDetails: payload
    });
  }

  @Action(UpdateOnfidoDetails)
  updateOnfidoDetails({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateOnfidoDetails) {
    patchState({
      onfidoDetails: payload
    });
  }

  @Action(UpdateStepValidators)
  updateStepValidators({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateStepValidators) {
    patchState({
      stepValidators: payload
    });
  }

  @Action(UpdatePartnerInformation)
  updatePartnerInformation(
    { patchState }: StateContext<RegistrationStateModel>,
    { payload }: UpdatePartnerInformation
  ) {
    patchState({
      partnerInformation: payload
    });
  }

  @Action(UpdateUseMockEE)
  updateUseMockEE({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateUseMockEE) {
    patchState({
      useMockEE: payload
    });
  }

  @Action(UpdateKidAddress)
  updateKidAddress({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateKidAddress) {
    patchState({
      kidAddress: payload
    });
  }

  @Action(UpdateKidCommunication)
  updateKidCommunication({ patchState }: StateContext<RegistrationStateModel>, { payload }: UpdateKidCommunication) {
    patchState({
      kidCommunication: payload
    });
  }
}
