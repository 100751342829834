<div *ngIf="productDetail.styleTheme !== themes.gcash" class="section section--hero section--bg-error">
  <div class="container row--col-2">
    <div class="col error--column"></div>
    <div class="col">
      <div class="hero__content">
        <h1>Houston, we have a problem</h1>
        <p class="lh-loose">
          It looks like we're experiencing a small technical glitch but don't panic, we've called in our special ops IT
          team who are working on getting the problem fixed as quickly as possible.
        </p>
        <br />
        <button
          class="btn-solid btn-solid--primary"
          type="button"
          (click)="back()"
          *ngIf="(productType$ | async) !== productTypes.satrix"
        >
          Take me back to EasyID
        </button>
        <button
          class="btn-solid btn-solid--primary"
          type="button"
          (click)="back()"
          *ngIf="(productType$ | async) === productTypes.satrix"
        >
          Take me back to SatrixNOW
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="productDetail.styleTheme !== themes.gcash" class="section section--bg-error mobile-error">
  <div class="container mobile-error--container">
    <div class="hero__content">
      <h1>Houston, we have a problem</h1>
      <p class="lh-loose">
        It looks like we're experiencing a small technical glitch but don't panic, we've called in our special ops IT
        team who are working on getting the problem fixed as quickly as possible.
      </p>
      <br />
      <button
        class="btn-solid btn-solid--primary"
        type="button"
        (click)="back()"
        *ngIf="(productType$ | async) !== productTypes.satrix"
      >
        Take me back to EasyID
      </button>
      <button
        class="btn-solid btn-solid--primary"
        type="button"
        (click)="back()"
        *ngIf="(productType$ | async) === productTypes.satrix"
      >
        Take me back to SatrixNOW
      </button>
    </div>
  </div>
</div>

<div *ngIf="productDetail.styleTheme !== themes.gcash" class="section maintenance section--padded">
  <div class="container">
    <div class="row row--justify-center">
      <div class="page-title">
        <h1>Got a question for us?</h1>
        <p class="page-subtitle">
          Our knowledge base is jam packed with answers to all the questions you can think of.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="knowledge-base">
        <div
          class="image"
          [style.background]="'url(https://cdn2.hubspot.net/hubfs/1690236/EE-Error-pages/image-13.jpg)'"
        >
          <mat-icon>chat</mat-icon>
        </div>
        <div class="content">
          <h3>Our Top FAQs:</h3>
          <ul class="list--bullet">
            <li>What price will I receive when I buy/sell securities?</li>
            <li>When and how will my tax certificate be supplied to me</li>
            <li>Who is eligible to purchase shares using the EasyEquities platform?</li>
            <li>How are my assets protected?</li>
            <li>How do I buy or send an EasyEquities voucher?</li>
            <li>Can I transfer tax-free investments with another service provider to my EasyEquities account?</li>
          </ul>

          <div class="check-out">
            <button class="btn-solid btn-solid--primary" (click)="supportUrl()">Check out our knowledge base</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="productDetail.styleTheme !== themes.gcash" class="section maintenance section--bg-light section--padded">
  <div class="container contact">
    <div class="row row--col-2">
      <div class="col pr-1">
        <div class="page-title">
          <h1>Need help with anything?</h1>
          <p class="text--gray-light">
            Submit a ticket <a [href]="(urlBundle$ | async)?.newTicket">here</a> to receive assistance on any technical
            queries you may have relating to your EasyEquities account.
          </p>
          <div class="error-btns">
            <button class="btn-solid btn-solid--primary" (click)="newTicketUrl()">Create a support ticket</button>
          </div>
        </div>
      </div>
      <div class="col">
        <img src="/assets/images/image-8.jpg" />
      </div>
    </div>
  </div>
</div>

<eid-gcash-error *ngIf="productDetail.styleTheme === themes.gcash"></eid-gcash-error>
