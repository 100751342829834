<div class="section">
  <div class="container">
    <div class="row row--justify-center">
      <div class="message">
        <div class="row">
          <div class="col message__text">
            <h2 class="panel__title">Investing from another country?</h2>
            <p>
              Currently we only open accounts online for residents from the listed countries. If your country is not one
              of the listed ones, please reach out to our client engagement team
              <a [href]="(urlBundle$ | async)?.newTicket" target="_blank">here</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
