import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isNil } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { LOGGER, Logger } from '../../logging/logger.service';
import {
  Authenticate,
  UpdateApiToken,
  UpdateErrorRequests,
  UpdateInitialize,
  UpdateIsLoggedIn,
  UpdateUrl
} from '../../store/authentication.actions';
import { AuthenticationState } from '../../store/authentication.state';
import { ApiTokenModel } from '../models/api-token.model';
import { CurrentUser } from '../models/current-user.model';
import { RegistrationExistsModel, RegistrationStateModel } from '../models/registration-state.model';
import { ComplianceStoreService } from './compliance-store.service';
import { EngagementService } from './engagement.service';
import { GameStoreService } from './game-store.service';
import { RegisterStoreService } from './register-store.service';
import { RegistrationService } from './registration.service';
import { UserStoreService } from './user-store.service';

@Injectable()
export class AuthenticationService {
  constructor(
    @Inject(LOGGER) private logger: Logger,
    private httpClient: HttpClient,
    private store: Store,
    private userStoreService: UserStoreService,
    private registerStoreService: RegisterStoreService,
    private gameStoreService: GameStoreService,
    private complianceStoreService: ComplianceStoreService,
    private registrationService: RegistrationService,
    private engagementService: EngagementService
  ) {}

  public clearAuthToken() {
    const currentTokenModel = this.store.selectSnapshot(AuthenticationState.apiTokenModel);
    const tokenModel = {
      token: '',
      idToken: '',
      issued: '',
      expires: '',
      isLoggedIn: false,
      url: currentTokenModel.url,
      initialized: false,
      errorRequests: currentTokenModel.errorRequests
    };
    this.store.dispatch(new Authenticate());
    this.store.dispatch(new UpdateApiToken(tokenModel));
  }

  public clearAuthTokenWithUrl() {
    const tokenModel = {
      token: '',
      idToken: '',
      issued: '',
      expires: '',
      isLoggedIn: false,
      url: '',
      initialized: false,
      errorRequests: []
    };
    this.store.dispatch(new Authenticate());
    this.store.dispatch(new UpdateApiToken(tokenModel));
  }

  public getUrl() {
    return this.store.selectSnapshot(AuthenticationState.getUrl);
  }

  public updateUrl(url) {
    return this.store.dispatch(new UpdateUrl(url));
  }

  public getIsInitialized() {
    return this.store.selectSnapshot(AuthenticationState.isInitialized);
  }

  public updateIsInitialized(busy) {
    return this.store.dispatch(new UpdateInitialize(busy));
  }

  public isUserLoggedIn() {
    const token = this.store.selectSnapshot(AuthenticationState.apiTokenModel);
    return token.isLoggedIn;
  }

  public updateIsLoggedIn(isLoggedIn) {
    return this.store.dispatch(new UpdateIsLoggedIn(isLoggedIn));
  }

  public getErrorRequests() {
    return this.store.selectSnapshot(AuthenticationState.errorRequests);
  }

  public updateErrorRequests(url) {
    return this.store.dispatch(new UpdateErrorRequests(url));
  }

  public getCachedAuthToken() {
    return this.store.selectSnapshot(AuthenticationState.getToken);
  }

  public getTokenModel() {
    return this.store.selectSnapshot(AuthenticationState.apiTokenModel);
  }

  public clearAllStateStores() {
    this.clearAuthToken();
    this.userStoreService.clearUser();
    this.registerStoreService.clearRegistrationStore();
    this.gameStoreService.clearGame();
    this.complianceStoreService.clearCompliance();
    this.engagementService.clearEngagement();
  }

  public clearAllStateStoresWithUrl() {
    this.clearAuthTokenWithUrl();
    this.userStoreService.clearUser();
    this.registerStoreService.clearRegistrationStore();
    this.gameStoreService.clearGame();
    this.complianceStoreService.clearCompliance();
    this.engagementService.clearEngagement();
  }

  public logout() {
    const apiTokenModel = this.getTokenModel();

    let params = new HttpParams();
    params = params.set('id_token_hint', apiTokenModel.idToken);
    params = params.set('post_logout_redirect_uri', window.location.origin + '/home');
    // For local testing - cannot redirect to http
    // if (window.location.origin.indexOf('http://') > -1) {
    //   params = params.set('post_logout_redirect_uri', 'https://qa-easyid.easyidentity.io/home');
    // }

    this.clearAllStateStores();

    const url = environment.idpurl + '/connect/endsession?' + params.toString();
    window.location.href = url;
  }

  public registerUser() {
    const productType = this.registerStoreService.getProduct();
    let params = new HttpParams();
    params = params.set('landingUrl', window.location.origin + '/register/country?productid=' + productType);
    const url = environment.idpurl + '/Registration/Register?' + params.toString();
    window.location.href = url;
    return of(true);
  }

  public authorizeUser() {
    let params = new HttpParams();
    params = params.set('client_id', environment.idpClientId);
    params = params.set('redirect_uri', window.location.origin + '/authorize');
    params = params.set('response_type', 'code');
    params = params.set('scope', environment.idpScope);

    const url = environment.idpurl + '/connect/authorize?' + params.toString();
    this.logger.debug('AuthenticationService authorizeUser ' + url);

    window.location.href = url;
    return of(true);
  }

  public checkIsLoggedIn() {
    let params = new HttpParams();
    params = params.set('client_id', environment.idpClientId);
    params = params.set('redirect_uri', window.location.origin + '/authorize');
    params = params.set('response_type', 'code');
    params = params.set('scope', environment.idpScope);
    params = params.set('prompt', 'none');

    const url = environment.idpurl + '/connect/authorize?' + params.toString();
    this.logger.debug('AuthenticationService checkIsLoggedIn ' + url);
    window.location.href = url;
    return of(true);
  }

  public getAccessToken(code) {
    this.logger.debug('AuthenticationService getAccessToken ' + code);
    return this.tokenExhange(code).pipe(
      tap((apiTokenModel) => {
        this.logger.debug('AuthenticationService got token for code ' + code);
        // User will only be set as logged in after the userinfo is available as well.
        const currentTokenModel = this.store.selectSnapshot(AuthenticationState.apiTokenModel);
        apiTokenModel.url = currentTokenModel.url;
        apiTokenModel.isLoggedIn = false;
        apiTokenModel.initialized = currentTokenModel.initialized;
        apiTokenModel.errorRequests = currentTokenModel.errorRequests;
        this.store.dispatch(new Authenticate());
        this.store.dispatch(new UpdateApiToken(apiTokenModel));
      })
    );
  }

  private tokenExhange(code) {
    const data = {
      code,
      redirectUri: window.location.origin + '/authorize'
    };
    return this.httpClient.post<ApiTokenModel>('/api/identity/accesstoken', data);
  }

  public shouldBeLoggedInUrl(url: string) {
    if (
      url.indexOf('/dashboard') > -1 ||
      url.indexOf('/profile/') > -1 ||
      url.indexOf('/minor') > -1 ||
      url.indexOf('/compliance') > -1 ||
      url.indexOf('/game') > -1 ||
      url.indexOf('/engagement') > -1
    ) {
      return true;
    }
    return false;
  }

  public getUserInfo(isMinor: boolean) {
    return this.getUserInfoRequest().pipe(
      tap((user) => {
        user.productType = this.userStoreService.getProductType();
        this.userStoreService.updateUser(user);
      }),
      switchMap((user) => {
        if (isMinor) {
          return of(user);
        }
        const registrationStore = this.registerStoreService.getRegistrationStore();
        if (
          !this.userStoreService.isProfileComplete() &&
          !registrationStore.enqueueRegistration &&
          !this.registerStoreService.isV1Product(registrationStore.productType)
        ) {
          return this.resumeRegistration(user).pipe(
            map(() => {
              return user;
            })
          );
        }
        return this.updateUserInRegistrationStore(user, registrationStore).pipe(
          map(() => {
            return user;
          })
        );
      })
    );
  }

  private getUserInfoRequest() {
    return this.httpClient.get<CurrentUser>('/api/identity/userinfo');
  }

  private resumeRegistration(user: CurrentUser): Observable<boolean> {
    const sessionState = this.registerStoreService.getRegistrationStore();

    return this.registrationService.getRegistrationExists(user.username, null, sessionState.productType, user.sub).pipe(
      switchMap((registrationExists: RegistrationExistsModel) => {
        if (!registrationExists.registrationExists || !registrationExists.isMatch) {
          return this.updateUserInRegistrationStore(user, sessionState).pipe(
            map(() => {
              return true;
            })
          );
        }
        return this.handleDatabaseState(user, registrationExists.registrationState, sessionState).pipe(
          map(() => {
            return true;
          })
        );
      })
    );
  }

  public updateUserInRegistrationStore(user: CurrentUser, registrationState: RegistrationStateModel) {
    const newRegistrationState = Object.assign({}, registrationState);
    newRegistrationState.user = Object.assign({}, registrationState.user);
    newRegistrationState.personalDetail = Object.assign({}, registrationState.personalDetail);

    if (isNil(user.userId) || user.userId === '') {
      newRegistrationState.user.username = user.username;
      newRegistrationState.user.email = user.email;
      newRegistrationState.user.sub = user.sub;
      if (!isNil(user.firstName)) {
        newRegistrationState.personalDetail.firstName = user.firstName;
      }
      if (!isNil(user.lastName)) {
        newRegistrationState.personalDetail.lastName = user.lastName;
      }
    }
    if (isNil(user.username) || user.username === '') {
      return this.registerStoreService.dispatchRegistrationStore(newRegistrationState);
    }
    return this.registerStoreService.updateRegistrationStore(newRegistrationState);
  }

  private handleDatabaseState(
    user: CurrentUser,
    databaseRegistration: RegistrationStateModel,
    sessionState: RegistrationStateModel
  ): Observable<boolean> {
    // If it is the same registration, do nothing
    if (databaseRegistration.registrationId === sessionState.registrationId) {
      return of(true);
    }
    // The session registration does not exists yet
    if (sessionState.registrationId === '') {
      return this.updateUserInRegistrationStore(user, databaseRegistration).pipe(
        map(() => {
          return true;
        })
      );
    } else {
      // Keep the session affiliate details
      databaseRegistration.affiliate = sessionState.affiliate;
      return this.registrationService.deleteRegistrationState(sessionState.registrationId).pipe(
        switchMap(() => {
          this.updateUserInRegistrationStore(user, databaseRegistration);
          return of(true);
        })
      );
    }
  }
}
