import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';

import { Theme } from '../registration/models/theme.model';
import {
  CloseSidenav,
  OpenSidenav,
  SetHeader,
  SetIsDialog,
  SetLayout,
  SetLeftAction,
  SetPageTitle,
  SetRightAction,
  SetTheme,
  ToggleSidenav,
} from './layout.actions';
import { HeaderAction } from './models/header-action.model';
import { HeaderProperies } from './models/header.model';

export class LayoutStateModel {
  isDialog: boolean;
  pageTitle: string;
  actionLeft: HeaderAction;
  actionRight: HeaderAction;
  theme: Theme;
  sideNavOpen: boolean;
}

@State<LayoutStateModel>({
  name: 'layout',
  defaults: {
    isDialog: false,
    pageTitle: null,
    actionLeft: null,
    actionRight: null,
    theme: null,
    sideNavOpen: false
  }
})
@Injectable()
export class LayoutState implements NgxsOnInit {
  @Selector()
  static getSidenavState(state: LayoutStateModel): boolean {
    return state.sideNavOpen;
  }

  @Selector()
  static getActiveTheme(state: LayoutStateModel): Theme {
    return state.theme;
  }

  @Selector()
  static getIsDialog(state: LayoutStateModel): boolean {
    return state.isDialog;
  }

  @Selector()
  static getPageTitle(state: LayoutStateModel): string {
    return state.pageTitle;
  }

  @Selector()
  static getLeftAction(state: LayoutStateModel): HeaderAction {
    return state.actionLeft;
  }

  @Selector()
  static getRightAction(state: LayoutStateModel): HeaderAction {
    return state.actionRight;
  }

  constructor() {}

  ngxsOnInit(ctx: StateContext<LayoutStateModel>) {}

  @Action(SetLayout)
  setLayoutState({ patchState }: StateContext<LayoutStateModel>, { payload }: SetLayout) {
    patchState(payload);
  }

  @Action(SetPageTitle)
  setPageTitle({ patchState }: StateContext<LayoutStateModel>, action: SetPageTitle) {
    patchState({
      pageTitle: action.title
    });
  }

  @Action(SetIsDialog)
  setIsDialog({ patchState }: StateContext<LayoutStateModel>, action: SetIsDialog) {
    patchState({
      pageTitle: action.isDialog
    });
  }

  @Action(SetLeftAction)
  setLeftAction({ patchState }: StateContext<LayoutStateModel>, action: SetLeftAction) {
    patchState({
      actionLeft: action.actionLeft
    });
  }

  @Action(SetRightAction)
  setRightAction({ patchState }: StateContext<LayoutStateModel>, action: SetRightAction) {
    patchState({
      actionRight: action.actionRight
    });
  }

  @Action(SetHeader)
  setHeader({ patchState }: StateContext<LayoutStateModel>, action: HeaderProperies) {
    patchState({
      pageTitle: action.title,
      actionLeft: action.leftAction,
      actionRight: action.rightAction
    });
  }

  @Action(SetTheme)
  setTheme({ patchState }: StateContext<LayoutStateModel>, { payload }: SetTheme) {
    patchState({
      theme: payload
    });
  }

  @Action(OpenSidenav)
  openSidenav({ patchState }: StateContext<LayoutStateModel>) {
    patchState({
      sideNavOpen: true
    });
  }

  @Action(CloseSidenav)
  closeSidenav({ patchState }: StateContext<LayoutStateModel>) {
    patchState({
      sideNavOpen: false
    });
  }

  @Action(ToggleSidenav)
  toggleSidenav({ patchState, getState }: StateContext<LayoutStateModel>) {
    const state = getState();
    patchState({
      sideNavOpen: !state.sideNavOpen
    });
  }
}
