import { Component } from '@angular/core';
import { IntroFiveComponent } from './intro-five.component';

@Component({
  selector: 'eid-intro-five-desktop',
  templateUrl: './intro-five.desktop.component.html',
  styleUrls: ['./intro-five.desktop.component.scss']
})
export class IntroFiveDesktopComponent extends IntroFiveComponent {}

