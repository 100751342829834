import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { EngagementService } from '../../shared/services/engagement.service';
import { EngagementModel } from '../models/engagement.model';

@Component({
  template: `
    <eid-live-prices-desktop *eidBreakpoint="'desktop'"></eid-live-prices-desktop>
    <eid-live-prices-mobile *eidBreakpoint="'mobile'"></eid-live-prices-mobile>
  `
})
export class LivePricesContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class LivePricesComponent {
  public engagement$: Observable<EngagementModel>;

  constructor(private engagementService: EngagementService) {
    this.engagement$ = this.engagementService.getEngagementData();
  }
}
