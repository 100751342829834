import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
  template: `
    <eid-share-buttons-desktop *eidBreakpoint="'desktop'"></eid-share-buttons-desktop>
    <eid-share-buttons-mobile *eidBreakpoint="'mobile'"></eid-share-buttons-mobile>
  `
})
export class ShareButtonsContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class ShareButtonsComponent {
  @Input() blurred = false;
  @Output() public loading: EventEmitter<boolean> = new EventEmitter();

  public showMore: boolean = false;
  public busySharing: boolean = false;

  public more() {
    this.showMore = true;
  }

  public less() {
    this.showMore = false;
  }

  public busy(event) {
    this.loading.emit(event);
    this.busySharing = event;
  }
}
