<form [formGroup]="addressForm" autocomplete="off">
  <input style="display: none" />
  <input type="search" style="display: none" />

  <div class="eid-input-control-group">
    <mat-form-field
      [ngClass]="{
        'validation-error-mobile': fieldInvalid(addressForm.controls.address),
        'validation-success-mobile': fieldValid(addressForm.controls.address)
      }"
      class="mat-form-field--mobile"
      [floatLabel]="'auto'"
    >
      <div class="custom-field custom-field--label custom-field--label__address">
        <mat-label>Street Address<span aria-hidden="true" class="custom-marker">*</span></mat-label>
      </div>
      <div class="custom-field custom-field--input custom-field--input__address">
        <input
          #search
          type="text"
          [autocomplete]="'new-st'"
          matInput
          placeholder="Street Address"
          [formControlName]="'address'"
          (focus)="scrollIntoView($event); geolocate()"
          (keydown.enter)="$event.preventDefault()"
          required
        />
      </div>
    </mat-form-field>
    <div class="validation-group">
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.address, 'required')">
        Please enter your street number and street name.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.address, 'numberPattern')">
        Street number can contain the letters a-z and A-Z, the digits 0-9 and any of the following symbols: . -;:'_,\/
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.address, 'namePattern')">
        Street name can not contain "
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.address, 'length')">
        Maximum length for street number is 5 characters and for street name is 50 characters.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.address, 'minimallength')">
        Maximum length for street number is 5 characters and for street name is 256 characters.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.address, 'blockedUnicode')">
        Street name may not contain the following symbols: × ‘ ’
      </div>
    </div>
  </div>
  <div class="hints">
    <div class="form-hint form-hint--mobile">E.g. 18 Peach Street</div>

    <div class="form-hint form-hint--mobile form-hint--right">
      <img src="./assets/logos/powered-by-google.png" alt="Powered by Google" />
    </div>
  </div>

  <div class="eid-input-control-group eid-input-control-group--spacing-top">
    <mat-form-field
      [ngClass]="{
        'validation-error-mobile': fieldInvalid(addressForm.controls.addressLine2),
        'validation-success-mobile': fieldValid(addressForm.controls.addressLine2)
      }"
      class="mat-form-field--mobile"
      [floatLabel]="'auto'"
    >
      <div class="custom-field custom-field--label">
        <mat-label>Complex / Building Name <span class="sub-text">(optional)</span></mat-label>
      </div>
      <div class="custom-field custom-field--input">
        <input matInput [formControlName]="'addressLine2'" [autocomplete]="'new-st2'" />
      </div>
    </mat-form-field>
    <div class="validation-group">
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.addressLine2, 'required')">
        Please enter your unit/floor number and complex/building name.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.addressLine2, 'numberPattern')">
        Unit/floor number can contain the letters a-z and A-Z, and the digits 0-9.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.addressLine2, 'namePattern')">
        Complex/building name can contain the letters a-z, A-Z and any of the following symbols: . -;:'_,\/
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.addressLine2, 'length')">
        Maximum length for unit/floor number is 5 characters and for complex/building name is 25 characters.
      </div>
    </div>
  </div>

  <div class="form-hint form-hint--mobile">Complex/Building Name, Apt, Flat or Floor</div>

  <div class="eid-input-control-group">
    <mat-form-field
      [ngClass]="{
        'validation-error-mobile': fieldInvalid(addressForm.controls.suburb),
        'validation-success-mobile': fieldValid(addressForm.controls.suburb)
      }"
      class="mat-form-field--mobile"
      [floatLabel]="'auto'"
    >
      <div class="custom-field custom-field--label custom-field--label__sm">
        <mat-label
          ><span class="ws-nowrap">Suburb<span aria-hidden="true" class="custom-marker">*</span></span>
        </mat-label>
      </div>
      <div class="custom-field custom-field--input custom-field--input__lrg">
        <input matInput [formControlName]="'suburb'" required [autocomplete]="'new-sub'" />
      </div>
    </mat-form-field>
    <div class="validation-group">
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.suburb, 'required')">
        Please enter your suburb.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.suburb, 'pattern')">
        Suburb can contain the letters a-z and A-Z, the digits 0-9 and any of the following symbols: . -;:'_,\/
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.suburb, 'length')">
        Maximum length for suburb is 50 characters.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.suburb, 'minimallength')">
        Maximum length for suburb is 256 characters.
      </div>
    </div>

    <div class="divider"></div>
    <mat-form-field
      [ngClass]="{
        'validation-error-mobile': fieldInvalid(addressForm.controls.city),
        'validation-success-mobile': fieldValid(addressForm.controls.city)
      }"
      class="mat-form-field--mobile"
      [floatLabel]="'auto'"
    >
      <div class="custom-field custom-field--label custom-field--label__sm">
        <mat-label>City<span aria-hidden="true" class="custom-marker">*</span> </mat-label>
      </div>
      <div class="custom-field custom-field--input custom-field--input__lrg">
        <input matInput [formControlName]="'city'" required [autocomplete]="'new-ct'" />
      </div>
    </mat-form-field>
    <div class="validation-group">
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.city, 'required')">
        Please enter your city.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.city, 'pattern')">
        City can contain the letters a-z, A-Z and any of the following symbols: . -;:'_,\/
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.city, 'length')">
        Maximum length for city is 50 characters.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.city, 'minimallength')">
        Maximum length for city is 256 characters.
      </div>
    </div>

    <div class="divider"></div>
    <mat-form-field
      [ngClass]="{
        'validation-error-mobile': fieldInvalid(addressForm.controls.code),
        'validation-success-mobile': fieldValid(addressForm.controls.code)
      }"
      class="mat-form-field--mobile"
      [floatLabel]="'auto'"
    >
      <div class="custom-field custom-field--label custom-field--label__sm">
        <mat-label
          ><span class="ws-nowrap">Postal Code<span aria-hidden="true" class="text--required">*</span></span>
        </mat-label>
      </div>
      <div class="custom-field custom-field--input custom-field--input__lrg">
        <input matInput [formControlName]="'code'" required [autocomplete]="'new-cd'" />
      </div>
    </mat-form-field>
    <div class="validation-group">
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.code, 'required')">
        Please enter your postal code.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.code, 'pattern')">
        Postal code can only contain the digits 0-9.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.code, 'minimalpattern')">
        Postal code cannot contain special characters.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.code, 'length')">
        Maximum length for postal code is 10 characters.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(addressForm.controls.code, 'minimallength')">
        Maximum length for postal code is 11 characters.
      </div>
    </div>

    <div class="divider"></div>
    <eid-selector
      [ngClass]="{
        'validation-error-mobile': fieldInvalid(addressForm.controls.state),
        'validation-success-mobile': fieldValid(addressForm.controls.state)
      }"
      [formControlName]="'state'"
      class="selector selector--hasNoIcon"
      (selectionChange)="updateFormField('state', $event)"
      [selectorList]="states$ | async"
      [description]="provinceStateDescription"
      [(value)]="state"
      [required]="true"
    >
      {{ provinceStateDescription }}<span class="text--required">*</span>
    </eid-selector>
    <div class="validation-group">
      <div class="validation-message-mobile" *ngIf="fieldInvalid(addressForm.controls.state)">
        Please select your {{ provinceStateDescription | lowercase }}.
      </div>
    </div>
  </div>

  <div class="validation-summary" *ngIf="addressForm.invalid && (isSubmitted | async)">
    <p>Please fix the following fields and then submit the form:</p>
    <ul>
      <li *ngIf="fieldSummaryError(addressForm.controls.address)">Street address is invalid</li>
      <li *ngIf="fieldSummaryError(addressForm.controls.addressLine2)">Address 2 is invalid</li>
      <li *ngIf="fieldSummaryError(addressForm.controls.suburb)">Suburb is invalid</li>
      <li *ngIf="fieldSummaryError(addressForm.controls.city)">City is invalid</li>
      <li *ngIf="fieldSummaryError(addressForm.controls.code)">Postal code is invalid</li>
      <li *ngIf="fieldSummaryError(addressForm.controls.state)">{{ provinceStateDescription }} is required</li>
    </ul>
  </div>
</form>
