<div class="funded-accounts-card">
  <div class="funded-accounts-root">
    <a (click)="fundedAccounts()" class="funded-account-link">
      <div class="left">
        <mat-icon svgIcon="funds-white" class="funded-accounts-icon"></mat-icon>

        <div class="funded-container">
          <h2 class="title">Funded Trusts and Accounts</h2>

          <h3 class="subtitle">{{ (engagement$ | async)?.fundedAccount?.length }} Funded</h3>
        </div>
      </div>

      <div class="right" id="funded-chevron">
        <mat-icon svgIcon="chevron-right-lg" class="chevron"></mat-icon>
      </div>
    </a>
  </div>
</div>
