import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';
import { CountryCategory, ProductType } from '../../registration/models/product.model';
import { CountryIdEnum } from '../constants/enums';
import { SelectorList, SelectorListItem } from '../models/selector-list-item.model';
import { ProductService } from './product.service';

@Injectable({ providedIn: 'root' })
export class CountryService {
  constructor(private productService: ProductService) {}

  splitFavouritesFromCountries(countries, productType: ProductType) {
    const favouriteIds = this.getFavouriteCountryIds(productType);
    const countriesList = [];
    // This is done to keep the sorting of favourites as added to the list.
    favouriteIds.forEach((id) => {
      countriesList.push(countries.filter((c) => c.countryId === id)[0]);
    });
    return countriesList;
  }

  getMobileGroupedCountries(countries, productType: ProductType): SelectorList {
    // Get favourite countries that should show on top
    const favourites = this.splitFavouritesFromCountries(countries, productType);
    const favouriteSelectList = favourites.map(
      (country) => new SelectorListItem(country.countryName, country.countryId)
    );

    // Map countries to selectorList
    const countrySelectList = countries.map((country) => new SelectorListItem(country.countryName, country.countryId));
    // Group countries alphabetically.
    const grouped = groupBy(countrySelectList, (item) => {
      return item.label.charAt(0).toUpperCase();
    });

    // Add favourites to the top of the groups.
    return {
      ...grouped,
      '': favouriteSelectList
    };
  }

  getFavouriteCountryIds(productType: ProductType) {
    var productDetail = this.productService.getProductDetail(productType);
    let favouriteIds = [CountryIdEnum.australia, CountryIdEnum.southAfrica];

    if (this.isSAProduct(productType) || productDetail.countries === CountryCategory.cma) {
      favouriteIds = [CountryIdEnum.southAfrica];
    }

    if (productDetail.countries === CountryCategory.philippines) {
      return [CountryIdEnum.philippines, CountryIdEnum.malaysia];
    }
    return favouriteIds;
  }

  isSAProduct(productType: ProductType) {
    var productDetail = this.productService.getProductDetail(productType);
    if (productDetail.countries === CountryCategory.sa) {
      return true;
    }
    return false;
  }
}
