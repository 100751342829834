import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PartnerCategory, ProductDetailModel, ProductType } from '../../registration/models/product.model';
import { ProductService } from '../../shared/services/product.service';
import { RegisterStoreService } from '../../shared/services/register-store.service';
import { RegistrationState } from '../../store/registration.state';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'eid-layout',
  styleUrls: ['./layout.component.scss'],
  template: `
    <eid-layout-desktop *eidBreakpoint="'desktop'" class="eid-root-layout-desktop"></eid-layout-desktop>
    <eid-layout-mobile *eidBreakpoint="'mobile'" class="eid-root-layout-mobile"></eid-layout-mobile>
  `
})
export class LayoutContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class LayoutComponent implements OnInit {
  @Select(RegistrationState.productType) public productType$: Observable<ProductType>;
  public productDetail: ProductDetailModel;
  public partnerCategories = PartnerCategory;
  public mock: boolean;

  constructor(
    protected layoutService: LayoutService,
    protected registerStoreService: RegisterStoreService,
    private productService: ProductService,
    protected store: Store,
    @Inject(DOCUMENT) protected document: Document
  ) {
    this.productType$
      .pipe(
        map((productType) => {
          this.productDetail = this.productService.getProductDetail(productType);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.layoutService.detectTheme();
    this.mock = this.registerStoreService.getUseMockEE();
  }

  public setMockEE(mock: boolean): void {
    this.mock = mock;
    this.registerStoreService.updateUseMockEE(mock);
  }
}
