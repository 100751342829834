<div class="mobile-page-container">
  <form [formGroup]="addTaxForm">
    <!-- Tax Residences -->
    <ng-container>
      <!-- Tax residence (country) -->
      <div class="eid-input-control-group">
        <eid-selector
          [ngClass]="{
            'validation-error-mobile': fieldInvalid(addTaxForm.controls.taxCountry),
            'validation-success-mobile': fieldValid(addTaxForm.controls.taxCountry)
          }"
          class="selector selector--hasNoIcon selector--hasNoIcon__wrap"
          [formControlName]="'taxCountry'"
          [selectorGroupedList]="countrySelectList"
          [description]="'Tax Residence Country'"
          [(value)]="country"
          (selectionChange)="updateFormField('taxCountry', $event)"
          [required]="true"
          [highlightSelectedValue]="false"
        >
          Country<span class="text--required">*</span>
        </eid-selector>
        <div class="validation-group">
          <div class="validation-message-mobile" *ngIf="fieldTypeError(addTaxForm.controls.taxCountry, 'required')">
            Please select your tax residence.
          </div>
        </div>
        <div class="divider"></div>
        <!-- TAX ID TYPE -->
        <eid-selector
          [ngClass]="{
            'validation-error-mobile': fieldInvalid(addTaxForm.controls.taxIdentificationType),
            'validation-success-mobile': fieldValid(addTaxForm.controls.taxIdentificationType)
          }"
          class="selector selector--hasNoIcon selector--hasNoIcon__wrap"
          [formControlName]="'taxIdentificationType'"
          [searchable]="false"
          [selectorGroupedList]="taxIdentificationTypesSelectList$ | async"
          [description]="'Tax Identification Type'"
          [(value)]="taxType"
          (selectionChange)="updateFormField('taxIdentificationType', $event)"
          [required]="true"
        >
          Tax Identification Type<span class="text--required">*</span>
        </eid-selector>
        <div class="validation-group">
          <div
            class="validation-message-mobile"
            *ngIf="fieldTypeError(addTaxForm.controls.taxIdentificationType, 'required')"
          >
            Please select your tax identification type.
          </div>
        </div>
        <div class="divider"></div>

        <!-- TAX NUMBER -->
        <mat-form-field
          *ngIf="(showReason$ | async) === false"
          [ngClass]="{
            'validation-error-mobile': fieldInvalid(addTaxForm.controls.taxNumber),
            'validation-success-mobile': fieldValid(addTaxForm.controls.taxNumber)
          }"
          class="mat-form-field--mobile"
          [floatLabel]="'always'"
        >
          <div class="custom-field custom-field--label">
            <mat-label>Tax Number<span class="text--required">*</span></mat-label>
          </div>
          <div class="custom-field custom-field--input">
            <input matInput [formControlName]="'taxNumber'" required [autocomplete]="'new-fn'" />
          </div>
        </mat-form-field>
        <div class="validation-group">
          <div class="validation-message-mobile" *ngIf="fieldTypeError(addTaxForm.controls.taxNumber, 'required')">
            Please enter your tax number.
          </div>
          <div class="validation-message-mobile" *ngIf="fieldTypeError(addTaxForm.controls.taxNumber, 'length')">
            Tax number must be exactly 10 characters.
          </div>
          <div class="validation-message-mobile" *ngIf="fieldTypeError(addTaxForm.controls.taxNumber, 'startWith')">
            Tax number should start with 0,1,2,3 or 9.
          </div>
          <div class="validation-message-mobile" *ngIf="fieldTypeError(addTaxForm.controls.taxNumber, 'spaces')">
            Tax number cannot contain spaces.
          </div>
          <div
            class="validation-message-mobile"
            *ngIf="fieldTypeError(addTaxForm.controls.taxNumber, 'startEndSpaces')"
          >
            Tax number cannot start or end with spaces.
          </div>
          <div class="validation-message-mobile" *ngIf="fieldTypeError(addTaxForm.controls.taxNumber, 'pattern')">
            Tax number cannot contain special characters.
          </div>
        </div>

        <!-- REASON -->
        <mat-form-field
          *ngIf="(showReason$ | async) === true"
          [ngClass]="{
            'validation-error-mobile': fieldInvalid(addTaxForm.controls.taxNoNumberReason),
            'validation-success-mobile': fieldValid(addTaxForm.controls.taxNoNumberReason)
          }"
          class="mat-form-field--mobile"
          [floatLabel]="'always'"
        >
          <div class="custom-field custom-field--label">
            <mat-label>Reason<span class="text--required">*</span></mat-label>
          </div>
          <div class="custom-field custom-field--input">
            <input matInput [formControlName]="'taxNoNumberReason'" required [autocomplete]="'new-fn'" />
          </div>
        </mat-form-field>
        <div *ngIf="(showReason$ | async) === true" class="form-hint form-hint--mobile strong">
          Explanation for why Tax Number / TIN is {{ typeSelectedDescription }}.
        </div>

        <div class="validation-group">
          <div
            class="validation-message-mobile"
            *ngIf="fieldTypeError(addTaxForm.controls.taxNoNumberReason, 'required')"
          >
            Please enter your reason why Tax Number / TIN is {{ typeSelectedDescription }}.
          </div>
        </div>
      </div>
    </ng-container>
  </form>

  <div class="validation-summary" *ngIf="addTaxForm.invalid && isSubmitted">
    <p>Please fix the following fields and then submit the form:</p>
    <ul>
      <li *ngIf="fieldSummaryError(addTaxForm.controls.taxCountry)">Tax residency country is required</li>
      <li *ngIf="fieldSummaryError(addTaxForm.controls.taxIdentificationType)">Tax identification type is required</li>
      <li *ngIf="fieldSummaryError(addTaxForm.controls.taxNumber)">Tax number is not valid</li>
      <li *ngIf="fieldSummaryError(addTaxForm.controls.taxNoNumberReason)">
        Reason why Tax Number / TIN is {{ typeSelectedDescription }}, is invalid
      </li>
    </ul>
  </div>

  <div class="mobile-page-actions">
    <button (click)="save()" class="btn-solid btn-solid--primary">Save</button>
  </div>
</div>
