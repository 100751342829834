import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { UrlBundle } from '../../../../shared/models/urlBundle';
import { UrlProviderService } from '../../../../shared/services/url.provider';

@UntilDestroy()
@Component({
  selector: 'eid-footer-profile',
  templateUrl: './footer-profile.component.html',
  styleUrls: ['./footer-profile.component.scss']
})
export class FooterProfileComponent {
  public urlBundle$: Observable<UrlBundle>;
  public isProfile: boolean;
  public isDashboard: boolean;

  constructor(private urlProviderService: UrlProviderService) {
    const url = window.location.href;
    this.isProfile = url.indexOf('/profile/') > -1;
    this.isDashboard = url.indexOf('/dashboard') > -1;
    this.urlBundle$ = this.urlProviderService.urlBundle$;
  }
}
