<div class="intro-box">
  <div class="column column--image">
    <img src="assets/icons/intro/intro-four.png" alt="intro-four" />
  </div>
  <div class="column column--content">
    <div class="close-btn">
      <mat-icon svgIcon="close" class="close-icon" (click)="close()"></mat-icon>
    </div>
    <div class="copy-column">
      <div class="copy">
        <h2>As cheap as chips and easy as pie</h2>
        <p>
          Keep more of your cash for epic adventures! Enjoy affordable investing on SA’s cheapest platform with low
          fees, and no minimums.
        </p>
      </div>
      <div class="intro-step">
        <eid-intro-stepper [active]="4" (stepSelected)="stepSelected($event)"></eid-intro-stepper>
      </div>
    </div>
  </div>
</div>
