import { HttpClient } from '@angular/common/http';
import { inject, InjectionToken } from '@angular/core';
import { environment } from '../../environments/environment';

import { LogLevel } from './log-level';

interface LogMessage {
  level: LogLevel;
  message: string;
  meta?: any;
}

export enum LogType {
  noop = 0,
  console = 1,
  webApi = 2
}

export const LOGGER = new InjectionToken<Logger>('Logger Service', {
  factory: () => {
    if (environment.logType === LogType.console) {
      return new ConsoleLogger();
    }
    if (environment.logType === LogType.webApi) {
      return new WebApiLogger(inject(HttpClient));
    }
    return new NoopLogger();
  }
});

export abstract class Logger {
  trace = (message: string, meta?: any) => this.log(LogLevel.Trace, message, meta);
  debug = (message: string, meta?: any) => this.log(LogLevel.Debug, message, meta);
  info = (message: string, meta?: any) => this.log(LogLevel.Information, message, meta);
  warn = (message: string, meta?: any) => this.log(LogLevel.Warning, message, meta);
  error = (message: string, meta?: any) => this.log(LogLevel.Error, message, meta);
  critical = (message: string, meta?: any) => this.log(LogLevel.Critical, message, meta);

  abstract log(level: LogLevel, message: string, meta?: any): void;
}

export class NoopLogger extends Logger {
  log(level: LogLevel, message: string, meta: any): void {}
}

export class ConsoleLogger extends Logger {
  private prefix = environment.logPrefix;
  private logLevel = environment.logLevel;

  log(level: LogLevel, message: string, meta?: any): void {
    if (this.logLevel > level) {
      return;
    }

    switch (level) {
      case LogLevel.Trace:
        // tslint:disable-next-line: no-console
        return console.trace(`${this.prefix} ${message}`, meta);

      case LogLevel.Debug:
        // tslint:disable-next-line: no-console
        return console.debug(`${this.prefix} ${message}`, meta);

      case LogLevel.Information:
        // tslint:disable-next-line: no-console
        return console.info(`${this.prefix} ${message}`, meta);

      case LogLevel.Warning:
        return console.warn(`${this.prefix} ${message}`, meta);

      case LogLevel.Error:
        return console.error(`${this.prefix} ${message}`, meta);

      case LogLevel.Critical:
        return console.error(`${this.prefix} ${message}`, meta);

      case LogLevel.None:
        break;
    }
  }
}

export class WebApiLogger extends Logger {
  private prefix = environment.logPrefix;
  private logLevel = environment.logLevel;

  constructor(private httpClient: HttpClient) {
    super();
  }

  log(level: LogLevel, message: string, meta?: any): void {
    if (this.logLevel > level) {
      return;
    }

    const body: LogMessage = {
      level,
      message: this.prefix + ' ' + message,
      meta
    };

    this.httpClient.post('/api/logger', body).subscribe();
  }
}
