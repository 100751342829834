import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { PartnerCategory } from '../registration/models/product.model';
import { TenantIdEnum } from '../shared/constants/enums';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ProductService } from '../shared/services/product.service';
import { UserStoreService } from '../shared/services/user-store.service';
import { GuardService } from './guard.service';

@Injectable()
export class HomeGuard implements CanActivate {
  constructor(
    private guardService: GuardService,
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private userStoreService: UserStoreService,
    private router: Router
  ) {}

  canActivate() {
    //You can only go to the home screen if we checked that you are not logged in on the IDP.
    if (!this.guardService.isInitialized()) {
      return false;
    }

    if (this.authenticationService.isUserLoggedIn()) {
      this.authenticationService.checkIsLoggedIn();
      return false;
    }

    const user = this.userStoreService.getUser();
    const productDetail = this.productService.getProductDetail(user.productType);
    if (user.tenantId === TenantIdEnum.gcash || productDetail.partnerCategory === PartnerCategory.full) {
      this.router.navigate(['/message', 'error']);
      return false;
    }

    return true;
  }
}
