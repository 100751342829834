<div class="header">
  <div class="wrapper">
    <div class="main-header container">
      <div class="row row--justify-space-between row--align-center">
        <!-- LOGO -->
        <div class="logo">
          <div *ngIf="(productType$ | async) !== productTypes.satrix" class="logo__app"></div>
          <div *ngIf="(productType$ | async) === productTypes.satrix" class="product-logo"></div>
        </div>

        <div class="main-header main-header--actions">
          <button
            *ngIf="(productType$ | async) !== productTypes.satrix"
            class="btn btn--grip-menu"
            id="easyAppsMenuSource"
            [matMenuTriggerFor]="collapsedMenu"
          >
            <mat-icon svgIcon="grip-dotted"></mat-icon>
          </button>

          <a class="question-mark" [href]="(urlBundle$ | async)?.support" target="_blank">
            <mat-icon svgIcon="question-mark"></mat-icon>
          </a>

          <!-- CURRENT USER -->
          <eid-current-user></eid-current-user>
        </div>
      </div>
    </div>
    <div class="sub-header container">
      <div class="row row--flex-start">
        <div class="row">
          <!-- MENU -->
          <div *ngIf="(productType$ | async) !== productTypes.satrix" class="eid-menu">
            <div *ngFor="let item of menus$ | async">
              <div class="eid-menu-item eid-menu-item--{{ item.id }}">
                <a
                  *ngIf="item.url"
                  [href]="item.url"
                  [ngClass]="{
                    active: item.active
                  }"
                >
                  <span>{{ item.label }}</span>
                </a>
                <a
                  *ngIf="item.routerLink"
                  [routerLink]="item.routerLink"
                  [ngClass]="{
                    active: item.active
                  }"
                >
                  <span>{{ item.label }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-menu #collapsedMenu="matMenu" class="eid-collapsed-menu">
  <div class="eid-collapsed-menu--heading">
    <mat-icon svgIcon="eid-apps"></mat-icon>
    <h3>EasyApps</h3>
  </div>
  <ul class="menu-list">
    <li *ngFor="let item of productDisplayList" class="item-container">
      <div mat-menu-item>
        <ng-container>
          <a [href]="item.redirectUrl" target="_blank" class="nav-item nav-item--{{ item.id }}">
            <mat-icon *ngIf="item.icon" mat-list-icon [svgIcon]="item.icon"></mat-icon>
            <span *ngIf="item.name">{{ item.name }}</span>
          </a>
        </ng-container>
      </div>
    </li>
  </ul>
</mat-menu>
