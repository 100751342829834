import { GameStateModel } from '../shared/models/game-state.model';

export class Game {
  static readonly type = '[Game] Game';
}

export class UpdateGameState {
  static readonly type = '[Game] Update Game State';
  constructor(public payload: GameStateModel) {}
}

export class UpdateGamePath {
  static readonly type = '[Game] Update Game Path of deep link';
  constructor(public payload: string) {}
}

export class UpdateGameRedirect {
  static readonly type = '[Game] Update Game redirect url';
  constructor(public payload: string) {}
}
