<form [formGroup]="addressForm" autocomplete="off">
  <input style="display: none" />
  <input type="search" style="display: none" />

  <div>
    <div class="row">
      <!-- Address -->
      <div class="eid-input-control-group form-group">
        <div class="form-group">
          <label class="form-group__label">Street Address<span class="required text--accent">*</span></label>
          <input
            [ngClass]="{
              'validation-error': fieldInvalid(addressForm.controls.address),
              'validation-success': fieldValid(addressForm.controls.address)
            }"
            #search
            class="form-group__control"
            type="search"
            formControlName="address"
            placeholder="Search your address"
            (focus)="geolocate()"
            (keydown.enter)="$event.preventDefault()"
          />
          <div *ngIf="fieldTypeError(addressForm.controls.address, 'required')" class="validation-message">
            Please enter your street number and street name.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.address, 'numberPattern')" class="validation-message">
            Street number can contain the letters a-z and A-Z, the digits 0-9 and any of the following symbols: .
            -;:'_,\/
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.address, 'namePattern')" class="validation-message">
            Street name can not contain "
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.address, 'length')" class="validation-message">
            Maximum length for street number is 5 characters and for street name is 50 characters.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.address, 'minimallength')" class="validation-message">
            Maximum length for street number is 5 characters and for street name is 256 characters.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.address, 'blockedUnicode')" class="validation-message">
            Street name may not contain the following symbols: × ‘ ’
          </div>
        </div>
      </div>

      <!-- Address 2 -->
      <div class="eid-input-control-group form-group">
        <div class="form-group">
          <label class="form-group__label">Address 2 (optional)</label>
          <input
            [ngClass]="{
              'validation-error': fieldInvalid(addressForm.controls.addressLine2),
              'validation-success': fieldValid(addressForm.controls.addressLine2)
            }"
            class="form-group__control"
            type="text"
            formControlName="addressLine2"
            autocomplete="new-add2"
          />
          <div class="form-group__hint">Complex / building name, unit number or floor</div>
          <div *ngIf="fieldTypeError(addressForm.controls.addressLine2, 'required')" class="validation-message">
            Please enter your unit/floor number and complex/building name.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.addressLine2, 'numberPattern')" class="validation-message">
            Unit/floor number can contain the letters a-z and A-Z, and the digits 0-9.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.addressLine2, 'namePattern')" class="validation-message">
            Complex/building name can contain the letters a-z, A-Z and any of the following symbols: . -;:'_,\/
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.addressLine2, 'length')" class="validation-message">
            Maximum length for unit/floor number is 5 characters and for complex/building name is 25 characters.
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Suburb -->
      <div class="eid-input-control-group form-group">
        <div class="form-group">
          <label class="form-group__label">Suburb<span class="required text--accent">*</span></label>
          <input
            [ngClass]="{
              'validation-error': fieldInvalid(addressForm.controls.suburb),
              'validation-success': fieldValid(addressForm.controls.suburb)
            }"
            class="form-group__control"
            type="text"
            formControlName="suburb"
            autocomplete="new-suburb"
          />
          <div *ngIf="fieldTypeError(addressForm.controls.suburb, 'required')" class="validation-message">
            Please enter your suburb.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.suburb, 'pattern')" class="validation-message">
            Suburb can contain the letters a-z and A-Z, the digits 0-9 and any of the following symbols: . -;:'_,\/
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.suburb, 'length')" class="validation-message">
            Maximum length for suburb is 50 characters.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.suburb, 'minimallength')" class="validation-message">
            Maximum length for suburb is 256 characters.
          </div>
        </div>
      </div>
      <!-- City -->
      <div class="eid-input-control-group form-group">
        <div class="form-group">
          <label class="form-group__label">City<span class="required text--accent">*</span></label>
          <input
            [ngClass]="{
              'validation-error': fieldInvalid(addressForm.controls.city),
              'validation-success': fieldValid(addressForm.controls.city)
            }"
            class="form-group__control"
            type="text"
            formControlName="city"
            autocomplete="'new-city'"
          />
          <div *ngIf="fieldTypeError(addressForm.controls.city, 'required')" class="validation-message">
            Please enter your city.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.city, 'pattern')" class="validation-message">
            City can contain the letters a-z, A-Z and any of the following symbols: . -;:'_,\/
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.city, 'length')" class="validation-message">
            Maximum length for city is 50 characters.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.city, 'minimallength')" class="validation-message">
            Maximum length for city is 256 characters.
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Code -->
      <div class="eid-input-control-group form-group">
        <div class="form-group">
          <label class="form-group__label">Postal Code<span class="required text--accent">*</span></label>
          <input
            [ngClass]="{
              'validation-error': fieldInvalid(addressForm.controls.code),
              'validation-success': fieldValid(addressForm.controls.code)
            }"
            class="form-group__control form-group__control--sml"
            type="text"
            formControlName="code"
            autocomplete="new-code"
          />
          <div *ngIf="fieldTypeError(addressForm.controls.code, 'required')" class="validation-message">
            Please enter your postal code.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.code, 'pattern')" class="validation-message">
            Postal code can only contain the digits 0-9.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.code, 'minimalpattern')" class="validation-message">
            Postal code cannot contain special characters.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.code, 'length')" class="validation-message">
            Maximum length for postal code is 10 characters.
          </div>
          <div *ngIf="fieldTypeError(addressForm.controls.code, 'minimallength')" class="validation-message">
            Maximum length for postal code is 11 characters.
          </div>
        </div>
      </div>
      <!-- State -->
      <div class="eid-input-control-group form-group">
        <mat-form-field class="mat-select-form-field" floatLabel="always">
          <mat-label>{{ provinceStateDescription }}<span class="required text--accent">*</span></mat-label>
          <mat-select
            [ngClass]="{
              'validation-error': fieldInvalid(addressForm.controls.state),
              'validation-success': fieldValid(addressForm.controls.state)
            }"
            formControlName="state"
            placeholder="Select"
          >
            <mat-option [value]="''">Select</mat-option>
            <mat-option *ngFor="let opt of states$ | async" [(value)]="opt.value">
              {{ opt.label }}
            </mat-option>
          </mat-select>
          <mat-error>Please select your {{ provinceStateDescription | lowercase }}. </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
