import { AffiliateDetail } from '../../registration/models/affiliate.model';
import { ContractingEntity } from '../../registration/models/contracting.model';
import { CountrySelect } from '../../registration/models/country.model';
import { IdVerify } from '../../registration/models/id-verify.model';
import { PersonalDetail } from '../../registration/models/personal.model';
import { ProductType } from '../../registration/models/product.model';
import { TaxCompliance } from '../../registration/models/tax-compliance.model';
import { User } from '../../registration/models/user.model';
import { Address } from './address.model';
import { FicaDetail } from './financial.model';
import { JumioDetail } from './jumio.model';
import { OnfidoDetail } from './onfido.model';
import { ProfileType } from './profile.model';

export class RegistrationStateModel {
  registrationId: string;
  countrySelect: CountrySelect;
  contractingEntity: ContractingEntity;
  productType: ProductType;
  incomingProductType: ProductType;
  affiliate: AffiliateDetail;
  profileType: ProfileType;
  partnerInformation: string;
  partnerReference: string;
  enqueueRegistration: boolean;
  guardianUserId: string;
  user: User;
  personalDetail: PersonalDetail;
  address: Address;
  ficaDetail: FicaDetail;
  taxCompliance: TaxCompliance;
  idVerify: IdVerify;
  jumioDetails: JumioDetail[];
  onfidoDetails: OnfidoDetail[];
  stepValidators: StepValidator[];
  useMockEE: boolean;
  kidAddress: boolean;
  kidCommunication: boolean;
}

export class RegistrationExistsModel {
  registrationExists: boolean;
  isMatch: boolean;
  registrationState: RegistrationStateModel;
}

export class UsernameExistsModel {
  usernameExists: boolean;
  registrationExists: boolean;
}
export class StepValidator {
  step: string;
  valid: boolean;
}

export const Steps = {
  country: 'country',
  user: 'create-id',
  personalDetail: 'personal',
  address: 'address',
  ficaDetail: 'fica',
  verification: 'verification'
};
