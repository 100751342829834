import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LOGGER, Logger } from '../../../../logging/logger.service';
import { ProductDetailModel, ProductType } from '../../../../registration/models/product.model';
import { Theme } from '../../../../registration/models/theme.model';
import { RegistrationState } from '../../../../store/registration.state';
import { UrlBundle } from '../../../models/urlBundle';
import { ProductService } from '../../../services/product.service';
import { RegisterStoreService } from '../../../services/register-store.service';
import { UrlProviderService } from '../../../services/url.provider';

@UntilDestroy()
@Component({
  selector: 'eid-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  @Select(RegistrationState.productType) public productType$: Observable<ProductType>;

  public productTypes = ProductType;
  public themes = Theme;

  public productDetail: ProductDetailModel;
  public urlBundle$: Observable<UrlBundle>;

  constructor(
    @Inject(LOGGER) private logger: Logger,
    public urlProviderService: UrlProviderService,
    private location: Location,
    private registerStoreService: RegisterStoreService,
    private productService: ProductService
  ) {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
    const productType = this.registerStoreService.getProduct();
    this.productDetail = this.productService.getProductDetail(productType);
  }

  ngOnInit(): void {
    var error = new Error();
    this.logger.error('ErrorPageComponent ngOnInit ', error.stack);
  }

  public supportUrl(): void {
    this.urlBundle$.pipe(untilDestroyed(this)).subscribe((urlBundle) => {
      window.location.href = urlBundle.support;
    });
  }

  public newTicketUrl(): void {
    this.urlBundle$.pipe(untilDestroyed(this)).subscribe((urlBundle) => {
      window.location.href = urlBundle.newTicket;
    });
  }

  public back(): void {
    this.location.back();
  }
}
