<div class="engagement" eidLoadingSpinner [loading]="loading === true">
  <eid-beta *ngIf="showBeta" (closed)="closeBeta($event)" class="beta"></eid-beta>

  <eid-month-selector-mobile></eid-month-selector-mobile>

  <eid-share [blurred]="blur" (blur)="toggleBlur($event)"></eid-share>

  <eid-blur-mobile [blurred]="blur" [isModal]="false" (blur)="toggleBlur($event)"></eid-blur-mobile>

  <div id="engagementimagecanvas">
    <eid-portfolio-mobile [blur]="blur"></eid-portfolio-mobile>

    <eid-account-activity-mobile></eid-account-activity-mobile>

    <eid-funded-accounts-mobile></eid-funded-accounts-mobile>

    <eid-holdings-mobile></eid-holdings-mobile>

    <eid-thrive-mobile></eid-thrive-mobile>

    <eid-dividend-mobile></eid-dividend-mobile>

    <eid-deposits-mobile></eid-deposits-mobile>

    <eid-live-prices-mobile></eid-live-prices-mobile>

    <eid-referrals-mobile></eid-referrals-mobile>
  </div>
</div>
