import { Component } from '@angular/core';

import { RootLayoutComponent } from './root-layout.component';

@Component({
  selector: 'eid-root-layout-mobile',
  templateUrl: './root-layout.mobile.component.html',
  styleUrls: ['./root-layout.mobile.component.scss']
})
export class RootLayoutMobileComponent extends RootLayoutComponent {}
