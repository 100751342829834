import { Component } from '@angular/core';

import { ProductComponent } from './product.component';

@Component({
  selector: 'eid-product-desktop',
  templateUrl: './product.desktop.component.html',
  styleUrls: ['./product.desktop.component.scss']
})
export class ProductDesktopComponent extends ProductComponent {}
