import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { MenuItem } from '../../shared/models/menu-item.model';

@Component({
  selector: 'eid-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {
  @HostBinding('attr.class') private layoutCls = 'eid-menu-item';
  @Input() public item: MenuItem;

  public get hasSubmenu(): boolean {
    return this.item.children && this.item.children.length > 0;
  }

  constructor() {}

  ngOnInit(): void {
    this.layoutCls += ` eid-menu-item--${this.item.id}`;
  }
}
