<div class="footer">
  <div class="icon-container">
    <div class="footer-icon" *ngIf="isDashboard">
      <a class="footer-icon-gs" [routerLink]="['/dashboard']" [ngClass]="{ active: isDashboard }">
        <img alt="View Home" class="mb-4" id="home" src="assets/icons/home-footer-active.svg" />
        <label for="home">Home</label>
      </a>
    </div>
    <div class="footer-icon" *ngIf="!isDashboard">
      <a class="footer-icon-gs" [routerLink]="['/dashboard']">
        <img alt="View Home" class="mb-4" id="home" src="assets/icons/home-footer.svg" />
        <label for="home">Home</label>
      </a>
    </div>
    <div class="footer-icon" *ngIf="isProfile">
      <a class="footer-icon-gs" [routerLink]="['/profile/personal']" [ngClass]="{ active: isProfile }">
        <img alt="Profile" id="profile" class="mb-4" src="assets/icons/profile-footer-active.svg" />
        <label for="profile">Profile</label>
      </a>
    </div>
    <div class="footer-icon" *ngIf="!isProfile">
      <a class="footer-icon-gs" [routerLink]="['/profile/personal']">
        <img alt="Profile" id="profile" class="mb-4" src="assets/icons/profile-footer.svg" />
        <label for="profile">Profile</label>
      </a>
    </div>
    <div class="footer-icon">
      <a class="footer-icon-gs" [href]="(urlBundle$ | async)?.apps">
        <img alt="Apps" id="apps" class="mb-4" src="assets/icons/apps-footer.svg" />
        <label for="apps" class="active">Apps</label>
      </a>
    </div>
    <div class="footer-icon">
      <a class="footer-icon-gs" [href]="(urlBundle$ | async)?.security">
        <img alt="View Security" id="security" class="mb-4" src="assets/icons/security-footer.svg" />
        <label for="security">Security</label>
      </a>
    </div>
  </div>
</div>
