<div *ngIf="(productType$ | async) !== productTypes.satrix" class="section section--hero section--home-page">
  <div class="container">
    <div class="row row--justify-center">
      <div class="heading">
        <h3 class="heading__title">Welcome to EasyID</h3>
        <p class="heading__content">One account for all of your favourite EasyApps</p>
        <button class="btn-solid btn-solid--primary btn-solid--rounded heading__btn" type="button" (click)="login()">
          Login
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(productType$ | async) !== productTypes.satrix" class="section">
  <div class="container container--home-page">
    <div class="column">
      <div class="panel panel--landing-page">
        <div class="panel__title panel__title--center">
          <h3>What is EasyID?</h3>
          <div class="content">
            <p>
              An EasyID is the <strong>personal account</strong> you use to access Easy Apps like EasyEquities, MrPhy,
              EasyProperties, EasyCrypto and others to come in the future.
            </p>
            <p>
              It includes the information you use to sign in, as well as all the contact, fica, tax, and banking details
              that you’ll use across Easy Apps and services.
            </p>
          </div>

          <div class="image" [style.background]="'url(../../../assets/images/apps.svg) no-repeat'"></div>
        </div>
      </div>
      <div class="panel panel--landing-page panel--landing-page__grey-bg">
        <div class="panel__title panel__why-sign-up">
          <div class="row row--content-list">
            <!-- <div class="col col--image"></div> -->
            <div class="col col--image" [style.background-image]="'var(--eid-why-bg)'"></div>
            <div class="col col--list">
              <h3 class="list--heading">Why sign up for an EasyID?</h3>
              <ul class="list--bullet">
                <li>Safe and secure</li>
                <li>No need to remember multiple passwords and usernames</li>
                <li>All your apps are accessible from one place</li>
              </ul>
              <button class="btn-solid btn-solid--primary btn-solid--rounded" type="button" (click)="createEasyId()">
                Create your EasyID
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="panel panel--landing-page">
        <div class="panel__title panel__faq">
          <h3>Frequently Asked Questions</h3>
          <div class="col col--faq-list">
            <ul class="faq-list">
              <li>
                <span>What is an EasyID?</span>
                <p>
                  An EasyID is the personal account you use to access Easy Apps like EasyEquities, MrPhy,
                  EasyProperties, EasyCrypto and others to come in the future. It includes the information you use to
                  sign in, as well as all the contact, FICA, tax, and banking details that you’ll use across Easy Apps
                  and services.
                </p>
              </li>
              <li>
                <span>When do I use my EasyID?</span>
                <p>
                  Any time you activate a new Easy service or use any Easy app on desktop or mobile, you will be asked
                  to sign in with your EasyID and password. Once signed in you’ll have access to the service and all the
                  personal information in your account.
                </p>
              </li>
              <li>
                <span>How many EasyIDs do I need?</span>
                <p>
                  Just one. Use the same EasyID everywhere you sign in to ensure that all your Easy apps work together
                  seamlessly and you can access your wallet funds via inter-account transfers between your Easy Apps.
                </p>
              </li>
              <li>
                <span>How can I keep my EasyID secure?</span>
                <p>
                  Security and privacy are very important to us and we provide a number of ways to secure your EasyID
                  and protect your privacy including strong passwords, encryption, and more.
                </p>
              </li>
            </ul>
          </div>

          <div class="btn-outline btn-outline--green" type="button">
            <mat-icon>info_outline</mat-icon>
            <div class="info-btn-content">
              <span>For more information or help, visit</span>
              <a [href]="(urlBundle$ | async)?.support" target="_blank" class="support-link"> EasyEquities Support</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(productType$ | async) === productTypes.satrix" class="section section--hero section--bg-create-id"></div>

<div *ngIf="(productType$ | async) === productTypes.satrix" class="section">
  <div class="container">
    <div class="row row--justify-center">
      <div class="panel panel--padded-half panel--faq">
        <div class="panel__title panel__title--center">
          <h2>Frequently Asked Questions</h2>
          <p>
            Being an online business furthers our cause to make investing accessible to everyone. This includes
            empowering you to access any information you may need on our platform, at any time.
          </p>
        </div>

        <div class="message message--list">
          <div class="row align-top">
            <div class="col message__icon">
              <img src="./assets/icons/video.png" />
            </div>
            <div class="col message__text">
              <h2 class="panel__title">Watch our video</h2>
              <p>
                <a [href]="(urlBundle$ | async)?.video" target="_blank">Watch our video</a>
                for an overview of the SatrixNOW platform and how to log in and invest.
              </p>
            </div>
          </div>
          <hr />
          <div class="row align-top">
            <div class="col message__icon">
              <img src="./assets/icons/help-centre.png" />
            </div>
            <div class="col message__text">
              <h2 class="panel__title">Visit our help centre</h2>
              <p>
                Our intuitive and easy-to-use
                <a [href]="(urlBundle$ | async)?.support" target="_blank">Help Centre</a>
                is searchable and contains FAQs which we are constantly updating.
              </p>
              <p>
                <a [href]="(urlBundle$ | async)?.newTicket" target="_blank">Submit a query here</a>
                if you can't find what you need in the FAQs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
