<form [formGroup]="crsTaxForm">
  <div class="tw-flex tw-flex-col tw-pb-10 tw-items-baseline">
    <label
      *ngIf="
        productDetail.complianceCategory === complianceCategories.combined ||
        residenceCountryId !== countryId.southAfrica
      "
      class="tw-text-left tw-pb-2.5 text-primary"
      >Are you registered for tax purposes?<span class="text--required">*</span></label
    >
    <label
      *ngIf="
        productDetail.complianceCategory !== complianceCategories.combined &&
        residenceCountryId === countryId.southAfrica
      "
      class="tw-text-left tw-pb-2.5 text-primary"
      >Do you have any tax obligations <span class="text--underline">outside</span> of South Africa?<span
        class="text--required"
        >*</span
      ></label
    >
    <div class="form-radio-group">
      <label class="form-radio" for="yes">
        <input type="radio" formControlName="taxRegistered" id="registered-yes" [value]="true" />
        <span class="form-radio__button">
          <span class="box"></span>
          <span class="label">Yes</span>
        </span>
      </label>
      <label class="form-radio" for="no">
        <input type="radio" formControlName="taxRegistered" id="registered-no" [value]="false" />
        <span class="form-radio__button">
          <span class="box"></span>
          <span class="label">No</span>
        </span>
      </label>
    </div>
  </div>

  <!-- ADD REASON FOR NOT HAVING A TAX NUMBER-->
  <div
    *ngIf="(taxRegistered$ | async) === false && residenceCountryId !== countryId.southAfrica"
    class="tw-flex tw-flex-col tw-pb-10 tw-items-baseline"
  >
    <label class="text-primary-large tw-pb-2.5">Reason<span class="required text--accent"> *</span></label>
    <input
      [ngClass]="{
        'validation-error': fieldInvalid(crsTaxForm.controls.noDataReason),
        'validation-success': fieldValid(crsTaxForm.controls.noDataReason)
      }"
      class="form-group__control tw-w-full"
      type="search"
      required
      formControlName="noDataReason"
      autocomplete="off"
      data-lpignore="true"
    />
    <div class="validation-group">
      <div class="validation-message" *ngIf="fieldTypeError(crsTaxForm.controls.noDataReason, 'required')">
        Please enter your reason for not being tax registered.
      </div>
      <div class="validation-message" *ngIf="fieldTypeError(crsTaxForm.controls.noDataReason, 'pattern')">
        Reason for not being tax registered cannot start or end with a space.
      </div>
    </div>
  </div>

  <div *ngIf="(taxRegistered$ | async) === true && (taxResidences$ | async)?.length > 0" class="row">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
      <ng-container matColumnDef="taxResidence">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="taxResidence">Tax Residence</th>
        <td mat-cell *matCellDef="let element">
          {{ element.taxResidence }}
        </td>
      </ng-container>

      <ng-container matColumnDef="taxType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="taxType">Tax ID Type</th>
        <td mat-cell *matCellDef="let element">{{ element.taxType }}</td>
      </ng-container>

      <ng-container matColumnDef="taxNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="taxNumber" class="align-right">
          Tax Number / Tax Identification Number (TIN)
        </th>
        <td mat-cell *matCellDef="let element">{{ element.taxNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="taxNoNumberReason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="taxNoNumberReason">Reason</th>
        <td mat-cell *matCellDef="let element">{{ element.taxNoNumberReason }}</td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length"></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns" class="example-element-row"></tr>
    </table>
  </div>

  <div *ngIf="(taxRegistered$ | async) === true" class="tw-flex tw-flex-col">
    <label
      *ngIf="
        productDetail.complianceCategory !== complianceCategories.combined &&
        residenceCountryId === countryId.southAfrica
      "
      class="text-primary-large tw-text-left tw-pb-5"
    >
      Please add all jurisdictions <span class="text--underline">outside</span> of South Africa in which you are a tax
      resident and/or required to pay tax:
    </label>
    <label
      *ngIf="
        productDetail.complianceCategory === complianceCategories.combined ||
        residenceCountryId !== countryId.southAfrica
      "
      class="text-primary-large tw-text-left tw-pb-5"
    >
      Please add all jurisdictions in which you are tax resident and/or required to pay tax:
    </label>

    <div class="tw-flex tw-flex-row tw-justify-between">
      <div class="tw-flex tw-flex-col tw-grow tw-mr-2.5">
        <mat-form-field class="mat-select-form-field" floatLabel="always">
          <mat-label>Tax Residence<span class="required text--accent">*</span></mat-label>
          <mat-select
            [ngClass]="{
              'validation-error': fieldInvalid(crsTaxForm.controls.taxCountry),
              'validation-success': fieldValid(crsTaxForm.controls.taxCountry)
            }"
            formControlName="taxCountry"
            placeholder="Select"
          >
            <mat-option [value]="''">Select</mat-option>
            <mat-option
              *ngFor="let opt of countrySelectListItems; let i = index"
              [(value)]="opt.value"
              [ngClass]="{ 'has-underline': hasUnderline(i) }"
            >
              {{ opt.label }}
            </mat-option>
          </mat-select>
          <mat-error>Please select your tax residence.</mat-error>
        </mat-form-field>
      </div>
      <div class="tw-flex tw-flex-col tw-grow tw-mr-2.5">
        <mat-form-field class="mat-select-form-field mat-select-form-field--id-type" floatLabel="always">
          <mat-label>Tax Identification Type<span class="required text--accent">*</span></mat-label>
          <mat-select
            [ngClass]="{
              'validation-error': fieldInvalid(crsTaxForm.controls.taxIdentificationType),
              'validation-success': fieldValid(crsTaxForm.controls.taxIdentificationType)
            }"
            formControlName="taxIdentificationType"
            placeholder="Select"
          >
            <mat-option [value]="''">Select</mat-option>
            <mat-option *ngFor="let opt of taxIdentificationTypesSelectList$ | async" [(value)]="opt.value">
              {{ opt.label }}
            </mat-option>
          </mat-select>
          <mat-error>Please select your tax identification type.</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="(showReason$ | async) === false" class="tw-flex tw-flex-col tw-grow">
        <div class="form-group">
          <label class="form-group__label tw-text-left"
            >Tax Number / TIN<span class="required text--accent">*</span></label
          >
          <input
            [ngClass]="{
              'validation-error': fieldInvalid(crsTaxForm.controls.taxNumber),
              'validation-success': fieldValid(crsTaxForm.controls.taxNumber)
            }"
            class="form-group__control"
            type="text"
            formControlName="taxNumber"
          />
          <div class="validation-group">
            <div class="validation-message" *ngIf="fieldTypeError(crsTaxForm.controls.taxNumber, 'required')">
              Please enter your tax number / TIN.
            </div>
            <div class="validation-message" *ngIf="fieldTypeError(crsTaxForm.controls.taxNumber, 'length')">
              Tax number must be exactly 10 characters.
            </div>
            <div class="validation-message" *ngIf="fieldTypeError(crsTaxForm.controls.taxNumber, 'startWith')">
              Tax number should start with 0,1,2,3 or 9.
            </div>
            <div class="validation-message" *ngIf="fieldTypeError(crsTaxForm.controls.taxNumber, 'spaces')">
              Tax number cannot contain spaces.
            </div>
            <div class="validation-message" *ngIf="fieldTypeError(crsTaxForm.controls.taxNumber, 'pattern')">
              Tax number cannnot contain special characters.
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(showReason$ | async) === true" class="tw-flex tw-flex-col tw-grow">
        <div class="form-group !tw-mb-0">
          <label class="form-group__label tw-text-left">Reason<span class="required text--accent">*</span></label>
          <input
            [ngClass]="{
              'validation-error': fieldInvalid(crsTaxForm.controls.taxNoNumberReason),
              'validation-success': fieldValid(crsTaxForm.controls.taxNoNumberReason)
            }"
            class="form-group__control"
            type="text"
            formControlName="taxNoNumberReason"
          />
          <div class="validation-group">
            <div class="validation-message" *ngIf="fieldTypeError(crsTaxForm.controls.taxNoNumberReason, 'required')">
              Please enter your reason why Tax Number / TIN is {{ typeSelectedDescription }}.
            </div>
          </div>
        </div>
        <div class="form-hint form-hint--desktop strong">
          Explanation for why Tax Number / TIN is {{ typeSelectedDescription }}.
        </div>
      </div>
    </div>
    <div class="row row--justify-end row--align-center">
      <button class="btn-solid btn-solid--primary" (click)="add()" type="button">Add</button>
    </div>
  </div>
</form>
