import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { CloseSidenav } from '../layout.actions';
import { LayoutState } from '../layout.state';
import { LayoutComponent } from './layout.component';

@Component({
  selector: 'eid-layout-mobile',
  templateUrl: './layout.mobile.component.html',
  styleUrls: ['./layout.mobile.component.scss']
})
export class LayoutMobileComponent extends LayoutComponent implements OnInit {
  @ViewChild('rootPage') private rootPage!: ElementRef<HTMLElement>;
  @Select(LayoutState.getSidenavState) public sidenavState$: Observable<boolean>;

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public onActivate(evt) {
    if (this.rootPage) {
      this.document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
    }
  }

  public sidenavClosed(evt) {
    this.store.dispatch(new CloseSidenav());
  }
}
