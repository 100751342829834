import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractingEntity } from '../../../../registration/models/contracting.model';
import { ProductType } from '../../../../registration/models/product.model';
import { UrlBundle } from '../../../../shared/models/urlBundle';
import { UrlProviderService } from '../../../../shared/services/url.provider';
import { RegistrationState } from '../../../../store/registration.state';
import { UserState } from '../../../../store/user.state';

@Component({
  selector: 'eid-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Select(RegistrationState.contractingEntity) public regContractingEntity$: Observable<ContractingEntity>;
  @Select(UserState.contractingEntity) public userContractingEntity$: Observable<ContractingEntity>;
  @Select(UserState.isProfileComplete) public isProfileComplete$: Observable<boolean>;
  @Select(RegistrationState.productType) public productType$: Observable<ProductType>;

  public contractingEntity$: Observable<ContractingEntity>;

  public contractingEntities = ContractingEntity;
  public disclaimerActive = false;
  public productTypes = ProductType;

  public urlBundle$: Observable<UrlBundle>;

  constructor(private urlProviderService: UrlProviderService) {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
  }

  public ngOnInit(): void {
    this.contractingEntity$ = combineLatest([
      this.isProfileComplete$,
      this.regContractingEntity$,
      this.userContractingEntity$
    ]).pipe(
      map(([profileComplete, registrationContractingEntity, userContractingEntity]) => {
        if (profileComplete) {
          return userContractingEntity;
        }
        return registrationContractingEntity;
      })
    );
  }

  public toggleDisclaimer(e: MouseEvent): void {
    e.preventDefault();
    this.disclaimerActive = !this.disclaimerActive;
  }
}
