<div class="referral-card">
  <div class="referral-card-left">
    <div class="referral-card-left-header">
      <mat-icon class="referral-icon-sm" svgIcon="referrals"></mat-icon>
      <h2 class="referral-title">Successful Referrals</h2>
    </div>
    <div class="referral-card-left-body-sm">
      <div>
        <h3>Your Referrals</h3>
        <h4>
          <span>{{ (engagement$ | async)?.userReferralsCount }}&#160;</span>
          Referrals
        </h4>
      </div>
      <div>
        <h3>Community Avg</h3>
        <h4>
          <span>{{ (engagement$ | async)?.communityReferralsCountAverage }}&#160;</span>
          Referrals
        </h4>
      </div>
    </div>
  </div>
  <div class="referral-card-right" id="referral-gradient">
    <div class="referral-card-right-top-sm">
      <div>
        <mat-icon svgIcon="easymoney" class="referral-card-right-top-sm-icon"></mat-icon>
        <h2 class="referral-card-right-top-sm-title">EasyMoney</h2>
      </div>
      <div>
        <h3>R {{ (engagement$ | async)?.easymoneyEarnedThroughReferrals }}</h3>
        <h3>
          R {{ (engagement$ | async)?.savingsThroughEasymoney }}
          <span> &#160;Savings </span>
        </h3>
      </div>
    </div>
  </div>
</div>
