import { Component, EventEmitter, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs/internal/Observable';
import { UrlBundle } from '../../shared/models/urlBundle';
import { UrlProviderService } from '../../shared/services/url.provider';

@UntilDestroy()
@Component({
  selector: 'eid-beta',
  templateUrl: './beta.component.html',
  styleUrls: ['./beta.component.scss']
})
export class BetaComponent {
  @Output() public closed: EventEmitter<boolean> = new EventEmitter();

  public urlBundle$: Observable<UrlBundle>;

  constructor(protected urlProviderService: UrlProviderService) {
    this.urlBundle$ = urlProviderService.urlBundle$;
  }

  public close() {
    this.closed.emit(true);
  }
}
