import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CustomIconService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  init() {
    this.matIconRegistry.addSvgIcon(
      'arrow-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/arrow-right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-left.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-right-account',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-right-account.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-right-profile',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-right-profile.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'chevron-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-down.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-down-account',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-down-account.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'hash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/pound.svg')
    );
    this.matIconRegistry.addSvgIcon('menu', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/menu.svg'));

    this.matIconRegistry.addSvgIcon(
      'eid-calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/cs-calendar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-globe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/globe.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-help',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/help.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-help-flat',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/help-circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-security',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eid-security.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-partners',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eid-partners.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'link-alt',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/link-variant.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-apps',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/apps.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-app-easyequities',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/easyequities.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-app-easycrypto',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/easycrypto.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-app-satrix',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/satrix.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-app-mrphy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/mrphy.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-app-properties',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/easyproperties.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-app-academy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/easyequities.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-app-gt247',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/gt247.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-wallet',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/wallet.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-security',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/security.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-profile',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/profile.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-home',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/home.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-income',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/income.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-unusual',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/unusual.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-document-upload',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/document-upload.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'eid-profile-address',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eid-profile-address.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-profile-bank',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eid-profile-bank.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-profile-kyc',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eid-profile-kyc.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-profile-personal',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eid-profile-personal.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'eid-profile-tax',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eid-profile-tax.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'edit-tax',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/edit-tax.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'edit-tax-red',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/edit-tax-red.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'question-mark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/question-circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'grip-dotted',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/grip-dotted.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'close',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/close.svg')
    );

    // FANTASY GAME
    this.matIconRegistry.addSvgIcon(
      'add-request',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/add-request.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'ranking',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/ranking.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'badge',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/badge.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'stats-up',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/stats-up.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'stats-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/stats-down.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'trophy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/trophy.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'prize',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/prize.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'prize-1',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/prize-1.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'prize-2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/prize-2.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'prize-3',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/prize-3.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'crown',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/crown.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'prize-money',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/prize-money.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'clans',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/clans.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'compare',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/compare.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'create-clan',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/create-clan.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'join-clan',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/join-clan.svg')
    );
    this.matIconRegistry.addSvgIcon('add', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/add.svg'));
    this.matIconRegistry.addSvgIcon('copy', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/copy.svg'));
    this.matIconRegistry.addSvgIcon(
      'manage-clan',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/manage.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/delete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'verify-status',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/24-verify.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'calendar-large',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/calendar-large.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'error-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/error-icon.svg')
    );

    // ENGAGEMENT DASHBOARD ICONS
    this.matIconRegistry.addSvgIcon(
      'bar-chart',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/bar-chart.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bar-chart-black',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/bar-chart-black.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bar-chart-purple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/bar-chart-purple.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'right-corner-img',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/right-corner-img.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-right-white',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/chevron-right-white.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'decrease-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/decrease-arrow.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'decrease-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/decrease-circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'increase-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/increase-arrow.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'increase-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/increase-circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'deposits',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/deposits.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'holdings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/holdings.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'holdings-sm',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/holdings-sm.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'holdings-black',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/holdings-black.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'crypto',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/crypto.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bundles',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/bundles.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'efts',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/efts.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'equities',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/equities.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'unit-trust',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/unit-trust.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'property',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/property.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'wallet-md',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/wallet-md.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'grid-icon-sm',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/grid-icon-sm.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/calendar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'calendar-black',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/calendar-black.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'community-box-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/community-box-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-right-lg',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/chevron-right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'dividends-corner',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/dividends-corner.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'dividends',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/dividends.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'dividends-sm',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/dividends-sm.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'thrive',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/Thrive.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'dash-thrive',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/dash-thrive.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'deposit-price-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/deposit-price-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'checkbox-empty',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/checkbox-empty.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'checkbox',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/checkbox.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'funds',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/funds.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'funds-white',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/funds-white.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'icon-check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/icon-check.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'easymoney',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/easymoney.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'referrals',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/referrals.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'referrals-box-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/referrals-box-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'referrals-box-icon-sm',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/referrals-box-icon-sm.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'blur',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/blur.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'blur-no',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/blur-no.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'beta-one',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/beta-one.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'beta-two',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/engagement/beta-two.svg')
    );

    // FOOTER ICONS
    this.matIconRegistry.addSvgIcon(
      'portfolio',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/portfolio.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'invest',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/invest.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'trophy-footer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/trophy-footer.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'watchlist',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/watchlist.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'notify',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/notify.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'home-footer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/home-footer.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'home-footer-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/home-footer-active.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'profile-footer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/profile-footer.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'profile-footer-active',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/profile-footer-active.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'apps-footer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/apps-footer.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'security-footer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/security-footer.svg')
    );

    // COUNTRY ICONS
    this.matIconRegistry.addSvgIcon(
      'sa-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/south-africa.svg')
    );

    // SOCIAL MEDIA ICONS
    this.matIconRegistry.addSvgIcon(
      'huawei',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/social-media/huawei.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'google-play',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/social-media/google-play.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'apple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/social-media/apple.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/social-media/facebook.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'youtube',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/social-media/youtube.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'rss',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/social-media/rss.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'instagram',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/social-media/instagram.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/social-media/twitter.svg')
    );

    // SHARING ICONS
    this.matIconRegistry.addSvgIcon(
      'share',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/share.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'facebook-sharing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/facebook.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'twitter-sharing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/twitter.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'instagram-sharing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/instagram.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pinterest-sharing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/pinterest.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'whatsapp-sharing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/whatsapp.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'messenger-sharing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/messenger.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'telegram-sharing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/telegram.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'linkedin-sharing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/linkedin.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'copy-sharing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/copy.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'email-sharing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/email.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'more-sharing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/ellipsis.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'less-sharing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/minus.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'megaphone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sharing/megaphone.svg')
    );
  }
}
