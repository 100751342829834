export interface AccountType {
  name: string;
  label: string;
}
export interface AccountUser {
  name: string;
  avatar: string;
}

export interface AccountDto {
  accountTypeName: string;
  accountTypeLabel: string;
  userName: string;
  userAvatar: string;
}

export class LinkedAccount {
  public type: AccountType;
  public user: AccountUser;

  constructor(account: AccountDto) {
    this.type = {
      name: account.accountTypeName,
      label: account.accountTypeLabel
    };
    this.user = {
      name: account.userName,
      avatar: account.userAvatar
    };
  }
}
