<div class="section section--bg-dark">
  <div class="container">
    <div class="logoAndButtons">
      <div class="logo"></div>
      <div class="social-media">
        <button (click)="goto('facebook')" mat-icon-button>
          <mat-icon [svgIcon]="'facebook'"></mat-icon>
        </button>
        <button (click)="goto('twitter')" mat-icon-button>
          <mat-icon [svgIcon]="'twitter'"></mat-icon>
        </button>
        <button (click)="goto('youtube')" mat-icon-button>
          <mat-icon [svgIcon]="'youtube'"></mat-icon>
        </button>
        <button (click)="goto('googleplay')" mat-icon-button>
          <mat-icon [svgIcon]="'google-play'"></mat-icon>
        </button>
        <button (click)="goto('apple')" mat-icon-button>
          <mat-icon [svgIcon]="'apple'"></mat-icon>
        </button>
        <button (click)="goto('huawei')" mat-icon-button>
          <mat-icon [svgIcon]="'huawei'"></mat-icon>
        </button>
      </div>
      <div class="call-to-action">
        <a class="btn-outline btn-outline--light" [href]="(urlBundle$ | async)?.contact" target="_blank">Contact Us</a>
        <a class="btn-outline btn-outline--light" [href]="(urlBundle$ | async)?.invest" target="_blank">Invest Now</a>
      </div>
    </div>
    <div class="row row--justify-space-between row--align-bottom">
      <div class="navigation">
        <ul>
          <li>
            <a [href]="(urlBundle$ | async)?.about" target="_blank">About Satrix</a>
            |
            <a [href]="(urlBundle$ | async)?.products" target="_blank">Our Products</a>
            |
            <a [routerLink]="['/faq']">Frequently asked questions</a>
          </li>
          <li>
            <a [href]="(urlBundle$ | async)?.complaintsProcess" target="_blank">Complaints Process</a>
          </li>
          <li>
            <a [href]="(urlBundle$ | async)?.termsOfUse" target="_blank"
              >Access Important Documents (i.e. Terms and Conditions, Cost Profile etc)</a
            >
          </li>
        </ul>
      </div>
      <div class="ee-logo"></div>
    </div>
  </div>
</div>

<div class="section section--bg-darker">
  <div class="container">
    <p class="copyright">Copyright &copy; SatrixNow 2021</p>
  </div>
</div>

<div class="section">
  <div class="container">
    <div class="row row-disclaimer">
      <div class="disclaimer text-block text-block--small">
        <p>
          Satrix Managers (RF) (Pty) Ltd (Satrix) a registered and approved Manager in Collective Investment Schemes in
          Securities and an authorised financial services provider in terms of the FAIS. Collective investment schemes
          are generally medium- to long-term investments. Unit Trusts and ETFs the investor essentially owns a
          “proportionate share” (in proportion to the participatory interest held in the fund) of the underlying
          investments held by the fund. With Unit Trusts, the investor holds participatory units issued by the fund
          while in the case of an ETF, the participatory interest, while issued by the fund, comprises a listed security
          traded on the stock exchange. ETFs are index tracking funds, registered as a Collective Investment and can be
          traded by any stockbroker on the stock exchange or via Investment Plans and online trading platforms. ETFs may
          incur additional costs due to it being listed on the JSE. Past performance is not necessarily a guide to
          future performance and the value of investments / units may go up or down. A schedule of fees and charges, and
          maximum commissions are available on the Minimum Disclosure Document or upon request from the Manager.
          Collective investments are traded at ruling prices and can engage in borrowing and scrip lending. Should the
          respective portfolio engage in scrip lending, the utility percentage and related counterparties can be viewed
          on the ETF Minimum Disclosure Document. The Manager does not provide any guarantee either with respect to the
          capital or the return of a portfolio. The index, the applicable tracking error and the portfolio performance
          relative to the index can be viewed on the ETF Minimum Disclosure Document and or on the Satrix website.
        </p>
        <p>
          Performance is based on NAV to NAV calculations of the portfolio. Individual performance may differ to that of
          the portfolio as a result of initial fees, actual investment date, dividend withholding tax and income
          reinvestment date. The reinvestment of income is calculated based on actual distributed amount and factors
          such as payment date and reinvestment date must be considered.
        </p>
        <p>
          *Note exchange prices are delayed in accordance with regional exchange rules. South African prices are delayed
          by 15 minutes; North American prices are delayed by 15 minutes.
        </p>
      </div>
    </div>
  </div>
</div>
