import { Component, EventEmitter, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay, startWith, tap } from 'rxjs/operators';

import { StaticCountry, StaticCountryModel } from '../../../registration/models/country.model';
import { ComplianceCategory } from '../../../registration/models/product.model';
import { CountryIdEnum, TaxTypeEnum, TaxTypeIdEnum } from '../../constants/enums';
import { TaxResidence } from '../../models/compliance.model';
import { SelectorListItem } from '../../models/selector-list-item.model';
import { TaxDataModel } from '../../models/tax.model';
import { TaxValidator } from '../../validators/tax.validator';
import { CrsTaxComponent } from './crs-tax.component';

@UntilDestroy()
@Component({
  selector: 'eid-crs-tax-desktop',
  templateUrl: './crs-tax.desktop.component.html',
  styleUrls: ['./crs-tax.desktop.component.scss']
})
export class CrsTaxDesktopComponent extends CrsTaxComponent implements OnInit {
  public countries$: Observable<StaticCountryModel>;
  public taxIdentificationTypes$: Observable<TaxDataModel>;

  public taxIdentificationTypesSelectList$: Observable<SelectorListItem[]>;

  public favourites: StaticCountry[];
  public displayedColumns: string[] = ['taxResidence', 'taxType', 'taxNumber'];

  public showReason$: Observable<boolean>;
  public isRequestBusy$ = new EventEmitter<boolean>(false);

  public typeSelectedDescription: string;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.createForm();
    this.loadData();
    this.loadStaticData();
  }

  private createForm(): void {
    this.crsTaxForm = this.fb.group(
      {
        taxRegistered: ['', Validators.required],
        noDataReason: [''],
        taxCountry: ['', [Validators.required]],
        taxIdentificationType: ['', [Validators.required]],
        taxNumber: [''],
        taxNoNumberReason: ['']
      },
      {
        validators: [
          TaxValidator.NoDataComplianceValidate(this.residenceCountryId),
          TaxValidator.ResidenceComplianceValidate
        ]
      }
    );
  }

  private loadStaticData(): void {
    this.countries$ = this.staticDataService.getCountries().pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    this.taxIdentificationTypes$ = this.staticDataService
      .getTaxIdentificationTypes()
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    var taxCountryChange$;
    if (
      this.productDetail.complianceCategory === ComplianceCategory.combined ||
      this.residenceCountryId !== CountryIdEnum.southAfrica
    ) {
      taxCountryChange$ = this.crsTaxForm.get('taxCountry').valueChanges.pipe(startWith(this.residenceCountryId));
    } else {
      taxCountryChange$ = this.crsTaxForm.get('taxCountry').valueChanges.pipe(startWith(''));
    }

    const product = this.productType;

    this.countries$
      .pipe(
        tap(
          (countries) =>
            (this.favourites = this.countryService.splitFavouritesFromCountries(countries.countries, product))
        ),
        untilDestroyed(this)
      )
      .subscribe();

    this.taxIdentificationTypesSelectList$ = combineLatest([
      this.countries$,
      this.taxIdentificationTypes$,
      taxCountryChange$
    ]).pipe(
      map(([countries, taxDataModel, taxCountryChange]: [StaticCountryModel, TaxDataModel, any]) => {
        this.countries = countries;

        var selectedCountry = this.getSelectedCountry(taxCountryChange, countries);
        if (!isNil(selectedCountry)) {
          this.crsTaxForm.get('taxCountry').patchValue(selectedCountry.countryId, { emitEvent: false, onlySelf: true });
        }

        this.crsTaxForm.get('taxIdentificationType').patchValue('');
        return this.getTaxList(taxDataModel, selectedCountry);
      })
    );

    this.showReason$ = this.crsTaxForm.get('taxIdentificationType').valueChanges.pipe(
      startWith(false),
      map((taxIdType) => {
        if (taxIdType === TaxTypeIdEnum.notAvailable) {
          this.typeSelectedDescription = TaxTypeEnum.notAvailable.toLowerCase();
          return true;
        }
        if (taxIdType === TaxTypeIdEnum.notIssued) {
          this.typeSelectedDescription = TaxTypeEnum.notIssued.toLowerCase();
          return true;
        }
        return false;
      })
    );
  }

  private getTaxList(taxDataModel: TaxDataModel, selectedCountry: any) {
    let filteredIdTypes;
    if (isNil(selectedCountry)) {
      filteredIdTypes = taxDataModel.taxIdentificationTypes;
    } else {
      filteredIdTypes = this.staticDataService.getTaxIdTypesByCountry(
        taxDataModel.taxIdentificationTypes,
        selectedCountry.countryCode2
      );
    }

    var taxIdTypeSelectList = filteredIdTypes.map((c) => ({
      label: c.description,
      value: c.taxIdentificationTypeId
    })) as SelectorListItem[];

    taxIdTypeSelectList.push({
      label: TaxTypeEnum.notIssued,
      value: TaxTypeIdEnum.notIssued
    });
    taxIdTypeSelectList.push({
      label: TaxTypeEnum.notAvailable,
      value: TaxTypeIdEnum.notAvailable
    });

    return taxIdTypeSelectList;
  }

  private getSelectedCountry(taxCountryChange: any, countries: StaticCountryModel) {
    var selectedCountry;
    if (isNil(taxCountryChange) || taxCountryChange === 0 || taxCountryChange === '') {
      if (
        this.productDetail.complianceCategory === ComplianceCategory.combined ||
        this.residenceCountryId !== CountryIdEnum.southAfrica
      ) {
        selectedCountry = countries.countries.filter((c) => c.countryId === this.residenceCountryId)[0];
      } else {
        return null;
      }
    } else {
      selectedCountry = countries.countries.filter((c) => c.countryId === taxCountryChange)[0];
    }
    return selectedCountry;
  }

  public add() {
    if (
      this.crsTaxForm.controls.taxCountry.valid &&
      this.crsTaxForm.controls.taxIdentificationType.valid &&
      this.crsTaxForm.controls.taxNumber.valid &&
      this.crsTaxForm.controls.taxNoNumberReason.valid
    ) {
      const data = {
        countryId: this.crsTaxForm.controls.taxCountry.value,
        taxIdentificationTypeId: this.crsTaxForm.controls.taxIdentificationType.value,
        taxIdentificationNumber: this.crsTaxForm.controls.taxNumber.value,
        taxNoNumberReason: this.crsTaxForm.controls.taxNoNumberReason.value
      } as TaxResidence;
      this.addTaxRecord(data);
      this.crsTaxForm.patchValue({
        taxCountry: '',
        taxIdentificationType: '',
        taxNumber: '',
        taxNoNumberReason: ''
      });

      this.clearControl('taxCountry');
      this.clearControl('taxIdentificationType');
      this.clearControl('taxNumber');
      this.clearControl('taxNoNumberReason');
    } else {
      this.crsTaxForm.get('taxCountry').markAsTouched();
      this.crsTaxForm.get('taxIdentificationType').markAsTouched();
      this.crsTaxForm.get('taxNumber').markAsTouched();
      this.crsTaxForm.get('taxNoNumberReason').markAsTouched();
      this.isRequestBusy$.emit(true);
    }
  }

  private clearControl(controlName: string) {
    this.crsTaxForm.get(controlName).setErrors(null);
    this.crsTaxForm.get(controlName).markAsUntouched();
    this.crsTaxForm.get(controlName).markAsPristine();
  }

  public hasUnderline(i): boolean {
    return i === this.favourites.length - 1;
  }
}
