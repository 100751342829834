import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AffiliateDetail } from '../../registration/models/affiliate.model';
import { ContractingEntity } from '../../registration/models/contracting.model';
import { IdVerify } from '../../registration/models/id-verify.model';
import { PersonalDetail } from '../../registration/models/personal.model';
import { PartnerCategory, ProductType } from '../../registration/models/product.model';
import { Theme } from '../../registration/models/theme.model';
import {
  UpdateAddress,
  UpdateContractingEntity,
  UpdateFicaDetail,
  UpdateIdVerify,
  UpdateIncomingProductType,
  UpdateJumioDetails,
  UpdateKidAddress,
  UpdateKidCommunication,
  UpdateOnfidoDetails,
  UpdatePartnerInformation,
  UpdateProductType,
  UpdateProfileType,
  UpdateRegistrationState,
  UpdateStepValidators,
  UpdateTaxCompliance,
  UpdateUseMockEE,
  UpdateUser
} from '../../store/registration.actions';
import { RegistrationState } from '../../store/registration.state';
import { ProfileType } from '../models/profile.model';
import { RegistrationStateModel, Steps } from '../models/registration-state.model';
import { ProductService } from './product.service';
import { RegistrationService } from './registration.service';

@Injectable()
export class RegisterStoreService {
  constructor(
    private store: Store,
    private registrationService: RegistrationService,
    private productService: ProductService
  ) {}

  public getRegistrationId() {
    return this.store.selectSnapshot(RegistrationState.registrationId);
  }

  public contactingEntityChange(countrySelect) {
    const registrationState = this.getRegistrationStore();
    const contractingEntity = this.registrationService.contractingEntityFromCountrySelected(
      this.productService,
      countrySelect,
      registrationState.productType
    );
    if (
      registrationState.countrySelect &&
      (registrationState.countrySelect.residence.countryCode3 !== countrySelect.residence.countryCode3 ||
        registrationState.countrySelect.citizenship.countryCode3 !== countrySelect.citizenship.countryCode3 ||
        registrationState.contractingEntity !== contractingEntity)
    ) {
      return true;
    }
    return false;
  }

  public countrySelectChange(countrySelect): Observable<RegistrationStateModel> {
    const registrationState = this.getRegistrationStore();
    const newRegistrationState = Object.assign({}, registrationState);

    const contractingEntity = this.registrationService.contractingEntityFromCountrySelected(
      this.productService,
      countrySelect,
      registrationState.productType
    );

    // If the contracting entity or country of residence/citizenship changed, we need to invalidate certain fields
    if (
      registrationState.countrySelect &&
      (registrationState.countrySelect.residence.countryCode3 !== countrySelect.residence.countryCode3 ||
        registrationState.countrySelect.citizenship.countryCode3 !== countrySelect.citizenship.countryCode3 ||
        registrationState.contractingEntity !== contractingEntity)
    ) {
      // Do not override any detail if it was pre-populated by the enqueue registration request from a partner
      // but still make sure the user complete all the steps to update data if needed.
      if (!registrationState.enqueueRegistration) {
        newRegistrationState.personalDetail = this.invalidatePersonalDetail(newRegistrationState.personalDetail);
        newRegistrationState.address = this.invalidateAddress();
        newRegistrationState.idVerify = this.invalidateVerification();
        newRegistrationState.jumioDetails = [];
        newRegistrationState.onfidoDetails = [];
      }
      newRegistrationState.stepValidators = this.removeAllSteps(newRegistrationState.stepValidators);
    }
    newRegistrationState.stepValidators = this.addStep(newRegistrationState.stepValidators, Steps.country);

    // If this is not a V1 product, user will be populated already.
    if (!this.isV1Product(newRegistrationState.productType)) {
      newRegistrationState.stepValidators = this.addStep(newRegistrationState.stepValidators, Steps.user);
    }
    newRegistrationState.contractingEntity = contractingEntity;
    const thisCountrySelect = {
      citizenship: countrySelect.citizenship,
      residence: countrySelect.residence,
      birth: countrySelect.birth
    };
    newRegistrationState.countrySelect = thisCountrySelect;

    const user = Object.assign({}, newRegistrationState.user);
    user.marketingReferral = countrySelect.marketingReferral;
    user.referral = countrySelect.referral;
    newRegistrationState.user = user;

    return this.store.dispatch(new UpdateRegistrationState(newRegistrationState)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  private invalidatePersonalDetail(personalDetail) {
    // Clear the phone number - country code is now invalid.
    // Clear identification type - we need to make sure only valid id types can be selected.
    if (personalDetail) {
      const newPersonalDetail = Object.assign({}, personalDetail);
      newPersonalDetail.countryCode = '';
      newPersonalDetail.mobileNumber = '';
      newPersonalDetail.identificationTypeId = '';
      newPersonalDetail.identificationNumber = '';
      newPersonalDetail.idSourceCountry = '';
      return newPersonalDetail;
    }
  }

  private invalidateAddress() {
    const address = {
      unitNumber: '',
      complexName: '',
      streetNumber: '',
      streetName: '',
      suburb: '',
      city: '',
      code: '',
      provinceId: 0
    };
    return address;
  }

  public addStep(stepValidators, stepToAdd) {
    const newStepValidator = [...stepValidators.map((x) => ({ ...x }))];
    newStepValidator.forEach((step) => {
      if (step.step === stepToAdd) {
        step.valid = true;
      }
    });
    return newStepValidator;
  }

  public removeStep(stepValidators, stepToRemove) {
    const newStepValidator = [...stepValidators.map((x) => ({ ...x }))];
    newStepValidator.forEach((step) => {
      if (step.step === stepToRemove) {
        step.valid = false;
      }
    });
    return newStepValidator;
  }

  private removeAllSteps(stepValidators) {
    const newStepValidator = [...stepValidators.map((x) => ({ ...x }))];
    newStepValidator.forEach((step) => {
      step.valid = false;
    });
    return newStepValidator;
  }

  private invalidateVerification() {
    const data = {
      done: false,
      valid: false,
      reference: ''
    };
    return data;
  }

  public getSelectedCountry() {
    return this.store.selectSnapshot(RegistrationState.country);
  }

  public getContractingEntity() {
    return this.store.selectSnapshot(RegistrationState.contractingEntity);
  }

  public updateContractingEntity(contractingEntity) {
    return this.store.dispatch(new UpdateContractingEntity(contractingEntity));
  }

  public updateProduct(product) {
    const registrationState = this.getRegistrationStore();
    const newRegistrationState = Object.assign({}, registrationState);

    newRegistrationState.productType = product;
    if (newRegistrationState.personalDetail) {
      newRegistrationState.stepValidators = this.invalidateStepsForProduct(newRegistrationState.stepValidators);
    }

    return this.store.dispatch(new UpdateRegistrationState(newRegistrationState)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  public dispathProduct(product) {
    return this.store.dispatch(new UpdateProductType(product));
  }

  public getProduct() {
    return this.store.selectSnapshot(RegistrationState.productType);
  }

  public isV1Product(productType: ProductType) {
    const v1ProdStr = environment.v1Products || '';
    const v1Products = v1ProdStr.toLocaleLowerCase().replace(/\s/g, '').split(',');
    return v1Products.some((prod) => prod === productType.toString().toLowerCase());
  }

  public defaultProfileType() {
    const registrationState = this.getRegistrationStore();
    const productDetail = this.productService.getProductDetail(registrationState.productType);
    if (registrationState.enqueueRegistration || productDetail.partnerCategory !== PartnerCategory.none) {
      return true;
    }
    return false;
  }

  public dispatchIncomingProduct(product): Observable<RegistrationStateModel> {
    const registrationState = this.getRegistrationStore();
    const newRegistrationState = Object.assign({}, registrationState);

    newRegistrationState.incomingProductType = product;
    newRegistrationState.productType = product;
    if (newRegistrationState.personalDetail) {
      newRegistrationState.stepValidators = this.invalidateStepsForProduct(newRegistrationState.stepValidators);
    }

    return this.store.dispatch(new UpdateRegistrationState(newRegistrationState));
  }

  public updateIncomingProduct(product): Observable<RegistrationStateModel> {
    const registrationState = this.getRegistrationStore();
    const newRegistrationState = Object.assign({}, registrationState);

    newRegistrationState.incomingProductType = product;
    newRegistrationState.productType = product;
    if (newRegistrationState.personalDetail) {
      newRegistrationState.stepValidators = this.invalidateStepsForProduct(newRegistrationState.stepValidators);
    }

    return this.store.dispatch(new UpdateRegistrationState(newRegistrationState)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  private invalidateStepsForProduct(stepValidators) {
    // Update all steps - need to go through all the views again so that validations can be done again.
    const newStepValidator = [...stepValidators.map((x) => ({ ...x }))];
    newStepValidator.forEach((step) => {
      if (step.step === Steps.personalDetail) {
        step.valid = false;
      }
    });
    return newStepValidator;
  }

  public getIncomingProduct() {
    return this.store.selectSnapshot(RegistrationState.incomingProductType);
  }

  public updateAffiliate(product: ProductType, affiliate: AffiliateDetail) {
    const registrationState = this.getRegistrationStore();
    const newRegistrationState = Object.assign({}, registrationState);

    newRegistrationState.incomingProductType = product;
    newRegistrationState.productType = product;
    newRegistrationState.affiliate = affiliate;
    if (newRegistrationState.personalDetail) {
      newRegistrationState.stepValidators = this.invalidateStepsForProduct(newRegistrationState.stepValidators);
    }

    return this.store.dispatch(new UpdateRegistrationState(newRegistrationState)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  public getAffiliate() {
    return this.store.selectSnapshot(RegistrationState.affiliate);
  }

  public getProfileType() {
    return this.store.selectSnapshot(RegistrationState.profileType);
  }

  public updateProfileType(profileType) {
    return this.store.dispatch(new UpdateProfileType(profileType)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  public dispathPartnerInformation(url: string) {
    return this.store.dispatch(new UpdatePartnerInformation(url));
  }

  public getPartnerInformation() {
    return this.store.selectSnapshot(RegistrationState.partnerInformation);
  }

  public setupMinorStore() {
    const registrationState = this.getRegistrationStore();
    const newRegistrationState = Object.assign({}, registrationState);
    newRegistrationState.profileType = ProfileType.minor;
    const stepValidators = [
      { step: Steps.personalDetail, valid: false },
      { step: Steps.address, valid: false },
      { step: Steps.ficaDetail, valid: false },
      { step: Steps.user, valid: false }
    ];
    newRegistrationState.stepValidators = stepValidators;
    return this.updateRegistrationStore(newRegistrationState);
  }

  public updateUser(user) {
    return this.store.dispatch(new UpdateUser(user)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  public getUser() {
    return this.store.selectSnapshot(RegistrationState.user);
  }

  public updatePersonalDetail(personalDetail: PersonalDetail) {
    const registrationState = this.getRegistrationStore();
    const newRegistrationState = Object.assign({}, registrationState);
    newRegistrationState.personalDetail = personalDetail;

    const countrySelect = {
      citizenship: registrationState.countrySelect.citizenship,
      residence: registrationState.countrySelect.residence,
      birth: registrationState.countrySelect.birth
    };

    const countrySelectContractingEntity = this.registrationService.contractingEntityFromCountrySelected(
      this.productService,
      countrySelect,
      registrationState.productType
    );

    const newContractingEntity = this.registrationService.contractingEntityFromIdSource(
      this.productService,
      countrySelectContractingEntity,
      personalDetail.idSourceCountry,
      registrationState.productType
    );

    // If the contracting entity (due to changes in id source country) changed, we need to invalidate certain fields
    if (registrationState.contractingEntity !== newContractingEntity) {
      // Make sure that contracting entity AU is always producttype EasyEquities.
      if (newContractingEntity === ContractingEntity.aus) {
        newRegistrationState.productType = ProductType.easyequities;
      }
      //If the contracting entity is different from what the country selection dictated.
      newRegistrationState.idVerify = this.invalidateVerification();
      newRegistrationState.jumioDetails = [];
      newRegistrationState.onfidoDetails = [];
      newRegistrationState.stepValidators = this.invalidateStepsForPersonal(newRegistrationState.stepValidators);

      newRegistrationState.contractingEntity = newContractingEntity;
    } else {
      newRegistrationState.stepValidators = this.addStep(newRegistrationState.stepValidators, Steps.personalDetail);
    }

    return this.store.dispatch(new UpdateRegistrationState(newRegistrationState)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  private invalidateStepsForPersonal(stepValidators) {
    // Make sure the verification will happen again, since the id source country changed.
    const newStepValidator = [...stepValidators.map((x) => ({ ...x }))];
    newStepValidator.forEach((step) => {
      if (step.step === Steps.verification) {
        step.valid = false;
      }
      if (step.step === Steps.personalDetail) {
        step.valid = true;
      }
    });
    return newStepValidator;
  }

  public getPersonalDetail() {
    return this.store.selectSnapshot(RegistrationState.personalDetail);
  }

  public updateAddress(address) {
    return this.store.dispatch(new UpdateAddress(address)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  public getAddress() {
    return this.store.selectSnapshot(RegistrationState.address);
  }

  public updateFicaDetail(ficaDetail) {
    return this.store.dispatch(new UpdateFicaDetail(ficaDetail)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  public getFicaDetail() {
    return this.store.selectSnapshot(RegistrationState.ficaDetail);
  }

  public updateTaxCompliance(taxCompliance) {
    return this.store.dispatch(new UpdateTaxCompliance(taxCompliance)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  public getTaxCompliance() {
    return this.store.selectSnapshot(RegistrationState.taxCompliance);
  }

  public invalidateStepsFromPersonal() {
    const stepValidators = this.getStepValidator();
    // Make sure the verification will happen again, since the id source country changed.
    const newStepValidator = [...stepValidators.map((x) => ({ ...x }))];
    newStepValidator.forEach((step) => {
      if (
        step.step === Steps.personalDetail ||
        step.step === Steps.address ||
        step.step === Steps.ficaDetail ||
        step.step === Steps.verification
      ) {
        step.valid = false;
      }
    });
    return this.updateStepValidator(newStepValidator);
  }

  public getStepValidator() {
    const profileType = this.store.selectSnapshot(RegistrationState.profileType);
    const productType = this.store.selectSnapshot(RegistrationState.productType);
    let stepValidators = this.store.selectSnapshot(RegistrationState.stepValidator);

    let sorting = ['country', 'create-id', 'personal', 'address', 'fica', 'verification'];
    if (profileType === ProfileType.minor) {
      sorting = ['personal', 'address', 'fica', 'create-id'];
    }

    if (!this.isV1Product(productType) && profileType !== ProfileType.minor) {
      stepValidators = stepValidators.filter((s) => s.step !== Steps.user);
    }

    const sortedSteps = [...stepValidators].sort((a, b) => {
      return sorting.indexOf(a.step) - sorting.indexOf(b.step);
    });

    return sortedSteps;
  }

  public updateStepValidator(stepValidators) {
    return this.store.dispatch(new UpdateStepValidators(stepValidators)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  public updateIdVerificationResults(idVerify: IdVerify) {
    return this.store.dispatch(new UpdateIdVerify(idVerify)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  public getIdentityVerificationResults() {
    return this.store.selectSnapshot(RegistrationState.idVerify);
  }

  public updateOnfidoDetail(onfidoDetails) {
    return this.store.dispatch(new UpdateOnfidoDetails(onfidoDetails)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  public updateJumioDetail(jumioDetails) {
    return this.store.dispatch(new UpdateJumioDetails(jumioDetails)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  public getJumioDetails() {
    return this.store.selectSnapshot(RegistrationState.jumioDetails);
  }

  public getOnfidoDetails() {
    return this.store.selectSnapshot(RegistrationState.onfidoDetails);
  }

  public updateUseMockEE(mock) {
    return this.store.dispatch(new UpdateUseMockEE(mock));
  }

  public getUseMockEE() {
    return this.store.selectSnapshot(RegistrationState.useMockEE);
  }

  public updateKidAddress(kidAddress) {
    return this.store.dispatch(new UpdateKidAddress(kidAddress));
  }

  public getKidAddress() {
    return this.store.selectSnapshot(RegistrationState.kidAddress);
  }

  public updateKidCommunication(kidCommunication) {
    return this.store.dispatch(new UpdateKidCommunication(kidCommunication));
  }

  public getKidCommunication() {
    return this.store.selectSnapshot(RegistrationState.kidCommunication);
  }

  public updateRegistrationStore(registrationState) {
    return this.store.dispatch(new UpdateRegistrationState(registrationState)).pipe(
      switchMap(() => {
        return this.syncRegistration();
      })
    );
  }

  public dispatchRegistrationStore(registrationState) {
    return this.store.dispatch(new UpdateRegistrationState(registrationState));
  }

  public getRegistrationStore(): RegistrationStateModel {
    return this.store.selectSnapshot(RegistrationState);
  }

  public clearRegistrationStore() {
    // Keep the contracting entity so that we do not show the wrong URL's
    const contractingEntity = this.getContractingEntity();

    let productType = this.getProduct();
    const productDetail = this.productService.getProductDetail(productType);

    // We keep the certain products so that we do not remove the styling.
    if (productDetail.styleTheme === Theme.easyid) {
      productType = ProductType.none;
    }

    this.store
      .dispatch(new UpdateRegistrationState(null))
      .pipe(
        tap(() => {
          this.store.dispatch(new UpdateContractingEntity(contractingEntity));
          return this.store.dispatch(new UpdateIncomingProductType(productType));
        })
      )
      .subscribe();
  }

  public getFirstInvalidStep(step: string): string {
    // If all the previous steps are validated, return blank
    // Otherwise, return the first invalid step
    let found = false;
    let valid = true;
    let redirectToStep = '';
    const routes = this.getStepValidator();
    for (let i = 0; i < routes.length && !found && valid; i++) {
      const item = routes[i];
      if (item.step === step) {
        found = true;
        redirectToStep = '';
      } else {
        valid = item.valid;
        redirectToStep = item.step;
      }
    }
    return redirectToStep;
  }

  public canGoToStep(step): boolean {
    let found = false;
    let valid = true;
    const routes = this.getStepValidator();
    for (let i = 0; i < routes.length && !found; i++) {
      const item = routes[i];
      if (item.step === step) {
        found = true;
      } else {
        valid = item.valid;
      }
    }
    return valid;
  }

  public isStepValid(step): boolean {
    let found = false;
    let valid = false;
    const routes = this.getStepValidator();
    for (let i = 0; i < routes.length && !found; i++) {
      const item = routes[i];
      if (item.step === step) {
        found = true;
        valid = item.valid;
      }
    }
    return valid;
  }

  // API call for database state
  public updateRegistrationState(stateModel: RegistrationStateModel): Observable<RegistrationStateModel> {
    return this.registrationService.updateRegistrationState(stateModel).pipe(
      switchMap((state: RegistrationStateModel) => {
        return this.updateStoreWithState(state);
      })
    );
  }

  private createRegistrationState(stateModel: RegistrationStateModel): Observable<RegistrationStateModel> {
    return this.registrationService.createRegistrationState(stateModel).pipe(
      switchMap((state: RegistrationStateModel) => {
        return this.updateStoreWithState(state);
      })
    );
  }

  private updateStoreWithState(state: RegistrationStateModel): Observable<RegistrationStateModel> {
    return this.store.dispatch(new UpdateRegistrationState(state)).pipe(
      map((store) => {
        return store.register;
      })
    );
  }

  private syncRegistration(): Observable<RegistrationStateModel> {
    const stateModel = this.getRegistrationStore();
    if (stateModel.registrationId.length > 0) {
      return this.updateRegistrationState(stateModel);
    }
    return this.createRegistrationState(stateModel);
  }
}
