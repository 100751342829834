import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ContractingEntity } from '../../registration/models/contracting.model';

@Injectable()
export class HubspotService {
  constructor(private httpClient: HttpClient, @Inject(DOCUMENT) private document: Document) {}

  public logHubspot(email: string, username: string, contractingEntiry: ContractingEntity) {
    if (environment.hsenabled) {
      const hutk = this.readCookie('hubspotutk');
      const url =
        'https://api.hsforms.com/submissions/v3/integration/submit/' +
        environment.hsportalid +
        '/' +
        environment.hsformid;
      let contractingEntityDesc = 'FWT';
      switch (contractingEntiry) {
        case ContractingEntity.aus:
          contractingEntityDesc = 'EEAus';
          break;
        case ContractingEntity.fwt:
          contractingEntityDesc = 'FWT';
          break;
      }

      this.httpClient
        .post(url, {
          fields: [
            {
              name: 'email',
              value: email
            },
            {
              name: 'username',
              value: username
            },
            {
              name: 'contracting_entity',
              value: contractingEntityDesc
            }
          ],
          context: {
            hutk,
            pageUri: environment.hosturl + '/register',
            pageName: 'EasyID'
          }
        })
        .subscribe();
    }
  }

  readCookie(name) {
    // Escape regexp special characters
    name = name.replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1');

    const regex = new RegExp(`(?:^|;)\\s?${name}=(.*?)(?:;|$)`, 'i');
    const match = document.cookie.match(regex);

    return match && unescape(match[1]);
  }
}
