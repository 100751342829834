import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ProductDetailModel, ProductType } from '../../../registration/models/product.model';
import { Theme } from '../../../registration/models/theme.model';
import { ProductService } from '../../../shared/services/product.service';
import { RegisterStoreService } from '../../../shared/services/register-store.service';
import { RegistrationState } from '../../../store/registration.state';
import { CloseSidenav } from '../../layout.actions';
import { LayoutState } from '../../layout.state';
import { HeaderAction } from '../../models/header-action.model';

@UntilDestroy()
@Component({
  selector: 'eid-function-header-mobile',
  templateUrl: './function-header.mobile.component.html',
  styleUrls: ['./function-header.mobile.component.scss']
})
export class FunctionHeaderMobileComponent implements OnInit {
  @Select(LayoutState.getPageTitle) public pageTitle$: Observable<string>;
  @Select(RegistrationState.productType) public productType$: Observable<ProductType>;
  @Select(LayoutState.getLeftAction) public leftAction$: Observable<HeaderAction>;
  @Select(LayoutState.getRightAction) public rightAction$: Observable<HeaderAction>;

  public leftAction: HeaderAction;
  public rightAction: HeaderAction;
  public productDetail: ProductDetailModel;
  public isGCashGame: boolean;

  constructor(
    protected matIconRegistry: MatIconRegistry,
    protected domSanitizer: DomSanitizer,
    private store: Store,
    private registerStoreService: RegisterStoreService,
    private productService: ProductService
  ) {
    const productType = this.registerStoreService.getProduct();
    const url = window.location.href;
    this.productDetail = this.productService.getProductDetail(productType);
    this.isGCashGame = url.indexOf('/game/') > -1 && this.productDetail.styleTheme === Theme.gcash;
  }

  ngOnInit() {
    this.setIcons();
    this.leftAction$.pipe(untilDestroyed(this)).subscribe((left) => (this.leftAction = left));
    this.rightAction$.pipe(untilDestroyed(this)).subscribe((right) => (this.rightAction = right));
  }

  private setIcons() {
    this.matIconRegistry.addSvgIcon(
      'chevron-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-left.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chevron-right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'hash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/pound.svg')
    );
    this.matIconRegistry.addSvgIcon('menu', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/menu.svg'));
  }

  public callAction(action: HeaderAction) {
    action.fn();
  }

  public closeNavigation(): void {
    this.store.dispatch(new CloseSidenav());
  }
}
