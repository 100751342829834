import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { SetLayout } from '../../layout/layout.actions';
import { LayoutState, LayoutStateModel } from '../../layout/layout.state';
import { HeaderAction } from '../../layout/models/header-action.model';
import { ShareButtonsMobileComponent } from '../share-buttons/share-buttons.mobile.component';

@UntilDestroy()
@Component({
  selector: 'eid-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnChanges {
  @Input() public blurred = false;
  @Output() public blur: EventEmitter<boolean> = new EventEmitter();

  public checked = false;

  private isDialog: boolean;
  private pageTitle: string;
  private actionLeft: HeaderAction;
  private actionRight: HeaderAction;

  constructor(protected dialog: MatDialog, protected store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.checked = this.blurred;
    }
  }

  public share() {
    this.saveHeaderOriginalState();
    const dialog = this.dialog.open(ShareButtonsMobileComponent, {
      maxWidth: '100vw',
      panelClass: 'custom-selector-overlay',
      hasBackdrop: false,
      data: {
        checked: this.checked
      }
    });

    dialog.componentInstance.blur
      .pipe(
        map((blur) => {
          this.blur.emit(blur);
        }),
        untilDestroyed(this)
      )
      .subscribe();

    dialog
      .afterOpened()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogLayout(dialog, 'Share');
      });

    dialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetLayout();
      });
  }

  private dialogLayout(dialog, description) {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: true,
      pageTitle: description,
      actionLeft: new HeaderAction(undefined, 'chevron-left', null, () => {
        dialog.close();
      })
    };
    this.store.dispatch(new SetLayout(layoutState));
  }

  private resetLayout() {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: this.isDialog,
      pageTitle: this.pageTitle,
      actionLeft: this.actionLeft,
      actionRight: this.actionRight
    };
    this.store.dispatch(new SetLayout(layoutState));
  }

  private saveHeaderOriginalState(): void {
    this.isDialog = this.store.selectSnapshot(LayoutState.getIsDialog);
    this.pageTitle = this.store.selectSnapshot(LayoutState.getPageTitle);
    this.actionLeft = this.store.selectSnapshot(LayoutState.getLeftAction);
    this.actionRight = this.store.selectSnapshot(LayoutState.getRightAction);
  }
}
