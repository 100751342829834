export class HeaderAction {
  public label: string;
  public icon: string;
  public color?: string;
  fn: () => void;

  constructor(label: string, icon: string, color: string = null, fn: () => void = null) {
    this.label = label || null;
    this.icon = icon || null;
    this.color = color || null;
    this.fn = fn || null;
  }
}
