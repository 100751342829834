import { Component } from '@angular/core';

import { RootLayoutComponent } from './root-layout.component';

@Component({
  selector: 'eid-root-layout-desktop',
  templateUrl: './root-layout.desktop.component.html',
  styleUrls: ['./root-layout.desktop.component.scss']
})
export class RootLayoutDesktopComponent extends RootLayoutComponent {}
