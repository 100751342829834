import { Component, OnInit } from '@angular/core';

@Component({
  template: `
    <eid-month-selector-desktop *eidBreakpoint="'desktop'"></eid-month-selector-desktop>
    <eid-month-selector-mobile *eidBreakpoint="'mobile'"></eid-month-selector-mobile>
  `
})
export class MonthSelectorContainerComponent {}

@Component({
  template: ''
})
export class MonthSelectorComponent implements OnInit {
  date = '';

  constructor() {}

  ngOnInit(): void {
    // Set previous month
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);

    this.date = `${currentDate.toLocaleString('default', { month: 'long' })} ${currentDate.getFullYear()}`;
  }
}
