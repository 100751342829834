import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { isNil } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, shareReplay, startWith, take } from 'rxjs/operators';

import { PartnerCategory, ProductDetailModel, ProductType } from '../../registration/models/product.model';
import { Theme } from '../../registration/models/theme.model';
import { BreakPointDirective } from '../../shared/breakpoint.directive';
import { VerificationType } from '../../shared/constants/enums';
import { ProductService } from '../../shared/services/product.service';
import { RegisterStoreService } from '../../shared/services/register-store.service';
import { RegistrationService } from '../../shared/services/registration.service';
import { UrlProviderService } from '../../shared/services/url.provider';
import { AuthenticationState } from '../../store/authentication.state';
import { RegistrationState } from '../../store/registration.state';
import { UserState } from '../../store/user.state';
import { SetLayout } from '../layout.actions';
import { LayoutState, LayoutStateModel } from '../layout.state';
import { HeaderAction } from '../models/header-action.model';

export enum HeaderType {
  none = 'none',
  public = 'public',
  register = 'register',
  profile = 'profile',
  minimal = 'minimal',
  v1 = 'v1',
  function = 'function'
}

@UntilDestroy()
@Component({
  selector: 'eid-header-container',
  styleUrls: ['./header-container.component.scss'],
  template: `
    <ng-container *ngIf="(headerType$ | async) === headerTypes.public">
      <eid-public-header-desktop *eidBreakpoint="'desktop'"></eid-public-header-desktop>
      <eid-public-header-mobile *eidBreakpoint="'mobile'"></eid-public-header-mobile>
    </ng-container>
    <ng-container *ngIf="(headerType$ | async) === headerTypes.register">
      <eid-register-header-desktop *eidBreakpoint="'desktop'"></eid-register-header-desktop>
      <eid-register-header-mobile *eidBreakpoint="'mobile'"></eid-register-header-mobile>
    </ng-container>
    <ng-container *ngIf="(headerType$ | async) === headerTypes.profile">
      <eid-profile-header-desktop *eidBreakpoint="'desktop'"></eid-profile-header-desktop>
      <eid-profile-header-mobile *eidBreakpoint="'mobile'"></eid-profile-header-mobile>
    </ng-container>
    <ng-container *ngIf="(headerType$ | async) === headerTypes.minimal">
      <eid-minimal-header-desktop *eidBreakpoint="'desktop'"></eid-minimal-header-desktop>
      <eid-minimal-header-mobile *eidBreakpoint="'mobile'"></eid-minimal-header-mobile>
    </ng-container>
    <ng-container *ngIf="(headerType$ | async) === headerTypes.v1">
      <eid-v1-header-desktop *eidBreakpoint="'desktop'"></eid-v1-header-desktop>
      <eid-register-header-mobile *eidBreakpoint="'mobile'"></eid-register-header-mobile>
    </ng-container>
    <ng-container *ngIf="(headerType$ | async) === headerTypes.function">
      <eid-function-header-mobile></eid-function-header-mobile>
    </ng-container>
  `
})
export class HeaderContainerComponent implements OnInit {
  @Select(LayoutState.getIsDialog) public isDialog$: Observable<boolean>;
  @Select(LayoutState.getPageTitle) public pageTitle$: Observable<boolean>;
  @Select(UserState.isProfileComplete) public isProfileComplete$: Observable<boolean>;
  @Select(AuthenticationState.isLoggedIn) public isLoggedIn$: Observable<boolean>;
  @Select(RegistrationState.productType) public productType$: Observable<ProductType>;

  public headerTypes = HeaderType;
  public headerType$: Observable<HeaderType>;
  public urlChanges$: Observable<string>;

  public productDetail: ProductDetailModel;
  public isGame: boolean;

  public themes = Theme;

  constructor(
    private router: Router,
    private registerStoreService: RegisterStoreService,
    private registrationService: RegistrationService,
    private productService: ProductService,
    private urlService: UrlProviderService,
    private breakPoint: BreakPointDirective,
    private store: Store
  ) {
    this.urlChanges$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => (event as RouterEvent)?.url),
      startWith(''),
      shareReplay()
    );

    const productType = registerStoreService.getProduct();
    const url = window.location.href;
    this.productDetail = this.productService.getProductDetail(productType);
    this.isGame = url.indexOf('/game/') > -1;
  }

  ngOnInit(): void {
    this.setupHeaderTypes();
  }

  private setupHeaderTypes() {
    this.headerType$ = combineLatest([
      this.isProfileComplete$,
      this.isLoggedIn$,
      this.productType$,
      this.isDialog$,
      this.urlChanges$
    ]).pipe(
      map(
        ([isProfileComplete, isLoggedIn, productType, isDialog, urlChanges]: [
          boolean,
          boolean,
          ProductType,
          boolean,
          string
        ]) => {
          const url = window.location.href;
          const productDetail = this.productService.getProductDetail(productType);

          //No header when:
          //All full page spinners.
          //GCash screens.
          //All compliance screens.
          if (
            this.isBusy(url) ||
            (!isDialog &&
              (productDetail.partnerCategory === PartnerCategory.full ||
                url.indexOf('/compliance/') > -1 ||
                url.indexOf('/share') > -1))
          ) {
            return HeaderType.none;
          }

          if (this.isFunction(url, isDialog, productType) && this.breakPoint.easyBreakpoint === 'mobile') {
            return HeaderType.function;
          }

          if (this.isFunction(url, isDialog, productType) && this.isGame) {
            return HeaderType.function;
          }

          if (this.isMinimal(url)) {
            return HeaderType.minimal;
          }

          if (isLoggedIn) {
            return isProfileComplete ? HeaderType.profile : HeaderType.register;
          }

          const isV1Flow = this.registerStoreService.isV1Product(productType);
          return isV1Flow ? HeaderType.v1 : HeaderType.public;
        }
      )
    );
  }

  private isFunction(url, isDialog, productType) {
    if (isDialog) {
      return true;
    }

    if (url.indexOf('/minor/') > -1 && url.indexOf('minor/complete') < 0) {
      this.addMinorState(productType);
      return true;
    }

    return false;
  }

  private isBusy(url) {
    if (url.indexOf('authorize') > -1 || url.indexOf('initialize') > -1 || url.indexOf('logout') > -1) {
      return true;
    }
    return false;
  }

  private isMinimal(url) {
    if (url.indexOf('register/complete') > -1 || url.indexOf('minor/complete') > -1 || url.indexOf('/message/') > -1) {
      return true;
    }
    if (url.indexOf('/register/details/verification') > -1) {
      if (this.isOnfido()) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  private isOnfido() {
    const registrationStore = this.registerStoreService.getRegistrationStore();
    const verificationType = this.registrationService.getVerificationTypeForUser(
      registrationStore,
      this.productService
    );
    if (verificationType === VerificationType.onfido) {
      return true;
    }
    return false;
  }

  private addMinorState(productType) {
    const minorDescription = productType === ProductType.satrix ? 'Child' : 'Kid';
    const closeUrl = this.registerStoreService.getPartnerInformation();

    let header;
    if (!isNil(closeUrl) && closeUrl !== '') {
      header = new HeaderAction('Back', undefined, undefined, () => {
        window.location.href = closeUrl;
      });
    } else if (productType === ProductType.satrix) {
      header = new HeaderAction('Back', undefined, undefined, () => {
        this.urlService.urlBundle$
          .pipe(take(1), untilDestroyed(this))
          .subscribe((bundle) => (window.location.href = bundle.login));
      });
    } else {
      header = new HeaderAction('Back', undefined, undefined, () => {
        this.urlService.urlBundle$
          .pipe(take(1), untilDestroyed(this))
          .subscribe((bundle) => (window.location.href = bundle.easyEquitiesLogin));
      });
    }

    const layoutState: Partial<LayoutStateModel> = {
      // tslint:disable-next-line: quotemark
      pageTitle: 'Add a ' + minorDescription + "'s Account",
      actionLeft: null,
      actionRight: header
    };
    this.store.dispatch(new SetLayout(layoutState));
  }
}
