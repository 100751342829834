import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { ProductType } from '../registration/models/product.model';
import { ProfileType } from '../shared/models/profile.model';
import { AuthenticationService } from '../shared/services/authentication.service';
import { RegisterStoreService } from '../shared/services/register-store.service';
import { GuardService } from './guard.service';

@Injectable()
export class ProfileTypeGuard implements CanActivate {
  constructor(
    private router: Router,
    private registerStoreService: RegisterStoreService,
    private authenticationService: AuthenticationService,
    private guardService: GuardService
  ) {}

  canActivate() {
    if (!this.guardService.isInitialized()) {
      return false;
    }

    // EasyCrypto has their own profile selection before redirecting to EasyID - Not true, never implemented.
    // Enqueued products and partners should not have the profile page
    if (this.registerStoreService.defaultProfileType()) {
      this.registerStoreService.updateProfileType(ProfileType.adult);
      let productType = this.registerStoreService.getProduct();
      let incomingProductType = this.registerStoreService.getIncomingProduct();

      const url = window.location.href;
      if (url.indexOf('satrix') > -1) {
        productType = ProductType.satrix;
        incomingProductType = ProductType.satrix;
      }

      if (incomingProductType === ProductType.none) {
        this.router.navigate(['/product']);
        return false;
      }

      if (this.registerStoreService.isV1Product(productType) || this.authenticationService.getTokenModel().isLoggedIn) {
        this.router.navigate(['/register/country']);
        return true;
      }

      this.authenticationService.registerUser();
      return false;
    }
    return true;
  }
}
