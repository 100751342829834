import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { EngagementService } from '../../shared/services/engagement.service';

@Component({
  template: `
    <eid-deposits-desktop *eidBreakpoint="'desktop'"></eid-deposits-desktop>
    <eid-deposits-mobile *eidBreakpoint="'mobile'"></eid-deposits-mobile>
  `
})
export class DepositsContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class DepositsComponent {
  userDepositCount: number = 0;
  communityDepositAverage: number = 0;
  depositPercentageToPortfolio: number = 0;

  userPurchasedLivePrices: number = 0;
  communityPurchasedLivePricesAverage: number = 0;

  constructor(private engagementService: EngagementService) {
    this.engagementService
      .getEngagementData()
      .pipe(
        map((data) => {
          this.userDepositCount = data?.userDepositCount;
          this.communityDepositAverage = data?.communityDepositAverage;

          if (data?.portfolioValue > 0) {
            this.depositPercentageToPortfolio = parseFloat(
              parseFloat('' + (data.userPreviousMonthDepositValue / data.portfolioValue) * 100).toFixed(2)
            );
          }

          this.userPurchasedLivePrices = data?.userPurchasedLivePrices;
          this.communityPurchasedLivePricesAverage = data?.communityPurchasedLivePricesAverage;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
