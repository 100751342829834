import { Component, Injector } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SetLayout } from '../../../layout/layout.actions';
import { LayoutState, LayoutStateModel } from '../../../layout/layout.state';
import { HeaderAction } from '../../../layout/models/header-action.model';
import { CurrentUserPanelComponent } from './current-user-panel.component';
import { CurrentUserComponent } from './current-user.component';

@UntilDestroy()
@Component({
  selector: 'eid-current-user-mobile',
  templateUrl: './current-user.mobile.component.html',
  styleUrls: ['./current-user.mobile.component.scss']
})
export class CurrentUserMobileComponent extends CurrentUserComponent {
  @Select(LayoutState.getPageTitle) public pageTitle$: Observable<string>;
  @Select(LayoutState.getLeftAction) public leftAction$: Observable<HeaderAction>;
  @Select(LayoutState.getRightAction) public rightAction$: Observable<HeaderAction>;

  private dialogRef: MatDialogRef<CurrentUserPanelComponent>;

  constructor(protected injector: Injector, private dialog: MatDialog, private store: Store) {
    super(injector);
  }

  public openUserPanel(): void {
    this.dialogRef = this.dialog.open(CurrentUserPanelComponent, {
      hasBackdrop: false,
      maxWidth: '100vw',
      panelClass: 'selector-overlay'
    });
    this.dialogRef
      .afterOpened()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.setPanelHeader();
      });
    this.dialogRef
      .afterClosed()
      //This is causing the afterClosed not to trigger??
      //.pipe(untilDestroyed(this))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe((isLoggedOut) => {
        this.resetLayout();
        if (isLoggedOut) {
          return;
        }
      });
  }

  private setPanelHeader(): void {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: true,
      pageTitle: 'Account',
      actionLeft: null,
      actionRight: new HeaderAction('Close', undefined, undefined, () => {
        this.dialogRef.close();
      })
    };
    this.store.dispatch(new SetLayout(layoutState));
  }

  public resetLayout() {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: false,
      pageTitle: null,
      actionLeft: null,
      actionRight: null
    };
    this.store.dispatch(new SetLayout(layoutState));
  }
}
