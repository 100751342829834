import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlBundle } from '../../../models/urlBundle';
import { UrlProviderService } from '../../../services/url.provider';

@Component({
  selector: 'eid-unsupported-product-mobile',
  templateUrl: './unsupported-product.mobile.component.html',
  styleUrls: ['./unsupported-product.mobile.component.scss']
})
export class UnsupportedProductMobileComponent {
  public urlBundle$: Observable<UrlBundle>;

  constructor(private urlProviderService: UrlProviderService) {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
  }
}
