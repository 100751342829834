<div class="beta-header">
  <mat-icon class="beta-one-icon" [svgIcon]="'beta-one'"></mat-icon>
  <p>Please Be Kind; We’re In Beta!</p>
  <div class="close-click" (click)="close()">
    <mat-icon class="close-icon" svgIcon="close"></mat-icon>
  </div>
</div>
<div class="beta-content">
  <div>
    <span class="beta-highlight"
      >Being in Beta means that the dashboard is still undergoing testing and development.</span
    >
    You might encounter occasional quirks - please
    <a [href]="(urlBundle$ | async)?.newTicket" target="_blank">log a ticket</a> if any of your data is incorrect. We
    appreciate your understanding as we work to enhance your experience.
  </div>
  <div class="beta-two-icon"><mat-icon [svgIcon]="'beta-two'"></mat-icon></div>
</div>
