import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { ProductType } from '../../../registration/models/product.model';
import { UrlBundle } from '../../../shared/models/urlBundle';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { RegisterStoreService } from '../../../shared/services/register-store.service';
import { UrlProviderService } from '../../../shared/services/url.provider';
import { RegistrationState } from '../../../store/registration.state';

@Component({
  selector: 'eid-v1-header-desktop',
  templateUrl: './v1-header.desktop.component.html',
  styleUrls: ['./v1-header.desktop.component.scss']
})
export class V1HeaderDesktopComponent {
  @HostBinding('class.eid-header-desktop') public elCls = true;

  @Select(RegistrationState.productType) public productType$: Observable<ProductType>;

  public isSatrix$: Observable<boolean>;
  public urlBundle$: Observable<UrlBundle>;
  public urlSegment = window.location.pathname;

  constructor(
    protected route: ActivatedRoute,
    protected authenticationService: AuthenticationService,
    protected registerStoreService: RegisterStoreService,
    protected urlProviderService: UrlProviderService
  ) {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
    const productType = registerStoreService.getProduct();
    const url = window.location.href;

    if (url.indexOf('satrix') > -1 || productType === ProductType.satrix) {
      this.isSatrix$ = of(true);
    } else {
      this.isSatrix$ = of(false);
    }
  }

  routerClasses() {
    if (
      this.urlSegment === '/register/create-id' ||
      this.urlSegment === '/register/country' ||
      this.urlSegment === '/profile-type'
    ) {
      return {
        active: true
      };
    } else {
      return {
        active: false
      };
    }
  }

  public login(): void {
    this.authenticationService.authorizeUser();
  }
}
