import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/desktop/footer-common/footer.component';
import { FooterSatrixComponent } from './footer/desktop/footer-satrix/footer-satrix.component';
import { FooterContainerComponent } from './footer/footer-container.component';
import { FooterGameComponent } from './footer/mobile/footer-game/footer-game.component';
import { FooterProfileComponent } from './footer/mobile/footer-profile/footer-profile.component';
import { MinimalHeaderDesktopComponent } from './header/desktop/minimal-header.desktop.component';
import { ProfileHeaderDesktopComponent } from './header/desktop/profile-header.desktop.component';
import { PublicHeaderDesktopComponent } from './header/desktop/public-header.desktop.component';
import { RegisterHeaderDesktopComponent } from './header/desktop/register-header.desktop.component';
import { V1HeaderDesktopComponent } from './header/desktop/v1-header.desktop.component';
import { HeaderContainerComponent } from './header/header-container.component';
import { FunctionHeaderMobileComponent } from './header/mobile/function-header.mobile.component';
import { MinimalHeaderMobileComponent } from './header/mobile/minimal-header.mobile.component';
import { ProfileHeaderMobileComponent } from './header/mobile/profile-header.mobile.component';
import { PublicHeaderMobileComponent } from './header/mobile/public-header.mobile.component';
import { RegisterHeaderMobileComponent } from './header/mobile/register-header.mobile.component';
import { LayoutComponent, LayoutContainerComponent } from './layout/layout.component';
import { LayoutDesktopComponent } from './layout/layout.desktop.component';
import { LayoutMobileComponent } from './layout/layout.mobile.component';
import { RootLayoutComponent, RootLayoutContainerComponent } from './root-layout/root-layout.component';
import { RootLayoutDesktopComponent } from './root-layout/root-layout.desktop.component';
import { RootLayoutMobileComponent } from './root-layout/root-layout.mobile.component';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';

@NgModule({
  declarations: [
    RootLayoutContainerComponent,
    RootLayoutComponent,
    RootLayoutDesktopComponent,
    RootLayoutMobileComponent,

    FooterComponent,
    FooterContainerComponent,
    FooterSatrixComponent,
    FooterGameComponent,
    FooterProfileComponent,

    LayoutContainerComponent,
    LayoutComponent,
    LayoutDesktopComponent,
    LayoutMobileComponent,

    SideNavigationComponent,

    HeaderContainerComponent,
    PublicHeaderDesktopComponent,
    RegisterHeaderDesktopComponent,
    ProfileHeaderDesktopComponent,
    V1HeaderDesktopComponent,
    MinimalHeaderDesktopComponent,
    PublicHeaderMobileComponent,
    RegisterHeaderMobileComponent,
    ProfileHeaderMobileComponent,
    MinimalHeaderMobileComponent,
    FunctionHeaderMobileComponent
  ],
  imports: [CommonModule, RouterModule, SharedModule, HttpClientModule],
  exports: [
    RootLayoutContainerComponent,
    RootLayoutComponent,
    RootLayoutDesktopComponent,
    RootLayoutMobileComponent,

    LayoutContainerComponent,
    LayoutComponent,
    LayoutDesktopComponent,
    LayoutMobileComponent
  ]
})
export class LayoutModule {}
