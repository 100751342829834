import { Component, Injector, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ProductType } from '../../registration/models/product.model';
import { AuthenticationState } from '../../store/authentication.state';
import { IntroDesktopComponent } from '../intro/intro/intro.desktop.component';
import { ProfileTypeComponent } from './profile-type.component';

@Component({
  selector: 'eid-profile-type-desktop',
  templateUrl: './profile-type.desktop.component.html',
  styleUrls: ['./profile-type.desktop.component.scss']
})
export class ProfileTypeDesktopComponent extends ProfileTypeComponent implements OnInit {
  @Select(AuthenticationState.isLoggedIn) public isLoggedIn$: Observable<boolean>;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    let productType = this.registerStoreService.getProduct();
    if (productType !== ProductType.satrix) {
      setTimeout(() => {
        this.openModal();
      }, 0);
    }
  }

  public openModal() {
    this.dialog.open(IntroDesktopComponent, {
      maxWidth: '100vw',
      panelClass: 'custom-intro-overlay',
      hasBackdrop: false
    });
  }
}
