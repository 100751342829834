import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isNil } from 'lodash';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { LOGGER, Logger } from '../../logging/logger.service';
import { AffiliateDetail } from '../../registration/models/affiliate.model';
import { ProductType } from '../../registration/models/product.model';
import { ValidatorPattern } from '../constants/validator-patterns';
import { RegistrationStateModel } from '../models/registration-state.model';
import { isDefined, omit } from '../utilities/utils';
import { AuthenticationService } from './authentication.service';
import { ComplianceStoreService } from './compliance-store.service';
import { GameStoreService } from './game-store.service';
import { RegisterStoreService } from './register-store.service';
import { RegistrationService } from './registration.service';
import { UserStoreService } from './user-store.service';

@Injectable({
  providedIn: 'root'
})
export class InitializeService {
  constructor(
    @Inject(LOGGER) private logger: Logger,
    private router: Router,
    private authenticationService: AuthenticationService,
    private registerStoreService: RegisterStoreService,
    private registrationService: RegistrationService,
    private complianceStoreService: ComplianceStoreService,
    private gameStoreService: GameStoreService,
    private userStoreService: UserStoreService
  ) {}

  public handleIncomingParameters(): Observable<boolean> {
    // We handle product
    // ?productid=easyequities

    // or product and affiliate details
    // eslint-disable-next-line max-len
    // ?productid=easyequities&affiliateid=1&affiliatekey=txfb6AFjS0pZvhDcR1okoXTNJCsjel%20lSLgywHiSSO5O2xbjUc4g2WCaR%20prMTqedXneuXYTSol8ywL6Gl2VjvxxI1RR0mMnGA%2FIcwZ5%2FVCI9gJQRZLCJ9IH9FDTAtkj
    // ?productid=satrix&affiliateid=14&affiliatekey=r+fI9YCS1uXFAlfjhyuRIme4mFnDwF97Ah17Vk4X/xATqveT/sXE6DVkoQNDDH5lIiiojmvXECMEhp7268D68V8fgGc/pvB8U6tLKAlT2zUJwZK+pzPYEA==
    // ?productid=easyproperties&affiliateid=173&affiliatekey=XTs7G/Jp69beLMHx1HJCiqFlSnAQQ1nvzUc8sB%2Bs9frYQVUQFOwyd002Vb2D3ZiI%2B7MBK0NWpKsBzJDwgXPkDNjSP1cKfNZQ7ZKM0mHxv9Wz4UFXtixXwulQEvY9pYAw

    // or product and referralnumber
    // ?productid=mrphy&referralnumber=12345

    // or partner details (Partner category verified/unverified)
    // ?registrationid=e42b5877-11b3-464c-a0ae-203a41831e5b& whatever partner add on here

    // or partner without front-end (GCash)
    // ?registrationrequestid=e42b5877-11b3-464c-a0ae-203a41831e5b& whatever partner add on here

    // or Satrix product
    // Satrix is on a different url, we determine the product if satrix is contained in the URL.

    // or Compliance
    // compliance?redirecturl=

    // or Minor registrations
    // minor/personal?redirecturl=

    // or Game
    // game/challenge?redirecturl= or any other game url

    const url = this.authenticationService.getUrl();
    if (isNil(url) || url === '') {
      return of(true);
    }
    const searchParamsLower = this.getQueryParameters(url.toLowerCase());
    const searchParams = this.getQueryParameters(url);

    const productType = this.getProductType(url, searchParamsLower);
    const registrationId = searchParamsLower.get('registrationid');
    const affiliateid = searchParamsLower.get('affiliateid');
    const affiliatekey = searchParams.get('affiliatekey');
    const referralnumber = searchParamsLower.get('referralnumber');
    const registrationrequestid = searchParamsLower.get('registrationrequestid');
    const redirecturl = searchParamsLower.get('redirecturl');
    const successRedirecturl = searchParamsLower.get('successredirecturl');

    if (productType !== null && productType !== ProductType.none && affiliateid !== null && affiliatekey !== null) {
      return this.handleAffiliateDetail(productType, affiliateid, affiliatekey);
    } else if (productType !== null && productType !== ProductType.none && referralnumber !== null) {
      return this.handleReferralDetail(productType, referralnumber);
    } else if (registrationId !== null) {
      return this.handlePartnerDetail(registrationId, url);
    } else if (registrationrequestid !== null) {
      return this.handleFullPartnerDetail(registrationrequestid);
    } else if (redirecturl != null) {
      return this.handleRedirectUrl(redirecturl, successRedirecturl, url);
    } else if (productType !== null && productType !== ProductType.none) {
      return this.handleIncomingProduct(productType);
    }
    return of(true);
  }

  public handleRedirects(): Observable<boolean> {
    //Deciding which scenario's should only check logged in status, and which should do a full login before continuing.
    const url = this.authenticationService.getUrl();
    if (isNil(url) || url === '') {
      this.authenticationService.checkIsLoggedIn();
      return of(true);
    }

    //V1 products need not be logged in to register.
    const productType = this.registerStoreService.getProduct();
    const isV1Product = this.registerStoreService.isV1Product(productType);
    if (url.indexOf('/register/') > -1 && isV1Product) {
      this.authenticationService.checkIsLoggedIn();
      return of(true);
    }

    //When registering a user without showing EasyID views, user has to be logged in.
    const searchParamsLower = this.getQueryParameters(url.toLowerCase());
    const registrationrequestid = searchParamsLower.get('registrationrequestid');
    if (registrationrequestid !== null) {
      this.authenticationService.authorizeUser();
      return of(true);
    }

    //For these URL's the user has to be logged in (if not V1)
    if (url.indexOf('/register/') > -1 || this.authenticationService.shouldBeLoggedInUrl(url)) {
      this.authenticationService.authorizeUser();
      return of(true);
    }

    //For these scenario's we just check if the user is logged in.
    const originalUrl = new URL(url);
    const pathName = originalUrl.pathname;
    if (
      isV1Product ||
      pathName.endsWith('/') ||
      pathName.indexOf('/home') > -1 ||
      url.indexOf('/profile-type') ||
      url.indexOf('/product')
    ) {
      this.authenticationService.checkIsLoggedIn();
      return of(true);
    }

    //Everything else
    this.authenticationService.authorizeUser();
  }

  private getProductType(url, searchParams): ProductType {
    if (this.isSatrix(url)) {
      return ProductType.satrix;
    }

    const productid = searchParams.get('productid');
    if (isDefined(productid) && productid !== '') {
      return productid;
    }
    return ProductType.none;
  }

  private isSatrix(url) {
    if (url.indexOf(ProductType.satrix) > -1) {
      return true;
    }
    return false;
  }

  private handleAffiliateDetail(
    productType: ProductType,
    affiliateid: string,
    affiliatekey: string
  ): Observable<boolean> {
    return this.registrationService.affiliateDetails(productType, affiliateid, affiliatekey).pipe(
      filter((affiliate: AffiliateDetail) => !isNil(affiliate)),
      switchMap((affiliate: AffiliateDetail) => {
        return this.registerStoreService.updateAffiliate(productType, affiliate).pipe(
          map(() => {
            return true;
          })
        );
      })
    );
  }

  private handleReferralDetail(productType: ProductType, referralNumber: string): Observable<boolean> {
    if (!referralNumber.startsWith('EE')) {
      referralNumber = 'EE' + referralNumber;
    }
    const affiliateDetails = {
      marketingChannelReferral: 'Referral',
      referralCode: referralNumber,
      subsystemId: ''
    };
    return this.registerStoreService.updateAffiliate(productType, affiliateDetails).pipe(
      map(() => {
        return true;
      })
    );
  }

  private handlePartnerDetail(registrationId: string, url: string): Observable<boolean> {
    return this.registrationService.getRegistrationState(registrationId).pipe(
      switchMap((databaseState: RegistrationStateModel) => {
        if (databaseState === null) {
          this.logTokenModel('Initialize service handlePartnerDetail databaseState ' + registrationId);
          this.router.navigate(['/message/error']);
          return of(false);
        }
        const redirectUrl = url.match(ValidatorPattern.redirectUrl);
        if (isNil(redirectUrl)) {
          this.logTokenModel('Initialize service handlePartnerDetail redirectUrl ' + registrationId);
          this.router.navigate(['/message/error']);
          return of(false);
        }
        const partnerUrl = redirectUrl[2];
        databaseState.partnerInformation = partnerUrl;
        return this.registerStoreService.updateRegistrationState(databaseState).pipe(
          map(() => {
            return true;
          })
        );
      })
    );
  }

  private handleFullPartnerDetail(registrationRequestId: string): Observable<boolean> {
    return this.registrationService.getRegistrationState(registrationRequestId).pipe(
      switchMap((databaseState: RegistrationStateModel) => {
        if (databaseState === null) {
          this.logTokenModel('Initialize service handleFullPartnerDetail databaseState ' + registrationRequestId);
          this.router.navigate(['/message/error']);
          return of(false);
        }
        return this.registerStoreService.updateRegistrationState(databaseState).pipe(
          map(() => {
            return true;
          })
        );
      })
    );
  }

  private handleRedirectUrl(redirectUrl: string, successUrl: string, url: string): Observable<boolean> {
    if (url.toLowerCase().indexOf('compliance') > 0) {
      return this.complianceStoreService.updateRedirectUrls(redirectUrl, successUrl).pipe(
        map(() => {
          return true;
        })
      );
    }

    const redirectUrlOnly = url.match(ValidatorPattern.redirectUrl);
    const partnerUrl = redirectUrlOnly[2];

    if (url.toLowerCase().indexOf('game') > 0) {
      this.gameStoreService.updateGamePath(url);
      this.gameStoreService.updateGameRedirect(redirectUrl);
    }

    this.registerStoreService.dispathPartnerInformation(partnerUrl);
    return of(true);
  }

  private handleIncomingProduct(productType: ProductType): Observable<boolean> {
    this.userStoreService.updateProductType(productType);
    return this.registerStoreService.dispatchIncomingProduct(productType).pipe(
      map(() => {
        return true;
      })
    );
  }

  private getQueryParameters(url): URLSearchParams {
    if (url === '') {
      return null;
    }
    const newURL = new URL(url);
    return newURL.searchParams;
  }

  private logTokenModel(message: string) {
    const tokenModel = this.authenticationService.getTokenModel();
    const model = omit(tokenModel, 'token', 'idToken', 'issued', 'expires');
    this.logger.error(message, model);
  }
}
