import { Component, OnInit } from '@angular/core';

import { ProductType } from '../../../registration/models/product.model';
import { Theme } from '../../../registration/models/theme.model';
import { RegisterStoreService } from '../../services/register-store.service';

@Component({
  selector: 'eid-dev-tools',
  templateUrl: './dev-tools.component.html',
  styleUrls: ['./dev-tools.component.scss']
})
export class DevToolsComponent implements OnInit {
  public mock: boolean;
  public themes = Theme;
  public productTypes = ProductType;

  constructor(protected registerStoreService: RegisterStoreService) {}

  ngOnInit(): void {
    this.mock = this.registerStoreService.getUseMockEE();
  }

  public setProduct(product: ProductType): void {
    this.registerStoreService.updateProduct(product);
  }

  public setMockEE(mock: boolean): void {
    this.mock = mock;
    this.registerStoreService.updateUseMockEE(mock);
  }
}
