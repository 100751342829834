import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShareButtonsComponent } from './share-buttons.component';

export class ShareData {
  checked: boolean;
}

@Component({
  selector: 'eid-share-buttons-mobile',
  templateUrl: './share-buttons.mobile.component.html',
  styleUrls: ['./share-buttons.mobile.component.scss']
})
export class ShareButtonsMobileComponent extends ShareButtonsComponent {
  @Output() public blur: EventEmitter<boolean> = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public data: ShareData) {
    super();
    this.blurred = data.checked;
  }

  public toggleBlur(event) {
    this.blur.emit(event);
    this.blurred = event;
  }
}
