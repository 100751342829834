import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';

import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { Theme } from '../../registration/models/theme.model';
import { BreakPointDirective } from '../../shared/breakpoint.directive';
import { LayoutState } from '../layout.state';

export enum FooterType {
  none = 'none',
  common = 'common',
  satrix = 'satrix',
  game = 'game',
  profile = 'profile'
}

@UntilDestroy()
@Component({
  selector: 'eid-footer-container',
  template: `
    <ng-container *ngIf="(footerType$ | async) === footerTypes.common">
      <eid-footer *eidBreakpoint="'desktop'"></eid-footer>
    </ng-container>
    <ng-container *ngIf="(footerType$ | async) === footerTypes.satrix">
      <eid-footer-satrix *eidBreakpoint="'desktop'"></eid-footer-satrix>
    </ng-container>
    <ng-container *ngIf="(footerType$ | async) === footerTypes.game">
      <eid-footer-game></eid-footer-game>
    </ng-container>
    <ng-container *ngIf="(footerType$ | async) === footerTypes.profile">
      <eid-footer-profile></eid-footer-profile>
    </ng-container>
  `
})
export class FooterContainerComponent implements OnInit {
  @Select(LayoutState.getActiveTheme) public theme$: Observable<Theme>;
  @Select(LayoutState.getIsDialog) public isDialog$: Observable<boolean>;

  public footerTypes = FooterType;
  public footerType$: Observable<FooterType>;
  public urlChanges$: Observable<string>;

  public themes = Theme;

  constructor(private router: Router, private breakPoint: BreakPointDirective) {
    this.urlChanges$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => (event as RouterEvent)?.url),
      startWith(''),
      shareReplay()
    );
  }

  ngOnInit(): void {
    this.setupFooterTypes();
  }

  private setupFooterTypes() {
    this.footerType$ = combineLatest([this.theme$, this.isDialog$, this.urlChanges$]).pipe(
      map(([theme, isDialog, urlChanges]: [Theme, boolean, string]) => {
        const url = window.location.href;

        //Regardless of desktop/mobile, game will always have the game footer.
        if (url.indexOf('/game') > -1) {
          return FooterType.game;
        }

        if (url.indexOf('/share') > -1) {
          return FooterType.none;
        }

        //Desktop can only have the common or Satrix footer.
        if (this.breakPoint.easyBreakpoint === 'desktop') {
          if (theme === Theme.satrix) {
            return FooterType.satrix;
          } else if (theme !== Theme.gcash) {
            return FooterType.common;
          }
          return FooterType.none;
        }

        //Mobile
        if (this.isBusy(url) || isDialog) {
          return FooterType.none;
        }

        if (url.indexOf('/profile/') > -1 || url.indexOf('/dashboard') > -1) {
          return FooterType.profile;
        }

        return FooterType.none;
      })
    );
  }

  private isBusy(url) {
    if (url.indexOf('authorize') > -1 || url.indexOf('initialize') > -1 || url.indexOf('logout') > -1) {
      return true;
    }
    return false;
  }
}
