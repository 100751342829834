<div class="root">
  <div class="calendar-container" id="activity-gradient">
    <h2 class="calendar-title">YOUR STATS</h2>

    <div class="calendar-header">
      <div>
        <h2 class="calendar-header-h2">{{ (engagement$ | async)?.userActiveDaysCount }}</h2>

        <div>
          <mat-icon *ngIf="activeDaysUp" class="arrow-icon" svgIcon="increase-circle"></mat-icon>
          <mat-icon *ngIf="!activeDaysUp" class="arrow-icon" svgIcon="decrease-circle"></mat-icon>
          <h3 class="calendar-header-h3">Active Days</h3>
        </div>
      </div>

      <div>
        <h2 class="calendar-header-h2">{{ (engagement$ | async)?.userLoginCount }}</h2>

        <div>
          <mat-icon *ngIf="loginsUp" class="arrow-icon" svgIcon="increase-circle"></mat-icon>
          <mat-icon *ngIf="!loginsUp" class="arrow-icon" svgIcon="decrease-circle"></mat-icon>
          <h3 class="calendar-header-h3">Log Ins</h3>
        </div>
      </div>
    </div>

    <div class="calendar-canvas">
      <eid-calendar-desktop></eid-calendar-desktop>
    </div>
  </div>

  <div class="community-container">
    <h2 class="community-title">COMMUNITY AVERAGE</h2>

    <div class="community-box">
      <div>
        <h2 class="community-box-title">{{ (engagement$ | async)?.communityActiveDays }}</h2>

        <h3 class="community-box-text">Active Days</h3>
      </div>

      <div>
        <mat-icon class="community-box-icon" svgIcon="community-box-icon"></mat-icon>
      </div>
    </div>
  </div>
</div>
