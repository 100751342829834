import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { MenuItem } from '../../shared/models/menu-item.model';
import { AuthenticationState } from '../../store/authentication.state';
import { UserState } from '../../store/user.state';
import { CloseSidenav } from '../layout.actions';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'eid-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit {
  @Select(UserState.isProfileComplete) public isProfileComplete$: Observable<boolean>;
  @Select(AuthenticationState.isLoggedIn) public isLoggedIn$: Observable<boolean>;
  @Input() sidenavState$: Observable<boolean>;

  public menu: MenuItem[];
  public menu$: Observable<MenuItem[]>;

  constructor(private layoutService: LayoutService, private store: Store) {}

  public ngOnInit(): void {
    this.loadMenu();
  }

  private loadMenu(): void {
    let activeMenu$ = this.layoutService.menus$.pipe(map((menus) => menus));
    this.menu$ = combineLatest([activeMenu$, this.sidenavState$]).pipe(
      map(([menu, sidenavState]: [MenuItem[], boolean]) => {
        if (sidenavState === false) {
          menu.forEach((value) => {
            value.selected = false;
          });
        }
        return menu;
      })
    );
  }

  public closeNavigation(): void {
    this.store.dispatch(new CloseSidenav());
  }

  public callAction(item: MenuItem) {
    this.store.dispatch(new CloseSidenav());
    item.action();
  }
}
