import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { LottieModule } from 'ngx-lottie';

import { AuthorizeComponent } from '../authorize/authorize.component';
import { InitializeComponent } from '../initialize/initialize.component';
import { MenuItemComponent } from '../layout/menu-item/menu-item.component';
import { LogoutComponent } from '../logout/logout.component';
import { MaterialComponentsModule } from '../material/material-components.module';
import { SetActiveOptionDirective } from '../registration/country/set-active-option-directive';
import { CrsTaxComponent, CrsTaxContainerComponent } from '../shared/components/crs-tax/crs-tax.component';
import { FaqComponent } from '../site/faq/faq.component';
import { HomeComponent } from '../site/home/home.component';
import { IntroFiveComponent } from '../site/intro/intro-five/intro-five.component';
import { IntroFiveDesktopComponent } from '../site/intro/intro-five/intro-five.desktop.component';
import { IntroFiveMobileComponent } from '../site/intro/intro-five/intro-five.mobile.component';
import { IntroFourComponent } from '../site/intro/intro-four/intro-four.component';
import { IntroFourDesktopComponent } from '../site/intro/intro-four/intro-four.desktop.component';
import { IntroFourMobileComponent } from '../site/intro/intro-four/intro-four.mobile.component';
import { IntroOneComponent } from '../site/intro/intro-one/intro-one.component';
import { IntroOneDesktopComponent } from '../site/intro/intro-one/intro-one.desktop.component';
import { IntroOneMobileComponent } from '../site/intro/intro-one/intro-one.mobile.component';
import { IntroStepperComponent } from '../site/intro/intro-stepper/intro-stepper.component';
import { IntroThreeComponent } from '../site/intro/intro-three/intro-three.component';
import { IntroThreeDesktopComponent } from '../site/intro/intro-three/intro-three.desktop.component';
import { IntroThreeMobileComponent } from '../site/intro/intro-three/intro-three.mobile.component';
import { IntroTwoComponent } from '../site/intro/intro-two/intro-two.component';
import { IntroTwoDesktopComponent } from '../site/intro/intro-two/intro-two.desktop.component';
import { IntroTwoMobileComponent } from '../site/intro/intro-two/intro-two.mobile.component';
import { IntroComponent } from '../site/intro/intro/intro.component';
import { IntroDesktopComponent } from '../site/intro/intro/intro.desktop.component';
import { IntroMobileComponent } from '../site/intro/intro/intro.mobile.component';
import { ProductComponent, ProductContainerComponent } from '../site/product/product.component';
import { ProductDesktopComponent } from '../site/product/product.desktop.component';
import { ProductMobileComponent } from '../site/product/product.mobile.component';
import { ProfileTypeComponent, ProfileTypeContainerComponent } from '../site/profile-type/profile-type.component';
import { ProfileTypeDesktopComponent } from '../site/profile-type/profile-type.desktop.component';
import { ProfileTypeMobileComponent } from '../site/profile-type/profile-type.mobile.component';
import { ShareActivityComponent } from '../site/share-activity/share-activity.component';
import { BreakPointDirective } from './breakpoint.directive';
import { AddCrsTaxComponent } from './components/add-crs-tax/add-crs-tax.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CrsTaxDesktopComponent } from './components/crs-tax/crs-tax.desktop.component';
import { CrsTaxMobileComponent } from './components/crs-tax/crs-tax.mobile.component';
import {
  CurrentUserComponent,
  CurrentUserContainerComponent,
  CurrentUserDesktopComponent,
  CurrentUserMobileComponent,
  CurrentUserPanelComponent
} from './components/current-user';
import { DatepickerHeaderComponent } from './components/datepicker-header/datepicker-header.component';
import { DevToolsComponent } from './components/dev-tools/dev-tools.component';
import { EditAddressComponent } from './components/edit-address/edit-address.component';
import { EditAddressDesktopComponent } from './components/edit-address/edit-address.desktop.component';
import { EditAddressMobileComponent } from './components/edit-address/edit-address.mobile.component';
import { EditCompactAddressDesktopComponent } from './components/edit-address/edit-compact-address.desktop.component';
import { LinkedAccountComponent } from './components/linked-account/linked-account.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LoadingSpinnerDirective } from './components/loading-spinner/loading-spinner.directive';
import { AlreadyRegisteredComponent } from './components/messages/already-registered/already-registered.component';
import { DesktopErrorComponent } from './components/messages/desktop-error/desktop-error.component';
import { EasyCryptoAlreadyRegisteredComponent } from './components/messages/ec-already-registered/ec-already-registered.component';
import { ErrorPageComponent } from './components/messages/error-page/error-page.component';
import { GCashErrorComponent } from './components/messages/gcash-error/gcash-error.component';
import { MaintenanceComponent } from './components/messages/maintenance/maintenance.component';
import { RegistrationErrorComponent } from './components/messages/registration-error/registration-error.component';
import { RegistrationPendingComponent } from './components/messages/registration-pending/registration-pending.component';
import { UnsupportedCountrySatrixMobileComponent } from './components/messages/unsupported-country-satrix/unsupported-country-satrix.mobile.component';
import { UnsupportedCountryUSMobileComponent } from './components/messages/unsupported-country-us/unsupported-country-us.mobile.component';
import { UnsupportedCountryMobileComponent } from './components/messages/unsupported-country/unsupported-country.mobile.component';
import { UnsupportedPartnerMobileComponent } from './components/messages/unsupported-partner/unsupported-partner.mobile.component';
import { UnsupportedProductMobileComponent } from './components/messages/unsupported-product/unsupported-product.mobile.component';
import { ProductsComponent, ProductsContainerComponent } from './components/products/products.component';
import { ProductsDesktopComponent } from './components/products/products.desktop.component';
import { ProductsMobileComponent } from './components/products/products.mobile.component';
import { SelectorListComponent } from './components/selector-list/selector-list.component';
import { SelectorComponent } from './components/selector/selector.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { SpaceBetweenNumbersPipe } from './pipes/number-space.pipe';
import { SliceUpperPipe } from './pipes/split-case.pipe';
import { AddressUtilsService } from './services/address.utils';
import { AuthenticationService } from './services/authentication.service';
import { CustomIconService } from './services/custom-icon.service';
import { HubspotService } from './services/hubspot.service';
import { JumioService } from './services/jumio.service';
import { OnfidoService } from './services/onfido.service';
import { RegisterStoreService } from './services/register-store.service';
import { RegistrationService } from './services/registration.service';
import { StaticDataService } from './services/static-data.service';
import { UserStoreService } from './services/user-store.service';
import { UtilService } from './services/util.service';

export function playerFactory() {
  return import('lottie-web');
}
@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    RouterModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  declarations: [
    BreakPointDirective,
    LoadingSpinnerDirective,
    SetActiveOptionDirective,
    SelectorComponent,
    SelectorListComponent,
    AlreadyRegisteredComponent,
    EasyCryptoAlreadyRegisteredComponent,
    RegistrationPendingComponent,
    UnsupportedCountryMobileComponent,
    UnsupportedCountryUSMobileComponent,
    UnsupportedProductMobileComponent,
    UnsupportedPartnerMobileComponent,
    UnsupportedCountrySatrixMobileComponent,
    ErrorPageComponent,
    DesktopErrorComponent,
    MaintenanceComponent,
    RegistrationErrorComponent,
    GCashErrorComponent,
    LoadingSpinnerComponent,
    DevToolsComponent,
    MenuItemComponent,
    LinkedAccountComponent,
    DatepickerHeaderComponent,

    AvatarComponent,
    FaqComponent,
    HomeComponent,
    AuthorizeComponent,
    InitializeComponent,
    LogoutComponent,
    ProfileTypeContainerComponent,
    ProfileTypeComponent,
    ProfileTypeDesktopComponent,
    ProfileTypeMobileComponent,
    ProductContainerComponent,
    ProductComponent,
    ProductDesktopComponent,
    ProductMobileComponent,
    CurrentUserContainerComponent,
    CurrentUserComponent,
    CurrentUserDesktopComponent,
    CurrentUserMobileComponent,
    CurrentUserPanelComponent,
    UserDetailsComponent,
    ShareActivityComponent,

    IntroStepperComponent,

    IntroComponent,
    IntroDesktopComponent,
    IntroMobileComponent,

    IntroOneComponent,
    IntroOneDesktopComponent,
    IntroOneMobileComponent,

    IntroTwoComponent,
    IntroTwoDesktopComponent,
    IntroTwoMobileComponent,

    IntroThreeComponent,
    IntroThreeMobileComponent,
    IntroThreeDesktopComponent,

    IntroFourComponent,
    IntroFourDesktopComponent,
    IntroFourMobileComponent,

    IntroFiveComponent,
    IntroFiveMobileComponent,
    IntroFiveDesktopComponent,

    CrsTaxContainerComponent,
    CrsTaxComponent,
    CrsTaxDesktopComponent,
    CrsTaxMobileComponent,

    EditAddressComponent,
    EditAddressDesktopComponent,
    EditCompactAddressDesktopComponent,
    EditAddressMobileComponent,
    AddCrsTaxComponent,

    ProductsContainerComponent,
    ProductsComponent,
    ProductsDesktopComponent,
    ProductsMobileComponent,

    SpaceBetweenNumbersPipe,
    SliceUpperPipe
  ],
  exports: [
    CommonModule,
    MaterialComponentsModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    BreakPointDirective,
    LoadingSpinnerDirective,
    SetActiveOptionDirective,
    SelectorComponent,
    SelectorListComponent,
    LoadingSpinnerComponent,
    DevToolsComponent,
    MenuItemComponent,
    CurrentUserContainerComponent,
    CurrentUserComponent,
    CurrentUserDesktopComponent,
    CurrentUserMobileComponent,
    CurrentUserPanelComponent,
    LinkedAccountComponent,
    DatepickerHeaderComponent,
    AvatarComponent,
    LottieModule,

    CrsTaxContainerComponent,
    CrsTaxComponent,
    CrsTaxDesktopComponent,
    CrsTaxMobileComponent,

    EditAddressComponent,
    EditAddressDesktopComponent,
    EditCompactAddressDesktopComponent,
    EditAddressMobileComponent,
    AddCrsTaxComponent,

    ProductsContainerComponent,
    ProductsComponent,
    ProductsDesktopComponent,
    ProductsMobileComponent,

    SpaceBetweenNumbersPipe
  ],
  providers: [
    CustomIconService,
    AuthenticationService,
    RegisterStoreService,
    UserStoreService,
    RegistrationService,
    UtilService,
    HubspotService,
    AddressUtilsService,
    StaticDataService,
    JumioService,
    OnfidoService
  ]
})
export class SharedModule {}
