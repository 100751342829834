<div class="intro-box">
  <div class="column column--image"></div>
  <div class="column column--content">
    <div class="intro-text">
      <h2>Experiment fearlessly with our risk-free demo account!</h2>
      <p>Play with virtual cash in our demo account, then venture into real investing with peace of mind.</p>
    </div>

    <div class="intro-step">
      <eid-intro-stepper [active]="2" (stepSelected)="stepSelected($event)"></eid-intro-stepper>
      <div class="close-text">
        <div (mousedown)="close()" class="skip-btn">
          <span class="text">Skip Intro</span>
        </div>
      </div>
    </div>
  </div>
</div>
