<div *ngIf="productDetail.styleTheme !== themes.gcash" class="tw-h-full" eidLoadingSpinner [loading]="true"></div>

<!-- GCASH ROCKET SPINNER -->
<div *ngIf="productDetail.styleTheme === themes.gcash" class="gcash-spinner-container">
  <div class="header-bg"></div>
  <div *eidBreakpoint="'desktop'" class="ee-spinner-desktop">
    <div *ngIf="(showAccountMessage$ | async) === true">
      <h3>We’re Setting Up Your Account</h3>
      <p class="ee-label-desktop">We’re dotting the i’s and crossing the t’s to get your account set up and ready...</p>
      <p class="ee-label-desktop--notice">Please don’t close your window</p>
    </div>
    <div class="rocket-container rocket-container--desktop">
      <ng-lottie class="loader-animation" [options]="gcashAnimationOptions"></ng-lottie>
    </div>
  </div>

  <div *eidBreakpoint="'mobile'" class="card">
    <div *ngIf="(showAccountMessage$ | async) === true">
      <h3>We’re Setting Up Your Account</h3>
      <p class="ee-label-mobile">We’re dotting the i’s and crossing the t’s to get your account set up and ready...</p>
      <p class="ee-label-mobile--notice">Please don’t close your window</p>
    </div>
    <div class="rocket-container rocket-conainer">
      <ng-lottie class="loader-animation" [options]="gcashAnimationOptions"></ng-lottie>
    </div>
  </div>
  <div class="page-footer">
    <img src="../../assets/logos/poweredByEE-inline.png" />
  </div>
</div>
