<div
  class="blur"
  [ngClass]="{
    'blur-not-modal': !modal,
    'blur-modal': modal
  }"
>
  <div class="blur-row">
    <p class="blur-text">Hide portfolio values:</p>
    <mat-icon class="info-icon" #tooltip="matTooltip" (click)="tooltip.toggle()" [matTooltip]="tooltipText"
      >info_fill</mat-icon
    >
  </div>

  <mat-slide-toggle [checked]="checked" (change)="change($event)"></mat-slide-toggle>
</div>
