<div class="section section--hero section--bg-create-id"></div>

<div class="section">
  <div class="container">
    <div class="row row--justify-center">
      <div class="panel panel--padded-half panel--faq">
        <div class="panel__title panel__title--center">
          <h2>Frequently Asked Questions</h2>
          <p *ngIf="(productType$ | async) === 'satrix'">
            Being an online business furthers our cause to make investing accessible to everyone. This includes
            empowering you to access any information you may need on our platform, at any time.
          </p>
        </div>

        <ng-container *ngIf="(productType$ | async) !== 'satrix'; else satrixFAQ">
          <div *ngFor="let item of questions$ | async" class="item">
            <h4 class="question">{{ item.q }}</h4>
            <p class="answer">{{ item.a }}</p>
          </div>
          <p>
            For more information or help, visit
            <a target="_blank" [href]="(urlBundle$ | async)?.support">EasyEquities Support</a>
          </p>
        </ng-container>
        <ng-template #satrixFAQ>
          <div class="message message--list">
            <div class="row align-top">
              <div class="col message__icon">
                <img src="./assets/icons/video.png" />
              </div>
              <div class="col message__text">
                <h2 class="panel__title">Watch our video</h2>
                <p>
                  <a [href]="(urlBundle$ | async)?.video" target="_blank">Watch our video</a>
                  for an overview of the SatrixNOW platform and how to log in and invest.
                </p>
              </div>
            </div>
            <hr />
            <div class="row align-top">
              <div class="col message__icon">
                <img src="./assets/icons/help-centre.png" />
              </div>
              <div class="col message__text">
                <h2 class="panel__title">Visit our help centre</h2>
                <p>
                  Our intuitive and easy-to-use
                  <a [href]="(urlBundle$ | async)?.support" target="_blank">Help Centre</a>
                  is searchable and contains FAQs which we are constantly updating.
                </p>
                <p>
                  <a [href]="(urlBundle$ | async)?.newTicket" target="_blank">Submit a query here</a>
                  if you can't find what you need in the FAQs.
                </p>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
