<div class="partner-alert">
  <p class="partner-heading">Oops! 🌍😞</p>
  <p class="partner-heading">
    International registrations for EasyEquities aren't available on the {{ productDetail.name }}
    app just yet.
  </p>
  <div *ngIf="productDetail.errorText === null || productDetail.errorText === ''" class="partner-text">
    For more info, reach out to our team
    <a target="_blank" [href]="(urlBundle$ | async)?.unsupportedCountry">here</a>. Thanks for your patience!
  </div>
  <div *ngIf="productDetail.errorText !== null && productDetail.errorText !== ''" class="partner-text">
    For more info, reach out to our team
    <a target="_blank" [href]="(urlBundle$ | async)?.unsupportedCountry">here</a> or call {{ productDetail.name }} at
    <strong>{{ productDetail.errorText }}</strong
    >. Thanks for your patience!
  </div>
</div>
