import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnimationOptions } from 'ngx-lottie';
import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ProductDetailModel } from '../registration/models/product.model';
import { Theme } from '../registration/models/theme.model';
import { AuthenticationService } from '../shared/services/authentication.service';
import { InitializeService } from '../shared/services/initialize.service';
import { ProductService } from '../shared/services/product.service';
import { RegisterStoreService } from '../shared/services/register-store.service';

@UntilDestroy()
@Component({
  selector: 'eid-initialize',
  templateUrl: './initialize.component.html',
  styleUrls: ['./initialize.component.scss']
})
export class InitializeComponent implements OnInit {
  public themes = Theme;
  public productDetail: ProductDetailModel;

  public showAccountMessage$ = new BehaviorSubject<boolean>(true);
  gcashAnimationOptions: AnimationOptions = {
    // tslint:disable-next-line: max-line-length
    path: '../../assets/animations/rocket_gcash.json' // download the JSON version of animation in your project directory and add the path to it like ./assets/animations/example.json
  };

  constructor(
    private initializeService: InitializeService,
    private authenticationService: AuthenticationService,
    private registerStoreService: RegisterStoreService,
    private productService: ProductService
  ) {
    const sessionUrl = this.authenticationService.getUrl();
    if (sessionUrl?.toLowerCase().indexOf('/compliance') > -1 || sessionUrl?.toLowerCase().indexOf('/game') > -1) {
      this.showAccountMessage$.next(false);
    }
    const productType = this.registerStoreService.getProduct();
    this.productDetail = this.productService.getProductDetail(productType);
  }

  ngOnInit(): void {
    this.initializeService
      .handleIncomingParameters()
      .pipe(
        switchMap((canContinue) => {
          if (canContinue) {
            return this.initializeService.handleRedirects();
          }
          return of(true);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
