import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CalendarEvent } from 'angular-calendar';
import { map } from 'rxjs/operators';
import { EngagementService } from '../../shared/services/engagement.service';

@Component({
  template: `
    <eid-calendar-desktop *eidBreakpoint="'desktop'"></eid-calendar-desktop>
    <eid-calendar-mobile *eidBreakpoint="'mobile'"></eid-calendar-mobile>
  `
})
export class CalendarContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class CalendarComponent {
  daysLabel: Array<string> = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  public viewDate;

  events: CalendarEvent[] = [];

  constructor(private engagementService: EngagementService) {
    this.viewDate = new Date();
    this.viewDate.setMonth(this.viewDate.getMonth() - 1);

    this.engagementService
      .getEngagementData()
      .pipe(
        map((data) => {
          // Clear old dates
          this.events = [];

          /* activity information */
          data?.userActiveDays.forEach((item: number) => {
            //Need to recreate the date every time because invalid item numbers can come in.
            let date = new Date();
            date.setMonth(date.getMonth() - 1);
            date.setDate(item);

            this.events.push({
              title: 'Login',
              allDay: true,
              draggable: false,
              start: date
            });
          });
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
