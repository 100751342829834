<div class="footer-top section section--bordered">
  <div class="container">
    <div class="row row--align-center top-row">
      <div class="logo">
        <div class="logo__app"></div>
        <div class="logo__powered-by"></div>
        <div class="footer-navigation">
          <a [routerLink]="['/faq']" routerLinkActive="active">FAQ</a>
          <a [href]="(urlBundle$ | async)?.support" target="_blank">Support</a>
          <a [href]="(urlBundle$ | async)?.contact" target="_blank">Contact Us</a>
        </div>
      </div>
      <div class="fill"></div>
    </div>
  </div>
</div>
<div class="section">
  <div class="container">
    <div class="row middle-row">
      <div class="text-block text-block--small">
        <p>
          First World Trader (Pty) Ltd t/a EasyEquities and EasyEquities Pty Ltd are separate legal entities and are
          both members of the Purple Group Limited group of companies. References to “EasyEquities” are to one or more
          of the Purple Group members or one of their respective affiliates (collectively the “EasyEquities entity/ies”.
          Each EasyEquities entity provides financial services in particular jurisdictions and is subject to the laws
          and regulations of the particular country/ies in which it operates. This website and the information, tools
          and material contained in it are not directed to, or intended for distribution to or use by, any person or
          entity who is a citizen or resident of or located in any jurisdiction where such distribution, publication,
          availability or use would be contrary to law or regulation or which would subject any EasyEquities entity to
          any registration or licensing requirement within such jurisdiction. To view a particular EasyEquities entity
          disclaimer for a particular jurisdiction,
          <a href="#" (click)="toggleDisclaimer($event)" [ngClass]="'text--accent'"
            ><span *ngIf="!disclaimerActive">please read more:</span><span *ngIf="disclaimerActive">CLOSE</span></a
          >
        </p>
        <div *ngIf="disclaimerActive" class="disclaimer">
          <p>
            <strong>South Africa</strong><br />
            &copy;EasyEquities. First World Trader (Pty) Ltd t/a EasyEquities is an authorized Financial Services
            Provider (FSP number 22588). All trades on the EasyEquities platform are subject to the legal terms and
            conditions to which you agree to be bound. The EasyEquities platform enables users to invest in securities
            which includes whole shares and fractional share rights (FSRs). EasyEquities acts as an agent for the issue
            of whole shares, where the investor is the registered owner of those shares, entitled to dividends,
            participation in corporate actions and all the economic benefits and risks associated with share ownership.
            In respect of FSRs, EasyEquities acts as principal to a contract for difference issued to the investor,
            where the investor will have a contractual claim against EasyEquities to the economic benefits and risks
            associated with share ownership (price movements and dividends) without having ownership rights in the
            underlying share. Fractional share rights (FSRs) which are issued through a contract for difference, are an
            over the counter derivative. Unlike whole shares, FSRs do not carry any voting rights. As the investor makes
            further investments in FSRs and ultimately ends up with a whole share, the contract for difference is closed
            out and ownership whole share is delivered to the investor.
          </p>

          <p>
            <strong>Australia</strong><br />
            EasyEquities Pty Ltd, trading as EasyEquities, ACN 632 503 416, is a Corporate Authorised Representative (AR
            No. 1276104) of Sanlam Private Wealth Pty Ltd (ACN: 136 960 775) which holds an AFS Licence (AFSL No.
            337927). All trades on the EasyEquities platform are subject to the legal terms and conditions to which you
            agree to be bound. The EasyEquities platform enables you to invest in securities with all investment
            decisions being made solely by you. The information contained on this website is general advice that has
            been prepared without taking account of your objectives, financial situation or needs. Before acting on this
            advice you should consider the appropriateness of the advice, having regard to your objectives, financial
            situation and needs. You should read and consider the relevant Product Disclosure Statement before making a
            decision about whether to purchase any financial products. The availability of any security on the
            EasyEquities platform is based on various factors but is not an indication of value and does not mean that
            any security is an appropriate investment for you. As with all investments, they may gain or lose value
            therefore before making any investment decision, please consider if it's appropriate for your financial
            situation and obtain the necessary financial, taxation and/or legal advice. Please view our Financial
            Services Guide before deciding to invest on the EasyEquities platform. The availability of any share on the
            EasyEquities platform is based on various factors but is not an indication of value and does not mean that
            any share is an appropriate investment for you. The availability of any share on the browse shares page does
            not necessarily indicate any contractual relationship between EasyEquities and the listed company, or the
            payment of fees for services. Brand Logos are owned by the respective companies and not by EasyEquities. The
            use of a company’s brand logo does not represent an endorsement of EasyEquities by the company, nor an
            endorsement of the company by EasyEquities, nor does it necessarily imply any contractual relationship.
            Further investment disclosures are available on the EasyEquities website.
          </p>
          <p>
            *Note exchange prices are delayed in accordance with regional exchange rules. South African prices are
            delayed by 15 minutes; North American prices are delayed by 15 minutes; Australian prices are delayed by 20
            minutes.
          </p>
          <p>EasyEquities is a subsidiary of Purple Group Limited, a company listed on the JSE Limited (PPE)</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section section--bg-light">
  <div class="container">
    <div class="row row--align-center bottom-row">
      <div
        class="legal-navigation"
        *ngIf="
          (contractingEntity$ | async) === contractingEntities.fwt && (productType$ | async) !== productTypes.easycrypto
        "
      >
        <a *ngIf="isProfileComplete$ | async" target="_blank" [href]="(urlBundle$ | async)?.termsAndConditions"
          >Terms &amp; Conditions</a
        >
        <a target="_blank" [href]="(urlBundle$ | async)?.termsOfUse">Terms of use</a>
        <a target="_blank" [href]="(urlBundle$ | async)?.privacy">Privacy policy</a>
        <a *ngIf="isProfileComplete$ | async" target="_blank" [href]="(urlBundle$ | async)?.complaintsProcess"
          >Complaints Process</a
        >
        <a target="_blank" [href]="(urlBundle$ | async)?.faisDisclosure">FAIS disclosure</a>
        <a target="_blank" [href]="(urlBundle$ | async)?.informationAccess">Access to info manual</a>
        <a *ngIf="isProfileComplete$ | async" target="_blank" [href]="(urlBundle$ | async)?.costProfile"
          >Cost Profile</a
        >
        <a *ngIf="isProfileComplete$ | async" target="_blank" [href]="(urlBundle$ | async)?.instrumentList"
          >Instrument List</a
        >
      </div>
      <div
        class="legal-navigation legal-navigation--importantdocs"
        *ngIf="
          (contractingEntity$ | async) === contractingEntities.aus ||
          (contractingEntity$ | async) === contractingEntities.unknown ||
          (productType$ | async) === productTypes.easycrypto
        "
      >
        <a target="_blank" [href]="(urlBundle$ | async)?.termsOfUse"
          >ACCESS IMPORTANT DOCUMENTS (I.E. TERMS AND CONDITIONS, COST PROFILE, POLICIES ETC)</a
        >
      </div>
    </div>
  </div>
</div>
