import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Theme } from '../registration/models/theme.model';
import { ProductService } from '../shared/services/product.service';
import { RegisterStoreService } from '../shared/services/register-store.service';

@Injectable()
export class DesktopGuard implements CanActivate {
  constructor(
    private deviceService: DeviceDetectorService,
    private productService: ProductService,
    private registerStoreService: RegisterStoreService,
    private router: Router
  ) {}

  canActivate() {
    const productType = this.registerStoreService.getProduct();
    const productDetail = this.productService.getProductDetail(productType);

    if (productDetail.styleTheme === Theme.gcash && this.deviceService.isDesktop()) {
      this.router.navigate(['/message/desktop-error']);
      return false;
    }
  }
}
