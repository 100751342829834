import { Theme } from '../registration/models/theme.model';
import { LayoutStateModel } from './layout.state';
import { HeaderAction } from './models/header-action.model';

export class SetLayout {
  static readonly type = '[Layout] Set Layout State';
  constructor(public payload: Partial<LayoutStateModel>) {}
}

export class SetHeader {
  static readonly type = '[HEADER] Set Properties';
  constructor(public headerProperties: { title: string; leftAction: HeaderAction; rightAction: HeaderAction }) {}
}

export class SetIsDialog {
  static readonly type = '[IS DIALOG] Set Is Dialog';
  constructor(public isDialog: string) {}
}

export class SetPageTitle {
  static readonly type = '[PAGE TITLE] Set Page Title';
  constructor(public title: string) {}
}

export class SetLeftAction {
  static readonly type = '[ACTION LEFT] Set Action Left';
  constructor(public actionLeft: HeaderAction) {}
}

export class SetRightAction {
  static readonly type = '[ACTION RIGHT] Set Action Right';
  constructor(public actionRight: HeaderAction) {}
}

export class SetTheme {
  static readonly type = '[Registration] Set Theme';
  constructor(public payload: Theme) {}
}

export class OpenSidenav {
  static readonly type = '[SIDENAV] Open';
  constructor() {}
}
export class CloseSidenav {
  static readonly type = '[SIDENAV] Close';
  constructor() {}
}

export class ToggleSidenav {
  static readonly type = '[SIDENAV] Toggle';
  constructor() {}
}
