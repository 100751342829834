<div class="box">
  <div class="root">
    <div class="left">
      <div class="left-header">
        <div>
          <mat-icon class="wallet-logo-sm" svgIcon="wallet-md"></mat-icon>
          <h2 class="left-title">Monthly Deposits</h2>
        </div>
      </div>
      <div class="left-body-sm">
        <div>
          <h3>Your Monthly Deposits</h3>
          <h4>
            <span>{{ userDepositCount }}&#160;</span>
            Deposits
          </h4>
        </div>
        <div>
          <h3>Community Avg</h3>
          <h4>
            <span>{{ communityDepositAverage }}&#160;</span>
            Deposits
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
