import { AfterViewInit, Component, ElementRef, Input, NgZone, Output, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { AddressModel } from '../../../profile/models/address.model';
import { StaticCountry } from '../../../registration/models/country.model';
import { Province, ProvinceModel } from '../../../registration/models/province.model';
import { CountryIdEnum } from '../../constants/enums';
import { SelectorListItem } from '../../models/selector-list-item.model';
import { AddressUtilsService } from '../../services/address.utils';
import { StaticDataService } from '../../services/static-data.service';
import { UserStoreService } from '../../services/user-store.service';
import { fieldInvalid, fieldSummaryError, fieldTypeError, fieldValid, isDefined } from '../../utilities/utils';
import { AddressValidators } from './edit-address.validators';

@UntilDestroy()
@Component({ template: '' })
export class EditAddressComponent implements AfterViewInit {
  @ViewChild('search') search: ElementRef;
  public autocomplete;

  @Input() public isSubmitted: Observable<boolean>;
  @Input() public residenceCountry: StaticCountry;
  @Input() public currentAddress: AddressModel;

  @Output() public busy: BehaviorSubject<boolean> = new BehaviorSubject(true);
  @Output() public valid: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Output() public address: BehaviorSubject<AddressModel> = new BehaviorSubject(null);

  public tenantId = '1';
  public states$;
  public provinces: SelectorListItem[];
  public allProvinces: Province[];

  public addressForm: UntypedFormGroup;
  public state: SelectorListItem;

  private googleStreetNumber = '';
  private googleStreetName = '';

  public provinceStateDescription: string;

  public fieldInvalid = fieldInvalid;
  public fieldTypeError = fieldTypeError;
  public fieldValid = fieldValid;
  public fieldSummaryError = fieldSummaryError;

  constructor(
    private fb: FormBuilder,
    private staticDataService: StaticDataService,
    private ngZone: NgZone,
    private addressUtilsService: AddressUtilsService,
    private userStoreService: UserStoreService
  ) {
    this.tenantId = this.userStoreService.getUser()?.tenantId;
    this.createForm();
  }

  public createForm() {
    this.addressForm = this.fb.group({
      address: ['', [Validators.required, AddressValidators.streetAddress(this.tenantId, this.addressUtilsService)]],
      addressLine2: ['', [AddressValidators.streetAddress2(this.tenantId, this.addressUtilsService)]],
      suburb: ['', [Validators.required, AddressValidators.suburb(this.tenantId)]],
      city: ['', [Validators.required, AddressValidators.city(this.tenantId)]],
      code: ['', [Validators.required, AddressValidators.code(this.tenantId)]],
      state: ['', [Validators.required, Validators.pattern(/^[1-9]\d*$/)]]
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadData();
      this.setupGoogleMaps();
    }, 0);
  }

  private loadData() {
    this.isSubmitted
      .pipe(
        filter((submitted) => submitted === true),
        map(() => {
          this.addressForm.markAllAsTouched();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.addressForm.statusChanges
      .pipe(
        tap((value) => {
          this.valid.next(value === 'VALID');
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.setupValueChanges();

    this.getStaticData();

    if (!this.residenceCountry?.countryId || this.residenceCountry?.countryId !== CountryIdEnum.southAfrica) {
      this.provinceStateDescription = 'State';
    } else {
      this.provinceStateDescription = 'Province';
    }
  }

  private setupGoogleMaps() {
    this.autocomplete = new google.maps.places.Autocomplete(this.search.nativeElement, {
      types: ['address'],
      componentRestrictions: { country: this.residenceCountry.countryCode2 }
    });

    this.autocomplete.setFields(['address_component']);

    this.autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = this.autocomplete.getPlace();
        const area = this.getPlace(place, ['administrative_area_level_1']);
        const province = this.getProvince(area);
        this.state = province;

        let newState = 0;
        if (!isNil(province)) {
          newState = province.value as number;
        }

        const subPremise = this.getPlace(place, ['subpremise']);
        this.googleStreetNumber = this.getPlace(place, ['street_number']);
        this.googleStreetName = this.getPlace(place, ['route']);
        if (!isNil(subPremise) && subPremise !== '') {
          this.googleStreetNumber = subPremise + '/' + this.googleStreetNumber;
        }

        this.addressForm.patchValue({
          address: this.googleStreetNumber + ' ' + this.googleStreetName,
          addressLine2: '',
          suburb: this.getPlace(place, ['sublocality_level_1']),
          city: this.getPlace(place, ['locality']),
          code: this.getPlace(place, ['postal_code']),
          state: newState
        });
        this.addressForm.markAllAsTouched();
      });
    });
  }

  private setupValueChanges() {
    this.addressForm.valueChanges
      .pipe(
        map(() => {
          let addressParts = {
            addressNumber: this.googleStreetNumber,
            addressName: this.googleStreetName
          };
          if (this.addressForm.value.address !== this.googleStreetNumber + ' ' + this.googleStreetName) {
            // Address changed by user
            addressParts = this.addressUtilsService.getAddressParts(this.addressForm.value.address);
          }
          const address2Parts = this.addressUtilsService.getAddressParts(this.addressForm.value.addressLine2);
          var selectedProvince = this.allProvinces.find((p) => p.provinceId === this.addressForm.value.state);
          const address = {
            physicalAddressId: this.currentAddress?.physicalAddressId ?? 0,
            unitNumber: address2Parts.addressNumber.trim(),
            complexName: address2Parts.addressName.trim(),
            streetNumber: addressParts.addressNumber.trim(),
            streetName: addressParts.addressName.trim(),
            suburb: this.addressForm.value.suburb.trim(),
            city: this.addressForm.value.city.trim(),
            code: this.addressForm.value.code.trim(),
            provinceId: this.addressForm.value.state,
            province: selectedProvince?.provinceName,
            countryId: this.residenceCountry.countryId,
            country: this.residenceCountry.countryName
          };
          this.address.next(address);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getStaticData() {
    this.staticDataService
      .getProvinces()
      .pipe(untilDestroyed(this))
      .subscribe((provinceModel) => {
        this.allProvinces = provinceModel.provinces;
        this.provinces = this.getProvincesForCountry(provinceModel);
        this.states$ = of(this.provinces);

        if (!isNil(this.currentAddress)) {
          this.state = this.provinces.filter((p) => p.value === this.currentAddress.provinceId)[0];

          const addressLine = this.formatAddress(this.currentAddress.streetNumber, this.currentAddress.streetName);
          const addressLine2 = this.formatAddress(this.currentAddress.unitNumber, this.currentAddress.complexName);

          this.addressForm.patchValue({
            address: addressLine,
            addressLine2: addressLine2,
            suburb: this.currentAddress.suburb.trim(),
            city: this.currentAddress.city.trim(),
            code: this.currentAddress.code.trim(),
            state: this.getValidProvince(this.currentAddress.provinceId)
          });

          if (
            this.provinces.length === 1 &&
            (this.addressForm.get('state').value === '' || this.addressForm.get('state').value === 0)
          ) {
            this.state = this.provinces[0];
            this.addressForm.patchValue({
              state: this.provinces[0].value
            });
          }

          this.markFilledFields();
          this.busy.next(false);
        }
      });
  }

  protected getValidProvince(selectedProvince: number): number {
    var validProvince = this.provinces.find((p) => p.value === selectedProvince);
    if (validProvince) {
      return selectedProvince;
    }
    return 0;
  }

  private formatAddress(streetNumber, name): string {
    const formattedNumber = streetNumber === null ? '' : streetNumber;
    const formattedName = name === null ? '' : name;
    return (formattedNumber + ' ' + formattedName).trim();
  }

  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        const circle = new google.maps.Circle({ center: geolocation, radius: position.coords.accuracy });
        this.autocomplete.setBounds(circle.getBounds());
      });
    }
  }

  private getProvince(area: string) {
    if (this.provinces.length === 1) {
      return this.provinces[0];
    }
    // This is a terrible hack to accomodate the difference between Google Maps API and EasyEquities API.
    // Need to fix in EasyEquities.
    if (area === 'KwaZulu-Natal') {
      area = 'Kwa-Zulu Natal';
    }
    const provinces = this.provinces.filter((p) => p.label === area);
    if (provinces.length > 0) {
      return provinces[0];
    }
    return null;
  }

  private markFilledFields() {
    Object.keys(this.addressForm.controls).forEach((key) => {
      if (
        isDefined(this.addressForm.get(key).value) &&
        this.addressForm.get(key).value !== '' &&
        this.addressForm.get(key).value !== 0
      ) {
        this.addressForm.get(key).markAsTouched();
      }
    });
  }

  private getPlace(place: any, types: string[]): string {
    let thisPlace = '';
    for (const type of types) {
      const line = place.address_components.find((c) => c.types.indexOf(type) > -1);
      if (line) {
        thisPlace = line.long_name;
        break;
      }
    }
    return thisPlace;
  }

  private getProvincesForCountry(value: ProvinceModel): SelectorListItem[] {
    const filterProvinces = value.provinces.filter((p) => p.countryId === this.residenceCountry.countryId);
    return filterProvinces.map((cc) => ({ label: cc.provinceName, value: cc.provinceId }));
  }
}
