import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { EngagementService } from '../../shared/services/engagement.service';
import { EngagementModel } from '../models/engagement.model';

@Component({
  template: `
    <eid-referrals-desktop *eidBreakpoint="'desktop'"></eid-referrals-desktop>
    <eid-referrals-mobile *eidBreakpoint="'mobile'"></eid-referrals-mobile>
  `
})
export class ReferralsContainerComponent {}
@UntilDestroy()
@Component({
  template: ''
})
export class ReferralsComponent {
  public engagement$: Observable<EngagementModel>;

  constructor(private engagementService: EngagementService) {
    this.engagement$ = this.engagementService.getEngagementData();
  }
}
