import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ApiTokenModel } from '../shared/models/api-token.model';
import {
  Authenticate,
  UpdateApiToken,
  UpdateErrorRequests,
  UpdateInitialize as UpdateInitialized,
  UpdateIsLoggedIn,
  UpdateUrl
} from './authentication.actions';

@State<ApiTokenModel>({
  name: 'authenticate',
  defaults: {
    token: '',
    idToken: '',
    issued: '',
    expires: '',
    isLoggedIn: false,
    url: '',
    initialized: false,
    errorRequests: []
  }
})
@Injectable()
export class AuthenticationState {
  @Selector()
  static apiTokenModel(state: ApiTokenModel) {
    return state;
  }

  @Selector()
  static getToken(state: ApiTokenModel) {
    return state.token;
  }

  @Selector()
  static isLoggedIn(state: ApiTokenModel) {
    return state.isLoggedIn;
  }

  @Selector()
  static getUrl(state: ApiTokenModel) {
    return state.url;
  }

  @Selector()
  static isInitialized(state: ApiTokenModel) {
    return state.initialized;
  }

  @Selector()
  static errorRequests(state: ApiTokenModel) {
    return state.errorRequests;
  }

  @Action(Authenticate)
  authenticate({ patchState }: StateContext<ApiTokenModel>) {
    patchState({
      token: '',
      idToken: '',
      issued: '',
      expires: '',
      isLoggedIn: false,
      url: '',
      initialized: false,
      errorRequests: []
    });
  }

  @Action(UpdateApiToken)
  updateApiToken({ patchState }: StateContext<ApiTokenModel>, { payload }: UpdateApiToken) {
    patchState({
      token: payload.token,
      idToken: payload.idToken,
      issued: payload.issued,
      expires: payload.expires,
      isLoggedIn: payload.isLoggedIn,
      url: payload.url,
      initialized: payload.initialized,
      errorRequests: payload.errorRequests
    });
  }

  @Action(UpdateUrl)
  UpdateUrl({ patchState }: StateContext<ApiTokenModel>, { payload }: UpdateUrl) {
    patchState({
      url: payload
    });
  }

  @Action(UpdateIsLoggedIn)
  UpdateIsLoggedIn({ patchState }: StateContext<ApiTokenModel>, { payload }: UpdateIsLoggedIn) {
    patchState({
      isLoggedIn: payload
    });
  }

  @Action(UpdateInitialized)
  UpdateIsInitialized({ patchState }: StateContext<ApiTokenModel>, { payload }: UpdateInitialized) {
    patchState({
      initialized: payload
    });
  }

  @Action(UpdateErrorRequests)
  UpdateErrorRequest({ patchState }: StateContext<ApiTokenModel>, { payload }: UpdateErrorRequests) {
    patchState({
      errorRequests: payload
    });
  }
}
