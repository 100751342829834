import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorizeComponent } from './authorize/authorize.component';
import { DesktopGuard } from './guards/desktop.guard';
import { HomeGuard } from './guards/home.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { MinorRegistrationGuard } from './guards/minor-registration.guard';
import { ProductGuard } from './guards/product.guard';
import { ProfileTypeGuard } from './guards/profile-type.guard';
import { RegistrationGuard } from './guards/registration.guard';
import { ShareActivityGuard } from './guards/share-activity.guard';
import { InitializeComponent } from './initialize/initialize.component';
import { LayoutContainerComponent } from './layout/layout/layout.component';
import { LogoutComponent } from './logout/logout.component';
import { AlreadyRegisteredComponent } from './shared/components/messages/already-registered/already-registered.component';
import { DesktopErrorComponent } from './shared/components/messages/desktop-error/desktop-error.component';
import { EasyCryptoAlreadyRegisteredComponent } from './shared/components/messages/ec-already-registered/ec-already-registered.component';
import { ErrorPageComponent } from './shared/components/messages/error-page/error-page.component';
import { MaintenanceComponent } from './shared/components/messages/maintenance/maintenance.component';
import { RegistrationErrorComponent } from './shared/components/messages/registration-error/registration-error.component';
import { RegistrationPendingComponent } from './shared/components/messages/registration-pending/registration-pending.component';
import { UnsupportedCountrySatrixMobileComponent } from './shared/components/messages/unsupported-country-satrix/unsupported-country-satrix.mobile.component';
import { UnsupportedCountryUSMobileComponent } from './shared/components/messages/unsupported-country-us/unsupported-country-us.mobile.component';
import { UnsupportedCountryMobileComponent } from './shared/components/messages/unsupported-country/unsupported-country.mobile.component';
import { UnsupportedPartnerMobileComponent } from './shared/components/messages/unsupported-partner/unsupported-partner.mobile.component';
import { UnsupportedProductMobileComponent } from './shared/components/messages/unsupported-product/unsupported-product.mobile.component';
import { SelectorListComponent } from './shared/components/selector-list/selector-list.component';
import { FaqComponent } from './site/faq/faq.component';
import { HomeComponent } from './site/home/home.component';
import { ProductContainerComponent } from './site/product/product.component';
import { ProfileTypeContainerComponent } from './site/profile-type/profile-type.component';
import { ShareActivityComponent } from './site/share-activity/share-activity.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'initialize',
    component: LayoutContainerComponent,
    children: [{ path: '', component: InitializeComponent }],
    canActivate: [DesktopGuard]
  },
  {
    path: 'authorize',
    component: LayoutContainerComponent,
    children: [{ path: '', component: AuthorizeComponent }],
    canActivate: [DesktopGuard]
  },
  {
    path: 'compliance',
    loadChildren: () => import('./compliance/compliance.module').then((m) => m.ComplianceModule),
    canActivate: [DesktopGuard]
  },
  {
    path: 'home',
    component: LayoutContainerComponent,
    children: [{ path: '', component: HomeComponent }],
    canActivate: [HomeGuard]
  },
  {
    path: 'register',
    canActivate: [RegistrationGuard, DesktopGuard],
    loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule)
  },
  {
    path: 'minor',
    canActivate: [MinorRegistrationGuard],
    loadChildren: () => import('./minor-registration/minor-registration.module').then((m) => m.MinorRegistrationModule)
  },
  {
    path: 'profile',
    component: LayoutContainerComponent,
    children: [{ path: '', loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule) }],
    canActivate: [LoggedInGuard]
  },
  {
    path: 'game',
    loadChildren: () => import('./game/game.module').then((m) => m.GameModule),
    canActivate: [DesktopGuard]
  },
  {
    path: 'engagement',
    loadChildren: () => import('./engagement/engagement.module').then((m) => m.EngagementModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'logout',
    component: LayoutContainerComponent,
    children: [{ path: '', component: LogoutComponent }]
  },
  {
    path: 'profile-type',
    component: LayoutContainerComponent,
    children: [{ path: '', component: ProfileTypeContainerComponent }],
    canActivate: [ProfileTypeGuard]
  },
  {
    path: 'product',
    component: LayoutContainerComponent,
    children: [{ path: '', component: ProductContainerComponent }],
    canActivate: [ProductGuard]
  },
  {
    path: 'faq',
    component: LayoutContainerComponent,
    children: [{ path: '', component: FaqComponent }]
  },
  {
    path: 'share',
    component: LayoutContainerComponent,
    children: [{ path: '', component: ShareActivityComponent }],
    canActivate: [ShareActivityGuard]
  },
  { path: 'select/:list', component: SelectorListComponent },

  {
    path: 'message',
    component: LayoutContainerComponent,
    children: [
      { path: 'duplicate', component: AlreadyRegisteredComponent },
      {
        path: 'ecduplicate',
        component: EasyCryptoAlreadyRegisteredComponent
      },
      {
        path: 'pending',
        component: RegistrationPendingComponent
      },
      { path: 'error', component: ErrorPageComponent },
      { path: 'maintenance', component: MaintenanceComponent },
      {
        path: 'registration-error',
        component: RegistrationErrorComponent
      },
      {
        path: 'unsupportedcountry',
        component: UnsupportedCountrySatrixMobileComponent
      },
      {
        path: 'unsupported',
        component: UnsupportedCountryMobileComponent
      },
      {
        path: 'unsupportedus',
        component: UnsupportedCountryUSMobileComponent
      },
      {
        path: 'unsupportedproduct',
        component: UnsupportedProductMobileComponent
      },
      {
        path: 'unsupportedpartner',
        component: UnsupportedPartnerMobileComponent
      },
      {
        path: 'desktop-error',
        component: DesktopErrorComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    HomeGuard,
    RegistrationGuard,
    MinorRegistrationGuard,
    LoggedInGuard,
    ProfileTypeGuard,
    ProductGuard,
    DesktopGuard,
    ShareActivityGuard
  ]
})
export class AppRoutingModule {}
