<div class="intro-box">
  <div class="column column--image">
    <img src="assets/icons/intro/intro-three.png" alt="intro-three" />
  </div>
  <div class="column column--content">
    <div class="close-btn">
      <mat-icon svgIcon="close" class="close-icon" (click)="close()"></mat-icon>
    </div>
    <div class="copy-column">
      <div class="copy">
        <h2>Start investing with your pocket change!</h2>
        <p>
          Begin your investment journey with any amount in fractional shares. Empowering all to grow wealth, one
          fraction at a time!
        </p>
      </div>
      <div class="intro-step">
        <eid-intro-stepper [active]="3" (stepSelected)="stepSelected($event)"></eid-intro-stepper>
      </div>
    </div>
  </div>
</div>
