import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceUpper'
})
export class SliceUpperPipe implements PipeTransform {
  transform(value: string): [string, string] {
    if (!value) return ['', ''];

    // Split the string at uppercase letters
    const parts = value.split(/(?=[A-Z])/);

    return [parts[0], parts[1]];
  }
}
