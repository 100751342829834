import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { EngagementModel, EngagementStateModel, ImageName, ImageURL } from '../../engagement/models/engagement.model';
import { LOGGER, Logger } from '../../logging/logger.service';
import { UpdateEngagementData } from '../../store/engagement.actions';
import { EngagementState } from '../../store/engagement.state';
import { isDefined } from '../utilities/utils';

@Injectable({
  providedIn: 'root'
})
export class EngagementService {
  private engagement$: Observable<EngagementModel>;
  private engagementDate: string;

  constructor(@Inject(LOGGER) private logger: Logger, private httpClient: HttpClient, private store: Store) {}

  public clearEngagement() {
    this.store.dispatch(new UpdateEngagementData(null));
  }

  public getEngagementData(): Observable<EngagementModel> {
    const today = new Date();
    const date = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();

    return this.store.select(EngagementState.getEngagementByDate(date)).pipe(
      switchMap((model) => {
        if (isDefined(model)) {
          return of(model);
        }
        return this.getAndCacheEngagement(date);
      })
    );
  }

  private getAndCacheEngagement(date: string): Observable<EngagementModel> {
    if (isDefined(this.engagement$) && this.engagementDate === date) {
      return this.engagement$;
    }

    this.engagementDate = date;
    this.engagement$ = this.httpClient.get<EngagementModel>('/api/engagement/dashboard').pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
      map((model) => {
        const engagementState: EngagementStateModel = {
          engagementModel: model,
          engagementDate: date
        };
        this.store.dispatch(new UpdateEngagementData(engagementState));
        return model;
      })
    );

    return this.engagement$;
  }

  public saveImage(data, identifier) {
    const imageData = {
      data,
      identifier: identifier
    };

    const returnMock = {
      name: 'Dri5gTS7cNJOXkYaa7WTwirKZwCWGHYbq0TdFRUo'
    };
    return of(returnMock);
    return this.httpClient.post<ImageName>('/api/engagement/saveimage', imageData);
  }

  public imagePath(fileName: string): Observable<ImageURL> {
    const imageData = {
      name: fileName
    };
    const returnMock = {
      url: 'https://d2oqvnohy6azqi.cloudfront.net/test/202403/Dri5gTS7cNJOXkYaa7WTwirKZwCWGHYbq0TdFRUo.png'
    };
    return of(returnMock);
    return this.httpClient.post<ImageURL>('/api/engagement/imagepath', imageData);
  }
}
