<div
  [ngClass]="{
    small: size === 'small',
    medium: size === 'medium',
    large: size === 'large'
  }"
  #avatar
  class="tw-flex tw-justify-center tw-items-center tw-bg-cover tw-bg-center tw-bg-no-repeat"
>
  <span class="initials">{{ initials }}</span>
</div>
