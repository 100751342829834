import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject, forkJoin, of } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';

import { TaxDetail } from '../../../profile/models/tax.model';
import { StaticCountryModel } from '../../../registration/models/country.model';
import { ComplianceCategory, ProductDetailModel, ProductType } from '../../../registration/models/product.model';
import { CountryIdEnum, TaxTypeEnum, TaxTypeIdEnum } from '../../constants/enums';
import { NoDataResidence, TaxResidence } from '../../models/compliance.model';
import { SelectorList, SelectorListItem } from '../../models/selector-list-item.model';
import { TaxDataModel } from '../../models/tax.model';
import { CountryService } from '../../services/country.service';
import { ProductService } from '../../services/product.service';
import { ProfileService } from '../../services/profile.service';
import { StaticDataService } from '../../services/static-data.service';
import {
  fieldInvalid,
  fieldRadioValid,
  fieldSummaryError,
  fieldTypeError,
  fieldValid,
  isDefined
} from '../../utilities/utils';

@Component({
  template: `
    <eid-crs-tax-mobile *eidBreakpoint="'mobile'"></eid-crs-tax-mobile>
    <eid-crs-tax-desktop *eidBreakpoint="'desktop'"></eid-crs-tax-desktop>
  `
})
export class CrsTaxContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class CrsTaxComponent {
  public productTypes = ProductType;
  public countryId = CountryIdEnum;
  public complianceCategories = ComplianceCategory;

  public taxRegistered$: Subject<boolean> = new Subject();
  public taxResidences$: BehaviorSubject<TaxResidence[]> = new BehaviorSubject([]);

  @Input() public isSubmitted: Observable<boolean>;
  @Input() public residenceCountryId: number;
  @Input() public productType: ProductType;
  @Input() public userRegistered: boolean;

  @Input() public countrySelectListItems: SelectorListItem[];
  @Input() public countrySelectList: SelectorList;

  @Output() public taxRegisteredValid: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Output() public noDataReasonValid: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Output() public taxRegistered = this.taxRegistered$;
  @Output() public noDataReason: EventEmitter<NoDataResidence> = new EventEmitter();
  @Output() public taxResidences = this.taxResidences$;

  public taxResidencesList: TaxResidence[];
  public thisIsSubmitted = false;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  dataSource$: Observable<MatTableDataSource<any>>;

  public crsTaxForm: FormGroup;
  public fb: FormBuilder;

  public staticDataService: StaticDataService;
  public profileService: ProfileService;
  public countryService: CountryService;
  private productService: ProductService;

  public productDetail: ProductDetailModel;
  protected countries: StaticCountryModel;
  private taxDataModel: TaxDataModel;

  public existingNoDataReason: NoDataResidence;

  public fieldInvalid = fieldInvalid;
  public fieldTypeError = fieldTypeError;
  public fieldValid = fieldValid;
  public fieldSummaryError = fieldSummaryError;
  public fieldRadioValid = fieldRadioValid;

  constructor(protected injector: Injector) {
    this.createServices(injector);
  }

  private createServices(injector: Injector) {
    this.fb = injector.get(FormBuilder);
    this.profileService = injector.get(ProfileService);
    this.staticDataService = injector.get(StaticDataService);
    this.countryService = injector.get(CountryService);
    this.productService = injector.get(ProductService);
  }

  public loadData(): void {
    this.productDetail = this.productService.getProductDetail(this.productType);
    this.getExistingTaxDetails();

    this.crsTaxForm
      .get('taxRegistered')
      .valueChanges.pipe(
        startWith(true),
        map((value) => {
          this.taxRegistered$.next(value === true);
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.crsTaxForm
      .get('noDataReason')
      .valueChanges.pipe(
        map((value) => {
          this.existingNoDataReason.noDataReason = value;
          this.noDataReason.emit(this.existingNoDataReason);
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.crsTaxForm
      .get('taxRegistered')
      .statusChanges.pipe(
        tap((value) => {
          this.taxRegisteredValid.next(value === 'VALID');
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.crsTaxForm
      .get('noDataReason')
      .statusChanges.pipe(
        tap((value) => {
          this.noDataReasonValid.next(value === 'VALID');
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.isSubmitted
      .pipe(
        filter((submitted) => submitted === true),
        map((submitted) => {
          this.thisIsSubmitted = submitted;
          this.crsTaxForm.get('taxRegistered').markAsTouched();
          this.crsTaxForm.get('noDataReason').markAsTouched();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getExistingTaxDetails() {
    const countries$ = this.staticDataService.getCountries().pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    const taxDataModel$ = this.staticDataService
      .getTaxIdentificationTypes()
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    const taxDetails$ = this.profileService.getTax().pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.existingNoDataReason = {
      taxResidenceId: 0,
      noDataReason: '',
      countryId: this.residenceCountryId
    } as NoDataResidence;

    forkJoin([countries$, taxDataModel$])
      .pipe(
        take(1),
        tap(([countries, taxDataModel]: [StaticCountryModel, TaxDataModel]) => {
          this.countries = countries;
          this.taxDataModel = taxDataModel;
        }),
        switchMap(() => {
          if (!this.userRegistered) {
            return of([]);
          }
          return taxDetails$.pipe(
            tap((taxDetails) => {
              this.populateExistingTax(taxDetails);
            }),
            untilDestroyed(this)
          );
        }),
        map((taxDetails) => {
          if (taxDetails.length > 0) {
            if (isDefined(taxDetails[0].noDataReason) && taxDetails[0].noDataReason !== '') {
              this.existingNoDataReason.taxResidenceId = taxDetails[0].taxResidenceId;
              this.existingNoDataReason.noDataReason = taxDetails[0].noDataReason;
            } else {
              if (this.productDetail.complianceCategory === ComplianceCategory.combined) {
                taxDetails = taxDetails.filter(
                  (t) =>
                    t.taxIdentificationTypeId !== TaxTypeIdEnum.ssn && t.taxIdentificationTypeId !== TaxTypeIdEnum.itin
                );
              }
              const residences = taxDetails.map((detail: TaxDetail) => {
                const taxResidence = this.mapToTaxResidence(detail);
                taxResidence.countryDescription = this.countries.countries.find(
                  (c) => c.countryId === detail.countryId
                )?.countryName;
                taxResidence.taxIdentificationTypeDescription = this.taxDataModel.taxIdentificationTypes.find(
                  (t) => t.taxIdentificationTypeId === detail.taxIdentificationTypeId
                ).description;
                return taxResidence;
              });

              return residences;
            }
          }
          return [];
        }),
        untilDestroyed(this)
      )
      .subscribe((residences) => {
        //Note to self: This is to get the initial value of the subject. More values will be added by form input.
        this.taxResidences$.next(residences);
        this.taxResidencesList = residences;

        this.setupDataSource(residences);
      });
  }

  private populateExistingTax(taxDetails: TaxDetail[]) {
    if (
      this.residenceCountryId === CountryIdEnum.southAfrica &&
      this.productDetail.complianceCategory !== ComplianceCategory.combined
    ) {
      this.populateTaxRegistered(false, '');
    } else if (taxDetails.length === 1 && isDefined(taxDetails[0].noDataReason) && taxDetails[0].noDataReason !== '') {
      this.populateTaxRegistered(false, taxDetails[0].noDataReason);
    } else {
      this.populateTaxRegistered(true, '');
    }
  }

  private populateTaxRegistered(taxRegistered, noDataReason) {
    this.crsTaxForm.patchValue({
      taxRegistered,
      noDataReason
    });
    this.crsTaxForm.get('taxRegistered').markAsTouched();
    if (this.thisIsSubmitted) {
      this.crsTaxForm.markAllAsTouched();
    }
  }

  protected addTaxRecord(taxResidence: TaxResidence) {
    if (taxResidence) {
      taxResidence.countryDescription = this.countries.countries.find(
        (c) => c.countryId === taxResidence.countryId
      )?.countryName;

      if (this.isReason(taxResidence.taxIdentificationTypeId)) {
        if (taxResidence.taxIdentificationTypeId === TaxTypeIdEnum.notIssued) {
          taxResidence.taxIdentificationTypeDescription = TaxTypeEnum.notIssued;
        } else if (taxResidence.taxIdentificationTypeId === TaxTypeIdEnum.notAvailable) {
          taxResidence.taxIdentificationTypeDescription = TaxTypeEnum.notAvailable;
        }
      } else {
        taxResidence.taxIdentificationTypeDescription = this.taxDataModel.taxIdentificationTypes.find(
          (t) => t.taxIdentificationTypeId === taxResidence.taxIdentificationTypeId
        ).description;
      }

      taxResidence.taxResidenceId = 0;
      this.taxResidencesList.push(taxResidence);
      this.taxResidences$.next(this.taxResidencesList);
      this.setupDataSource(this.taxResidencesList);
    }
  }

  private setupDataSource(residences: TaxResidence[]) {
    const taxData = residences?.map((t) => ({
      taxResidence: t.countryDescription,
      taxType: t.taxIdentificationTypeDescription,
      taxNumber: t.taxIdentificationNumber ? t.taxIdentificationNumber : t.taxNoNumberReason
    }));

    this.dataSource.data = taxData;
  }

  public updateFormField(fieldName: string, item) {
    this.crsTaxForm.get(fieldName).patchValue(item.value);
  }

  private isReason(taxIdentificationType) {
    if (isDefined(taxIdentificationType) && taxIdentificationType !== '') {
      if (taxIdentificationType === TaxTypeIdEnum.notIssued || taxIdentificationType === TaxTypeIdEnum.notAvailable) {
        return true;
      }
    }
    return false;
  }

  private mapToTaxResidence(taxDetail: TaxDetail): TaxResidence {
    return {
      taxResidenceId: taxDetail.taxResidenceId,
      taxIdentificationNumber: taxDetail.taxIdentificationNumber,
      taxIdentificationTypeId: taxDetail.taxIdentificationTypeId,
      taxNoNumberReason: '',
      countryId: taxDetail.countryId,
      countryDescription: '',
      taxIdentificationTypeDescription: ''
    };
  }
}
