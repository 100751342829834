import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ProductType } from '../../../registration/models/product.model';
import { RegisterStoreService } from '../../../shared/services/register-store.service';

@UntilDestroy()
@Component({
  selector: 'eid-minimal-header-mobile',
  templateUrl: './minimal-header.mobile.component.html',
  styleUrls: ['./minimal-header.mobile.component.scss']
})
export class MinimalHeaderMobileComponent {
  public productType: ProductType;

  constructor(private registerStoreService: RegisterStoreService) {
    this.productType = this.registerStoreService.getProduct();
  }

  public productLogo() {
    const url = window.location.href;
    //Satrix will always only have the product logo
    if (
      this.productType === ProductType.satrix ||
      url.indexOf('/compliance/') > -1 ||
      url.indexOf('/register/complete') > -1 ||
      url.indexOf('/minor/complete') > -1
    ) {
      return false;
    } else {
      return true;
    }
  }
}
