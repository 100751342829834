import { Inject, Injectable } from '@angular/core';
import { LOGGER, Logger } from '../../logging/logger.service';
import { CountryCategory, ProductDetailModel, ProductDisplayModel } from '../../registration/models/product.model';
import { CountryIdEnum } from '../constants/enums';
import { CurrentUser } from '../models/current-user.model';
import { pick } from '../utilities/utils';
import { RegistrationService } from './registration.service';
import { UtilService } from './util.service';

@Injectable({ providedIn: 'root' })
export class ProductService {
  private products: ProductDetailModel[];
  private productDisplay: ProductDisplayModel[];

  constructor(
    @Inject(LOGGER) private logger: Logger,
    private registrationService: RegistrationService,
    private utilService: UtilService
  ) {}

  public init(products: ProductDetailModel[], productDisplay: ProductDisplayModel[]) {
    this.products = products;
    this.productDisplay = productDisplay;
  }

  public getProductDetail(productName: string) {
    const product = this.products.find((p) => p.product === productName);
    return product;
  }

  public getProductDisplay() {
    return this.productDisplay;
  }

  public getProductDisplayByProduct(productName: string) {
    const productDisplay = this.productDisplay.find((p) => p.product === productName);
    return productDisplay;
  }

  public getQualifyingProductDisplay(currentUser: CurrentUser): ProductDisplayModel[] {
    var validProductList: ProductDisplayModel[];
    if (
      currentUser.countryOfCitizenshipId === CountryIdEnum.southAfrica &&
      currentUser.countryOfResidenceId === CountryIdEnum.southAfrica
    ) {
      validProductList = this.getSA(this.productDisplay);
    } else if (
      this.registrationService.isMonetaryArea(currentUser.countryOfCitizenshipId) &&
      this.registrationService.isMonetaryArea(currentUser.countryOfResidenceId)
    ) {
      validProductList = this.getCMA(this.productDisplay);
    } else {
      validProductList = this.getRules(this.productDisplay);
    }

    const age = this.utilService.getAge(currentUser.dob);
    var validList = validProductList.filter((p) => age >= p.minAge);

    if (validList.length < 4) {
      const model = pick(currentUser, 'username', 'dob', 'countryOfCitizenshipId', 'countryOfResidenceId');
      this.logger.debug('getQualifyingProductDisplay', model);
      this.logger.debug(
        'getQualifyingProductDisplay productDisplay ' + currentUser.username + ' ' + this.productDisplay.length
      );
      this.logger.debug('getQualifyingProductDisplay validList ' + currentUser.username + ' ' + validList.length);
    }

    return validList;
  }

  private getSA(productDisplayList: ProductDisplayModel[]): ProductDisplayModel[] {
    var newList = productDisplayList.filter(
      (p) =>
        this.getProductDetail(p.product).countries === CountryCategory.sa ||
        this.getProductDetail(p.product).countries === CountryCategory.cma ||
        this.getProductDetail(p.product).countries === CountryCategory.rules
    );
    return newList;
  }

  private getCMA(productDisplayList: ProductDisplayModel[]): ProductDisplayModel[] {
    var newList = productDisplayList.filter(
      (p) =>
        this.getProductDetail(p.product).countries === CountryCategory.cma ||
        this.getProductDetail(p.product).countries === CountryCategory.rules
    );
    return newList;
  }

  private getRules(productDisplayList: ProductDisplayModel[]): ProductDisplayModel[] {
    var newList = productDisplayList.filter(
      (p) => this.getProductDetail(p.product).countries === CountryCategory.rules
    );
    return newList;
  }
}
