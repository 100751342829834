import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { SetLayout } from '../../layout/layout.actions';
import { LayoutState, LayoutStateModel } from '../../layout/layout.state';
import { HeaderAction } from '../../layout/models/header-action.model';
import { EngagementService } from '../../shared/services/engagement.service';
import { MobileFundedAccountsComponent } from '../mobile-funded-accounts/mobile-funded-accounts.component';
import { FundedAccountsComponent } from './funded-accounts.component';
@UntilDestroy()
@Component({
  selector: 'eid-funded-accounts-mobile',
  templateUrl: './funded-accounts.mobile.component.html',
  styleUrls: ['./funded-accounts.mobile.component.scss']
})
export class FundedAccountsMobileComponent extends FundedAccountsComponent {
  private isDialog: boolean;
  private pageTitle: string;
  private actionLeft: HeaderAction;
  private actionRight: HeaderAction;

  constructor(protected engagementService: EngagementService, private dialog: MatDialog, private store: Store) {
    super(engagementService);
  }

  public fundedAccounts() {
    this.saveHeaderOriginalState();
    const dialog = this.dialog.open(MobileFundedAccountsComponent, {
      maxWidth: '100vw',
      panelClass: 'custom-selector-overlay',
      hasBackdrop: false
    });

    dialog
      .afterOpened()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogLayout(dialog, 'Funded Trusts and Accounts');
      });

    dialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetLayout();
      });
  }

  public dialogLayout(dialog, description) {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: true,
      pageTitle: description,
      actionLeft: new HeaderAction(undefined, 'chevron-left', null, () => {
        dialog.close();
      })
    };
    this.store.dispatch(new SetLayout(layoutState));
  }

  public resetLayout() {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: this.isDialog,
      pageTitle: this.pageTitle,
      actionLeft: this.actionLeft,
      actionRight: this.actionRight
    };
    this.store.dispatch(new SetLayout(layoutState));
  }

  private saveHeaderOriginalState(): void {
    this.isDialog = this.store.selectSnapshot(LayoutState.getIsDialog);
    this.pageTitle = this.store.selectSnapshot(LayoutState.getPageTitle);
    this.actionLeft = this.store.selectSnapshot(LayoutState.getLeftAction);
    this.actionRight = this.store.selectSnapshot(LayoutState.getRightAction);
  }
}
