import { Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

import { SetLayout } from '../../../layout/layout.actions';
import { LayoutStateModel } from '../../../layout/layout.state';
import { HeaderAction } from '../../../layout/models/header-action.model';
import { TaxValidator } from '../../validators/tax.validator';
import { AddCrsTaxComponent } from '../add-crs-tax/add-crs-tax.component';
import { CrsTaxComponent } from './crs-tax.component';

@UntilDestroy()
@Component({
  selector: 'eid-crs-tax-mobile',
  templateUrl: './crs-tax.mobile.component.html',
  styleUrls: ['./crs-tax.mobile.component.scss']
})
export class CrsTaxMobileComponent extends CrsTaxComponent implements OnInit {
  public loading = false;

  constructor(protected injector: Injector, private dialog: MatDialog, private store: Store) {
    super(injector);
  }

  ngOnInit(): void {
    this.createForm();
    this.loadData();
  }

  private createForm(): void {
    this.crsTaxForm = this.fb.group(
      {
        taxRegistered: ['', Validators.required],
        noDataReason: ['']
      },
      {
        validators: [TaxValidator.NoDataComplianceValidate(this.residenceCountryId)]
      }
    );
  }

  public showTaxForm() {
    this.loading = true;
    const dialog = this.dialog.open(AddCrsTaxComponent, {
      maxWidth: '100vw',
      panelClass: 'selector-overlay',
      hasBackdrop: false,
      data: {
        residenceCountryId: this.residenceCountryId,
        productType: this.productType,
        userRegistered: this.userRegistered,
        countrySelectList: this.countrySelectList
      }
    });

    dialog
      .afterOpened()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogLayout(dialog);
      });

    dialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((taxAddModel) => {
        this.addTaxRecord(taxAddModel);

        this.resetLayout();
        this.loading = false;
      });
  }

  public dialogLayout(dialog) {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: true,
      pageTitle: 'Add Tax Residence',
      actionLeft: new HeaderAction(undefined, 'chevron-left', null, () => {
        dialog.close();
      }),
      actionRight: null
    };
    this.store.dispatch(new SetLayout(layoutState));
  }

  public resetLayout() {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: false,
      pageTitle: null,
      actionLeft: null,
      actionRight: null
    };
    this.store.dispatch(new SetLayout(layoutState));
  }
}
