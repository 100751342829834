<div eidLoadingSpinner [loading]="busySharing" class="tw-flex tw-flex-col tw-w-full tw-h-full">
  <div class="share-row">
    <div class="share-button">
      <mat-icon [svgIcon]="'megaphone'"></mat-icon>
    </div>
  </div>

  <div class="share-row">
    <p class="share-text">Share Your Stat-tastic Selfie Moment!</p>
  </div>

  <eid-blur-mobile [blurred]="blurred" [isModal]="true" (blur)="toggleBlur($event)"></eid-blur-mobile>

  <div class="share-row">
    <div class="share-col">
      <eid-share-button
        [buttonType]="'facebook'"
        [svgIcon]="'facebook-sharing'"
        platform="mobile"
        [blurred]="blurred"
        (busy)="busy($event)"
      ></eid-share-button>
      <p>Facebook</p>
    </div>

    <div class="share-col">
      <eid-share-button
        [buttonType]="'twitter'"
        [svgIcon]="'twitter-sharing'"
        platform="mobile"
        [blurred]="blurred"
        (busy)="busy($event)"
      ></eid-share-button>
      <p>Twitter</p>
    </div>

    <div class="share-col">
      <eid-share-button
        [buttonType]="'whatsapp'"
        [svgIcon]="'whatsapp-sharing'"
        platform="mobile"
        [blurred]="blurred"
        (busy)="busy($event)"
      ></eid-share-button>
      <p>WhatsApp</p>
    </div>
  </div>

  <div class="share-row">
    <div class="share-col">
      <eid-share-button
        [buttonType]="'linkedin'"
        [svgIcon]="'linkedin-sharing'"
        platform="mobile"
        [blurred]="blurred"
        (busy)="busy($event)"
      ></eid-share-button>
      <p>LinkedIn</p>
    </div>

    <div class="share-col">
      <eid-share-button
        [buttonType]="'copy'"
        [svgIcon]="'copy-sharing'"
        platform="mobile"
        [blurred]="blurred"
        (busy)="busy($event)"
      ></eid-share-button>
      <p>Copy Link</p>
    </div>

    <div class="share-col">
      <eid-share-button
        [buttonType]="'email'"
        [svgIcon]="'email-sharing'"
        platform="mobile"
        [blurred]="blurred"
        (busy)="busy($event)"
      ></eid-share-button>
      <p>Email</p>
    </div>
  </div>
</div>
