<div class="thrive-card">
  <div class="root">
    <div class="header">
      <mat-icon class="icon" svgIcon="thrive"></mat-icon>
      <h2 class="title">{{ date | date: 'LLL' }} '{{ date | date: 'yy' }} on Thrive</h2>
    </div>

    <div class="thrive-container">
      <div>
        <h3>THRIVE LEVEL</h3>

        <h2>
          {{ (engagement$ | async)?.thriveLevel }}
        </h2>
      </div>

      <div>
        <h3>BROKERAGE SAVED</h3>

        <h2>R {{ (engagement$ | async)?.brokerageSavedByThrive }}</h2>
      </div>
    </div>

    <div class="footer">
      <mat-progress-bar class="thrive-gauge" role="progressbar" value="{{ (engagement$ | async)?.thriveLevel }}">
      </mat-progress-bar>
    </div>
  </div>
</div>
