<div class="user-details" *ngIf="user">
  <eid-avatar [first]="user.firstName" [second]="user?.lastName"></eid-avatar>
  <div class="user-details__name">{{ user?.firstName }} {{ user?.lastName }}</div>
  <div class="user-details__details">
    <div class="detail detail--username">
      <div class="label">Username</div>
      <div class="value">{{ user?.username }}</div>
    </div>
    <div class="detail">
      <div class="label">EasyID</div>
      <div class="value">{{ user?.userId }}</div>
    </div>
  </div>
</div>
