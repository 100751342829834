import { AbstractControl } from '@angular/forms';

import { TenantIdEnum } from '../../constants/enums';
import { ValidatorPattern } from '../../constants/validator-patterns';
import { AddressUtilsService } from '../../services/address.utils';

export class AddressValidators {
  static streetAddress(tenantId: string, addressUtilsService: AddressUtilsService) {
    return (control: AbstractControl) => {
      if (control.value) {
        const addressParts = addressUtilsService.getAddressParts(control.value.toString());
        const isMinimalValidation = tenantId === TenantIdEnum.gcash;

        if (isMinimalValidation) {
          if (addressParts.addressNumber.toString().length > 5) {
            return { minimallength: true };
          }

          if (addressParts.addressName.toString().trim().length < 1) {
            return { required: true };
          }

          if (addressParts.addressName.toString().length > 256) {
            return { minimallength: true };
          }

          return null;
        }
        // EasyID validation
        // EasyEquities validation
        if (/\d{1}/.test(addressParts.addressNumber)) {
          if (
            !ValidatorPattern.addressNumber.test(addressParts.addressNumber) ||
            ValidatorPattern.blockedUnicode.test(addressParts.addressNumber)
          ) {
            return { numberPattern: true };
          }
        }

        if (ValidatorPattern.blockedUnicode.test(addressParts.addressName)) {
          return { blockedUnicode: true };
        }

        if (addressParts.addressNumber.toString().length > 5) {
          return { length: true };
        }

        // EasyID validation
        if (!/[A-Za-z]{1}/.test(addressParts.addressName)) {
          return { required: true };
        }

        if (addressParts.addressName.indexOf('"') > 0) {
          return { namePattern: true };
        }

        // EasyEquities validation
        if (addressParts.addressName.toString().length > 50) {
          return { length: true };
        }
      }

      return null;
    };
  }

  static streetAddress2(tenantId: string, addressUtilsService: AddressUtilsService) {
    return (control: AbstractControl) => {
      if (control.value) {
        const addressParts = addressUtilsService.getAddressParts(control.value.toString());
        const isMinimalValidation = tenantId === TenantIdEnum.gcash;

        if (isMinimalValidation) {
          //If number exists, name is required
          if (addressParts.addressNumber.toString().trim().length > 0) {
            if (addressParts.addressNumber.toString().length > 5) {
              return { length: true };
            }

            if (addressParts.addressName.toString().length < 1) {
              return { required: true };
            }

            if (addressParts.addressName.toString().length > 25) {
              return { length: true };
            }
          }

          //If name exists, number is required
          if (addressParts.addressName.toString().trim().length > 0) {
            if (addressParts.addressNumber.toString().length > 5) {
              return { length: true };
            }

            if (addressParts.addressNumber.toString().length < 1) {
              return { required: true };
            }

            if (addressParts.addressName.toString().length > 25) {
              return { length: true };
            }
          }

          return null;
        }

        // EasyID validation
        if (!/\d{1}/.test(addressParts.addressNumber)) {
          return { numberPattern: true };
        }

        // EasyEquities validation
        if (!ValidatorPattern.unitNumber.test(addressParts.addressNumber)) {
          return { numberPattern: true };
        }

        if (addressParts.addressNumber.toString().length > 5) {
          return { length: true };
        }

        // EasyID validation
        if (!/[A-Za-z]{1}/.test(addressParts.addressName)) {
          return { namePattern: true };
        }

        // EasyEquities validation
        if (!ValidatorPattern.complexName.test(addressParts.addressName)) {
          return { namePattern: true };
        }

        if (addressParts.addressName.toString().length > 25) {
          return { length: true };
        }
      }

      return null;
    };
  }

  static suburb(tenantId: string) {
    return (control: AbstractControl) => {
      if (control.value) {
        const suburb = control.value.toString()?.trim();
        const isMinimalValidation = tenantId === TenantIdEnum.gcash;

        if (isMinimalValidation) {
          if (suburb === '') {
            return { required: true };
          }

          if (suburb.toString().length > 256) {
            return { minimallength: true };
          }

          return null;
        }

        if (suburb === '') {
          return { required: true };
        }

        // EasyEquities validation
        if (!ValidatorPattern.suburb.test(suburb)) {
          return { pattern: true };
        }

        if (suburb.toString().length > 50) {
          return { length: true };
        }
      }

      return null;
    };
  }

  static city(tenantId: string) {
    return (control: AbstractControl) => {
      if (control.value) {
        const city = control.value.toString()?.trim();
        const isMinimalValidation = tenantId === TenantIdEnum.gcash;

        if (isMinimalValidation) {
          if (city === '') {
            return { required: true };
          }

          if (city.toString().length > 256) {
            return { minimallength: true };
          }

          return null;
        }

        if (city === '') {
          return { required: true };
        }

        // EasyEquities validation
        if (!ValidatorPattern.city.test(city)) {
          return { pattern: true };
        }

        if (city.toString().length > 50) {
          return { length: true };
        }
      }

      return null;
    };
  }

  static code(tenantId: string) {
    return (control: AbstractControl) => {
      if (control.value) {
        const code = control.value.toString()?.trim();
        const isMinimalValidation = tenantId === TenantIdEnum.gcash;

        if (isMinimalValidation) {
          if (code === '') {
            return { required: true };
          }

          if (!ValidatorPattern.minimalcode.test(code)) {
            return { minimalpattern: true };
          }

          if (code.toString().length > 11) {
            return { minimallength: true };
          }

          return null;
        }

        // EasyEquities validation
        if (code === '') {
          return { required: true };
        }

        if (!ValidatorPattern.code.test(code)) {
          return { pattern: true };
        }

        if (code.toString().length > 10) {
          return { length: true };
        }
      }

      return null;
    };
  }
}
