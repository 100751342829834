import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ThriveComponent } from './thrive.component';
@UntilDestroy()
@Component({
  selector: 'eid-thrive-desktop',
  templateUrl: './thrive.desktop.component.html',
  styleUrls: ['./thrive.desktop.component.scss']
})
export class ThriveDesktopComponent extends ThriveComponent {}
