<div class="box">
  <div class="root">
    <div class="left">
      <div class="left-header">
        <mat-icon svgIcon="dividends-sm" class="header-icon-sm"></mat-icon>
        <h2 class="header-title">Monthly Dividends</h2>
      </div>
      <div class="left-body-sm">
        <div class="left-body-sm-container">
          <div class="left-body-sm-title">
            <h3>Your Monthly Dividends</h3>
          </div>
          <div class="left-body-sm-values">
            <mat-icon *ngIf="dividentsUp" svgIcon="increase-circle" class="increase-icon"></mat-icon>
            <mat-icon *ngIf="!dividentsUp" svgIcon="decrease-circle" class="decrease-icon"></mat-icon>
            <h3 class="rand-value">R {{ userMonthlyDividendValue }}</h3>
            <h3 class="dividend-value">{{ userMonthlyDividendCount }} Dividends</h3>
          </div>
        </div>

        <div class="community-avg">
          <h3>Community Avg</h3>
          <h3 class="align-right">{{ communityDividendAverage }} Dividends</h3>
        </div>
      </div>
    </div>
  </div>
</div>
