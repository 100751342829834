import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EngagementService } from '../../shared/services/engagement.service';
import { EngagementModel } from '../models/engagement.model';

@Component({
  template: `
    <eid-activity-stats-desktop *eidBreakpoint="'desktop'"></eid-activity-stats-desktop>
    <eid-activity-stats-mobile *eidBreakpoint="'mobile'"></eid-activity-stats-mobile>
  `
})
export class ActivityStatsContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class ActivityStatsComponent {
  public engagement$: Observable<EngagementModel>;

  public activeDaysUp: boolean;
  public loginsUp: boolean;

  constructor(private engagementService: EngagementService) {
    this.engagement$ = this.engagementService.getEngagementData();

    this.engagement$
      .pipe(
        map((data) => {
          this.activeDaysUp = data?.userActiveDaysCount >= data?.userPreviousMonthActiveDaysCount;
          this.loginsUp = data?.userLoginCount >= data?.userPreviousMonthLoginCount;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
