import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { distinctUntilChanged } from 'rxjs/operators';

import { CloseSidenav, SetLayout } from '../layout/layout.actions';
import { LayoutStateModel } from '../layout/layout.state';

export enum BreakpointType {
  mobile = 'mobile',
  desktop = 'desktop'
}

@UntilDestroy()
@Directive({
  selector: '[eidBreakpoint]'
})
export class BreakPointDirective implements OnInit {
  @Input() set eidBreakpoint(val: string) {
    if (val !== BreakpointType.mobile && val !== BreakpointType.desktop) {
      throw new Error(`The value supplied for 'eidBreakpoint' must be mobile or desktop.`);
    }
    this.easyBreakpoint = val;
  }

  public easyBreakpoint: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private store: Store
  ) {}

  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((device) => {
        if (device.matches) {
          this.updateView(BreakpointType.mobile);
        } else {
          this.dialog.closeAll();
          this.resetLayout();
          this.updateView(BreakpointType.desktop);
        }
      });
  }

  private updateView(device: BreakpointType) {
    this.viewContainer.clear();
    this.viewContainer.remove();

    if (this.easyBreakpoint !== device) {
      return;
    }

    if (this.viewContainer.length > 0) {
      return;
    }
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  public resetLayout() {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: false,
      pageTitle: '',
      actionLeft: null,
      actionRight: null
    };
    this.store.dispatch(new SetLayout(layoutState));
    this.store.dispatch(new CloseSidenav());
  }
}
