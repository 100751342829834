import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { AddCurrentUser, UpdateCurrentUser } from '../../store/user.actions';
import { UserState } from '../../store/user.state';
import { CurrentUser } from '../models/current-user.model';

@Injectable()
export class UserStoreService {
  constructor(private store: Store) {}

  public clearUser() {
    this.store.dispatch(new AddCurrentUser());
  }

  public updateUser(user) {
    this.store.dispatch(new AddCurrentUser());
    this.store.dispatch(new UpdateCurrentUser(user));
  }

  public isProfileComplete() {
    const user = this.store.selectSnapshot(UserState);
    if (user.sub !== '' && user.userId !== '') {
      return true;
    }
    return false;
  }

  public getUser(): CurrentUser {
    return this.store.selectSnapshot(UserState);
  }

  public getProductType() {
    return this.store.selectSnapshot(UserState.productType);
  }

  public updateProductType(productType) {
    const userState = this.getUser();
    const newUserState = Object.assign({}, userState);
    newUserState.productType = productType;

    return this.updateUser(newUserState);
  }
}
