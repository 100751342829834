import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ProductType } from '../../../registration/models/product.model';
import { UserState } from '../../../store/user.state';
import { CurrentUser } from '../../models/current-user.model';
import { RegisterStoreService } from '../../services/register-store.service';
import { UrlProviderService } from '../../services/url.provider';
import { UtilService } from '../../services/util.service';

@UntilDestroy()
@Component({
  selector: 'eid-current-user-panel',
  templateUrl: './current-user-panel.component.html',
  styleUrls: ['./current-user-panel.component.scss']
})
export class CurrentUserPanelComponent {
  @Select(UserState.currentUser) public currentUser$: Observable<CurrentUser>;

  public productTypes = ProductType;
  public minorEnabled = environment.minorEnabled;
  public minorDescription: string;

  constructor(
    public dialogRef: MatDialogRef<CurrentUserPanelComponent>,
    private utilService: UtilService,
    private registerStoreService: RegisterStoreService,
    private urlProviderService: UrlProviderService,
    private router: Router
  ) {
    this.minorDescription = this.registerStoreService.getProduct() === ProductType.satrix ? 'Child' : 'Kid';
  }

  public isMinor(dob) {
    return this.utilService.isMinor(dob);
  }

  public logout() {
    this.dialogRef.close(true);
    this.router.navigate(['/logout']);
  }

  public back() {
    this.dialogRef.close(true);
    this.urlProviderService.urlBundle$.pipe(untilDestroyed(this)).subscribe((urlBundle) => {
      window.location.href = urlBundle.login;
    });
  }

  public minor() {
    this.dialogRef.close(true);
    if (this.registerStoreService.getProduct() !== ProductType.satrix) {
      this.registerStoreService.dispathProduct(ProductType.none);
    }
    this.router.navigate(['/minor/product']);
  }
}
