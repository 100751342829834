import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import {
  Compliance,
  UpdateOutstandingCompliance,
  UpdateRedirectUrl,
  UpdateSuccessRedirectUrl,
  UpdateUserId
} from '../../store/compliance.actions';
import { ComplianceState } from '../../store/compliance.state';
import { ComplianceStateModel } from '../models/compliance-state.model';
import { OutstandingCompliance } from '../models/compliance.model';

@Injectable({ providedIn: 'root' })
export class ComplianceStoreService {
  constructor(private store: Store) {}

  public clearCompliance() {
    this.store.dispatch(new Compliance());
  }

  public clearOutstandingDeclarations() {
    this.store.dispatch(new UpdateUserId(''));
    return this.store.dispatch(new UpdateOutstandingCompliance(null));
  }

  public updateRedirectUrls(redirectUrl: string, successRedirectUrl: string) {
    this.store.dispatch(new Compliance());
    this.store.dispatch(new UpdateRedirectUrl(redirectUrl));
    return this.store.dispatch(new UpdateSuccessRedirectUrl(successRedirectUrl));
  }

  public getRedirectUrl() {
    return this.store.selectSnapshot(ComplianceState.redirectUrl);
  }

  public getSuccessRedirectUrl() {
    return this.store.selectSnapshot(ComplianceState.successRedirectUrl);
  }

  public updateUserId(userId: string) {
    return this.store.dispatch(new UpdateUserId(userId));
  }

  public getUserId() {
    return this.store.selectSnapshot(ComplianceState.userId);
  }

  public updateOutstandingCompliance(outstandingCompliance: OutstandingCompliance) {
    return this.store.dispatch(new UpdateOutstandingCompliance(outstandingCompliance));
  }

  public getOutstandingCompliance(): OutstandingCompliance {
    return this.store.selectSnapshot(ComplianceState.outstandingCompliance);
  }

  public getCompliance(): ComplianceStateModel {
    return this.store.selectSnapshot(ComplianceStateModel);
  }
}
