import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('/api/') > -1) {
      const baseUrl = environment.apiUrl;
      const apiReq = req.clone({ url: `${baseUrl}${req.url}` });
      return next.handle(apiReq);
    }
    return next.handle(req);
  }
}
