import { Component } from '@angular/core';

import { ProductComponent } from './product.component';

@Component({
  selector: 'eid-product-mobile',
  templateUrl: './product.mobile.component.html',
  styleUrls: ['./product.mobile.component.scss']
})
export class ProductMobileComponent extends ProductComponent {}
