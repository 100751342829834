import { Component, HostBinding, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SafeUrl } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ProductDisplayModel, ProductType } from '../../../registration/models/product.model';
import { UrlBundle } from '../../../shared/models/urlBundle';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { ProductService } from '../../../shared/services/product.service';
import { RegisterStoreService } from '../../../shared/services/register-store.service';
import { UrlProviderService } from '../../../shared/services/url.provider';
import { UserStoreService } from '../../../shared/services/user-store.service';
import { UserState } from '../../../store/user.state';

export interface MainMenuItem {
  id: string;
  label: string;
  routerLink?: string[];
  url?: SafeUrl;
  active: boolean;
}

@UntilDestroy()
@Component({
  selector: 'eid-profile-header-desktop',
  templateUrl: './profile-header.desktop.component.html',
  styleUrls: ['./profile-header.desktop.component.scss']
})
export class ProfileHeaderDesktopComponent implements OnInit {
  @HostBinding('attr.class') private layourCls = 'eid-private-header-desktop';
  @Select(UserState.productType) public productType$: Observable<ProductType>;

  public menus$: Observable<MainMenuItem[]>;
  public urlBundle$: Observable<UrlBundle>;
  public productDisplayList: ProductDisplayModel[];
  public productTypes = ProductType;

  public isProfile: boolean;
  public isDashboard: boolean;
  public isActivity: boolean;

  constructor(
    protected authenticationService: AuthenticationService,
    protected registerStoreService: RegisterStoreService,
    private urlProviderService: UrlProviderService,
    private productService: ProductService,
    private userStoreService: UserStoreService
  ) {}

  public ngOnInit(): void {
    const url = window.location.href;
    this.isProfile = url.indexOf('/profile/') > -1;
    this.isDashboard = url.indexOf('/dashboard') > -1;
    this.isActivity = url.indexOf('/engagement/activity') > -1;
    this.loadMenu();
  }

  private loadMenu(): void {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
    var currentUser = this.userStoreService.getUser();
    this.productDisplayList = this.productService.getQualifyingProductDisplay(currentUser);

    this.menus$ = this.urlBundle$.pipe(
      map((urlBundle) => {
        return this.getProfileMenu(urlBundle);
      })
    );
  }

  public getProfileMenu(urlBundle: UrlBundle): MainMenuItem[] {
    return [
      {
        id: 'home',
        label: 'Home',
        routerLink: ['/dashboard'],
        active: this.isDashboard
      },
      {
        id: 'profile',
        label: 'My Profile',
        routerLink: ['/profile', 'personal'],
        active: this.isProfile
      },
      {
        id: 'activity',
        label: 'Activity',
        routerLink: ['/engagement', 'activity'],
        active: this.isActivity
      },
      {
        id: 'apps',
        label: 'Partner Apps',
        url: urlBundle.apps,
        active: false
      },
      {
        id: 'security',
        label: 'Security',
        url: urlBundle.security,
        active: false
      }
    ];
  }
}
