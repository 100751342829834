import { Component } from '@angular/core';

import { EditAddressComponent } from './edit-address.component';

@Component({
  selector: 'eid-edit-address-mobile',
  templateUrl: './edit-address.mobile.component.html',
  styleUrls: ['./edit-address.mobile.component.scss']
})
export class EditAddressMobileComponent extends EditAddressComponent {
  public updateFormField(fieldName: string, item) {
    if (!item.value) {
      this.addressForm.get(fieldName).patchValue(null);
      return;
    }
    this.addressForm.get(fieldName).patchValue(item.value);
  }

  public scrollIntoView(event: MouseEvent): void {
    const scrollable: HTMLElement = document.querySelector('.root-page');
    scrollable.scrollTop = 150;
  }
}
