<mat-drawer-container [hasBackdrop]="false" CloseOnDocumentClick="true">
  <mat-drawer #drawer mode="over" [opened]="sidenavState$ | async" (closed)="sidenavClosed($event)">
    <eid-side-navigation [sidenavState$]="sidenavState$"></eid-side-navigation>
  </mat-drawer>
  <mat-drawer-content>
    <eid-header-container class="layoutHeader"></eid-header-container>
    <div class="root-page layoutContent" #rootPage cdkScrollable>
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
    <eid-footer-container class="layoutFooter"></eid-footer-container>
  </mat-drawer-content>
</mat-drawer-container>
