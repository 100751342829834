import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, zip } from 'rxjs';
import { debounceTime, filter, map, withLatestFrom } from 'rxjs/operators';
import { IntroComponent } from './intro.component';

@UntilDestroy()
@Component({
  selector: 'eid-intro-mobile',
  templateUrl: './intro.mobile.component.html',
  styleUrls: ['./intro.mobile.component.scss']
})
export class IntroMobileComponent extends IntroComponent implements AfterViewInit {
  @ViewChild('introView', { static: true }) viewport: ElementRef;

  ngAfterViewInit(): void {
    zip(
      fromEvent<TouchEvent>(this.viewport.nativeElement, 'touchstart'),
      fromEvent<TouchEvent>(this.viewport.nativeElement, 'touchend').pipe(
        withLatestFrom(fromEvent<TouchEvent>(document, 'touchmove'))
      )
    )
      .pipe(
        debounceTime(100),
        filter(([touchstart, [touchend, touchmove]]) => {
          return touchstart.timeStamp <= touchmove.timeStamp;
        }),
        map(([touchstart, [touchend, touchmove]]) => {
          return touchstart.touches[0].clientX - touchmove.touches[0].clientX;
        }),
        filter((xDiff) => {
          return Math.abs(xDiff) > 0.3 * this.viewport.nativeElement.clientWidth;
        }),
        map((xDiff) => {
          return xDiff <= 0 ? 'left' : 'right';
        }),
        untilDestroyed(this)
      )
      .subscribe((direction) => {
        const newStep = direction === 'left' ? Math.max(this.activeStep - 1, 1) : Math.min(this.activeStep + 1, 6);
        if (newStep < 6) {
          this.activeStep = newStep;
        } else {
          this.close();
        }
      });
  }
}
