<div class="intro-box">
  <div class="column column--image"></div>
  <div class="column column--content">
    <div class="intro-text">
      <h2>Be the financial rockstar you were born to be!</h2>
      <p>
        EasyEquities empowers everyone to invest in local and global shares with ease and confidence, regardless of
        experience or financial status.
      </p>
    </div>

    <div class="intro-step">
      <eid-intro-stepper [active]="1" (stepSelected)="stepSelected($event)"></eid-intro-stepper>
      <div class="close-text">
        <div (mousedown)="close()" class="skip-btn">
          <span class="text">Skip Intro</span>
        </div>
      </div>
    </div>
  </div>
</div>
