import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { BankDataModel } from '../../profile/models/bank.model';
import { StaticCountryModel } from '../../registration/models/country.model';
import { PersonalDataModel } from '../../registration/models/personal.model';
import { ProvinceModel } from '../../registration/models/province.model';
import { TaxDataModel, TaxIdentificationType } from '../../shared/models/tax.model';
import { Country2Enum, TaxTypeEnum } from '../constants/enums';
import {
  FinancialDataModel,
  IncomeBand,
  PEP,
  ProminentPersonModel,
  ProminentPersonRoleDescription
} from '../models/financial.model';

@Injectable({ providedIn: 'root' })
export class StaticDataService {
  public prominentPerson$: Observable<ProminentPersonModel>;

  constructor(private httpClient: HttpClient) {}

  public getCountries(): Observable<StaticCountryModel> {
    return this.httpClient.get<StaticCountryModel>('/api/staticdata/countries');
  }

  public getPersonalData(): Observable<PersonalDataModel> {
    return this.httpClient.get<PersonalDataModel>('/api/staticdata/personaldata').pipe(
      map((personalData) => {
        // Limit genders to Male and Female
        personalData.genders = personalData.genders.filter((genders) => {
          return genders.genderId === 0 || genders.genderId === 1;
        });
        return personalData;
      })
    );
  }

  public getProvinces(): Observable<ProvinceModel> {
    return this.httpClient.get<ProvinceModel>('/api/staticdata/provinces').pipe(
      map((model) => {
        model.provinces = model.provinces.filter((p) => p.provinceName !== 'Non Resident');
        return model;
      })
    );
  }

  public getFinancialData(): Observable<FinancialDataModel> {
    return this.httpClient.get<FinancialDataModel>('/api/staticdata/financialdata');
  }

  public getTaxIdentificationTypes(): Observable<TaxDataModel> {
    return this.httpClient.get<TaxDataModel>('/api/staticdata/taxdata');
  }

  public getTaxIdTypesByCountry(taxIdTypes: TaxIdentificationType[], countryCode: string): TaxIdentificationType[] {
    //To filter we need to convert certain countries.
    var taxCountryCode = countryCode;
    if (countryCode === Country2Enum.malaysia || countryCode === Country2Enum.philippines) {
      taxCountryCode = TaxTypeEnum.philippines;
    } else if (countryCode === Country2Enum.southAfrica) {
      taxCountryCode = TaxTypeEnum.southAfrica;
    } else if (countryCode === Country2Enum.australia) {
      taxCountryCode = TaxTypeEnum.australia;
    }
    //Filter according to residence
    const complianceIdTypes = taxIdTypes.filter(
      (id) =>
        id.description.toLowerCase().startsWith(taxCountryCode?.toLowerCase()) ||
        id.description.toLowerCase().startsWith(TaxTypeEnum.other.toLowerCase())
    );

    return complianceIdTypes;
  }

  public getBankData(): Observable<BankDataModel> {
    return this.httpClient.get<BankDataModel>('/api/staticdata/bankData');
  }

  public getMarketingChannelReferral(productType: string): Observable<string[]> {
    return this.httpClient.get<string[]>('/api/staticdata/marketingchannels?productType=' + productType);
  }

  public getInternationalIncomeBands(): IncomeBand[] {
    return [
      {
        incomeBandId: 1,
        name: '0 – 49 999',
        minimum: 0,
        maximum: 49999
      },
      {
        incomeBandId: 2,
        name: '50 000 - 99 999',
        minimum: 50000,
        maximum: 99999
      },
      {
        incomeBandId: 3,
        name: '100 000 - 149 999',
        minimum: 100000,
        maximum: 149999
      },
      {
        incomeBandId: 4,
        name: '150 000 and above',
        minimum: 150000,
        maximum: -1
      }
    ];
  }

  public getPEP(): Observable<PEP[]> {
    return this.prominentPersonRequest().pipe(
      map((prominentPersonModel: ProminentPersonModel) => {
        return prominentPersonModel.pepStatuses;
      })
    );
  }

  public getProminentPersonRole(identifier: string): Observable<ProminentPersonRoleDescription[]> {
    return this.prominentPersonRequest().pipe(
      map((prominentPersonModel: ProminentPersonModel) => {
        const roles = prominentPersonModel.prominentPersonRoles.filter((p) => p.identifier === identifier)[0];
        return roles.descriptions;
      })
    );
  }

  public prominentPersonRequest(): Observable<ProminentPersonModel> {
    if (this.prominentPerson$) {
      return this.prominentPerson$;
    }

    this.prominentPerson$ = this.httpClient
      .get<ProminentPersonModel>('/api/staticdata/prominentperson')
      .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    return this.prominentPerson$;
  }
}
