export interface RedirectData {
  timestamp: string;
  scanReference: string;
  redirectUrl: string;
}

export interface JumioDetail {
  idType: string;
  country: string;
  progress: string;
  registrationId: string;
  userReference: string;
  timestamp: string;
  redirectUrl: string;
  scanReference: string;
  transactionStatus: string;
  errorCode: string;
}

export interface JumioStatus {
  timestamp: string;
  scanReference: string;
  status: RequestStatus;
}

export enum RequestStatus {
  pending = 'PENDING',
  done = 'DONE',
  failed = 'FAILED'
}

export enum Progress {
  none = 'none',
  loaded = 'loaded',
  success = 'success',
  error = 'error'
}
