<!-- Burger menu -->
<div class="action action--left">
  <button (click)="callAction()">
    <mat-icon [svgIcon]="'menu'" [style.color]="'var(--eid-col-gray)'"></mat-icon>
  </button>
</div>

<div class="eid-header-mobile__content">
  <!-- Logo -->
  <div class="app-logo">
    <div class="main"></div>
  </div>

  <!-- Create your EasyID -->
  <div *ngIf="(productType$ | async) !== productTypes.satrix" class="create-profile-container">
    <a [routerLink]="['/profile-type']" [routerLinkActive]="'active'" class="create-link" (click)="closeNavigation()">
      Create your EasyID
    </a>
  </div>
</div>
