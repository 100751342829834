import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil, omit } from 'lodash';
import { AnimationOptions } from 'ngx-lottie';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '../../environments/environment';
import { LOGGER, Logger } from '../logging/logger.service';
import { PartnerCategory, ProductDetailModel } from '../registration/models/product.model';
import { Theme } from '../registration/models/theme.model';
import { ValidatorPattern } from '../shared/constants/validator-patterns';
import { AuthenticationService } from '../shared/services/authentication.service';
import { GameStoreService } from '../shared/services/game-store.service';
import { ProductService } from '../shared/services/product.service';
import { RegisterStoreService } from '../shared/services/register-store.service';
import { RegistrationService } from '../shared/services/registration.service';
import { UserStoreService } from '../shared/services/user-store.service';

@UntilDestroy()
@Component({
  selector: 'eid-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss']
})
export class AuthorizeComponent implements OnInit {
  public themes = Theme;
  public productDetail: ProductDetailModel;

  public showAccountMessage$ = new BehaviorSubject<boolean>(true);
  public identifier;

  gcashAnimationOptions: AnimationOptions = {
    // tslint:disable-next-line: max-line-length
    path: '../../assets/animations/rocket_gcash.json' // download the JSON version of animation in your project directory and add the path to it like ./assets/animations/example.json
  };

  constructor(
    @Inject(LOGGER) private logger: Logger,
    private authenticationService: AuthenticationService,
    private registrationService: RegistrationService,
    private registerStoreService: RegisterStoreService,
    private userStoreService: UserStoreService,
    private gameStoreService: GameStoreService,
    private productService: ProductService,
    private router: Router
  ) {
    const productType = this.registerStoreService.getProduct();
    this.productDetail = this.productService.getProductDetail(productType);

    this.identifier = uuidv4();
    var sessionUrl = this.authenticationService.getUrl();
    if (sessionUrl?.toLowerCase().indexOf('/compliance') > -1 || sessionUrl?.toLowerCase().indexOf('/game') > -1) {
      this.showAccountMessage$.next(false);
    }
  }

  ngOnInit(): void {
    this.handleCodeFlow();
  }

  private handleCodeFlow(): void {
    const registrationRequestid = this.getRegistrationRequestId();

    const sessionUrl = this.authenticationService.getUrl();
    this.logger.debug(
      this.identifier +
        ' AuthorizeComponent registrationRequestid ' +
        registrationRequestid +
        ' sessionUrl ' +
        sessionUrl
    );

    if ((isNil(sessionUrl) || sessionUrl === '') && isNil(registrationRequestid)) {
      this.logTokenModelDebug(this.identifier + ' Authorize service authenticationService.getUrl empty');
      this.router.navigate(['/home']);
      return;
    }
    const originalUrl = new URL(sessionUrl);
    const pathName = originalUrl.pathname;

    const url = window.location.href;
    this.logger.debug(this.identifier + ' AuthorizeComponent pathName ' + pathName + ' href ' + url);
    const searchParams = this.getQueryParameters(url);
    const code = searchParams.get('code');
    const error = searchParams.get('error');

    this.authenticationService.updateIsInitialized(true);

    //Login was checked and the user is not logged in.
    if (error === 'login_required') {
      this.authenticationService.updateIsLoggedIn(false);
      if (pathName.endsWith('/')) {
        this.logger.debug(this.identifier + ' AuthorizeComponent login_required navigate home');
        this.router.navigate(['/home']);
        return;
      }

      this.logger.debug(this.identifier + ' AuthorizeComponent login_required navigate ' + pathName);
      this.router.navigate([pathName]);
      return;
    }

    //Something went wrong.
    if (error !== null) {
      this.logTokenModelOnError(this.identifier + ' Authorize service error in url ' + url);
      this.router.navigate(['/message/error']);
      return;
    }

    if (code !== null) {
      if (!isNil(registrationRequestid)) {
        this.logger.debug(
          this.identifier + ' AuthorizeComponent registerPartner ' + code + ' ' + registrationRequestid
        );
        this.registerPartner(code, registrationRequestid);
      } else {
        this.logger.debug(this.identifier + ' AuthorizeComponent authorizeAndContinue ' + code + ' ' + pathName);
        this.authorizeAndContinue(code, pathName);
      }
      return;
    }

    //Why would this ever trigger?
    this.logger.debug(this.identifier + ' AuthorizeComponent error page');
    this.router.navigate(['/message/error']);
  }

  private getRegistrationRequestId() {
    const originalUrl = this.authenticationService.getUrl();
    const originalSearchParams = this.getQueryParameters(originalUrl?.toLowerCase());
    const param = originalSearchParams?.get('registrationrequestid');
    return param;
  }

  private authorizeAndContinue(code: string, pathName: string) {
    this.authenticationService
      .getAccessToken(code)
      .pipe(
        switchMap((tokenModel) => {
          return this.authenticationService.getUserInfo(pathName.indexOf('minor') > 0);
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          this.loggedInRouting(pathName);
        },
        error: (message) => {
          this.logger.debug(this.identifier + ' AuthorizeComponent authorizeAndContinue failed ' + message);
          this.router.navigate(['/message/error']);
        }
      });
  }

  private loggedInRouting(pathName: string) {
    this.authenticationService.updateIsLoggedIn(true);
    const user = this.userStoreService.getUser();
    const registration = this.registerStoreService.getRegistrationStore();

    // Prevent the user from hitting the back button and ending up on the authorize component with an invalid code.
    window.history.replaceState({}, '');

    //User not completed registration yet
    if (user.userId === '' || isNil(user.userId)) {
      // If the verification was completed, or defaulted to true (like discovery) but user not registered yet.
      if (
        registration.idVerify?.done ||
        registration.onfidoDetails.filter((o) => o.progress.toLowerCase() === 'completed').length > 0 ||
        registration.onfidoDetails.filter((o) => o.progress.toLowerCase() === 'results retrieved').length > 0
      ) {
        this.router.navigate(['/register/complete']);
        return;
      }

      if (pathName.indexOf('/register/') > -1) {
        this.router.navigate([pathName]);
        return;
      }

      const productDetail = this.productService.getProductDetail(user.productType);
      if (productDetail.partnerCategory === PartnerCategory.full) {
        this.router.navigate(['/message/error']);
        return false;
      }

      this.router.navigate(['/register/country']);
      return;
    }

    //User is logged in and registration completed.
    if (pathName.indexOf('/compliance') > -1) {
      this.router.navigate([pathName]);
      return true;
    }

    if (pathName.indexOf('/minor/') > -1) {
      this.router.navigate(['minor/personal']);
      return;
    }

    if (pathName.indexOf('/profile/') > -1) {
      this.router.navigate([pathName]);
      return;
    }

    if (pathName.indexOf('/game') > -1) {
      this.gameRouting(pathName);
      return;
    }

    if (pathName.indexOf('/engagement/') > -1) {
      this.router.navigate([pathName]);
      return;
    }

    this.router.navigate(['/dashboard']);
  }

  private gameRouting(pathName: string) {
    const redirectUrl = this.gameStoreService.getGameRedirect();
    if (redirectUrl !== '') {
      this.router.navigate([pathName]);
    } else {
      this.router.navigate(['/game']);
    }
  }

  //This is partnerCategory = full registration.
  private registerPartner(code: string, registrationrequestId) {
    this.authenticationService
      .getAccessToken(code)
      .pipe(
        switchMap(() => this.authenticationService.getUserInfo(false)),
        switchMap(() => {
          //For full partners data must be updated in the database immediately.
          const registration = this.registerStoreService.getRegistrationStore();
          return this.registerStoreService.updateRegistrationState(registration);
        }),
        switchMap(() => this.registrationService.register(registrationrequestId)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        //While GCash is still doing partly manual verification, we need to show this complete page first.
        if (environment.manualVerification) {
          this.router.navigate(['/register/complete']);
          return;
        }
        const originalUrl = this.authenticationService.getUrl();
        const redirectUrl = originalUrl?.match(ValidatorPattern.redirectUrl);

        if (isNil(redirectUrl)) {
          this.logTokenModelOnError('Authorize service registerPartner redirectURL ' + registrationrequestId);
          this.router.navigate(['/message/error']);
          return;
        }

        const registration = this.registerStoreService.getRegistrationStore();
        let partnerUrl = redirectUrl[2];
        if (registration.partnerReference) {
          partnerUrl = partnerUrl + '?partner_user_id=' + registration.partnerReference;
        }

        this.authenticationService.clearAllStateStoresWithUrl();
        window.location.href = partnerUrl;
      });
  }

  private getQueryParameters(url): URLSearchParams {
    if (url === '' || isNil(url)) {
      return null;
    }
    const newURL = new URL(url);
    return newURL.searchParams;
  }

  private logTokenModelOnError(message: string) {
    const tokenModel = this.authenticationService.getTokenModel();
    const model = omit(tokenModel, 'token', 'idToken', 'issued', 'expires');
    this.logger.error(message, model);
  }

  private logTokenModelDebug(message: string) {
    const tokenModel = this.authenticationService.getTokenModel();
    const model = omit(tokenModel, 'token', 'idToken', 'issued', 'expires');
    this.logger.debug(message, model);
  }
}
