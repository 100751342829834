<div class="profile-card">
  <div class="profile-card-top">
    <div class="profile-card-left">
      <div class="profile-card-left-box">
        <eid-avatar
          *ngIf="currentUser"
          [first]="currentUser?.firstName"
          [second]="currentUser?.lastName"
          class="profile-picture"
        ></eid-avatar>
        <div>
          <h2 class="profile-name">{{ currentUser.firstName }}&nbsp;{{ currentUser.lastName }}</h2>

          <div class="text-container-md">
            <h2 class="profile-card-description">EASY INVESTOR FOR:</h2>

            <h3>
              <span>{{ yearsSinceRegistration }}</span
              >&#160;years&#160;&#160;<span>{{ monthsSinceRegistration }}</span
              >&#160;months
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="profile-card-right">
      <div class="profile-grid-container">
        <mat-icon class="profile-grid" svgIcon="right-corner-img"></mat-icon>
      </div>
    </div>
  </div>

  <div class="profile-card-bottom">
    <div class="profile-card-bottom-header">
      <mat-icon class="profile-card-bottom-icon" svgIcon="bar-chart"></mat-icon>
      <h2 class="profile-card-bottom-header-title">
        Your Portfolio for {{ date | date: 'LLL' }} '{{ date | date: 'yy' }}
      </h2>
    </div>

    <div class="profile-card-bottom-box">
      <div class="portfolio-row">
        <div class="portfolio-label">Portfolio Value</div>
        <div class="blur-value" *ngIf="blur">xxxxxxxxx</div>
        <div class="portfolio-value" *ngIf="!blur">R {{ portfolioValue | spaceBetweenNumbers }}</div>
      </div>

      <div class="portfolio-row-center">
        <div class="portfolio-label">Change In NAV</div>
        <div class="portfolio-row-center">
          <mat-icon *ngIf="changeInNav >= 0" svgIcon="increase-circle"></mat-icon>
          <mat-icon *ngIf="changeInNav < 0" svgIcon="decrease-circle"></mat-icon>
          <div class="portfolio-value">{{ changeInNav }}%</div>
        </div>
      </div>
    </div>
  </div>
</div>
