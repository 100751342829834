<div *ngIf="isEngagement" class="back-action back-action--left">
  <button (click)="dashboard()">
    <mat-icon [svgIcon]="'chevron-left'"></mat-icon>
  </button>
</div>

<div *ngIf="isDashboard" class="eid-header-mobile__content">Home</div>
<div *ngIf="isProfile" class="eid-header-mobile__content">My Profile</div>
<div *ngIf="isEngagement" class="eid-header-mobile__content-engagement">Activity Dashboard</div>
<div *ngIf="!isDashboard && !isProfile && !isEngagement" class="eid-header-mobile__content"></div>

<!-- Current user -->
<div *ngIf="!isEngagement" class="action">
  <eid-current-user></eid-current-user>
</div>
