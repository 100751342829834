<div class="engagement" eidLoadingSpinner [loading]="loading === true">
  <div class="pageTitle">Welcome To Your Monthly Activity</div>

  <eid-beta *ngIf="showBeta" (closed)="closeBeta($event)" class="beta"></eid-beta>

  <eid-products-desktop size="large"></eid-products-desktop>

  <div class="header-border header-row">
    <div class="header-column"><eid-month-selector-desktop></eid-month-selector-desktop></div>

    <div class="header-row">
      <div class="header-column header-space">
        <eid-blur-desktop (blur)="toggleBlur($event)"></eid-blur-desktop>
      </div>
      <div class="header-column">
        <eid-share-buttons-desktop [blurred]="blur" (loading)="updateLoading($event)"></eid-share-buttons-desktop>
      </div>
    </div>
  </div>

  <div id="engagementimagecanvas" class="engagement-row">
    <div class="engagement-column">
      <eid-portfolio-desktop [blur]="blur"></eid-portfolio-desktop>

      <eid-account-activity-desktop></eid-account-activity-desktop>

      <eid-thrive-desktop></eid-thrive-desktop>
    </div>

    <div class="engagement-column">
      <eid-holdings-desktop></eid-holdings-desktop>

      <eid-dividend-desktop></eid-dividend-desktop>

      <div class="engagement-row">
        <div class="engagement-column">
          <eid-deposits-desktop></eid-deposits-desktop>
        </div>
        <div class="engagement-column">
          <eid-deposits-value-desktop></eid-deposits-value-desktop>
          <eid-live-prices-desktop></eid-live-prices-desktop>
        </div>
      </div>

      <eid-funded-accounts-desktop></eid-funded-accounts-desktop>

      <eid-referrals-desktop></eid-referrals-desktop>
    </div>
  </div>
</div>
