<div class="selector-container">
  <div class="selector">
    <div class="selector-prefix" #prefix>
      <ng-container>
        <mat-icon *ngIf="disabled">lock</mat-icon>
        <ng-content *ngIf="!disabled" select="[prefix]"></ng-content>
      </ng-container>
    </div>
    <div class="selector-group">
      <div class="selector-label" [ngClass]="{ 'selector-label--has-prefix': hasPrefix }">
        {{ description }}<span *ngIf="required" class="required">*</span>
      </div>
      <div class="selector-value">
        {{ value?.label }}
      </div>
    </div>
    <div class="selector-action" *ngIf="!disabled">
      <button *ngIf="!disabled" mat-icon-button type="button" [disabled]="disabled">
        <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
      </button>
    </div>
  </div>
  <div class="selector-validation">
    <ng-content select="[validation]"></ng-content>
  </div>
</div>
