<div class="account-overview-card">
  <div class="account-overview-top">
    <div class="account-overview-header">
      <div>
        <mat-icon class="calendar-icon" svgIcon="calendar"></mat-icon>

        <h2 class="account-overview-title">Account Activity</h2>
      </div>
    </div>
  </div>

  <eid-activity-stats-desktop></eid-activity-stats-desktop>
</div>
