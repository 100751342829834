import { Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { CurrentUser } from '../../shared/models/current-user.model';
import { EngagementService } from '../../shared/services/engagement.service';
import { UserStoreService } from '../../shared/services/user-store.service';
import { isDefined } from '../../shared/utilities/utils';

@Component({
  template: `
    <eid-portfolio-desktop *eidBreakpoint="'desktop'"></eid-portfolio-desktop>
    <eid-portfolio-mobile *eidBreakpoint="'mobile'"></eid-portfolio-mobile>
  `
})
export class PortfolioContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class ProfileComponent {
  @Input() blur = false;

  public currentUser: CurrentUser;

  public portfolioValue = 0;
  public changeInNav = 0;

  public yearsSinceRegistration = 0;
  public monthsSinceRegistration = 0;
  public date: Date;

  constructor(private engagementService: EngagementService, private userStoreService: UserStoreService) {
    this.currentUser = this.userStoreService.getUser();

    this.date = new Date();
    this.date.setMonth(this.date.getMonth() - 1);

    this.engagementService
      .getEngagementData()
      .pipe(
        map((data) => {
          this.portfolioValue = data?.portfolioValue;
          this.changeInNav = data?.changeInNav;

          if (isDefined(data?.registrationDate)) {
            const registrationDate = moment(data.registrationDate);
            const currentDate = moment();

            this.yearsSinceRegistration = currentDate.diff(registrationDate, 'years');
            registrationDate.add(this.yearsSinceRegistration, 'years');

            this.monthsSinceRegistration = currentDate.diff(registrationDate, 'months');
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
