import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ContractingEntity } from '../../registration/models/contracting.model';
import { PartnerCategory, ProductDetailModel, ProductType } from '../../registration/models/product.model';
import { RegistrationState } from '../../store/registration.state';
import { UserState } from '../../store/user.state';
import { UrlBundle } from '../models/urlBundle';
import { ProductService } from './product.service';

@Injectable({ providedIn: 'root' })
export class UrlProviderService {
  @Select(UserState.isProfileComplete) public isProfileComplete$: Observable<boolean>;
  @Select(RegistrationState.productType) public product$: Observable<ProductType>;
  @Select(RegistrationState.contractingEntity) public regContractingEntity$: Observable<ContractingEntity>;
  @Select(UserState.contractingEntity) public userContractingEntity$: Observable<ContractingEntity>;

  assetsPath = '/assets/urls/';
  defaultFileName = 'default-urls.json';
  public urlBundle$: Observable<UrlBundle>;
  public productService: ProductService;

  constructor(private http: HttpClient, productService: ProductService) {
    // Get the default bundle
    this.productService = productService;
    this.urlBundle$ = this.http.get<UrlBundle>(this.assetsPath + this.defaultFileName).pipe(
      switchMap((defaultUrlBundle) => {
        // Merge the default bundle with the product (and contracting entity) specific bundle
        return this.productSpecificBundle(defaultUrlBundle);
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );
  }

  private productSpecificBundle(defaultUrlBundle) {
    return combineLatest([
      this.isProfileComplete$,
      this.product$,
      this.regContractingEntity$,
      this.userContractingEntity$
    ]).pipe(
      switchMap(([isProfileComplete, product, regsContractingEntity, userContractingEntity]) => {
        // Update with some default values
        regsContractingEntity = this.updateRegsContractingEntity(product, regsContractingEntity);
        const productDetail = this.productService.getProductDetail(product);

        const bundlePath = this.getBundlePath(
          isProfileComplete,
          userContractingEntity,
          productDetail,
          regsContractingEntity
        );

        return this.getUrlBundle(defaultUrlBundle, bundlePath, product, productDetail);
      })
    );
  }

  private getBundlePath(
    isProfileComplete: boolean,
    userContractingEntity: ContractingEntity,
    productDetail: ProductDetailModel,
    regsContractingEntity: ContractingEntity
  ) {
    let path;
    if (
      isProfileComplete &&
      productDetail.url !== ProductType.satrix.toString() &&
      productDetail.partnerCategory !== PartnerCategory.full
    ) {
      path = (this.assetsPath + ProductType.none + '-' + userContractingEntity + '-urls.json').toLowerCase();
    } else {
      path = (this.assetsPath + productDetail.url + '-' + regsContractingEntity + '-urls.json').toLowerCase();
    }
    return path;
  }

  private updateRegsContractingEntity(product: ProductType, regsContractingEntity: ContractingEntity) {
    // All these products are known to be always contracting entity fwt
    const productDetail = this.productService.getProductDetail(product);
    if (productDetail.contractingEntity === ContractingEntity.fwt) {
      regsContractingEntity = ContractingEntity.fwt;
    }
    return regsContractingEntity;
  }

  private getUrlBundle(
    defaultUrlBundle,
    bundlePath,
    product,
    productDetail: ProductDetailModel
  ): Observable<UrlBundle> {
    return this.http.get<UrlBundle>(bundlePath).pipe(
      map((urlBundle) => {
        const newBundle = {
          ...defaultUrlBundle,
          ...urlBundle
        };
        this.replaceBaseUrls(newBundle, product, productDetail);
        return newBundle;
      })
    );
  }

  private replaceBaseUrls(bundle: UrlBundle, product: any, productDetail: ProductDetailModel) {
    const baseurl = this.getBaseUrl(productDetail.url);
    Object.keys(bundle).forEach((key) => {
      bundle[key] = bundle[key].replace('{baseurl}', baseurl);

      bundle[key] = bundle[key].replace('{idpurl}', environment.idpurl);
      bundle[key] = bundle[key].replace('{easyequitiesbaseurl}', environment.easyequitiesurl);
      bundle[key] = bundle[key].replace('{mrphybaseurl}', environment.mrphyurl);
      bundle[key] = bundle[key].replace('{easypropertiesbaseurl}', environment.easypropertiesurl);
      bundle[key] = bundle[key].replace('{easycryptobaseurl}', environment.easycryptourl);
      bundle[key] = bundle[key].replace('{thrivebaseurl}', environment.thriveurl);
    });
  }

  private getBaseUrl(product): string {
    let baseUrl = '';
    Object.keys(environment).forEach((key) => {
      if (key === product + 'url') {
        baseUrl = environment[key];
      }
    });
    return baseUrl;
  }
}
