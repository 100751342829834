import { Component, Injector } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { EngagementService } from '../../shared/services/engagement.service';
import { EngagementModel } from '../models/engagement.model';

@Component({
  template: `
    <eid-holdings-desktop *eidBreakpoint="'desktop'"></eid-holdings-desktop>
    <eid-holdings-mobile *eidBreakpoint="'mobile'"></eid-holdings-mobile>
  `
})
export class HoldingsContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class HoldingsComponent {
  public engagement$: Observable<EngagementModel>;

  constructor(protected injector: Injector) {
    const engagementService = injector.get(EngagementService);

    this.engagement$ = engagementService.getEngagementData();
  }
}
