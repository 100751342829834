import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'eid-profile-header-mobile',
  templateUrl: './profile-header.mobile.component.html',
  styleUrls: ['./profile-header.mobile.component.scss']
})
export class ProfileHeaderMobileComponent {
  private router: Router;
  public isProfile: boolean;
  public isDashboard: boolean;
  public isEngagement: boolean;

  constructor(router: Router) {
    this.router = router;
    const url = window.location.href;
    this.isProfile = url.indexOf('/profile') > -1;
    this.isDashboard = url.indexOf('/dashboard') > -1;
    this.isEngagement = url.indexOf('/engagement') > -1;
  }

  public dashboard() {
    this.router.navigate(['/dashboard']);
  }
}
