import { ContractingEntity } from './contracting.model';
import { Theme } from './theme.model';

export interface Product {
  id: number;
  name: string;
  key: string;
  description: string;
  logo: string;
  icon?: string;
  image?: string;
}

export enum ProductType {
  none = 'easyid',
  easyequities = 'easyequities',
  mrphy = 'mrphy',
  easyproperties = 'easyproperties',
  attooh = 'attooh',
  satrix = 'satrix',
  easycrypto = 'easycrypto'
}

export enum PartnerCategory {
  none = 'none',
  full = 'full',
  verified = 'verified',
  unverified = 'unverified'
}

export enum CountryCategory {
  rules = 'rules',
  sa = 'sa',
  cma = 'cma',
  philippines = 'philippines'
}

export enum ComplianceCategory {
  none = 'none',
  normal = 'normal',
  combined = 'combined'
}

export class ProductDetailModel {
  product: string;
  name: string;
  partnerCategory: PartnerCategory;
  productTheme: string;
  styleTheme: Theme;
  countries: CountryCategory;
  contractingEntity: ContractingEntity;
  url: string;
  complianceCategory: ComplianceCategory;
  game: boolean;
  errorText: string = '';
}

export interface ProductDisplayModel {
  product: string;
  name: string;
  description: string;
  logo: string;
  icon: string;
  image: string;
  redirectUrl: string;
  minAge: number;
}
