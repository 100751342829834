import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { ProductType } from '../registration/models/product.model';
import { AuthenticationService } from '../shared/services/authentication.service';
import { RegisterStoreService } from '../shared/services/register-store.service';
import { UserStoreService } from '../shared/services/user-store.service';
import { GuardService } from './guard.service';

@Injectable()
export class RegistrationGuard implements CanActivate {
  constructor(
    private router: Router,
    private registerStoreService: RegisterStoreService,
    private userStoreService: UserStoreService,
    private authenticationService: AuthenticationService,
    private guardService: GuardService
  ) {}

  canActivate() {
    if (!this.guardService.isInitialized()) {
      return false;
    }

    const url = window.location.href;
    if (url.indexOf('register/complete') > -1) {
      return true;
    }

    const user = this.userStoreService.getUser();
    if (user.userId !== '') {
      this.router.navigate(['/dashboard']);
      return false;
    }

    const registrationStore = this.registerStoreService.getRegistrationStore();
    if (registrationStore.productType === ProductType.none) {
      this.router.navigate(['/product']);
      return false;
    }

    if (!this.registerStoreService.isV1Product(registrationStore.productType)) {
      if (user.sub === '') {
        this.authenticationService.authorizeUser();
        return false;
      }
    }

    if (!this.guardService.profileTypeSelected()) {
      return false;
    }

    return true;
  }
}
