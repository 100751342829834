<div class="section">
  <div class="container">
    <div class="row row--justify-center">
      <div class="message">
        <div class="row">
          <div class="col message__text">
            <h2 class="panel__title">Investing from the United States?</h2>
            <p>
              Currently we do not open accounts online for residents or citizens from the United States. For further
              information, please reach out to our client engagement team
              <a [href]="(urlBundle$ | async)?.newTicket" target="_blank">here</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
