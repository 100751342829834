<div class="tw-flex tw-items-center tx-justify-center tw-flex-wrap tw-flex-row tw-w-full">
  <div
    class="product-content-item product-content-item--{{
      product.name | lowercase
    }} tw-flex tw-flex-row tw-items-center tw-justify-start tw-cursor-pointer tw-mt-6 tw-mr-3.5 tw-p-3.5"
    *ngFor="let product of products$ | async; let i = index"
  >
    <a
      [href]="product.redirectUrl"
      [routerLinkActive]="'active'"
      class="nav-item nav-item--{{ i + 1 }} tw-flex tw-flex-col
       tw-items-center tw-w-full tw-no-underline"
    >
      <div class="tw-flex tw-flex-row tw-items-center tw-w-full tw-justify-start">
        <mat-icon class="tw-mr-2" *ngIf="product.icon" mat-list-icon [svgIcon]="product.icon"></mat-icon>
        <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full">
          <div class="tw-font-secondary tw-font-normal tw-text-lg/5 tw-text-[#ed1747]">
            <span class="tw-font-bold">{{ (product.name | sliceUpper)[0] }}</span
            >{{ (product.name | sliceUpper)[1] }}
          </div>
          <mat-icon class="product-link" [svgIcon]="'arrow-right'" *ngIf="size === 'small'"></mat-icon>
        </div>
      </div>
      <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full tw-pt-3.5" *ngIf="size === 'large'">
        <div class="tw-flex tw-flex-row tw-items-center">
          <div class="tw-font-primary tw-font-normal tw-text-xs/4 tw-text-[#4a4a4a]" size="large">Learn more</div>
        </div>
        <div class="link-triangle link-triangle--{{ product.name | lowercase }}"></div>
      </div>
    </a>
  </div>
</div>
