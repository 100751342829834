import { Component, OnInit } from '@angular/core';

import { CustomIconService } from './shared/services/custom-icon.service';

@Component({
  selector: 'eid-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private customIconService: CustomIconService) {}

  public ngOnInit(): void {
    this.customIconService.init();
  }
}
