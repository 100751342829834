import { Component, ElementRef, ViewChild } from '@angular/core';

import { LayoutComponent } from './layout.component';

@Component({
  selector: 'eid-layout-desktop',
  templateUrl: './layout.desktop.component.html',
  styleUrls: ['./layout.desktop.component.scss']
})
export class LayoutDesktopComponent extends LayoutComponent {
  @ViewChild('rootPage') private rootPage!: ElementRef<HTMLElement>;

  public onActivate(evt) {
    if (this.rootPage) {
      (this.rootPage.nativeElement as HTMLElement).scrollTop = 0;
    }
  }
}
