import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutContainerComponent } from '../layout/layout/layout.component';
import { EngagementContainerComponent } from './engagement/engagement.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutContainerComponent,
    children: [
      { path: '', redirectTo: 'activity', pathMatch: 'full' },
      {
        path: 'activity',
        component: EngagementContainerComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class EngagementRoutingModule {}
