<div class="stepper-container">
  <button class="btn-solid btn-solid--primary" (click)="selectedStep(active + 1)">Next</button>
  <div class="stepper-stripes">
    <div class="step-container" (click)="selectedStep(1)">
      <div [ngClass]="{ active: active >= 1 }" class="step"></div>
    </div>
    <div class="step-container" (click)="selectedStep(2)">
      <div [ngClass]="{ active: active >= 2 }" class="step"></div>
    </div>
    <div class="step-container" (click)="selectedStep(3)">
      <div [ngClass]="{ active: active >= 3 }" class="step"></div>
    </div>
    <div class="step-container" (click)="selectedStep(4)">
      <div [ngClass]="{ active: active >= 4 }" class="step"></div>
    </div>
    <div class="step-container" (click)="selectedStep(5)">
      <div [ngClass]="{ active: active >= 5 }" class="step"></div>
    </div>
  </div>
</div>
