import { ProductType } from '../registration/models/product.model';
import { CurrentUser } from '../shared/models/current-user.model';

export class AddCurrentUser {
  static readonly type = '[User] Add';
}

export class UpdateCurrentUser {
  static readonly type = '[User] Update User';
  constructor(public payload: CurrentUser) {}
}

export class UpdateProductType {
  static readonly type = '[User] Update Product Type';
  constructor(public payload: ProductType) {}
}
