import { AffiliateDetail } from '../registration/models/affiliate.model';
import { ContractingEntity } from '../registration/models/contracting.model';
import { CountrySelect } from '../registration/models/country.model';
import { IdVerify } from '../registration/models/id-verify.model';
import { PersonalDetail } from '../registration/models/personal.model';
import { ProductType } from '../registration/models/product.model';
import { TaxCompliance } from '../registration/models/tax-compliance.model';
import { User } from '../registration/models/user.model';
import { Address } from '../shared/models/address.model';
import { FicaDetail } from '../shared/models/financial.model';
import { JumioDetail } from '../shared/models/jumio.model';
import { OnfidoDetail } from '../shared/models/onfido.model';
import { ProfileType } from '../shared/models/profile.model';
import { RegistrationStateModel, StepValidator } from '../shared/models/registration-state.model';

export class Register {
  static readonly type = '[Registration] Register';
}

export class UpdateRegistrationState {
  static readonly type = '[Registration] Update Registration State';
  constructor(public payload: RegistrationStateModel) {}
}

export class UpdateCountry {
  static readonly type = '[Registration] Update Country';
  constructor(public payload: CountrySelect) {}
}

export class UpdateContractingEntity {
  static readonly type = '[Registration] Update Contracting Entity';
  constructor(public payload: ContractingEntity) {}
}

export class UpdateProductType {
  static readonly type = '[Registration] Update Product Type';
  constructor(public payload: ProductType) {}
}

export class UpdateIncomingProductType {
  static readonly type = '[Registration] Update URL param Product Type';
  constructor(public payload: ProductType) {}
}

export class UpdateAffiliate {
  static readonly type = '[Registration] Update Affiliate';
  constructor(public payload: AffiliateDetail) {}
}

export class UpdateProfileType {
  static readonly type = '[Registration] Update Profile Type';
  constructor(public payload: ProfileType) {}
}

export class UpdateUser {
  static readonly type = '[Registration] Update User';
  constructor(public payload: User) {}
}

export class UpdatePersonalDetail {
  static readonly type = '[Registration] Update Personal Detail';
  constructor(public payload: PersonalDetail) {}
}

export class UpdateAddress {
  static readonly type = '[Registration] Update Address';
  constructor(public payload: Address) {}
}

export class UpdateFicaDetail {
  static readonly type = '[Registration] Update Fica Detail';
  constructor(public payload: FicaDetail) {}
}

export class UpdateTaxCompliance {
  static readonly type = '[Registration] Update Tax Compliance';
  constructor(public payload: TaxCompliance) {}
}

export class UpdateIdVerify {
  static readonly type = '[Registration] Update IdVerify Results';
  constructor(public payload: IdVerify) {}
}

export class UpdateJumioDetails {
  static readonly type = '[Registration] Update Jumio Details';
  constructor(public payload: JumioDetail[]) {}
}
export class UpdateOnfidoDetails {
  static readonly type = '[Registration] Update Onfido Details';
  constructor(public payload: OnfidoDetail[]) {}
}

export class UpdateStepValidators {
  static readonly type = '[Registration] Update Step validators';
  constructor(public payload: StepValidator[]) {}
}

export class UpdatePartnerInformation {
  static readonly type = '[Registration] Update redirect url';
  constructor(public payload: string) {}
}

export class UpdateUseMockEE {
  static readonly type = '[Registration] Update the use of mock ee requests';
  constructor(public payload: boolean) {}
}

export class UpdateKidAddress {
  static readonly type = '[Registration] Update whether kids address is diffferent from user';
  constructor(public payload: boolean) {}
}

export class UpdateKidCommunication {
  static readonly type = '[Registration] Update whether kids communication is diffferent from user';
  constructor(public payload: boolean) {}
}
