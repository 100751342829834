<div class="root">
  <div class="banner">
    <h2 class="banner-text-heading">
      <b>YOUR STATS</b>
    </h2>

    <h2 class="banner-text">
      <span class="total-holdings">{{ (engagement$ | async)?.userHoldingAccount }}</span>
      Holdings
    </h2>
  </div>

  <div class="holdings-container">
    <div class="holdings-box">
      <div *ngFor="let holding of (engagement$ | async)?.userHoldings" class="holding-card">
        <img
          src="../../../assets/icons/engagement/{{ holding.name | lowercase }}.svg"
          alt="{{ holding.name }}"
          class="holding-icon"
        />

        <h3 class="holding-type">
          {{ holding.name }}
        </h3>

        <h2 class="holding-amount">
          {{ holding.holdings }}
        </h2>
      </div>
    </div>
  </div>

  <div class="community-container">
    <h2 class="community-title">COMMUNITY AVERAGE</h2>

    <div class="community-box">
      <div>
        <h2 class="community-box-title">
          {{ (engagement$ | async)?.communityHoldingsAverage }}
        </h2>

        <h3 class="community-box-text">Holdings</h3>
      </div>

      <div>
        <mat-icon class="community-box-icon" svgIcon="community-box-icon"></mat-icon>
      </div>
    </div>
  </div>
</div>
