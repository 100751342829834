import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'eid-minimal-header-desktop',
  templateUrl: './minimal-header.desktop.component.html',
  styleUrls: ['./minimal-header.desktop.component.scss']
})
export class MinimalHeaderDesktopComponent implements OnInit {
  @HostBinding('attr.class') private layourCls = 'eid-private-header-desktop eid-private-header-desktop--minimal';
  public productLogo: boolean = true;

  ngOnInit(): void {
    const url = window.location.href;
    if (url.indexOf('/compliance/') > -1 || url.indexOf('satrix') > -1) {
      this.productLogo = false;
    }
  }
}
