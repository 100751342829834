export interface SelectorList {
  [key: string]: SelectorListItem[];
}

export class SelectorListItem {
  public label: string;
  public value: string | number;

  constructor(label, value) {
    this.label = label;
    this.value = value;
  }
}
