import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { EngagementService } from '../../shared/services/engagement.service';
import { EngagementModel } from '../models/engagement.model';
@UntilDestroy()
@Component({
  selector: 'eid-mobile-holdings',
  templateUrl: './mobile-holdings.component.html',
  styleUrls: ['./mobile-holdings.component.scss']
})
export class MobileHoldingsComponent {
  public engagement$: Observable<EngagementModel>;

  constructor(private engagementService: EngagementService, private location: Location) {
    this.engagement$ = this.engagementService.getEngagementData();
  }

  back(): void {
    this.location.back();
  }
}
