import { AfterViewInit, Directive, EventEmitter, Optional, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({ selector: '[eidSetActiveOption]' })
export class SetActiveOptionDirective implements AfterViewInit {
  @Output() activeOptionSelected = new EventEmitter<MatOption>();

  constructor(@Optional() private autoTrigger: MatAutocompleteTrigger, @Optional() private control: NgControl) {}

  ngAfterViewInit() {
    this.autoTrigger.panelClosingActions.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.autoTrigger.activeOption) {
        const value = this.autoTrigger.activeOption.value;
        if (this.control && this.control.control.value.toLowerCase() === value.toLowerCase()) {
          this.control.control.setValue(value, { emit: false });
          this.activeOptionSelected.emit(this.autoTrigger.activeOption);
        }
      }
    });
  }
}
