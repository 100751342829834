import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class ShareActivityGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    const url = window.location.href;
    if (url.indexOf('shareid') < 0) {
      this.router.navigate(['/message/error']);
      return false;
    }

    return true;
  }
}
