<ng-container *ngIf="item.url">
  <a [href]="item.url" target="_blank" class="1">
    <mat-icon *ngIf="item.displayIcon" [svgIcon]="item.icon"></mat-icon>
    <span *ngIf="item.displayLabel">{{ item.label }}</span>
  </a>
</ng-container>
<ng-container *ngIf="item.routerLink">
  <a [routerLink]="item.routerLink" class="2">
    <mat-icon *ngIf="item.displayIcon" [svgIcon]="item.icon"></mat-icon>
    <span *ngIf="item.displayLabel">{{ item.label }}</span>
  </a>
</ng-container>
<ng-container *ngIf="hasSubmenu" class="3">
  <button [matMenuTriggerFor]="menu">
    <mat-icon *ngIf="item.displayIcon" [svgIcon]="item.icon"></mat-icon>
    <span *ngIf="item.displayLabel">{{ item.label }}</span>
  </button>

  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let child of item.children">
      <a mat-menu-item *ngIf="child.routerLink && !child.url" [routerLink]="child.routerLink" class="4">
        <mat-icon *ngIf="child.displayIcon" [svgIcon]="child.icon"></mat-icon>
        <span *ngIf="child.displayLabel">{{ child.label }}</span>
      </a>
      <a mat-menu-item *ngIf="!child.routerLink && child.url" [href]="child.url" target="_blank" class="5">
        <mat-icon *ngIf="child.displayIcon" [svgIcon]="child.icon"></mat-icon>
        <span *ngIf="child.displayLabel">{{ child.label }}</span>
      </a>
    </ng-container>
  </mat-menu>
</ng-container>
