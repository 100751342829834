<eid-user-details [user]="currentUser$ | async" (closeUserDetail)="back()"></eid-user-details>

<div *ngIf="minorEnabled && (currentUser$ | async) && !isMinor((currentUser$ | async).dob)" class="minor">
  <button mat-button class="minor-button" (click)="minor()">
    <mat-icon>person_add</mat-icon> Add an account for my {{ minorDescription | lowercase }}
  </button>
</div>

<button
  *ngIf="(currentUser$ | async)?.productType === productTypes.satrix"
  class="account-function"
  mat-button
  (click)="back()"
>
  Back to SatrixNOW
</button>

<!-- Logout -->
<button
  *ngIf="(currentUser$ | async)?.productType !== productTypes.satrix"
  class="account-function"
  mat-button
  (click)="logout()"
>
  Log out
</button>
