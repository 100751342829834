<div class="intro-box">
  <div class="column column--image">
    <img src="assets/icons/intro/intro-two.png" alt="intro-two" />
  </div>
  <div class="column column--content">
    <div class="close-btn">
      <mat-icon svgIcon="close" class="close-icon" (click)="close()"></mat-icon>
    </div>
    <div class="copy-column">
      <div class="copy">
        <h2>Experiment fearlessly with our risk-free demo account</h2>
        <p>Play with virtual cash in our demo account, then venture into real investing with peace of mind.</p>
      </div>
      <div class="intro-step">
        <eid-intro-stepper [active]="2" (stepSelected)="stepSelected($event)"></eid-intro-stepper>
      </div>
    </div>
  </div>
</div>
