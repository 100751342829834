import { Component, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProductType } from '../../../registration/models/product.model';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { RegistrationState } from '../../../store/registration.state';

@Component({
  selector: 'eid-register-header-desktop',
  templateUrl: './register-header.desktop.component.html',
  styleUrls: ['./register-header.desktop.component.scss']
})
export class RegisterHeaderDesktopComponent {
  @HostBinding('class.eid-header-desktop') public elCls = true;

  @Select(RegistrationState.productType) public productType$: Observable<ProductType>;
  public productTypes = ProductType;
  public urlSegment = window.location.pathname;

  constructor(private router: Router, protected authenticationService: AuthenticationService) {}

  routerClasses() {
    if (
      this.urlSegment === '/register/country' ||
      this.urlSegment === '/register/create-id' ||
      this.urlSegment === '/register/details/personal' ||
      this.urlSegment === '/register/details/address' ||
      this.urlSegment === '/register/details/fica' ||
      this.urlSegment === '/profile-type'
    ) {
      return {
        active: true
      };
    } else {
      return {
        active: false
      };
    }
  }
  public logout(): void {
    this.router.navigate(['/logout']);
  }
}
