<div class="account-overview-card" id="activity-gradient">
  <div class="account-overview-top">
    <div class="account-overview-header">
      <a class="account-overview-header-link" (click)="accountActivity()">
        <div>
          <mat-icon class="calendar-icon" svgIcon="calendar"></mat-icon>
          <h2 class="account-overview-title">Account Activity</h2>
        </div>
        <div id="activity-chevron">
          <mat-icon svgIcon="chevron-right-lg" class="chevron-icon"></mat-icon>
        </div>
      </a>
    </div>
    <div class="account-overview-top-body">
      <h3>YOUR STATS</h3>
      <div class="calendar-container">
        <eid-calendar-mobile></eid-calendar-mobile>
      </div>
    </div>
  </div>
  <div class="account-overview-bottom-sm">
    <div>
      <mat-icon *ngIf="activeDaysUp" svgIcon="increase-circle"></mat-icon>
      <mat-icon *ngIf="!activeDaysUp" svgIcon="decrease-circle"></mat-icon>
      <h2>{{ (engagement$ | async)?.userActiveDaysCount }}</h2>
      <h3>Active Days</h3>
    </div>
    <div>
      <mat-icon *ngIf="activeDaysUp" svgIcon="increase-circle"></mat-icon>
      <mat-icon *ngIf="!activeDaysUp" svgIcon="decrease-circle"></mat-icon>
      <h2>{{ (engagement$ | async)?.userLoginCount }}</h2>
      <h3>Log Ins</h3>
    </div>
  </div>
</div>
