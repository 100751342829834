import { ComplianceStateModel } from '../shared/models/compliance-state.model';
import { OutstandingCompliance } from '../shared/models/compliance.model';

export class Compliance {
  static readonly type = '[Compliance] Compliance';
}

export class UpdateComplianceState {
  static readonly type = '[Compliance] Update Compliance State';
  constructor(public payload: ComplianceStateModel) {}
}

export class UpdateRedirectUrl {
  static readonly type = '[Compliance] Update RedirectUrl';
  constructor(public payload: string) {}
}

export class UpdateSuccessRedirectUrl {
  static readonly type = '[Compliance] Update Success RedirectUrl';
  constructor(public payload: string) {}
}

export class UpdateUserId {
  static readonly type = '[Compliance] Update UserId';
  constructor(public payload: string) {}
}

export class UpdateOutstandingCompliance {
  static readonly type = '[Compliance] Update OutstandingCompliance';
  constructor(public payload: OutstandingCompliance) {}
}
