import { Component, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
  template: ''
})
export class IntroThreeComponent {
  @Output() public step: BehaviorSubject<number> = new BehaviorSubject(3);

  constructor(private dialog: MatDialog) {}

  public stepSelected(step: number) {
    this.step.next(step);
  }

  public close() {
    this.dialog.closeAll();
  }
}
