import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProductType } from '../../../../registration/models/product.model';
import { RegistrationState } from '../../../../store/registration.state';
import { UrlBundle } from '../../../models/urlBundle';
import { UrlProviderService } from '../../../services/url.provider';

@Component({
  selector: 'eid-already-registered',
  templateUrl: './already-registered.component.html',
  styleUrls: ['./already-registered.component.scss']
})
export class AlreadyRegisteredComponent {
  @Select(RegistrationState.productType) public productType$: Observable<ProductType>;
  public productTypes = ProductType;

  public urlBundle$: Observable<UrlBundle>;

  constructor(public urlProviderService: UrlProviderService) {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
  }
}
