<div class="select-list-container">
  <div #searchFilter class="filter">
    <input #search type="text" placeholder="Search" autocomplete="off" />
    <mat-icon class="text--accent">search</mat-icon>
  </div>

  <div class="selector-list selector-list-spacing" *ngIf="config.simple">
    <ul class="selector-list__items">
      <ng-container *ngFor="let item of listFiltered; let i = index">
        <li *ngIf="!highlight" (click)="setSelectedValue(item)">{{ item.label }}</li>

        <ng-container *ngIf="highlight">
          <li id="selected" class="selected-value" (click)="setSelectedValue(item)">
            <div class="label">{{ item.label }}</div>
            <div *ngIf="item.value === selectedValue?.value" class="icon">
              <mat-icon class="status-icon">check</mat-icon>
            </div>
          </li>
        </ng-container>
        <div *ngIf="!searchable && i < listFiltered.length - 1" class="selected-border"></div>
      </ng-container>
    </ul>
  </div>

  <div
    class="selector-list"
    *ngIf="!config.simple"
    [ngClass]="{
      'selector-spacing': searchable
    }"
  >
    <ng-container *ngFor="let group of groupsFiltered | keyvalue; let i = index">
      <ng-container *ngIf="!config.simple">
        <h1 *ngIf="searchable" class="selector-list__header">{{ group.key }}</h1>
      </ng-container>
      <ul class="selector-list__items" id="{{ group.key }}">
        <ng-container *ngFor="let item of group.value; let i = index">
          <li *ngIf="!highlight" (click)="setSelectedValue(item)">{{ item.label }}</li>

          <ng-container *ngIf="highlight">
            <li id="selected" (click)="setSelectedValue(item)" class="selected-value">
              <div class="label">{{ item.label }}</div>
              <div *ngIf="item.value === selectedValue?.value" class="icon">
                <mat-icon class="status-icon">check</mat-icon>
              </div>
            </li>
          </ng-container>
          <div *ngIf="!searchable && i < (groupsFiltered | keyvalue).length" class="selected-border"></div>
        </ng-container>
      </ul>
    </ng-container>
  </div>
  <ng-container *ngIf="!config.simple">
    <div *ngIf="searchable" class="selector-list-pagination">
      <span
        *ngFor="let group of groupsFiltered | keyvalue"
        (click)="scrollTo(group.key)"
        (touchstart)="touchstart($event)"
        (touchmove)="touchmove($event)"
        >{{ group.key }}</span
      >
    </div>
  </ng-container>
</div>
