import { ShareButton } from '../models/shareButton.model';

export const shareButtons: ShareButton[] = [
  {
    buttonType: 'facebook',
    share: {
      desktop: 'https://www.facebook.com/sharer/sharer.php?'
    },
    params: {
      url: 'u'
    },
    action: 'share'
  },
  {
    buttonType: 'twitter',
    share: {
      desktop: 'https://twitter.com/intent/tweet?'
    },
    params: {
      url: 'url',
      description: 'text'
    },
    action: 'share'
  },
  {
    buttonType: 'whatsapp',
    share: {
      desktop: 'https://api.whatsapp.com/send?',
      android: 'whatsapp://send?',
      ios: 'https://api.whatsapp.com/send?'
    },
    params: {
      url: 'text'
    },
    urlText: 'Check out my monthly activities on EasyEquities: ',
    action: 'share'
  },
  {
    buttonType: 'linkedin',
    share: {
      desktop: 'https://www.linkedin.com/sharing/share-offsite?'
    },
    params: {
      url: 'url',
      title: 'title',
      description: 'text'
    },
    action: 'share'
  },
  {
    buttonType: 'copy',
    action: 'copy'
  },
  {
    buttonType: 'email',
    share: {
      desktop: 'mailto:?'
    },
    params: {
      url: 'body',
      title: 'subject'
    },
    urlText: 'Check out my monthly activities on EasyEquities: ',
    action: 'share'
  },
  {
    buttonType: 'more',
    action: 'more'
  },
  {
    buttonType: 'less',
    action: 'less'
  }
];
