import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { some } from 'lodash';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { SelectorList, SelectorListItem } from '../../models/selector-list-item.model';
import { SelectorComponent } from '../selector/selector.component';

export class SelectorDialogData {
  simple: boolean;
  reverse: boolean;
  description = '';
  searchable = true;
  selectedValue?: number | string;
  public list: SelectorListItem[];
  public groups: SelectorList;
  highlight: boolean;
}

@UntilDestroy()
@Component({
  selector: 'eid-selector-list',
  templateUrl: './selector-list.component.html',
  styleUrls: ['./selector-list.component.scss']
})
export class SelectorListComponent implements OnInit, AfterViewInit {
  @ViewChild('search', { static: true }) private searchInput: ElementRef;
  @ViewChild('searchFilter', { static: true }) private searchFilter: ElementRef;
  public filter$: Observable<KeyboardEvent>;
  public groups$: Observable<SelectorList>;

  public groups: SelectorList;
  public groupsFiltered: SelectorList;
  public favourites: SelectorList;

  public list: SelectorListItem[];
  public listFiltered: SelectorListItem[];

  public description;
  public searchable;
  public touchstartPosition: number;

  public selectedValue;
  public highlight;

  constructor(
    private dialogRef: MatDialogRef<SelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public config: SelectorDialogData
  ) {
    this.description = config.description;
    this.searchable = config.searchable;
    this.groups = config.groups;
    this.groupsFiltered = config.groups;
    this.list = config.list;
    this.selectedValue = config.selectedValue;
    this.highlight = config.highlight;
  }

  public ngOnInit(): void {
    this.config.reverse ? (this.list = this.list?.reverse()) : (this.list = this.list);
    this.config.reverse ? (this.listFiltered = this.list?.reverse()) : (this.listFiltered = this.list);
  }

  private filterGroups(filterText: string): void {
    const filteredGroups = Object.assign({}, this.groups);
    if (!filterText) {
      this.groupsFiltered = filteredGroups;
      return;
    }

    // list to keep track of items from all groups that form part of filtered results. Used to prevent duplicates.
    const filteredItemsFlatList = [];

    Object.keys(filteredGroups)
      .sort()
      .forEach((key) => {
        filteredGroups[key] = filteredGroups[key].filter((i) => {
          // Filter out values that is already added to the filteredItemsFlatlist. (unless search string is empty)
          const isDuplicate = some(filteredItemsFlatList, { value: i.value });
          return !isDuplicate && i.label.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
        });

        filteredItemsFlatList.push(...filteredGroups[key]);
        if (filteredGroups[key].length === 0) {
          delete filteredGroups[key];
        }
      });
    this.groupsFiltered = filteredGroups;
  }

  private filterList(q: string): void {
    this.listFiltered = [...this.list].filter((li) => li.label.toLowerCase().indexOf(q.toLowerCase()) > -1);
  }

  public ngAfterViewInit(): void {
    if (!this.searchable) {
      this.searchFilter.nativeElement.style.display = 'none';
      return;
    }
    this.filter$ = fromEvent(this.searchInput.nativeElement, 'keyup');
    this.filter$
      .pipe(
        map((event) => (event.target as HTMLInputElement).value),
        debounceTime(400),
        distinctUntilChanged()
      )
      .pipe(untilDestroyed(this))
      .subscribe((q) => {
        if (!this.config.simple) {
          this.filterGroups(q);
          return;
        }
        this.filterList(q);
      });
    this.scrollToSelectedValue();
  }

  public scrollToSelectedValue() {
    if (!this.highlight) {
      return;
    }

    if (!this.selectedValue || this.selectedValue === null) {
      return;
    }

    const selectedVal = document.getElementById('selected');
    if (!selectedVal) {
      return;
    }

    selectedVal.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
  }

  public scrollTo(pos: string) {
    const scrollable = document.getElementsByClassName('selector-list')[0];
    const element = document.getElementById(pos);

    if (scrollable && element) {
      scrollable.scrollTop = element.offsetTop - (56 + 24 + 48);
    }
  }

  public touchstart(evt: TouchEvent) {
    this.touchstartPosition = evt.touches[0].clientY;
  }

  public touchmove(evt: TouchEvent) {
    const el = document.getElementsByClassName('selector-list-pagination')[0] as HTMLElement;
    const scrollable = document.getElementsByClassName('selector-list')[0];

    const delta = evt.touches[0].clientY - this.touchstartPosition;
    const scrollPerc = delta / el.clientHeight;
    scrollable.scrollTop = scrollable.scrollHeight * scrollPerc;
  }

  public setSelectedValue(value: SelectorListItem) {
    this.dialogRef.close(value);
  }
}
