import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AffiliateDetail } from '../../registration/models/affiliate.model';
import { ContractingEntity } from '../../registration/models/contracting.model';
import { CountryModel } from '../../registration/models/country.model';
import { IdExists } from '../../registration/models/id-exists.model';
import { PartnerCategory, ProductType } from '../../registration/models/product.model';
import { SaIdVerificationResult, UserModel } from '../../registration/models/register.model';
import { CountryEnum, CountryIdEnum, IdTypeEnum, VerificationType } from '../constants/enums';
import {
  RegistrationExistsModel,
  RegistrationStateModel,
  UsernameExistsModel
} from '../models/registration-state.model';
import { isDefined } from '../utilities/utils';
import { ProductService } from './product.service';

@Injectable({ providedIn: 'root' })
export class RegistrationService {
  countryModel$: Observable<CountryModel>;

  constructor(private httpClient: HttpClient) {}

  public contractingEntityFromCountrySelected(
    productService: ProductService,
    countrySelect: any,
    productType: ProductType
  ) {
    // Country of residence is the primary check for determining the contracting entity.
    // If the country of residence is Aus, the user will contract with EE Aus;
    // if the country of residence is SA the user will contract with FWT;
    // if the country of residence is any other country the user will contract with FWT
    //    unless the user is an Australian citizen, which will mean he'll contract with EE Aus.

    //If configured for SA only, the rules do not apply (this is mainly for partners)
    const productDetail = productService.getProductDetail(productType);
    if (productDetail.contractingEntity === ContractingEntity.fwt) {
      return ContractingEntity.fwt;
    }

    let contractingEntity = ContractingEntity.fwt;
    if (countrySelect.residence && countrySelect.residence.countryCode3 === CountryEnum.australia) {
      contractingEntity = ContractingEntity.aus;
    } else if (
      countrySelect.residence &&
      countrySelect.citizenship &&
      countrySelect.residence.countryCode3 !== CountryEnum.southAfrica &&
      countrySelect.citizenship.countryCode3 === CountryEnum.australia
    ) {
      contractingEntity = ContractingEntity.aus;
    }
    return contractingEntity;
  }

  public contractingEntityFromIdSource(
    productService: ProductService,
    contractingEntity: ContractingEntity,
    countryCode3: string,
    productType: ProductType
  ) {
    //If configured for SA only, the rules do not apply (this is mainly for partners)
    const productDetail = productService.getProductDetail(productType);
    if (productDetail.contractingEntity === ContractingEntity.fwt) {
      return ContractingEntity.fwt;
    }

    // If the user now selects the IdSourceCountry as AUS, we will overwrite the FWT contracting entity to EE AUS
    if (countryCode3 === CountryEnum.australia) {
      contractingEntity = ContractingEntity.aus;
    }
    return contractingEntity;
  }

  public isMonetaryArea(countryId) {
    if (
      countryId === CountryIdEnum.southAfrica ||
      countryId === CountryIdEnum.namibia ||
      countryId === CountryIdEnum.lesotho ||
      countryId === CountryIdEnum.eswatini
    ) {
      return true;
    }
    return false;
  }

  public getVerificationTypeForUser(
    registrationStore: RegistrationStateModel,
    productService: ProductService
  ): VerificationType {
    //Verified products do not call any verification.
    const productDetail = productService.getProductDetail(registrationStore.productType);
    if (productDetail.partnerCategory === PartnerCategory.verified) {
      return VerificationType.none;
    }

    //This also checks if the registration store was already cleared and the product type is none/easyid
    if (
      (registrationStore.personalDetail.idSourceCountry === CountryEnum.southAfrica &&
        registrationStore.personalDetail.identificationTypeId === IdTypeEnum.idCard) ||
      registrationStore.productType === ProductType.none
    ) {
      return VerificationType.idverification;
    }

    if (registrationStore.productType === ProductType.satrix) {
      return VerificationType.error;
    }

    return VerificationType.onfido;
  }

  // This method should only be used in the registration process. It filters according to Onfido.
  // For profile, use static data service getCountries method.
  public getCountries(): Observable<CountryModel> {
    if (isDefined(this.countryModel$)) {
      return this.countryModel$;
    }
    return this.httpClient.get<CountryModel>('/api/registration/countries').pipe(
      map((c) => {
        this.countryModel$ = of(c);
        return c;
      })
    );
  }

  public affiliateDetails(productType, id, key) {
    const data = {
      productType,
      AffiliateId: id,
      EncryptedText: key
    };
    return this.httpClient.post<AffiliateDetail>('/api/registration/affiliates', data);
  }

  public productValid(data) {
    return this.httpClient.post<boolean>('/api/registration/productvalid', data);
  }

  public usernameExists(username: string) {
    const data = {
      username
    };
    return this.httpClient.post<UsernameExistsModel>('/api/registration/usernameexists', data);
  }

  public idExists(data) {
    return this.httpClient.post<IdExists>('/api/registration/idexists', data);
  }

  public idPending(data) {
    return this.httpClient.post<boolean>('/api/registration/idpending', data);
  }

  public saIdVerify(idNumber: string, lastName: string, gender: number): Observable<SaIdVerificationResult> {
    const data = {
      idNumber,
      lastName,
      gender
    };
    return this.httpClient.post<SaIdVerificationResult>('/api/registration/identityverify', data);
  }

  public register(registrationId: string) {
    const data = {
      registrationId
    };
    return this.httpClient.post<UserModel>('/api/registration/register', data);
  }

  public jumioSuccess(params): Observable<boolean> {
    return this.httpClient.post<boolean>('/api/registration/jumiosuccess', params);
  }

  public getRegistrationExists(
    username: string,
    password: string,
    productType: string,
    sub: string
  ): Observable<RegistrationExistsModel> {
    const data = {
      username,
      password,
      productType,
      sub
    };
    return this.httpClient.post<RegistrationExistsModel>('/api/registration/registrationexists', data);
  }

  public getRegistrationState(registrationId): Observable<RegistrationStateModel> {
    return this.httpClient.get<RegistrationStateModel>('/api/registration/state?id=' + registrationId);
  }

  public updateRegistrationState(registrationStateModel: RegistrationStateModel): Observable<RegistrationStateModel> {
    return this.httpClient.put<RegistrationStateModel>('/api/registration/state', registrationStateModel);
  }

  public createRegistrationState(registrationStateModel: RegistrationStateModel): Observable<RegistrationStateModel> {
    return this.httpClient.post<RegistrationStateModel>('/api/registration/state', registrationStateModel);
  }

  public deleteRegistrationState(registrationId) {
    return this.httpClient.delete<boolean>('/api/registration/state/' + registrationId);
  }
}
