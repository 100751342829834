<div class="section">
  <div class="container">
    <div class="row row--justify-center">
      <div class="message">
        <div class="row">
          <div class="col message__text">
            <h2 class="panel__title">Investing from another country?</h2>
            <p>
              Unfortunately, we do not support accounts from your country. Please see our available products for your
              country
              <a [href]="(urlBundle$ | async)?.unsupportedCountry" target="_blank">here</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

