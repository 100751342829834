<div class="action action--left" [ngClass]="{ 'action--game-dialog': isGCashGame }">
  <button *ngIf="leftAction" (click)="callAction(leftAction)">
    <mat-icon *ngIf="leftAction.icon" [svgIcon]="leftAction.icon" [style.color]="leftAction?.color"></mat-icon>
    <span *ngIf="leftAction.label" class="label" [style.color]="leftAction?.color">{{ leftAction.label }}</span>
  </button>
</div>

<div class="eid-header-mobile__content" [ngClass]="{ 'eid-header-mobile__game-dialog': isGCashGame }">
  <h1 *ngIf="pageTitle$ | async" class="page-title">{{ pageTitle$ | async }}</h1>
</div>

<div class="action" [ngClass]="{ 'action--game-dialog': isGCashGame, 'action--right': !isGCashGame }">
  <button *ngIf="rightAction" (click)="callAction(rightAction)">
    <mat-icon *ngIf="rightAction.icon" [svgIcon]="rightAction.icon" [style.color]="rightAction?.color"></mat-icon>
    <span *ngIf="rightAction.label" class="label" [style.color]="rightAction?.color">{{ rightAction.label }}</span>
  </button>
</div>
