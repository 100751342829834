import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ValidatorPattern } from '../../constants/validator-patterns';

@Component({
  selector: 'eid-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements AfterViewInit, OnChanges {
  @Input() public first: string;
  @Input() public second: string;
  @Input() public size: 'small' | 'medium' | 'large' = 'small';
  @Input() public index = 0;

  @ViewChild('avatar') avatar: ElementRef;

  public initials: string;

  constructor() {}

  ngAfterViewInit(): void {
    const element = this.avatar.nativeElement as HTMLElement;
    this.index = this.index % 8;
    element.classList.add('avatar-item-' + this.index);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.initials = this.getInitials(this.first, this.second);
    }
  }

  public getInitials(first, second): string {
    let initials = '';
    if (!first && !second) {
      return '😀';
    }
    if (!first && second) {
      initials = this.extractInitial(second);
    }
    if (first && !second) {
      const split = first.split(' ').filter((i) => i);
      initials = this.extractInitial(split[0]);
      if (split.length > 1) {
        initials = initials + this.extractInitial(split[1]);
      }
    }
    if (first && second) {
      initials = this.extractInitial(first) + this.extractInitial(second);
    }
    return initials;
  }

  private extractInitial(value: any) {
    const emoji = value.match(ValidatorPattern.emoji);
    if (emoji) {
      return emoji;
    } else {
      return value.charAt(0).toUpperCase();
    }
  }
}
