import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isNil } from 'lodash';

import { LOGGER, Logger } from '../logging/logger.service';
import { ProfileType } from '../shared/models/profile.model';
import { AuthenticationService } from '../shared/services/authentication.service';
import { RegisterStoreService } from '../shared/services/register-store.service';
import { UserStoreService } from '../shared/services/user-store.service';
import { UtilService } from '../shared/services/util.service';
import { omit } from '../shared/utilities/utils';

@Injectable({ providedIn: 'root' })
export class GuardService {
  constructor(
    @Inject(LOGGER) private logger: Logger,
    private router: Router,
    private authenticationService: AuthenticationService,
    private registerStoreService: RegisterStoreService,
    private userStoreService: UserStoreService,
    private utilService: UtilService
  ) {}

  public isInitialized(): boolean {
    const tokenModel = this.authenticationService.getTokenModel();
    if (!tokenModel.initialized) {
      const model = omit(tokenModel, 'token', 'idToken', 'issued', 'expires');
      this.logger.debug('GuardService updateUrl ' + window.location.href, model);

      //Get the URL without query parameters.
      const url = window.location.href.split('?')[0];
      if (url.indexOf('/authorize') < 0) {
        this.authenticationService.updateUrl(window.location.href);
      }
      this.router.navigate(['/initialize']);
      return false;
    }
    return true;
  }

  profileTypeSelected() {
    const registrationStore = this.registerStoreService.getRegistrationStore();
    if (
      (isNil(registrationStore.profileType) || registrationStore.profileType === ProfileType.none) &&
      !this.registerStoreService.defaultProfileType()
    ) {
      this.router.navigate(['/profile-type']);
      return false;
    } else {
      this.registerStoreService.updateProfileType(ProfileType.adult);
    }
    return true;
  }

  isAdultProfile() {
    const currentUser = this.userStoreService.getUser();
    if (this.utilService.isMinor(currentUser.dob)) {
      return false;
    }
    return true;
  }
}
