<div class="box">
  <div class="live-prices-card">
    <h2>Live Price Purchases</h2>
    <div>
      <h4>Your Live Prices</h4>
      <h3>{{ (engagement$ | async)?.userPurchasedLivePrices }}</h3>
    </div>
    <div>
      <h4>Community Avg</h4>
      <h3>{{ (engagement$ | async)?.communityPurchasedLivePricesAverage }}</h3>
    </div>
  </div>
</div>
