import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { EngagementService } from '../../shared/services/engagement.service';
import { isDefined } from '../../shared/utilities/utils';
import { ImageName } from '../models/engagement.model';
import { ShareButton } from '../models/shareButton.model';
import { shareButtons } from './share-constants';

@Injectable()
export class ShareService {
  private shareButtons: ShareButton[];
  public desktopEngagementUrl$: Observable<string>;
  public desktopBlurredEngagementUrl$: Observable<string>;
  public mobileEngagementUrl$: Observable<string>;
  public mobileBlurredEngagementUrl$: Observable<string>;

  constructor(private platform: Platform, public engagementService: EngagementService) {
    this.shareButtons = shareButtons;
  }

  public getShareConfig(buttonType: string) {
    const shareButton = this.shareButtons.find((p) => p.buttonType === buttonType);
    return shareButton;
  }

  public async saveImage(platform: string, blurred: boolean) {
    if (platform === 'desktop') {
      if (blurred) {
        return this.saveDesktopBlurredImage();
      }
      return this.saveDesktopImage();
    }
    if (blurred) {
      return this.saveBlurredMobileImage();
    }
    return this.saveMobileImage();
  }

  public getShareImageURL(shareButton: ShareButton, url: string) {
    let sharerLink = shareButton.share.desktop;
    if (this.platform.IOS && shareButton.share.ios) {
      sharerLink = shareButton.share.ios;
    } else if (this.platform.ANDROID && shareButton.share.android) {
      sharerLink = shareButton.share.android;
    }

    const finalUrl = sharerLink + this.serializeParams(shareButton, url);
    return finalUrl;
  }

  private async saveDesktopImage(): Promise<Observable<string>> {
    if (isDefined(this.desktopEngagementUrl$)) {
      return this.desktopEngagementUrl$;
    }

    const imageData = await this.formatImage();

    this.desktopEngagementUrl$ = this.saveImageAndGetUrl(imageData, 'desktop');
    return this.desktopEngagementUrl$;
  }

  private async saveDesktopBlurredImage(): Promise<Observable<string>> {
    if (isDefined(this.desktopBlurredEngagementUrl$)) {
      return this.desktopBlurredEngagementUrl$;
    }

    const imageData = await this.formatImage();

    this.desktopBlurredEngagementUrl$ = this.saveImageAndGetUrl(imageData, 'desktopblurred');
    return this.desktopBlurredEngagementUrl$;
  }

  private async saveMobileImage(): Promise<Observable<string>> {
    if (isDefined(this.mobileEngagementUrl$)) {
      return this.mobileEngagementUrl$;
    }

    const imageData = await this.formatImage();

    this.mobileEngagementUrl$ = this.saveImageAndGetUrl(imageData, 'mobile');
    return this.mobileEngagementUrl$;
  }

  private async saveBlurredMobileImage(): Promise<Observable<string>> {
    if (isDefined(this.mobileBlurredEngagementUrl$)) {
      return this.mobileBlurredEngagementUrl$;
    }

    const imageData = await this.formatImage();

    this.mobileBlurredEngagementUrl$ = this.saveImageAndGetUrl(imageData, 'mobileblurred');
    return this.mobileBlurredEngagementUrl$;
  }

  private async formatImage() {
    var element = document.querySelector('#engagementimagecanvas') as HTMLElement;

    const canvas = await html2canvas(element, {
      width: element.clientWidth + 10,
      height: element.clientHeight + 10,
      onclone: function (doc) {
        var clonedElement = doc.querySelector('#engagementimagecanvas') as HTMLElement;
        clonedElement.style.padding = '5px';

        updateRedLinearGradient('#activity-gradient', doc);
        updateRedLinearGradient('#holdings-gradient', doc);
        updateRedLinearGradient('#referral-gradient', doc);

        removeElement('#activity-chevron', doc);
        removeElement('#funded-chevron', doc);
        removeElement('#holdings-chevron', doc);
      },
      scale: 2
    });

    const imageData = canvas.toDataURL('image/png', 1);
    return imageData;

    function updateRedLinearGradient(elementName: string, doc: Document) {
      var element = doc.querySelector(elementName) as HTMLElement;
      if (!isNil(element)) {
        element.style.background = 'linear-gradient(180.37deg, #e5043e 4%, rgba(229, 4, 62, 0.5) 190%)';
      }
    }

    function removeElement(elementName: string, doc: Document) {
      var element = doc.querySelector(elementName) as HTMLElement;
      if (!isNil(element)) {
        element.style.display = 'none';
      }
    }
  }

  private saveImageAndGetUrl(imageData: string, identifier: string): Observable<string> {
    return this.engagementService.saveImage(imageData, identifier).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
      map((imageName: ImageName) => {
        return imageName.name;
      })
    );
  }

  private serializeParams(shareButton: ShareButton, url: string): string {
    return Object.entries(shareButton.params)
      .map(([key, value]) => {
        switch (key) {
          case 'url':
            var fullUrl = this.getURLtext(shareButton, url);
            return `${value}=${encodeURIComponent(fullUrl)}`;
          case 'description':
            return `${value}=${encodeURIComponent('Check out my monthly activities on EasyEquities: ')}`;
          case 'title':
            return `${value}=${encodeURIComponent('EasyEquities')}`;
        }
      })
      .filter((urlParam) => urlParam !== '')
      .join('&');
  }

  private getURLtext(shareButton: ShareButton, url: string) {
    return shareButton.urlText ? `${shareButton.urlText}\r\n${url}` : url;
  }
}
