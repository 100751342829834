import { Component, ViewEncapsulation } from '@angular/core';
import { CalendarComponent } from './calendar.component';

@Component({
  selector: 'eid-calendar-desktop',
  templateUrl: './calendar.desktop.component.html',
  styleUrls: ['./calendar.desktop.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarDesktopComponent extends CalendarComponent {}
