<div class="box">
  <div class="root">
    <div class="left">
      <div class="left-header">
        <div class="left-header-img">
          <img src="../../../assets/icons/engagement/deposit-box-icon.svg" class="funded-account-bg-img" />
        </div>
        <div class="left-header-content">
          <mat-icon class="wallet-logo-md" svgIcon="wallet-md"></mat-icon>
          <h2 class="left-title">Monthly Deposits</h2>
        </div>
      </div>
      <div class="left-body-md">
        <div>
          <h3>YOUR STATS</h3>
          <h2>{{ userDepositCount }}</h2>
          <h4>Deposits</h4>
        </div>
        <div>
          <h3>COMMUNITY AVG</h3>

          <h2>{{ communityDepositAverage }}</h2>
          <h4>Deposits</h4>
        </div>
      </div>
    </div>
  </div>
</div>
