export enum FundSource {
  INHERITANCE = 'Inheritance',
  SALARY = 'Salary',
  SAVINGS = 'Savings'
}
export enum VerificationStatus {
  UNVERIFIED = 'unverified',
  PENDING = 'pending',
  VERIFIED = 'verified',
  FAILED = 'failed',
  NONE = 'none'
}
export interface Deposit {
  account: string;
  description: string;
  amount: number;
  date: Date;
  source: FundSource;
  verificationDocument?: string;
  verificationStatus: VerificationStatus;
}
