import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ContractingEntity } from '../registration/models/contracting.model';
import { ProductType } from '../registration/models/product.model';
import { CurrentUser } from '../shared/models/current-user.model';
import { AddCurrentUser, UpdateCurrentUser, UpdateProductType } from './user.actions';

const userDefaults: CurrentUser = {
  firstName: '',
  lastName: '',
  username: '',
  userId: '',
  dateCreated: '',
  contractingEntity: ContractingEntity.unknown,
  dob: null,
  email: '',
  sub: '',
  subsystemId: '',
  tenantId: '',
  productType: ProductType.none,
  countryOfCitizenshipId: 0,
  countryOfResidenceId: 0,
  identificationNumber: ''
};

@State<CurrentUser>({
  name: 'currentuser',
  defaults: userDefaults
})
@Injectable()
export class UserState {
  @Selector()
  static currentUser(state: CurrentUser) {
    return state;
  }

  @Selector()
  static contractingEntity(state: CurrentUser) {
    return state.contractingEntity;
  }

  @Selector()
  static tenantId(state: CurrentUser) {
    return state.tenantId;
  }

  @Selector()
  static isProfileComplete(state: CurrentUser) {
    if (state.sub !== '' && state.userId !== '') {
      return true;
    }
    return false;
  }

  @Selector()
  static productType(state: CurrentUser) {
    return state.productType;
  }

  @Selector()
  static countryOfCitizenshipId(state: CurrentUser) {
    return state.countryOfCitizenshipId;
  }

  @Selector()
  static countryOfResidenceId(state: CurrentUser) {
    return state.countryOfResidenceId;
  }

  @Selector()
  static identificationNumber(state: CurrentUser) {
    return state.identificationNumber;
  }

  @Action(AddCurrentUser)
  registration({ patchState }: StateContext<CurrentUser>) {
    patchState({
      firstName: '',
      lastName: '',
      username: '',
      userId: '',
      dateCreated: '',
      contractingEntity: ContractingEntity.unknown,
      dob: null,
      email: '',
      sub: '',
      subsystemId: '',
      tenantId: '',
      productType: ProductType.none,
      countryOfCitizenshipId: 0,
      countryOfResidenceId: 0,
      identificationNumber: ''
    });
  }

  @Action(UpdateCurrentUser)
  updateUser({ patchState }: StateContext<CurrentUser>, { payload }: UpdateCurrentUser) {
    patchState({
      firstName: payload.firstName,
      lastName: payload.lastName,
      username: payload.username,
      userId: payload.userId,
      dateCreated: payload.dateCreated,
      contractingEntity: payload.contractingEntity,
      dob: payload.dob,
      email: payload.email,
      sub: payload.sub,
      subsystemId: payload.subsystemId,
      tenantId: payload.tenantId,
      productType: payload.productType,
      countryOfCitizenshipId: payload.countryOfCitizenshipId,
      countryOfResidenceId: payload.countryOfResidenceId,
      identificationNumber: payload.identificationNumber
    });
  }

  @Action(UpdateProductType)
  updateProductType({ patchState }: StateContext<CurrentUser>, { payload }: UpdateProductType) {
    patchState({
      productType: payload
    });
  }
}
