export enum Theme {
  satrix = 'satrix',
  telkom = 'telkom',
  easyid = 'easyid',
  gcash = 'gcash'
}
export enum LabelPlatform {
  satrix = 'SatrixNOW',
  telkom = 'Telkom',
  easyid = 'EasyEquities'
}
