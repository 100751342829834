<p class="share-text">Share:</p>

<eid-share-button
  [buttonType]="'facebook'"
  [svgIcon]="'facebook-sharing'"
  platform="desktop"
  [blurred]="blurred"
  (busy)="busy($event)"
></eid-share-button>

<eid-share-button
  [buttonType]="'twitter'"
  [svgIcon]="'twitter-sharing'"
  platform="desktop"
  [blurred]="blurred"
  (busy)="busy($event)"
></eid-share-button>

<eid-share-button
  [buttonType]="'whatsapp'"
  [svgIcon]="'whatsapp-sharing'"
  platform="desktop"
  [blurred]="blurred"
  (busy)="busy($event)"
></eid-share-button>

<eid-share-button
  *ngIf="showMore"
  [buttonType]="'linkedin'"
  [svgIcon]="'linkedin-sharing'"
  platform="desktop"
  [blurred]="blurred"
  (busy)="busy($event)"
></eid-share-button>

<eid-share-button
  *ngIf="showMore"
  [buttonType]="'copy'"
  [svgIcon]="'copy-sharing'"
  platform="desktop"
  [blurred]="blurred"
  (busy)="busy($event)"
></eid-share-button>

<eid-share-button
  *ngIf="showMore"
  [buttonType]="'email'"
  [svgIcon]="'email-sharing'"
  platform="desktop"
  [blurred]="blurred"
  (busy)="busy($event)"
></eid-share-button>

<eid-share-button
  *ngIf="!showMore"
  [buttonType]="'more'"
  [svgIcon]="'more-sharing'"
  (showMore)="more()"
></eid-share-button>

<eid-share-button
  *ngIf="showMore"
  [buttonType]="'less'"
  [svgIcon]="'less-sharing'"
  (showLess)="less()"
></eid-share-button>
