export enum IdTypeEnum {
  drivingLicense = 'DRIVING_LICENSE',
  idCard = 'ID_CARD',
  passport = 'PASSPORT',
  birthCertificate = 'BIRTH_CERTIFICATE'
}

export enum IdentificationTypeEnum {
  saIdNumber = 1,
  birthCertificate = 10
}

export enum CountryEnum {
  southAfrica = 'ZAF',
  australia = 'AUS',
  unitedstates = 'USA',
  europe = 'EUR',
  unitedkingdom = 'GBP'
}

export enum Country2Enum {
  southAfrica = 'ZA',
  australia = 'AU',
  unitedstates = 'US',
  malaysia = 'MY',
  philippines = 'PH'
}

export enum CountryIdEnum {
  southAfrica = 710,
  australia = 36,
  unitedstates = 840,
  namibia = 516,
  lesotho = 426,
  eswatini = 748,
  philippines = 608,
  malaysia = 458
}

export enum PhoneCodeEnum {
  southAfrica = '+27'
}

export enum CurrencyEnum {
  southAfrica = 'ZAR',
  australia = 'AUD',
  unitedstates = 'USD',
  europe = 'EUR',
  unitedkingdom = 'GBP'
}

export enum TenantIdEnum {
  easyEquities = '1',
  satrix = '2',
  gcash = '8'
}

export enum ComplianceFormIdEnum {
  crs = 700,
  ew8 = 177
}

export enum USTinType {
  ssn = 1,
  itin = 2
}

export enum TaxTypeIdEnum {
  ssn = 3,
  itin = 5,
  notAvailable = 100,
  notIssued = 101
}

export enum TaxTypeEnum {
  southAfrica = 'SA',
  australia = 'AUS',
  unitedstates = 'US',
  philippines = 'PH',
  other = 'Other',
  notAvailable = 'Not Available',
  notIssued = 'Not Issued'
}

export enum VerificationType {
  none = 'none',
  idverification = 'idverification',
  onfido = 'onfido',
  error = 'error'
}

export enum UpdateType {
  none = 'none',
  delete = 'delete',
  update = 'update'
}
