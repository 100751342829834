<div class="thrive-card">
  <div class="thrive-bg">
    <img class="thrive-bg-img" src="../../../assets/icons/engagement/Thrive-bg.svg" />
  </div>

  <div class="thrive-card-left">
    <mat-icon class="thrive-icon" svgIcon="dash-thrive"></mat-icon>
    <h2 class="thrive-card-title">{{ date | date: 'LLL' }} '{{ date | date: 'yy' }} <br />on Thrive</h2>
  </div>
  <div class="thrive-card-right">
    <div>
      <h3 class="thrive-card-right-h3">THRIVE LEVEL</h3>

      <h2 class="thrive-card-right-h2">
        {{ (engagement$ | async)?.thriveLevel }}
      </h2>
      <mat-progress-bar class="thrive-gauge" role="progressbar" value="{{ (engagement$ | async)?.thriveLevel }}">
      </mat-progress-bar>
    </div>
    <div>
      <h3 class="thrive-card-right-h3">BROKERAGE SAVED</h3>

      <h2 class="thrive-card-right-h2">R {{ (engagement$ | async)?.brokerageSavedByThrive }}</h2>
    </div>
  </div>
</div>
