import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Select } from '@ngxs/store';

import { Observable } from 'rxjs';
import { ContractingEntity } from '../../../../registration/models/contracting.model';
import { ProductDetailModel } from '../../../../registration/models/product.model';
import { Theme } from '../../../../registration/models/theme.model';
import { RegistrationState } from '../../../../store/registration.state';
import { UserState } from '../../../../store/user.state';
import { UrlBundle } from '../../../models/urlBundle';
import { ProductService } from '../../../services/product.service';
import { RegisterStoreService } from '../../../services/register-store.service';
import { UrlProviderService } from '../../../services/url.provider';

@Component({
  selector: 'eid-registration-error',
  templateUrl: './registration-error.component.html',
  styleUrls: ['./registration-error.component.scss']
})
export class RegistrationErrorComponent {
  @Select(RegistrationState.contractingEntity) public regContractingEntity$: Observable<ContractingEntity>;
  @Select(UserState.contractingEntity) public userContractingEntity$: Observable<ContractingEntity>;
  public themes = Theme;
  public productDetail: ProductDetailModel;
  public contractingEntity$: Observable<ContractingEntity>;
  public entities = ContractingEntity;

  public urlBundle$: Observable<UrlBundle>;
  iconPath = '../../../assets/icons/';

  constructor(
    public urlProviderService: UrlProviderService,
    private location: Location,
    private registerStoreService: RegisterStoreService,
    private productService: ProductService
  ) {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
    const productType = this.registerStoreService.getProduct();
    this.productDetail = this.productService.getProductDetail(productType);
  }

  public back(): void {
    this.location.back();
  }
}
