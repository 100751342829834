import { ComponentRef, Directive, ElementRef, Input, OnDestroy, ViewContainerRef, inject } from '@angular/core';

import { LoadingSpinnerComponent } from './loading-spinner.component';

@Directive({
  selector: '[eidLoadingSpinner]'
})
export class LoadingSpinnerDirective implements OnDestroy {
  private elementRef = inject(ElementRef);
  private viewContainerRef = inject(ViewContainerRef);

  private element = this.elementRef.nativeElement;
  private componentRef!: ComponentRef<LoadingSpinnerComponent>;

  @Input() set loading(value: boolean) {
    if (value) this.attachLoadingIndicator();
    else this.detachLoadingIndicator();
  }

  ngOnDestroy(): void {
    this.componentRef?.destroy();
  }

  private attachLoadingIndicator() {
    this.componentRef = this.viewContainerRef.createComponent(LoadingSpinnerComponent);

    const spinnerElement = this.componentRef.location.nativeElement;
    this.element.insertBefore(spinnerElement, this.element.firstChild);

    // Force the host element to be positioned,
    // see https://developer.mozilla.org/en-US/docs/Web/CSS/position
    if (this.element.style.position === '' || this.element.style.position === 'static') {
      this.element.style.position = 'relative';
    }
  }

  private detachLoadingIndicator() {
    this.componentRef?.destroy();
  }
}
