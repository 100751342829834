import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AddressModel } from '../../profile/models/address.model';
import { AddBankAccount, BankAccount } from '../../profile/models/bank.model';
import { Deposit, FundSource, VerificationStatus } from '../../profile/models/deposit.model';
import { PersonalDetailModel } from '../../profile/models/personal-detail.model';
import { TaxAddModel, TaxDetail } from '../../profile/models/tax.model';
import { StaticCountryModel } from '../../registration/models/country.model';
import { CountryIdEnum } from '../constants/enums';
import { isDefined } from '../utilities/utils';
import { StaticDataService } from './static-data.service';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  constructor(private httpClient: HttpClient, private staticDataService: StaticDataService) {}

  public getPersonalDetails(): Observable<PersonalDetailModel> {
    return this.httpClient.get<PersonalDetailModel>('/api/user/personaldetail');
  }

  public updatePersonalDetails(personalUpdateModel): Observable<boolean> {
    return this.httpClient.put<boolean>('/api/user/personaldetail', personalUpdateModel);
  }

  public getAddress(): Observable<AddressModel> {
    return this.httpClient.get<AddressModel>('/api/user/address');
  }

  public addAddress(addressModel: AddressModel): Observable<AddressModel> {
    return this.httpClient.post<AddressModel>('/api/user/address', addressModel);
  }

  public updateAddress(addressModel: AddressModel): Observable<AddressModel> {
    return this.httpClient.put<AddressModel>('/api/user/address', addressModel);
  }

  public getTax(): Observable<TaxDetail[]> {
    return this.httpClient.get<TaxDetail[]>('/api/user/tax');
  }

  public addTax(taxAddModel: TaxAddModel): Observable<TaxDetail> {
    return this.httpClient.post<TaxDetail>('/api/user/tax', taxAddModel);
  }

  public updateTax(taxDetail: TaxDetail): Observable<TaxDetail> {
    return this.httpClient.put<TaxDetail>('/api/user/tax', taxDetail);
  }

  public deleteTax(taxResidenceId: number): Observable<boolean> {
    return this.httpClient.delete<boolean>('/api/user/tax/' + taxResidenceId);
  }

  public getBankAccounts(): Observable<BankAccount[]> {
    return combineLatest([this.staticDataService.getCountries(), this.getBankAccountsRequest()]).pipe(
      map(([countries, accounts]) => {
        return accounts.map((a) => ({
          ...a,
          isoCurrencyCode: a.isoCurrencyCode,
          bankCountry: this.getBankCountry(countries, a.bankLocationCountryId)
        }));
      })
    );
  }

  public getBankAccountsRequest(): Observable<BankAccount[]> {
    return this.httpClient.get<BankAccount[]>('/api/user/bankdetails');
  }

  public addBankAccount(account: AddBankAccount): Observable<boolean> {
    return this.httpClient.post<boolean>('/api/user/bankdetails', account);
  }

  public updateBankAccount(account: AddBankAccount): Observable<boolean> {
    return this.httpClient.put<boolean>('/api/user/bankdetails', account);
  }

  public deleteBankAccount(bankAccountId: number): Observable<boolean> {
    return this.httpClient.delete<boolean>('/api/user/bankdetails/' + bankAccountId);
  }

  private getBankCountry(countryModel: StaticCountryModel, bankLocationCountryId) {
    if (!environment.openEasyEnabled) {
      const saCountry = countryModel.countries.find((c) => c.countryId === CountryIdEnum.southAfrica);
      return saCountry;
    }
    const country = countryModel.countries.find((c) => c.countryId === bankLocationCountryId);
    if (isDefined(country)) {
      return country;
    }
    return null;
  }

  public getDeposits(): Observable<Deposit[]> {
    return of([
      {
        account: 'EasyEquities ZAR',
        description: 'Debit Order Deposit',
        amount: 250.0,
        date: new Date('03/08/2020'),
        source: FundSource.INHERITANCE,
        verificationDocument: undefined,
        verificationStatus: VerificationStatus.UNVERIFIED
      },
      {
        account: 'EasyEquities ZAR',
        description: 'Debit Order Deposit',
        amount: 250.0,
        date: new Date('03/08/2020'),
        source: FundSource.INHERITANCE,
        verificationDocument: 'Scan20200918.pdf',
        verificationStatus: VerificationStatus.PENDING
      },
      {
        account: 'EasyEquities ZAR',
        description: 'Debit Order Deposit',
        amount: 250.0,
        date: new Date('03/08/2020'),
        source: FundSource.INHERITANCE,
        verificationDocument: undefined,
        verificationStatus: VerificationStatus.FAILED
      },
      {
        account: 'EasyEquities ZAR',
        description: 'Debit Order Deposit',
        amount: 250.0,
        date: new Date('03/08/2020'),
        source: FundSource.SALARY,
        verificationStatus: VerificationStatus.NONE
      },
      {
        account: 'EasyEquities ZAR',
        description: 'Debit Order Deposit',
        amount: 250.0,
        date: new Date('03/08/2020'),
        source: FundSource.SALARY,
        verificationStatus: VerificationStatus.NONE
      }
    ]);
  }
}
