<!-- Dear future me => this is the code copied from EE to fit in with their footer!!! -->
<div class="footer">
  <div class="icon-container">
    <div class="footer-icon">
      <a class="footer-icon-gs" [href]="(urlBundle$ | async)?.portfolio">
        <img alt="View Portfolio" class="mb-4" id="portfolio" src="assets/icons/menu-Portfolio.svg" />
        <label for="portfolio">Portfolio</label>
      </a>
    </div>
    <div class="footer-icon">
      <a class="footer-icon-gs" [href]="(urlBundle$ | async)?.eeinvest">
        <img alt="Invest Now" id="invest" class="mb-4" src="assets/icons/menu-Invest.svg" />
        <label for="invest">Invest</label>
      </a>
    </div>
    <div class="footer-icon" *ngIf="isChallenge()">
      <a class="footer-icon-gs" [routerLink]="['/game/challenge']" [ngClass]="{ active: isChallenge() }">
        <img alt="Challenge" id="challenge" class="mb-4" src="assets/icons/menu-Game-active.svg" />
        <label for="challenge">Challenge</label>
      </a>
    </div>
    <div class="footer-icon" *ngIf="!isChallenge()">
      <a class="footer-icon-gs" [routerLink]="['/game/challenge']">
        <img alt="Challenge" id="challenge" class="mb-4" src="assets/icons/menu-Game.svg" />
        <label for="challenge">Challenge</label>
      </a>
    </div>

    <div class="footer-icon">
      <a class="footer-icon-gs" [href]="(urlBundle$ | async)?.watchlist">
        <img alt="Watchlist" id="watchlist" class="mb-4" src="assets/icons/menu-Watchlist.svg" />
        <label for="watchlist" class="active">Watchlist</label>
      </a>
    </div>
    <div class="footer-icon">
      <a class="footer-icon-gs" [href]="(urlBundle$ | async)?.notify">
        <img alt="View Notifications" id="notification" class="mb-4" src="assets/icons/menu-notifications.svg" />
        <label for="notification">Notify</label>
      </a>
    </div>
  </div>
</div>
