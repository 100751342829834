<div class="container">
  <div class="row row--justify-space-between row--align-center">
    <div class="logo">
      <div class="logo__app"></div>
      <div
        *ngIf="(productType$ | async) !== productTypes.none && (productType$ | async) !== productTypes.satrix"
        class="product-logo"
      ></div>
    </div>
    <div class="eid-navigation">
      <a
        *ngIf="isSatrix$ | async"
        [routerLink]="['/profile-type']"
        [routerLinkActive]="'active'"
        class="btn-solid btn-solid--primary"
        >Create your SatrixNOW Account</a
      >
      <a *ngIf="(isSatrix$ | async) !== true" [routerLink]="['/profile-type']" [routerLinkActive]="'active'"
        >Create your EasyID</a
      >
      <button *ngIf="(isSatrix$ | async) !== true" class="btn-solid btn-solid--primary" (click)="login()">Login</button>
      <a *ngIf="isSatrix$ | async" [href]="(urlBundle$ | async)?.login" class="btn--satrix-login">Login</a>
    </div>
  </div>
</div>
