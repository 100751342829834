<button class="eid-current-user" [matMenuTriggerFor]="menu">
  <eid-avatar [first]="(currentUser$ | async)?.firstName" [second]="(currentUser$ | async)?.lastName"></eid-avatar>
  <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #menu="matMenu" class="eid-current-user-panel">
  <eid-user-details [user]="currentUser$ | async"></eid-user-details>

  <div *ngIf="minorEnabled && (currentUser$ | async) && !isMinor((currentUser$ | async).dob)" class="account-details">
    <div class="actions">
      <button (click)="minor()" mat-menu-item>
        <mat-icon>person_add</mat-icon>
        Add an account for my {{ minorDescription | lowercase }}
      </button>
    </div>
  </div>

  <button
    *ngIf="(currentUser$ | async)?.productType === productTypes.satrix"
    class="account-function"
    mat-button
    (click)="back()"
  >
    Back to SatrixNOW
  </button>

  <!-- Logout -->
  <button
    *ngIf="(currentUser$ | async)?.productType !== productTypes.satrix"
    class="account-function"
    mat-button
    (click)="logout()"
  >
    Log out
  </button>
</mat-menu>
