<div class="referral-card">
  <div class="referral-card-left">
    <div class="referral-card-left-header">
      <mat-icon class="referral-icon-md" svgIcon="referrals"></mat-icon>
      <h2 class="referral-title">Successful Referrals</h2>
    </div>
    <div class="referral-card-left-body">
      <div>
        <h2>YOUR STATS</h2>

        <h3>
          <span>{{ (engagement$ | async)?.userReferralsCount }}</span
          >&#160;Referrals
        </h3>
      </div>

      <div>
        <h2>COMMUNITY AVG</h2>

        <h3>
          <span>{{ (engagement$ | async)?.communityReferralsCountAverage }}</span
          >&#160;Referrals
        </h3>
      </div>
    </div>

    <mat-icon svgIcon="referrals-box-icon" class="referral-left-floating-icon"></mat-icon>
  </div>

  <div class="referral-card-right">
    <div class="referral-card-right-top" id="referral-gradient">
      <div>
        <h3 class="referral-card-right-top-left-h3">EasyMoney you earned through your referrals</h3>
      </div>

      <div>
        <h2 class="referral-card-right-top-h2">R {{ (engagement$ | async)?.easymoneyEarnedThroughReferrals }}</h2>

        <h3 class="referral-card-right-top-h3">EasyMoney</h3>
      </div>
    </div>

    <div class="referral-card-right-bottom">
      <div>
        <h3>Your savings through EasyMoney</h3>
      </div>

      <div>
        <h2>{{ (engagement$ | async)?.savingsThroughEasymoney }}</h2>
        <h3>Savings</h3>
      </div>

      <mat-icon svgIcon="referrals-box-icon-sm"></mat-icon>
    </div>
  </div>
</div>
