import { Component, HostBinding, Input } from '@angular/core';

import { LinkedAccount } from '../../models/account.model';

@Component({
  selector: 'eid-linked-account',
  templateUrl: './linked-account.component.html',
  styleUrls: ['./linked-account.component.scss']
})
export class LinkedAccountComponent {
  @HostBinding('attr.class') private layoutCls = 'eid-linked-account';
  @Input() public account: LinkedAccount;

  constructor() {}
}

