import { Component } from '@angular/core';
import { DepositsValueComponent } from './deposits-value.component';

@Component({
  selector: 'eid-deposits-value-desktop',
  templateUrl: './deposits-value.desktop.component.html',
  styleUrls: ['./deposits-value.desktop.component.scss']
})
export class DepositsValueDesktopComponent extends DepositsValueComponent {}

