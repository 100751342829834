<div class="intro-box">
  <div class="column column--image">
    <img src="assets/icons/intro/intro-five.png" alt="intro-five" />
  </div>
  <div class="column column--content">
    <div class="close-btn">
      <mat-icon svgIcon="close" class="close-icon" (click)="close()"></mat-icon>
    </div>
    <div class="copy-column">
      <div class="copy">
        <h2>Safety first, FOMO later</h2>
        <p>Rest assured, your investments are protected with top-notch security measures and regulatory compliance.</p>
      </div>
      <div class="intro-step">
        <button class="btn-solid btn-solid--primary intro-start" (mousedown)="close()">Get Started</button>
      </div>
    </div>
  </div>
</div>
