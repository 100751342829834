<ul>
  <ng-container *ngTemplateOutlet="recursiveMenu; context: { $implicit: menu$ | async }"></ng-container>
</ul>

<ng-template #recursiveMenu let-menu>
  <li *ngFor="let item of menu">
    <div class="item-container">
      <!-- item with router link -->
      <ng-container *ngIf="item.routerLink && !item.url && !item.action">
        <a
          [routerLink]="item.routerLink"
          [routerLinkActive]="'active'"
          class="nav-item nav-item--{{ item.id }}"
          (click)="closeNavigation()"
        >
          <mat-icon *ngIf="item.icon" mat-list-icon [svgIcon]="item.icon"></mat-icon>
          <span *ngIf="item.label">{{ item.label }}</span>
        </a>
      </ng-container>
      <!-- item with url -->
      <ng-container *ngIf="item.url && !item.routerLink && !item.action">
        <a
          *ngIf="item.newTab === false"
          [href]="item.url"
          class="nav-item nav-item--{{ item.id }}"
          (click)="closeNavigation()"
        >
          <mat-icon *ngIf="item.icon" mat-list-icon [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.label }}</span>
        </a>
        <a
          *ngIf="item.newTab !== false"
          [href]="item.url"
          target="_blank"
          class="nav-item nav-item--{{ item.id }}"
          (click)="closeNavigation()"
        >
          <mat-icon *ngIf="item.icon" mat-list-icon [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.label }}</span>
        </a>
      </ng-container>
      <!-- item with function call -->
      <ng-container *ngIf="!item.url && !item.routerLink && item.action">
        <a class="nav-item nav-item--{{ item.id }}" (click)="callAction(item)">
          <mat-icon *ngIf="item.icon" mat-list-icon [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.label }}</span>
        </a>
      </ng-container>
      <ng-container *ngIf="item.children">
        <a class="nav-item nav-item--{{ item.id }}" (click)="item.selected = !item.selected">
          <mat-icon *ngIf="item.icon" mat-list-icon [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.label }}</span>
        </a>
      </ng-container>
      <button mat-icon-button *ngIf="item.children" (click)="item.selected = !item.selected">
        <mat-icon *ngIf="!item.selected">expand_more</mat-icon>
        <mat-icon *ngIf="item.selected">expand_less</mat-icon>
      </button>
    </div>
    <ul *ngIf="item.selected">
      <ng-container *ngTemplateOutlet="recursiveMenu; context: { $implicit: item.children }"></ng-container>
    </ul>
  </li>
</ng-template>
