<div class="root">
  <div class="account-container">
    <div *ngFor="let account of (engagement$ | async)?.fundedAccount" [ngSwitch]="account.name" class="account-box">
      <div class="account-box-left">
        <img
          src="../../../assets/icons/engagement/currencies/{{ account.name }}.svg"
          alt="{{ account.name }}"
          class="account-logo"
        />
        <h2 *ngSwitchCase="'ZAR'" class="account-name">South African Rand</h2>
        <h2 *ngSwitchCase="'USD'" class="account-name">USD</h2>
        <h2 *ngSwitchCase="'AUS'" class="account-name">Australian Dollar</h2>
        <h2 *ngSwitchCase="'EUR'" class="account-name">Euro</h2>
        <h2 *ngSwitchCase="'GBP'" class="account-name">Pound</h2>
        <h2 *ngSwitchCase="'RA'" class="account-name">Retirement Annuity</h2>
        <h2 *ngSwitchCase="'TFSA'" class="account-name">Tax Free Savings</h2>
      </div>

      <div class="account-box-right">
        <mat-icon *ngIf="account.funded === true" svgIcon="icon-check"></mat-icon>
        <h2 *ngIf="account.funded === true; else notFundedAccountStatus" class="account-status">Funded</h2>
        <ng-template #notFundedAccountStatus>
          <h2 class="account-status">Not Funded</h2>
        </ng-template>
      </div>
    </div>
  </div>
</div>
