import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { EngagementService } from '../../shared/services/engagement.service';
import { EngagementModel } from '../models/engagement.model';

@Component({
  template: `
    <eid-funded-accounts-desktop *eidBreakpoint="'desktop'"></eid-funded-accounts-desktop>
    <eid-funded-accounts-mobile *eidBreakpoint="'mobile'"></eid-funded-accounts-mobile>
  `
})
export class FundedAccountsContainerComponent {}

@Component({
  template: ''
})
export class FundedAccountsComponent {
  public engagement$: Observable<EngagementModel>;

  constructor(protected engagementService: EngagementService) {
    this.engagement$ = this.engagementService.getEngagementData();
  }
}
