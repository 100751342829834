import { Component, Injector } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EngagementService } from '../../shared/services/engagement.service';
import { EngagementModel } from '../models/engagement.model';

@Component({
  template: `
    <eid-account-activity-desktop *eidBreakpoint="'desktop'"></eid-account-activity-desktop>
    <eid-account-activity-mobile *eidBreakpoint="'mobile'"></eid-account-activity-mobile>
  `
})
export class AccountActivityContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class AccountActivityComponent {
  public engagement$: Observable<EngagementModel>;

  public activeDaysUp: boolean;
  public loginsUp: boolean;

  constructor(protected injector: Injector) {
    const engagementService = injector.get(EngagementService);

    this.engagement$ = engagementService.getEngagementData();

    this.engagement$
      .pipe(
        map((data) => {
          this.activeDaysUp = data?.userActiveDaysCount >= data?.userPreviousMonthActiveDaysCount;
          this.loginsUp = data?.userLoginCount >= data?.userPreviousMonthLoginCount;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
