import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class UtilService {
  public isMinor(dateOfBirth) {
    const age = this.getAge(dateOfBirth);
    if (age < 18) {
      return true;
    }
    return false;
  }

  public getAge(dateOfBirth) {
    var date = new Date(dateOfBirth);
    return moment().diff(date, 'years');
  }

  public getDOBFromID(idNumber) {
    idNumber = idNumber?.trim();

    if (!isNil(idNumber) && idNumber.length > 5) {
      const idYear = idNumber.substring(0, 2);
      const idMonth = idNumber.substring(2, 4);
      const idDay = idNumber.substring(4, 6);

      const cutoff = new Date().getFullYear() - 2000;
      const prefixyear = idYear > cutoff.toString() ? '19' : '20';

      // Check if the first 6 chars of the id is actually a valid dob.
      const isIdNumberValidDate = this.validDate(prefixyear, idYear, idMonth, idDay);

      if (isIdNumberValidDate) {
        const newDob = new Date(Date.UTC(Number(prefixyear + idYear), Number(idMonth) - 1, Number(idDay)));
        return newDob;
      }
      return null;
    }
  }

  private validDate(prefix, year, month, day): boolean {
    const newDob = new Date(Date.UTC(Number(prefix + year), Number(month) - 1, Number(day)));
    const testValidNewDOB = this.getYearMonthDay(newDob);
    if (testValidNewDOB.year === year && testValidNewDOB.month === month && testValidNewDOB.day === day) {
      return true;
    }
    return false;
  }

  private getYearMonthDay(thisDate: Date) {
    if (thisDate) {
      const year = thisDate.getUTCFullYear().toString().substring(2, 4);
      const month = (thisDate.getUTCMonth() + 1).toString().padStart(2, '0');
      const day = thisDate.getUTCDate().toString().padStart(2, '0');
      return { year, month, day };
    }
    return null;
  }
}
