<div class="intro-box">
  <div class="column column--image">
    <img src="assets/icons/intro/intro-one.png" alt="intro-one" />
  </div>
  <div class="column column--content">
    <div class="close-btn">
      <mat-icon svgIcon="close" class="close-icon" (click)="close()"></mat-icon>
    </div>
    <div class="copy-column">
      <div class="copy">
        <h2>Be the financial rockstar you were born to be!</h2>
        <p>
          EasyEquities empowers everyone to invest in local and global shares with ease and confidence, regardless of
          experience or financial status.
        </p>
      </div>
      <div class="intro-step">
        <eid-intro-stepper [active]="1" (stepSelected)="stepSelected($event)"></eid-intro-stepper>
      </div>
    </div>
  </div>
</div>
