import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CurrentUser } from '../../models/current-user.model';

@Component({
  selector: 'eid-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent {
  @Input() public user: CurrentUser;
  @Output() public closeUserDetail: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  public back(): void {
    this.closeUserDetail.emit(true);
  }
}
