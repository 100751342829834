import { Component, OnInit } from '@angular/core';

import { ProductType } from '../../registration/models/product.model';
import { IntroMobileComponent } from '../intro/intro/intro.mobile.component';
import { ProfileTypeComponent } from './profile-type.component';

@Component({
  selector: 'eid-profile-type-mobile',
  templateUrl: './profile-type.mobile.component.html',
  styleUrls: ['./profile-type.mobile.component.scss']
})
export class ProfileTypeMobileComponent extends ProfileTypeComponent implements OnInit {
  ngOnInit(): void {
    let productType = this.registerStoreService.getProduct();
    if (productType !== ProductType.satrix) {
      this.openModal();
    }
  }

  public openModal() {
    this.dialog.open(IntroMobileComponent, {
      maxWidth: '100vw',
      panelClass: 'custom-intro-mobile-overlay',
      hasBackdrop: false
    });
  }
}
