import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RegisterStoreService } from '../shared/services/register-store.service';

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  constructor(private registerStoreService: RegisterStoreService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/api/') > -1) {
      var mock = this.registerStoreService.getUseMockEE();
      if (mock === true) {
        const mockRequest = request.clone({
          headers: request.headers.set('UseMockEE', 'True')
        });
        return next.handle(mockRequest);
      }
    }
    return next.handle(request);
  }
}
