<div class="box">
  <div class="root">
    <div class="holdings-container">
      <div class="header" id="holdings-gradient">
        <div class="header-left">
          <div>
            <mat-icon svgIcon="holdings" class="header-icon-md"></mat-icon>
            <h2 class="header-title">Diversification</h2>
          </div>
        </div>
        <div class="header-right">
          <div>
            <h4>YOUR STATS</h4>
            <h3>{{ (engagement$ | async)?.userHoldingAccount }}&nbsp;<span>Holdings</span></h3>
          </div>

          <div>
            <h4>COMMUNITY AVG</h4>
            <h3>{{ (engagement$ | async)?.communityHoldingsAverage }}&nbsp;<span>Holdings</span></h3>
          </div>
        </div>
      </div>

      <div class="holdings-box">
        <div *ngFor="let holding of (engagement$ | async)?.userHoldings" class="holding-card">
          <img
            src="../../../assets/icons/engagement/{{ holding.name | lowercase }}.svg"
            alt="{{ holding.name }}"
            class="holding-icon"
          />

          <h3 class="holding-subtitle">
            {{ holding.name }}
          </h3>

          <h2 class="holding-title">
            {{ holding.holdings }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</div>
