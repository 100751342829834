import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceBetweenNumbers'
})
export class SpaceBetweenNumbersPipe implements PipeTransform {
  transform(value: any): string {
    if (value) {
      // Convert the value to a string and add spaces between numeric characters
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    }
    return '';
  }
}
