<div class="intro-box">
  <div class="column column--image"></div>
  <div class="column column--content">
    <div class="intro-text">
      <h2>Safety first, FOMO later</h2>
      <p>Rest assured, your investments are protected with top-notch security measures and regulatory compliance.</p>
    </div>

    <div class="intro-step">
      <button class="btn-solid btn-solid--primary intro-start" (mousedown)="close()">Get Started</button>
    </div>
  </div>
</div>
