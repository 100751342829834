<div class="section gcash-maintenance section--bg-light section--padded">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="page-title">
          <ng-container *ngIf="errorType === 'general'; else registrationError">
            <mat-icon svgIcon="error-icon"></mat-icon>
            <h3>Sorry, we’re unable to process your request.</h3>
            <p>
              Please return to the GCash application to re-try or, for us to review your account, please send us a
              ticket below or by going to GCash > Profile > Help.
            </p>
            <div class="page-end">
              <div class="error-btns">
                <button class="btn-solid btn-solid--dark-blue">
                  <a [href]="(urlBundle$ | async)?.newTicket">Submit a ticket</a>
                </button>
              </div>
              <div class="powered-by">
                <img src="../../../../../assets/logos/poweredByEE-inline.png" />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #registrationError>
  <mat-icon svgIcon="error-icon"></mat-icon>
  <div class="message__text">
    <h3>Sorry, an error occurred while trying to create your account</h3>
    <p>
      Don’t worry, there’s nothing you need to do! We’ll automatically retry your application. This usually takes
      <strong>up to 2 hours.</strong>
    </p>

    <p>
      We’ll email you once verification has been completed. If you would like us to review your account, please send us
      a ticket by going to GCash > Profile > Help.
    </p>
  </div>
  <div class="page-end mt-4">
    <a class="help-center-button" [href]="(urlBundle$ | async)?.newTicket">GStocks - Global on help center</a>
    <div class="powered-by">
      <img src="../../../../../assets/logos/poweredByEE-inline.png" />
    </div>
  </div>
</ng-template>
