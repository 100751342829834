<div class="box">
  <div class="columns">
    <div>
      <h3 class="value-h3">
        {{ date | date: 'LLL' }} '{{ date | date: 'yy' }} deposits<br />
        as a % of your total value
      </h3>
    </div>
    <div>
      <h2 class="value-h2">{{ depositPercentageToPortfolio }}%</h2>
    </div>
  </div>
</div>
