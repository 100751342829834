import { Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'eid-intro-stepper',
  templateUrl: './intro-stepper.component.html',
  styleUrls: ['./intro-stepper.component.scss']
})
export class IntroStepperComponent {
  @Input() public active: number;
  @Output() public stepSelected: Subject<number> = new Subject();

  public selectedStep(step: number) {
    this.stepSelected.next(step);
  }
}
