import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductDetailModel, ProductType } from '../../../../registration/models/product.model';
import { UrlBundle } from '../../../models/urlBundle';
import { ProductService } from '../../../services/product.service';
import { RegisterStoreService } from '../../../services/register-store.service';
import { UrlProviderService } from '../../../services/url.provider';

@Component({
  selector: 'eid-unsupported-partner-mobile',
  templateUrl: './unsupported-partner.mobile.component.html',
  styleUrls: ['./unsupported-partner.mobile.component.scss']
})
export class UnsupportedPartnerMobileComponent {
  public urlBundle$: Observable<UrlBundle>;
  public productType: ProductType;
  public productDetail: ProductDetailModel;

  constructor(
    private urlProviderService: UrlProviderService,
    private registerStoreService: RegisterStoreService,
    private productService: ProductService
  ) {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
    this.productType = this.registerStoreService.getProduct();
    this.productDetail = this.productService.getProductDetail(this.productType);
  }
}
