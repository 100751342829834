import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { EngagementModel, EngagementStateModel } from '../engagement/models/engagement.model';
import { UpdateEngagementData } from './engagement.actions';

const engagementDefaults: EngagementModel = {
  registrationDate: 0,
  portfolioValue: 0,
  changeInNav: 0,

  /* Account Activity */
  userLoginCount: 0,
  userPreviousMonthLoginCount: 0,
  userPreviousMonthActiveDaysCount: 0,
  userActiveDaysCount: 0,
  userActiveDays: [],
  communityActiveDays: 0,

  /* Thrive */
  thriveLevel: 0,
  brokerageSavedByThrive: 0,

  /* Holdings Information */
  userHoldingAccount: 0,
  communityHoldingsAverage: 0,
  userHoldings: [
    { name: 'Equities', holdings: 0 },
    { name: 'ETF', holdings: 0 },
    { name: 'Unit Trusts', holdings: 0 },
    { name: 'Bundles', holdings: 0 },
    { name: 'Crypto', holdings: 0 },
    { name: 'Property', holdings: 0 }
  ],

  /* Dividend Information */
  userMonthlyDividendCount: 0,
  userMonthlyDividendValue: 0,
  userPreviousMonthDividendValue: 0,

  userPreviousMonthDepositValue: 0,
  communityDividendAverage: 0,

  /* Deposit Information */
  userDepositCount: 0,
  communityDepositAverage: 0,

  /* Live Price Information */
  userPurchasedLivePrices: 0,
  communityPurchasedLivePricesAverage: 0,

  /* Funded Accounts */
  fundedAccount: [
    { name: 'ZAR', funded: false },
    { name: 'USD', funded: false },
    { name: 'AUS', funded: false },
    { name: 'EUR', funded: false },
    { name: 'GBP', funded: false },
    { name: 'RA', funded: false },
    { name: 'TFSA', funded: false }
  ],

  /* Refferals */
  userReferralsCount: 0,
  communityReferralsCountAverage: 0,

  /* EasyMoney */
  easymoneyEarnedThroughReferrals: 0,
  savingsThroughEasymoney: 0
};

const engagementStateDefaults: EngagementStateModel = {
  engagementModel: engagementDefaults,
  engagementDate: null
};

@State<EngagementStateModel>({
  name: 'engagement',
  defaults: engagementStateDefaults
})
@Injectable()
export class EngagementState {
  static getEngagementByDate(date: string): (state: EngagementStateModel) => EngagementModel {
    return createSelector([EngagementState], (state: EngagementStateModel) => {
      if (state.engagementDate === date) {
        return state.engagementModel;
      }
      return null;
    });
  }

  @Selector()
  static engagement(state: EngagementStateModel) {
    return state;
  }

  @Action(UpdateEngagementData)
  updateEngagementData({ patchState }: StateContext<EngagementStateModel>, { payload }: UpdateEngagementData) {
    if (payload === null || payload?.engagementModel === null) {
      patchState({
        engagementModel: engagementStateDefaults.engagementModel,
        engagementDate: engagementStateDefaults.engagementDate
      });
    } else {
      patchState({
        engagementModel: payload.engagementModel,
        engagementDate: payload.engagementDate
      });
    }
  }
}
