<div class="section">
  <div class="container">
    <div class="row row--justify-center">
      <div class="page-title">
        <h1>Select your profile type</h1>
        <p *ngIf="(isSatrix$ | async) !== true && (isLoggedIn$ | async) !== true" class="page-subtitle">
          Already have an EasyID?
          <a class="clickable" (click)="login()">Login</a>
        </p>
        <p *ngIf="(isSatrix$ | async) && (isLoggedIn$ | async) !== true" class="page-subtitle">
          Already have a SatrixNOW account?
          <a class="clickable" [href]="(urlBundle$ | async)?.login">Login</a>
        </p>
      </div>
    </div>
    <div class="row cards cards--col-3">
      <div class="card" (click)="selectProfileAdult()">
        <h3 class="card__title">For Individuals, 18+ years</h3>
        <div class="card__description">For investing in your name</div>
        <div class="card__image card__image--individual"></div>
      </div>

      <div class="card" *ngIf="(isSatrix$ | async) !== true" (click)="navigateToMinorRegistration()">
        <h3 class="card__title">Kids</h3>
        <div class="row row--center">
          <mat-icon svgIcon="sa-icon"></mat-icon>
          <div class="title">South African Citizens Only</div>
        </div>

        <div class="row row--center">
          <mat-icon svgIcon="eid-globe"></mat-icon>
          <div class="coming-soon-text">Other</div>
          <div class="coming-soon-tag coming-soon-tag--coming-soon">
            <mat-icon svgIcon="eid-calendar"></mat-icon>
            <span>Coming Soon</span>
          </div>
        </div>

        <div class="card__image card__image--kids"></div>
      </div>
      <!-- SATRIX KIDS CARD -->
      <div class="card card--satrix" *ngIf="isSatrix$ | async" (click)="navigateToMinorRegistration()">
        <h3 class="card__title">Kids</h3>
        <div class="card__description card__description--satrix">
          For investors under 18 years, with a Parent/Guardian as trustee.
        </div>
        <div class="row row--center">
          <mat-icon svgIcon="sa-icon"></mat-icon>
          <div class="title">South African Citizens Only</div>
        </div>

        <div class="row row--center">
          <mat-icon svgIcon="eid-globe"></mat-icon>
          <div class="coming-soon-text">Other</div>
          <div class="coming-soon-tag coming-soon-tag--coming-soon">
            <mat-icon svgIcon="eid-calendar"></mat-icon>
            <span>Coming Soon</span>
          </div>
        </div>

        <div class="card__image card__image--kids"></div>
      </div>

      <div class="card" (click)="navigateToBusinessEntity()">
        <h3 class="card__title">Company &amp; Legal Entities</h3>
        <div class="row row--center">
          <mat-icon svgIcon="sa-icon"></mat-icon>
          <div class="title">South African Citizens Only</div>
        </div>
        <div class="row row--center" [ngClass]="{ 'row--center--min-height': isSatrix$ | async }">
          <mat-icon svgIcon="eid-globe"></mat-icon>
          <div class="coming-soon-text">Other</div>
          <div class="coming-soon-tag coming-soon-tag--coming-soon">
            <mat-icon svgIcon="eid-calendar"></mat-icon>
            <span>Coming Soon</span>
          </div>
        </div>

        <div class="card__image card__image--company"></div>
      </div>
    </div>
  </div>
</div>
