export class AddressModel {
  physicalAddressId: number;
  unitNumber: string;
  complexName: string;
  streetNumber: string;
  streetName: string;
  suburb: string;
  city: string;
  code: string;
  province: string;
  provinceId: number;
  countryId: number;
  country: string;
}
