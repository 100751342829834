import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({ template: '' })
export class IntroComponent {
  public activeStep: number = 1;

  constructor(private dialog: MatDialog) {}

  public stepSelected(step: number) {
    this.activeStep = step;
  }

  public close() {
    this.dialog.closeAll();
  }
}
