import { Component } from '@angular/core';

import { EditAddressComponent } from './edit-address.component';

@Component({
  selector: 'eid-edit-compact-address-desktop',
  templateUrl: './edit-compact-address.desktop.component.html',
  styleUrls: ['./edit-compact-address.desktop.component.scss']
})
export class EditCompactAddressDesktopComponent extends EditAddressComponent {}
