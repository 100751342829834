import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  ViewChild,
  inject
} from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, share, startWith } from 'rxjs/operators';
import { isDefined } from '../../utilities/utils';

@Component({
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingSpinnerComponent implements AfterViewInit, OnDestroy {
  private elementRef = inject(ElementRef);
  private scrollDispatcher = inject(ScrollDispatcher);
  private ngZone = inject(NgZone);
  private breakpointObserver = inject(BreakpointObserver);
  private element = this.elementRef.nativeElement;

  private subscription!: Subscription;

  @ViewChild('ruler') private rulerRef!: ElementRef;
  @ViewChild('container') private containerRef!: ElementRef;

  public diameter$: Observable<number>;
  public strokeWidth$: Observable<number>;

  constructor() {
    const breakpoint$ = this.breakpointObserver.observe([Breakpoints.Handset]).pipe(distinctUntilChanged(), share());
    this.diameter$ = breakpoint$.pipe(map((device) => (device.matches ? 50 : 80)));
    this.strokeWidth$ = breakpoint$.pipe(map((device) => (device.matches ? 5 : 8)));
  }

  async ngAfterViewInit(): Promise<void> {
    const dialog = this.element.closest('.mat-dialog-container');
    if (dialog) return;

    await Promise.resolve();
    this.subscribeToIntersectAndScroll();
  }

  private subscribeToIntersectAndScroll() {
    const scrolled$ = this.scrollDispatcher.scrolled(0).pipe(startWith(undefined));

    const scrollers = this.scrollDispatcher.getAncestorScrollContainers(this.element);
    const scrollContainer = last(scrollers)?.getElementRef().nativeElement;

    const ruler = this.rulerRef.nativeElement;
    const container = this.containerRef.nativeElement;

    const ratios = [];
    const bounds = ruler.getBoundingClientRect();
    const height = bounds.height;

    for (let i = 1; i <= height; i++) {
      ratios.push(i / height);
    }

    const intersection$ = getIntersectionObserver(ruler, {
      root: scrollContainer,
      threshold: ratios
    }).pipe(map(last), filter(isDefined));

    let frameRequested = false;

    this.subscription = combineLatest([scrolled$, intersection$])
      .pipe(filter(() => !frameRequested))
      .subscribe(([, entry]) => {
        window.requestAnimationFrame(() => {
          this.ngZone.runOutsideAngular(() => {
            const bounds = ruler.getBoundingClientRect();
            const intersectionRect = entry.intersectionRect;
            const left = entry.intersectionRect.x - bounds.x;
            const top = entry.intersection.top ? intersectionRect.y - bounds.y : 0;

            container.style.left = `${left}px`;
            container.style.top = `${top}px`;
            container.style.height = `${entry.intersectionRect.height}px`;
          });
          frameRequested = false;
        });
        frameRequested = true;
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}

function last<T>(array: Array<T>): T | null {
  const len = array?.length ?? 0;
  return len > 0 ? array[len - 1] : null;
}

type Intersection = { top: boolean; right: boolean; bottom: boolean; left: boolean };
type IntersectionObserverEntryEx = IntersectionObserverEntry & { intersection: Intersection };

function getIntersectionObserver(
  target: HTMLElement,
  options: IntersectionObserverInit
): Observable<IntersectionObserverEntryEx[]> {
  return new Observable(function subscribe(subscriber) {
    const callback: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
      const entriesEx = entries.map((entry: IntersectionObserverEntry) => {
        const { boundingClientRect, intersectionRect } = entry;
        const intersection: Intersection = {
          top: boundingClientRect.top < intersectionRect.top,
          right: boundingClientRect.right > intersectionRect.right,
          bottom: boundingClientRect.bottom > intersectionRect.bottom,
          left: boundingClientRect.left < intersectionRect.left
        };
        (entry as IntersectionObserverEntryEx)['intersection'] = intersection;
        return entry as IntersectionObserverEntryEx;
      });
      subscriber.next(entriesEx);
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(target);

    return () => {
      observer.disconnect();
    };
  });
}
