import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { EngagementService } from '../../shared/services/engagement.service';
import { EngagementModel } from '../models/engagement.model';

@Component({
  template: `
    <eid-thrive-desktop *eidBreakpoint="'desktop'"></eid-thrive-desktop>
    <eid-thrive-mobile *eidBreakpoint="'mobile'"></eid-thrive-mobile>
  `
})
export class ThriveContainerComponent {}

@Component({
  template: ''
})
export class ThriveComponent {
  public engagement$: Observable<EngagementModel>;
  public date: Date;

  constructor(private engagementService: EngagementService) {
    this.engagement$ = this.engagementService.getEngagementData();
    this.date = new Date();
    this.date.setMonth(this.date.getMonth() - 1);
  }
}
