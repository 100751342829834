import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FundedAccountsComponent } from './funded-accounts.component';
@UntilDestroy()
@Component({
  selector: 'eid-funded-accounts-desktop',
  templateUrl: './funded-accounts.desktop.component.html',
  styleUrls: ['./funded-accounts.desktop.component.scss']
})
export class FundedAccountsDesktopComponent extends FundedAccountsComponent {}
