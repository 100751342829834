<div class="section">
  <div class="scrollableContent">
    <div class="tw-inline-block tw-w-full tw-p-2.5 tw-text-center tw-my-0 tw-mx-auto">
      <div
        class=" product-content-item--{{
          product.name | lowercase
        }} tw-inline-flex tw-w-[100px] tw-flex-col tw-justify-start tw-items-center tw-cursor-pointer tw-h-[100px] tw-min-w-[100px] tw-p-2.5"
        *ngFor="let product of products$ | async; let i = index"
      >
        <a [href]="product.redirectUrl" [routerLinkActive]="'active'" class="nav-item nav-item--{{ i + 1 }}">
          <div class="icon-bg">
            <mat-icon class="product-icon" *ngIf="product.icon" mat-list-icon [svgIcon]="product.icon"></mat-icon>
          </div>
        </a>
        <div class="product-title tw-font-primary tw-font-semibold tw-text-xs/5 tw-text-[#4a4a4a] tw-mt-1.5">
          {{ product.name }}
        </div>
      </div>
    </div>
  </div>
</div>
