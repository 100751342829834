import { Component, HostBinding } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { UrlBundle } from '../../../../shared/models/urlBundle';
import { UrlProviderService } from '../../../../shared/services/url.provider';

@UntilDestroy()
@Component({
  selector: 'eid-footer-satrix',
  templateUrl: './footer-satrix.component.html',
  styleUrls: ['./footer-satrix.component.scss']
})
export class FooterSatrixComponent {
  @HostBinding('attr.class') private layoutCls = 'eid-satrix-footer';
  public urlBundle$: Observable<UrlBundle>;

  constructor(private urlProviderService: UrlProviderService) {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
  }

  public goto(url: string): void {
    this.urlBundle$.pipe(untilDestroyed(this)).subscribe((urlBundle) => {
      Object.keys(urlBundle).forEach((key) => {
        if (key === url) {
          window.open(urlBundle[key], '_blank');
        }
      });
    });
  }
}
