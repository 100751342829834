import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
  template: `
    <eid-blur-desktop *eidBreakpoint="'desktop'"></eid-blur-desktop>
    <eid-blur-mobile *eidBreakpoint="'mobile'"></eid-blur-mobile>
  `
})
export class BlurContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class BlurComponent implements OnChanges {
  @Input() public blurred: boolean = false;
  @Input() public isModal: boolean = false;

  @Output() public blur: EventEmitter<boolean> = new EventEmitter();

  public checked = false;
  public modal = false;

  tooltipText =
    'Turning on "Hide values" blurs the total portfolio amount. If you opt to share this page, the blurred values will not be visible in the image you share.';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.checked = this.blurred;
      this.modal = this.isModal;
    }
  }

  public change($event) {
    this.blur.emit($event.checked);
  }
}
