export class ValidatorPattern {
  public static username = /^[a-zA-Z0-9\.\-\;\:\'_\,]*$/;
  public static email =
    /^\s*([a-zA-Z0-9_'\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,63}|[0-9]{1,3})\s*$/;
  public static password = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
  public static referral = /^[Ee][Ee][0-9]{5,7}$/;
  public static satrixReferral = /^[Ss][Xx][0-9]{5,7}$/;

  // eslint-disable-next-line max-len
  public static idNumberSA =
    /(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;
  public static initials = /^$|^[a-zA-Z]+$/;
  public static firstName = /^[a-zA-ZÀ-ÿ\.\s\-\']*$/;
  public static lastName = /^[a-zA-ZÀ-ÿ\.\s\-\']*$/;
  // For mobile number validations - check MobileValidator

  public static addressName = /^[a-zA-Z0-9\.\s\-\;\:\'_\,\\\/]*$/;
  public static addressNumber = /^[a-zA-Z0-9\.\s\-\;\:\'_\,\\\/]*$/;

  public static unitNumber = /^[a-zA-Z0-9]*$/;
  public static complexName = /^[a-zA-Z\.\s\-\;\:\'_\,\\\/]*$/;

  public static suburb = /^[a-zA-Z0-9\.\s\-\;\:\'_\,\\\/]*$/;
  public static city = /^[a-zA-Z\.\s\-\;\:\'_\,\\\/]*$/;
  public static code = /^[0-9]*$/;
  public static minimalcode = /^[a-zA-Z0-9\\-\s]*$/;

  public static tax = /^[01239](.*)$/;
  public static taxNumbers = /^[a-zA-Z0-9\\-\s]*$/;

  public static accountNumber = /^[a-zA-Z0-9\\-]*$/;
  public static branchCode = /^[0-9]{3}-[0-9]{3}$/;

  public static clanId = /^[a-zA-Z0-9]*$/;

  public static spaces = /\s/;
  public static numeric = /^[0-9]*$/;
  public static startEnd = /^[a-zA-Z0-9].*[a-zA-Z0-9]$/;
  public static startEndNames = /^[a-zA-ZÀ-ÿ].*[a-zA-ZÀ-ÿ]$/;
  public static startNames = /^[a-zA-ZÀ-ÿ]*$/;
  public static atsign = /@/;
  public static startEndSpaces = /^[^\s]+(\s+[^\s]+)*$/;
  public static multiSpaces = /^.*\s[a-zA-ZÀ-ÿ]\s[a-zA-ZÀ-ÿ]\s[a-zA-ZÀ-ÿ].*$/;
  public static blockedUnicode = /[’‘×]/;

  public static redirectUrl = /(redirectUrl=)(.*)/i;
  public static bannedList = /(123456)|(password)|(qwerty)|(iloveyou)|(abc123)/i;

  public static emoji = /^\p{Extended_Pictographic}/u;
}
