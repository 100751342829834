import { Component, ElementRef, Injector, ViewChild } from '@angular/core';

import { MatMenu } from '@angular/material/menu';
import { CurrentUserComponent } from './current-user.component';

@Component({
  selector: 'eid-current-user-desktop',
  templateUrl: './current-user.desktop.component.html',
  styleUrls: ['./current-user.desktop.component.scss']
})
export class CurrentUserDesktopComponent extends CurrentUserComponent {
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  @ViewChild('menuButton', { static: true }) menuButton: ElementRef;

  constructor(protected injector: Injector) {
    super(injector);
  }
}
