<form [formGroup]="crsTaxForm" class="mobile-page-container">
  <div class="col col--full-width">
    <div
      *ngIf="
        productDetail.complianceCategory === complianceCategories.combined ||
        residenceCountryId !== countryId.southAfrica
      "
      class="eid-input-control-group__title"
    >
      Are you registered for tax purposes?<span class="text--required__default"> *</span>
    </div>
    <div
      *ngIf="
        productDetail.complianceCategory !== complianceCategories.combined &&
        residenceCountryId === countryId.southAfrica
      "
      class="eid-input-control-group__title"
    >
      Do you have any tax obligations <span class="text--underline bolder">outside</span> of South Africa?<span
        class="text--required"
        >*</span
      >
    </div>
    <div class="eid-input-control-group">
      <div class="eid-radio-mobile">
        <input type="radio" formControlName="taxRegistered" id="registered-yes" [value]="true" />
        <label
          [ngClass]="{
            'validation-error-mobile': fieldInvalid(crsTaxForm.controls.taxRegistered),
            'validation-success-mobile': fieldRadioValid(crsTaxForm.controls.taxRegistered, true)
          }"
          for="registered-yes"
        >
          <span class="eid-radio-mobile__label">Yes</span>
          <mat-icon>check</mat-icon>
        </label>
      </div>
      <div class="divider"></div>
      <div class="eid-radio-mobile">
        <input type="radio" formControlName="taxRegistered" id="registered-no" [value]="false" />
        <label
          [ngClass]="{
            'validation-error-mobile': fieldInvalid(crsTaxForm.controls.taxRegistered),
            'validation-success-mobile': fieldRadioValid(crsTaxForm.controls.taxRegistered, false)
          }"
          for="registered-no"
        >
          <span class="eid-radio-mobile__label">No</span>
          <mat-icon>check</mat-icon>
        </label>
      </div>
    </div>
  </div>
  <!-- ADD REASON FOR NOT HAVING A TAX NUMBER-->
  <div
    *ngIf="(taxRegistered$ | async) === false && residenceCountryId !== countryId.southAfrica"
    class="eid-input-control-group eid-input-control-group--spacing-top"
  >
    <mat-form-field
      [ngClass]="{
        'validation-error-mobile': fieldInvalid(crsTaxForm.controls.noDataReason),
        'validation-success-mobile': fieldValid(crsTaxForm.controls.noDataReason)
      }"
      class="mat-form-field--mobile"
      [floatLabel]="'auto'"
    >
      <div class="custom-field custom-field--label">
        <mat-label
          >Reason
          <span aria-hidden="true" class="text--required__default"> *</span>
        </mat-label>
      </div>
      <div class="custom-field custom-field--input">
        <input
          class="col-md"
          matInput
          [formControlName]="'noDataReason'"
          required
          type="search"
          autocomplete="off"
          data-lpignore="true"
        />
      </div>
    </mat-form-field>
    <div class="validation-group">
      <div class="validation-message-mobile" *ngIf="fieldTypeError(crsTaxForm.controls.noDataReason, 'required')">
        Please enter your reason for not being tax registered.
      </div>
      <div class="validation-message-mobile" *ngIf="fieldTypeError(crsTaxForm.controls.noDataReason, 'pattern')">
        Reason for not being tax registered cannot start or end with a space.
      </div>
    </div>
  </div>

  <div *ngIf="(taxRegistered$ | async) === true" class="mobile-page-container">
    <div class="section section--padding">
      <h3 class="heading">TAX RESIDENCES</h3>

      <div class="row row--justify-space-between" *ngFor="let tax of taxResidences$ | async">
        <div class="col">
          <div class="row row--no-side-padding">
            <div class="col">
              <div class="text text--bolder">{{ tax.countryDescription }}</div>
              <div class="text">{{ tax.taxIdentificationTypeDescription }}</div>
            </div>
            <div *ngIf="tax.taxIdentificationNumber !== ''" class="text text--overflow-right">
              {{ tax.taxIdentificationNumber }}
            </div>
            <div *ngIf="tax.taxNoNumberReason !== ''" class="text overflow-right">{{ tax.taxNoNumberReason }}</div>
          </div>
          <div class="row row--no-padding">
            <div class="divider divider--full-width"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ADD TAX RESIDENCE -->
  <div *ngIf="(taxRegistered$ | async) === true" class="col col--add-residence">
    <div class="row row--align-left">
      <p
        *ngIf="
          productDetail.complianceCategory !== complianceCategories.combined &&
          residenceCountryId === countryId.southAfrica
        "
        class="par-text par-text--bolder"
      >
        Please add all jurisdictions <span class="text--underline">outside</span> of South Africa in which you are a tax
        resident and/or required to pay tax.
      </p>
      <p
        *ngIf="
          productDetail.complianceCategory === complianceCategories.combined ||
          residenceCountryId !== countryId.southAfrica
        "
        class="par-text par-text--bolder"
      >
        Please add all jurisdictions in which you are a tax resident and/or required to pay tax.
      </p>
    </div>
  </div>
  <div *ngIf="(taxRegistered$ | async) === true" class="eid-input-control-group pl-1">
    <div class="row row--justify-space-between row--align-center">
      <button type="button" class="btn-icon" (click)="showTaxForm()">
        <div class="icon">
          <mat-icon>add</mat-icon>
        </div>
        <span class="label">Add Tax Residence</span>
      </button>
    </div>
  </div>
</form>
