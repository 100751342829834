import { ApiTokenModel } from '../shared/models/api-token.model';

export class Authenticate {
  static readonly type = '[Authentication] Authenticate';
}

export class UpdateApiToken {
  static readonly type = '[Authentication] Update Token';
  constructor(public payload: ApiTokenModel) {}
}

export class UpdateUrl {
  static readonly type = '[Authentication] Update Incoming URL';
  constructor(public payload: string) {}
}

export class UpdateIsLoggedIn {
  static readonly type = '[Authentication] Update IsLoggedIn';
  constructor(public payload: boolean) {}
}

export class UpdateInitialize {
  static readonly type = '[Authentication] Update IsBusyInitializing';
  constructor(public payload: boolean) {}
}

export class UpdateErrorRequests {
  static readonly type = '[Authentication] Update ErrorRequests';
  constructor(public payload: string[]) {}
}
