import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { EngagementService } from '../../shared/services/engagement.service';

@Component({
  template: `
    <eid-deposits-value-desktop *eidBreakpoint="'desktop'"></eid-deposits-value-desktop>
    <eid-deposits-value-mobile *eidBreakpoint="'mobile'"></eid-deposits-value-mobile>
  `
})
export class DepositsValueContainerComponent {}

@UntilDestroy()
@Component({
  template: ''
})
export class DepositsValueComponent {
  public depositPercentageToPortfolio: number = 0;
  public date: Date;

  constructor(private engagementService: EngagementService) {
    this.date = new Date();
    this.date.setMonth(this.date.getMonth() - 1);

    this.engagementService
      .getEngagementData()
      .pipe(
        map((data) => {
          if (data?.portfolioValue > 0) {
            this.depositPercentageToPortfolio = parseFloat(
              parseFloat('' + (data.userPreviousMonthDepositValue / data.portfolioValue) * 100).toFixed(2)
            );
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
