import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UICustomizationOptions, init } from 'onfido-sdk-ui';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import {
  CreateApplicantRequest,
  CreateCheckRequest,
  GenerateTokenRequest
} from '../../registration/models/onfido-request.model';
import {
  CreateApplicantResponse,
  CreateCheckResponse,
  GenerateTokenResponse,
  RetrieveCheckResponse
} from '../../registration/models/onfido.response.model';
import { Progress } from '../models/jumio.model';
import { OnfidoConfig, OnfidoDetail } from '../models/onfido.model';
import { RegisterStoreService } from './register-store.service';

@UntilDestroy()
@Injectable()
export class OnfidoService {
  constructor(private httpClient: HttpClient, private registerStoreService: RegisterStoreService) {}

  public createApplicant(request: CreateApplicantRequest): Observable<CreateApplicantResponse> {
    return this.httpClient.post<CreateApplicantResponse>('/api/onfido/applicant', request);
  }

  public generateToken(request: GenerateTokenRequest): Observable<GenerateTokenResponse> {
    return this.httpClient.post<GenerateTokenResponse>('/api/onfido/generatetoken', request);
  }

  public createCheck(request: CreateCheckRequest): Observable<CreateCheckResponse> {
    return this.httpClient.post<CreateCheckResponse>('/api/onfido/checks', request);
  }

  public retrieveCheck(checkId: string): Observable<RetrieveCheckResponse> {
    return this.httpClient.get<RetrieveCheckResponse>('/api/onfido/checks/' + { checkId });
  }

  public getApplicantRequest(): CreateApplicantRequest {
    const registrationId = this.registerStoreService.getRegistrationId();
    const { email } = this.registerStoreService.getUser();
    const { firstName, lastName, dob, idSourceCountry } = this.registerStoreService.getPersonalDetail();
    const country = this.registerStoreService.getSelectedCountry();
    const address = this.registerStoreService.getAddress();

    return {
      registrationId,
      dob,
      firstName,
      email,
      lastName,
      address: {
        country: country.residence.countryCode3,
        postcode: address.code,
        street: address.streetName,
        town: address.city
      }
    };
  }

  public initiateOnfido(
    { applicantId, token, country, enablePassport, enableDrivingLicence, enableNationalIdentityCard }: OnfidoConfig,
    onComplete = () => {},
    customUI: UICustomizationOptions = null
  ) {
    return init({
      token,
      containerId: 'onfido-mount',

      onComplete: () => {
        this.createCheck({
          applicantId,
          reportNames: ['document', 'facial_similarity_photo']
        })
          .pipe(
            switchMap((checkResponse) => this.updateOnfidoDetail({ applicantId, checkId: checkResponse.id })),
            first(),
            untilDestroyed(this)
          )
          .subscribe({
            next: onComplete,
            error: () => {}
          });
      },
      customUI,
      onError: (e) => {},
      steps: [
        {
          type: 'document',
          options: {
            documentTypes: {
              passport: enablePassport ? true : false,
              driving_licence: enableDrivingLicence ? { country } : false,
              national_identity_card: enableNationalIdentityCard ? { country } : false
            }
          }
        },
        'face',
        'complete'
      ]
    });
  }

  addNewOnfidoDetails(idType: string, applicantId: string) {
    const state = this.registerStoreService.getRegistrationStore();
    const { registrationId, personalDetail, onfidoDetails } = state;
    const existing = onfidoDetails?.find(
      (detail) => detail.applicantId === applicantId && detail.country === personalDetail.idSourceCountry
    );

    if (existing) {
      return of(state);
    }
    const updatedOnfidoDetails = [
      ...(onfidoDetails || []),
      {
        applicantId,
        registrationId,
        idType,
        country: personalDetail.idSourceCountry,
        progress: Progress.none,
        timestamp: '',
        checkId: ''
      }
    ];
    return this.registerStoreService.updateOnfidoDetail(updatedOnfidoDetails);
  }

  private updateOnfidoDetail({ applicantId, ...propsToUpdate }: Partial<OnfidoDetail>) {
    if (!applicantId) {
      throw new Error('Error updating onfido detail. No ApplicantId provided');
    }
    const registrationStore = this.registerStoreService.getRegistrationStore();
    const updatedDetails = [
      ...registrationStore.onfidoDetails.map((detail) => {
        if (detail.applicantId === applicantId && detail.country === registrationStore.personalDetail.idSourceCountry) {
          return { ...detail, ...propsToUpdate };
        }
        return detail;
      })
    ];
    return this.registerStoreService.updateOnfidoDetail(updatedDetails);
  }
}
