import { Component, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { SetLayout } from '../../layout/layout.actions';
import { LayoutState, LayoutStateModel } from '../../layout/layout.state';
import { HeaderAction } from '../../layout/models/header-action.model';
import { ActivityStatsMobileComponent } from '../activity-stats/activity-stats.mobile.component';
import { AccountActivityComponent } from './account-activity.component';

@UntilDestroy()
@Component({
  selector: 'eid-account-activity-mobile',
  templateUrl: './account-activity.mobile.component.html',
  styleUrls: ['./account-activity.mobile.component.scss']
})
export class AccountActivityMobileComponent extends AccountActivityComponent {
  private dialog: MatDialog;
  private store: Store;

  private isDialog: boolean;
  private pageTitle: string;
  private actionLeft: HeaderAction;
  private actionRight: HeaderAction;

  constructor(protected injector: Injector) {
    super(injector);
    this.dialog = injector.get(MatDialog);
    this.store = injector.get(Store);
  }

  public accountActivity() {
    this.saveHeaderOriginalState();
    const dialog = this.dialog.open(ActivityStatsMobileComponent, {
      maxWidth: '100vw',
      panelClass: 'custom-selector-overlay',
      hasBackdrop: false
    });

    dialog
      .afterOpened()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.dialogLayout(dialog, 'Account Activity');
      });

    dialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetLayout();
      });
  }

  public dialogLayout(dialog, description) {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: true,
      pageTitle: description,
      actionLeft: new HeaderAction(undefined, 'chevron-left', null, () => {
        dialog.close();
      })
    };
    this.store.dispatch(new SetLayout(layoutState));
  }

  public resetLayout() {
    const layoutState: Partial<LayoutStateModel> = {
      isDialog: this.isDialog,
      pageTitle: this.pageTitle,
      actionLeft: this.actionLeft,
      actionRight: this.actionRight
    };
    this.store.dispatch(new SetLayout(layoutState));
  }

  private saveHeaderOriginalState(): void {
    this.isDialog = this.store.selectSnapshot(LayoutState.getIsDialog);
    this.pageTitle = this.store.selectSnapshot(LayoutState.getPageTitle);
    this.actionLeft = this.store.selectSnapshot(LayoutState.getLeftAction);
    this.actionRight = this.store.selectSnapshot(LayoutState.getRightAction);
  }
}
