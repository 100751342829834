import { Component, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { ProductType } from '../../registration/models/product.model';
import { ProfileType } from '../../shared/models/profile.model';
import { UrlBundle } from '../../shared/models/urlBundle';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { RegisterStoreService } from '../../shared/services/register-store.service';
import { UrlProviderService } from '../../shared/services/url.provider';

@Component({
  template: `
    <eid-profile-type-desktop *eidBreakpoint="'desktop'"></eid-profile-type-desktop>
    <eid-profile-type-mobile *eidBreakpoint="'mobile'"></eid-profile-type-mobile>
  `
})
export class ProfileTypeContainerComponent {}

@UntilDestroy()
@Component({ template: '' })
export class ProfileTypeComponent {
  public isSatrix$: Observable<boolean>;

  public profileTypes = ProfileType;
  public urlBundle$: Observable<UrlBundle>;

  protected urlProviderService: UrlProviderService;
  protected authenticationService: AuthenticationService;
  protected registerStoreService: RegisterStoreService;
  protected router: Router;
  protected dialog: MatDialog;
  protected store: Store;

  constructor(protected injector: Injector) {
    this.createServices(injector);
    this.removeProductSelection();
    const url = window.location.href;
    if (url.indexOf('satrix') > -1) {
      this.isSatrix$ = of(true);
    } else {
      this.isSatrix$ = of(false);
    }

    this.urlBundle$ = this.urlProviderService.urlBundle$;
  }

  private createServices(injector: Injector) {
    this.urlProviderService = injector.get(UrlProviderService);
    this.authenticationService = injector.get(AuthenticationService);
    this.registerStoreService = injector.get(RegisterStoreService);
    this.router = injector.get(Router);
    this.dialog = injector.get(MatDialog);
    this.store = injector.get(Store);
  }

  removeProductSelection() {
    this.registerStoreService.updateProduct(ProductType.none).pipe(take(1), untilDestroyed(this)).subscribe();
  }

  public login() {
    this.authenticationService.authorizeUser();
  }

  public selectProfileAdult(): void {
    this.registerStoreService
      .updateProfileType(ProfileType.adult.toString())
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const url = window.location.href;
        let productType = this.registerStoreService.getProduct();
        let incomingProductType = this.registerStoreService.getIncomingProduct();
        if (url.indexOf('satrix') > -1) {
          productType = ProductType.satrix;
          incomingProductType = ProductType.satrix;
        }

        if (incomingProductType === ProductType.none) {
          this.router.navigate(['/product']);
        } else if (
          this.registerStoreService.isV1Product(productType) ||
          this.authenticationService.getTokenModel().isLoggedIn
        ) {
          this.router.navigate(['/register/country']);
        } else {
          this.authenticationService.registerUser();
        }
      });
  }

  public navigateToMinorRegistration(): void {
    this.urlProviderService.urlBundle$.pipe(untilDestroyed(this)).subscribe((urlBundle) => {
      window.location.href = urlBundle.minorRegistration;
    });
  }

  public navigateToBusinessEntity(): void {
    this.urlProviderService.urlBundle$.pipe(untilDestroyed(this)).subscribe((urlBundle) => {
      window.location.href = urlBundle.entityRegistration;
    });
  }
}
