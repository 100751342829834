import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendar, MatCalendarView } from '@angular/material/datepicker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MyMatDateFormats } from '../../constants/date-formats';

@UntilDestroy()
@Component({
  selector: 'eid-datepicker-header',
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerHeaderComponent<D> {
  constructor(
    public calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MyMatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    calendar.stateChanges.pipe(untilDestroyed(this)).subscribe(() => cdr.markForCheck());
  }

  get yearLabel() {
    return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.yearLabel).toLocaleUpperCase();
  }

  get monthLabel() {
    return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthLabel).toLocaleUpperCase();
  }

  get dayLabel() {
    return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.dayLabel).toLocaleUpperCase();
  }

  get canShowChevrons(): boolean {
    return this.calendar.currentView === 'multi-year';
  }

  public changeView(mode: MatCalendarView): void {
    this.calendar.currentView = mode;
  }

  public previousYear(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, -24);
  }
  public nextYear(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, 24);
  }
}
