<!-- Registration error -->
<div class="section" *ngIf="productDetail.styleTheme !== themes.gcash">
  <div class="container">
    <div class="row row--justify-center">
      <div class="panel complete-panel complete-panel--further-info mt-4">
        <div class="complete-container">
          <div class="verification-col">
            <div class="verification-col--icon col message__icon">
              <!-- <img [src]="iconPath + 'failed.svg'" width="120" height="120" /> -->
              <img class="theme-icon-failed" width="120" height="120" />
            </div>
            <div class="verification-col--message message__text">
              <h2>Registration Error</h2>
              <p>Unfortunately an error occurred while trying to create your account.</p>

              <p>
                Don’t worry, there’s nothing you need to do! We’ll automatically retry your application. This usually
                takes up to 2 hours.
              </p>

              <p>
                We'll email you once verification has been completed or you can
                <a target="_blank" [href]="(urlBundle$ | async)?.contact">contact us.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<eid-gcash-error *ngIf="productDetail.styleTheme === themes.gcash" errorType="registration"></eid-gcash-error>
