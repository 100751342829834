import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RedirectData } from '../models/jumio.model';

@Injectable()
export class JumioService {
  constructor(private httpClient: HttpClient) {}

  public getRedirectData(data): Observable<RedirectData> {
    return this.httpClient.post<RedirectData>('/api/jumio/redirectdata', data);
  }
}
