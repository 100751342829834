import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { PartnerCategory, ProductType } from '../registration/models/product.model';
import { TenantIdEnum } from '../shared/constants/enums';
import { ProfileType } from '../shared/models/profile.model';
import { UrlBundle } from '../shared/models/urlBundle';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ProductService } from '../shared/services/product.service';
import { RegisterStoreService } from '../shared/services/register-store.service';
import { UrlProviderService } from '../shared/services/url.provider';
import { UserStoreService } from '../shared/services/user-store.service';
import { GuardService } from './guard.service';

@Injectable()
@UntilDestroy()
export class LoggedInGuard implements CanActivate {
  public urlBundle$: Observable<UrlBundle>;

  constructor(
    private router: Router,
    private userStoreService: UserStoreService,
    private authenticationService: AuthenticationService,
    private registerStoreService: RegisterStoreService,
    private guardService: GuardService,
    private productService: ProductService,
    private urlProviderService: UrlProviderService
  ) {
    this.urlBundle$ = this.urlProviderService.urlBundle$;
  }

  canActivate() {
    //User must be logged in
    //GCash not allowed to see profile section
    //User must be fully registered, thus have sub and userid.
    if (this.registerStoreService.getProfileType() !== ProfileType.minor) {
      this.registerStoreService.clearRegistrationStore();
    }

    if (!this.guardService.isInitialized()) {
      return false;
    }

    const user = this.userStoreService.getUser();
    const productDetail = this.productService.getProductDetail(user.productType);

    if (user.sub === '') {
      this.authenticationService.authorizeUser();
      return false;
    }

    if (user.productType === ProductType.satrix) {
      this.urlBundle$.pipe(untilDestroyed(this)).subscribe((urlBundle) => {
        window.location.href = urlBundle.login;
      });
      return false;
    }

    if (user.tenantId === TenantIdEnum.gcash || productDetail.partnerCategory === PartnerCategory.full) {
      this.router.navigate(['/game']);
      return false;
    }

    if (user.userId === '') {
      this.router.navigate(['/register/country']);
      return false;
    }

    return true;
  }
}
