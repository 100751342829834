<div class="section">
  <div class="container">
    <div class="row row--justify-center">
      <div class="page-title">
        <h1>Select the product you would like to sign up for</h1>
        <p *ngIf="(productType$ | async) === 'satrix'" class="page-subtitle">
          SatrixNOW is your one username shared across all your SatrixNOW services. You can add additional products
          later.
        </p>
        <p *ngIf="(productType$ | async) !== 'satrix'" class="page-subtitle">
          EasyID is your one username shared across all your EasyEquities services. You can add additional products
          later.
        </p>
      </div>
    </div>

    <div class="row products">
      <div
        *ngFor="let product of products$ | async"
        (click)="selectProduct(product.product)"
        class="product card product--{{ product.product }}"
      >
        <img [src]="product.image" class="product__image" />
        <div class="product__name">{{ product.name }}</div>
        <p class="product__description">{{ product.description }}</p>
        <div class="product__logo">
          <img [src]="product.logo" />
        </div>
      </div>
    </div>
  </div>
</div>
