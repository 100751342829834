<div class="intro-box">
  <div class="column column--image"></div>
  <div class="column column--content">
    <div class="intro-text">
      <h2>Start investing with your pocket change!</h2>
      <p>
        Begin your investment journey with any amount in fractional shares. Empowering all to grow wealth, one fraction
        at a time!
      </p>
    </div>

    <div class="intro-step">
      <eid-intro-stepper [active]="3" (stepSelected)="stepSelected($event)"></eid-intro-stepper>
      <div class="close-text">
        <div (mousedown)="close()" class="skip-btn">
          <span class="text">Skip Intro</span>
        </div>
      </div>
    </div>
  </div>
</div>
