import { Component, ViewEncapsulation } from '@angular/core';
import { CalendarComponent } from './calendar.component';

@Component({
  selector: 'eid-calendar-mobile',
  templateUrl: './calendar.mobile.component.html',
  styleUrls: ['./calendar.mobile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarMobileComponent extends CalendarComponent {}
